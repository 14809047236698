import React from "react";

const MisthosiRow = ({
  handleMisthosiInputChange,
  handleMisthomaChange,
  misthosi,
}) => {
  const { misthoma = "" } = misthosi;
  return (
    <div className="all">
      <div className="vertical-line-focus"></div>
      <div className="form-section-row misthosi-row">
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="misthoma">
            Μίσθωμα
          </label>
          <input
            className="form-section-input"
            type="text"
            id="misthoma"
            placeholder="πχ. 300"
            name="misthoma"
            value={misthosi.misthoma}
            onChange={(e) => {
              handleMisthomaChange(e);
              handleMisthosiInputChange(e);
            }}
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="topos_symvasis">
            Τόπος Σύμβασης
          </label>
          <input
            className="form-section-input"
            type="text"
            id="topos_symvasis"
            placeholder="πχ. Αθήνα"
            name="topos_symvasis"
            value={misthosi.topos_symvasis}
            onChange={handleMisthosiInputChange}
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="imnia_ypografis">
            Ημερομηνία Υπογραφής
          </label>
          <input
            className="form-section-input"
            type="date"
            id="imnia_ypografis"
            name="imnia_ypografis"
            value={misthosi.imnia_ypografis}
            onChange={handleMisthosiInputChange}
          />
        </div>
        {/* Διάρκεια input */}
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="diarkeia">
            Διάρκεια (έτη)
          </label>
          <input
            className="form-section-input"
            type="number"
            id="diarkeia"
            placeholder="πχ. 12"
            name="diarkeia"
            value={misthosi.diarkeia}
            onChange={handleMisthosiInputChange}
          />
        </div>

        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="enarxi">
            Έναρξη
          </label>
          <input
            className="form-section-input"
            type="date"
            id="enarxi"
            name="enarxi"
            value={misthosi.enarxi}
            onChange={handleMisthosiInputChange}
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="lixi">
            Λήξη
          </label>
          <input
            className="form-section-input"
            type="date"
            id="lixi"
            name="lixi"
            value={misthosi.lixi}
            onChange={handleMisthosiInputChange}
          />
        </div>
        <div className="break"></div>
        <div className="anaprosarmoges">
          <div className="form-section-single-input">
            <label className="form-section-label" htmlFor="anaprosarmogi">
              Αναπροσαρμογή Μισθώματος
            </label>
            <input
              className="form-section-input"
              type="checkbox"
              id="anaprosarmogi"
              name="anaprosarmogi"
              checked={misthosi.anaprosarmogi}
              onChange={handleMisthosiInputChange}
            />
          </div>
          {misthosi.anaprosarmogi && (
            <div className="form-section-single-input">
              <label className="form-section-label" htmlFor="timarithmiki">
                Βάσει Τιμαρίθμου
              </label>
              <input
                className="form-section-input"
                type="checkbox"
                id="timarithmiki"
                name="timarithmiki"
                checked={misthosi.timarithmiki}
                onChange={handleMisthosiInputChange}
              />
            </div>
          )}
          {misthosi.anaprosarmogi && !misthosi.timarithmiki && (
            <div className="form-section-single-input">
              <label className="form-section-label" htmlFor="stathero_epitokio">
                Σταθερό Επιτόκιο
              </label>
              <input
                className="form-section-input"
                type="number"
                id="stathero_epitokio"
                name="stathero_epitokio"
                value={misthosi.stathero_epitokio}
                onChange={handleMisthosiInputChange}
              />
            </div>
          )}
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="eggyisi">
            Εγγύηση (μισθώματα)
          </label>
          <input
            className="form-section-input"
            type="number"
            id="eggyisi"
            placeholder="πχ. 2"
            name="eggyisi"
            value={misthosi.eggyisi}
            onChange={handleMisthosiInputChange}
          />
        </div>
        <div className="break"></div>
        <div className="form-section-single-input perigrafi-row">
          <label className="form-section-label" htmlFor="perigrafi">
            Περιγραφή Ακινήτου
          </label>
          <textarea
            value={misthosi.perigrafi}
            onChange={handleMisthosiInputChange}
            name="perigrafi"
            className="form-section-input triple-height"
            id="perigrafi"
            placeholder="πχ.  αυτοτελής οριζόντια ιδιοκτησία-διαμέρισμα επιφανείας 31 τετραγωνικών μέτρων,  που ευρίσκεται στον 2ο όροφο άνωθεν του ισογείου, κτιρίου κείμενου στην Αθήνα επί της οδού Στουρνάρη αριθμός 5, στην περιοχή Εξάρχεια."
          />
        </div>
      </div>
    </div>
  );
};

export default MisthosiRow;
