import React from 'react';


import UploadPhoto from './UploadPhoto';



export default function Example({ setCroppedImage }) {

  return (


    <UploadPhoto setCroppedImage={setCroppedImage} />


  );
}