import { removeDiacriticsAndCapitalize } from "../../utils/checkMisthosi";

export const dioikEfeteia = [
  "ΑΘΗΝΩΝ",
  "ΠΕΙΡΑΙΩΣ",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΙΩΑΝΝΙΝΩΝ",
  "ΚΟΜΟΤΗΝΗΣ",
  "ΛΑΡΙΣΑΣ",
  "ΠΑΤΡΩΝ",
  "ΤΡΙΠΟΛΗΣ",
  "ΧΑΝΙΩΝ",
]
  .map((dim) => removeDiacriticsAndCapitalize(dim))
  .sort();
