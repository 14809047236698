import React, { useEffect, useState } from "react";
import createReport from "docx-templates";
import fileDownload from "react-file-download";
import EkmisthotesRow from "./components/EkmisthotesRow";
import MisthotesRow from "./components/MisthotesRow";
import MisthosiRow from "./components/MisthosiRow";
import RandomChars from "../Util/RandomChars";
import "../../form.css";
import { checkIfFemale } from "../../utils/checkMisthosi";
import {
  misthosiTransform,
  transformAiMisthosiData,
} from "./utils/misthosiTransform";
import {
  getFirstDateOfNextMonth,
  addYearsToDate,
  capitalizeFirstLetter,
  getMonthRange,
  formatMonthRange,
} from "./utils/addYears";
import DiatagiRow from "./components/DiatagiRow";
import ExodikoRow from "./components/ExodikoRow";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { Bold, ProgressBar } from "@tremor/react";
const res = {
  εκμισθωτές: [
    {
      όνομα_εκμισθωτή: "Ιωάννης",
      επώνυμο_εκμισθωτή: "Μερτζάνης",
      όνομα_πατέρα__εκμισθωτή_στην_ονομαστική_κλίση: "Κωνσταντίνος",
      ΑΦΜ_εκμισθωτή: "070998837",
      εκμισθωτής_κάτοικος: "Νέας Σμύρνης, οδός Ιωνίας αρ.53",
      φύλο_εκμισθωτή_Άνδρας_ή_Γυναίκα: "Άνδρας",
    },
    {
      όνομα_εκμισθωτή: "Στυλιανός",
      επώνυμο_εκμισθωτή: "Μερτζάνης",
      όνομα_πατέρα__εκμισθωτή_στην_ονομαστική_κλίση: "Κωνσταντίνος",
      ΑΦΜ_εκμισθωτή: "01234598",
      εκμισθωτής_κάτοικος: "Πειραιά, οδός Ηρ.Πολυτεχνείου αρ.12",
      φύλο_εκμισθωτή_Άνδρας_ή_Γυναίκα: "Άνδρας",
    },
  ],
  μισθωτές: [
    {
      όνομα_μισθωτή: "Μαρία",
      επώνυμο_μισθωτή: "Μερτζάνη",
      όνομα_πατέρα_μισθωτή_στην_ονομαστική_κλίση: "Στυλιανός",
      ΑΦΜ_μισθωτή: "123456789",
      μισθωτής_κάτοικος: "Αθηνών, οδός Ομήρου αρ. 73",
      φύλο_μισθωτή_Άνδρας_ή_Γυναίκα: "Γυναίκα",
    },
  ],
  μίσθωση: {
    μίσθωμα: "300",
    διάρκεια_σε_έτη: "2",
    έναρξη: "01/06/2023",
    λήξη: "",
    περιγραφή_μισθίου:
      "Γκαρσονιέρα ισογείου ορόφου, εκτάσεως 32 τμ στη Νέα Σμύρνη στην οδό Ελ.Βενιζέλου αρ.32",
    ημνία_υπογραφής: "01/06/2023",
    τόπς_υπογραφής: "",
    αναπροσαρμογή: false,
    τιμαριθμική: false,
    σταθερό_επιτόκιο: "0",
  },
};

const downloadURL = (data, fileName) => {
  fileDownload(data, fileName);
};
function LeaseFormAi() {
  const [msg, setMsg] = React.useState("");
  const [response, setResponse] = React.useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [showDownload, setShowDownload] = React.useState(false);
  const [showSend, setShowSend] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  // function that each second increases the progress by 1
  const increase = () => {
    setProgress(progress + 1);
  };

  const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  const repeat = () => {
    setTimeout(() => {
      increase();
    }, randomNumber(100, 1000));
  };

  useEffect(() => {
    if (progress < 100 && isLoading) {
      repeat();
    }
  }, [progress, isLoading]);

  const handleSubmitMsg = async (e) => {
    e.preventDefault();
    setShowSend(false);
    setIsLoading(true);

    try {
      const response = await axiosPrivate.post(
        `/ai/`,
        JSON.stringify({
          msg: msg,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      //   wait for the response
      const data = await response.data;
      console.log(data);
      setResponse(JSON.stringify(data));
      setMisthosi(transformAiMisthosiData(data).misthosi);
      setEkmisthotes(transformAiMisthosiData(data).ekmisthotes);
      setMisthotes(transformAiMisthosiData(data).misthotes);
      setShowDownload(true);
      setProgress(100);

      setIsLoading(false);
      setProgress(0);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const misthosiPost = async (eidos) => {
    try {
      await axiosPrivate.post("/templates", JSON.stringify({ eidos }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const onTemplateChosen = async () => {
    const template = await fetch("/templates/misthosi.docx").then((res) =>
      res.arrayBuffer()
    );
    const report = await createReport({
      template,
      data: misthosiTransform({
        misthosi,
        ekmisthotes,
        misthotes,
        exodiko,
        diatagi,
      }),
    });
    downloadURL(
      report,
      `ΜΙΣΘΩΣΗ_${ekmisthotes[0].eponymo_ek}_${misthotes[0].eponymo_mi}.docx`
    );
  };

  const onTemplateChosenExodiko = async () => {
    const template = await fetch("/templates/exodiko.docx").then((res) =>
      res.arrayBuffer()
    );
    const report = await createReport({
      template,
      data: misthosiTransform({
        misthosi,
        ekmisthotes,
        misthotes,
        exodiko,
        diatagi,
      }),
    });
    downloadURL(
      report,
      `ΕΞΩΔΙΚΟ_${ekmisthotes[0].eponymo_ek}_${misthotes[0].eponymo_mi}.docx`
    );
  };
  const onTemplateChosenDiatagi = async () => {
    const template = await fetch("/templates/diatagi.docx").then((res) =>
      res.arrayBuffer()
    );
    const report = await createReport({
      template,
      data: misthosiTransform({
        misthosi,
        ekmisthotes,
        misthotes,
        exodiko,
        diatagi,
      }),
    });
    downloadURL(report, `ΔΙΑΤΑΓΗ_${ekmisthotes[0].eponymo_ek}.docx`);
  };
  const onTemplateChosenAitisi = async () => {
    const template = await fetch("/templates/aitisi.docx").then((res) =>
      res.arrayBuffer()
    );
    const report = await createReport({
      template,
      data: misthosiTransform({
        misthosi,
        ekmisthotes,
        misthotes,
        exodiko,
        diatagi,
      }),
    });
    downloadURL(
      report,
      `ΑΙΤΗΣΗ_ΑΠΟΔΟΣΗΣ_${ekmisthotes[0].eponymo_ek}_${misthotes[0].eponymo_mi}.docx`
    );
  };

  const [ekmisthotes, setEkmisthotes] = useState(
    transformAiMisthosiData(res).ekmisthotes
  );
  const [misthotes, setMisthotes] = useState(
    transformAiMisthosiData(res).misthotes
  );
  const [misthosi, setMisthosi] = useState(
    transformAiMisthosiData(res).misthosi
  );

  const [diatagi, setDiatagi] = useState({
    edra: "",
    edra_topos: "",
  });

  const [exodiko, setExodiko] = useState({
    startMonth: "",
    endMonth: "",
    ofeilomeno: "",
  });

  const addEkmisthotis = () => {
    setEkmisthotes([
      ...ekmisthotes,
      {
        onoma_ek: "",
        eponymo_ek: "",
        patronymo_ek: "",
        AFM_ek: "",
        katoikia_ek: "",
        fylo_ek: "Άνδρας",
      },
    ]);
  };

  const removeEkmisthotis = (i) => {
    const newEkmisthotes = [...ekmisthotes];
    newEkmisthotes.splice(i, 1);
    setEkmisthotes(newEkmisthotes);
  };

  const handleEkInputChange = (i, e) => {
    const { name, value } = e.target;
    const newEkmisthotes = [...ekmisthotes];
    newEkmisthotes[i][name] = capitalizeFirstLetter(value);
    setEkmisthotes(newEkmisthotes);
  };

  const handleEkFyloChange = (i, e) => {
    const { value } = e.target;
    const newEkmisthotes = [...ekmisthotes];
    newEkmisthotes[i].fylo_ek = checkIfFemale(value.trim())
      ? "Γυναίκα"
      : "Άνδρας";
    setEkmisthotes(newEkmisthotes);
  };

  const addMisthotis = () => {
    setMisthotes([
      ...misthotes,
      {
        onoma_mi: "",
        eponymo_mi: "",
        patronymo_mi: "",
        AFM_mi: "",
        katoikia_mi: "",
        fylo_mi: "Άνδρας",
      },
    ]);
  };

  const removeMisthotis = (i) => {
    const newMisthotes = [...misthotes];
    newMisthotes.splice(i, 1);
    setMisthotes(newMisthotes);
  };

  const handleMiInputChange = (i, e) => {
    const { name, value } = e.target;
    const newMisthotes = [...misthotes];
    newMisthotes[i][name] = capitalizeFirstLetter(value);
    setMisthotes(newMisthotes);
  };

  const handleMiFyloChange = (i, e) => {
    const { value } = e.target;
    const newMisthotes = [...misthotes];
    newMisthotes[i].fylo_mi = checkIfFemale(value.trim())
      ? "Γυναίκα"
      : "Άνδρας";
    setMisthotes(newMisthotes);
  };

  const handleMisthosiInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, parseFloat(value.trim()));

    if (name === "diarkeia" && value.trim() !== "") {
      setMisthosi({
        ...misthosi,
        [name]: value.trim(),
        lixi: addYearsToDate(misthosi.enarxi, parseInt(value.trim())),
      });
    } else if (name === "enarxi" && value.trim() !== "") {
      setMisthosi({
        ...misthosi,
        [name]: value.trim(),
        lixi: addYearsToDate(value.trim(), parseInt(misthosi.diarkeia)),
      });
    } else if (name === "perigrafi" && value.trim() !== "") {
      setMisthosi({ ...misthosi, [name]: capitalizeFirstLetter(value) });
    } else if (name === "anaprosarmogi") {
      setMisthosi({ ...misthosi, [name]: !misthosi.anaprosarmogi });
    } else if (name === "timarithmiki") {
      setMisthosi({ ...misthosi, [name]: !misthosi.timarithmiki });
    } else if (name === "stathero_epitokio") {
      setMisthosi({ ...misthosi, [name]: parseFloat(value.trim()) });
    } else {
      setMisthosi({ ...misthosi, [name]: value });
    }
  };

  const handleDiatagiInputChange = (e) => {
    const { name, value } = e.target;
    const newDiatagi = { ...diatagi };
    newDiatagi[name] = capitalizeFirstLetter(value);
    setDiatagi(newDiatagi);
  };

  const handleMisthomaChange = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" && parseInt(value.trim()) > 600) {
      setDiatagi({ ...diatagi, edra: "Πρωτοδικείο" });
    } else {
      setDiatagi({ ...diatagi, edra: "Ειρηνοδικείο" });
    }
    if (
      value.trim() !== "" &&
      exodiko.startMonth.trim() !== "" &&
      exodiko.endMonth.trim() !== ""
    ) {
      setExodiko({
        ...exodiko,
        ofeilomeno:
          getMonthRange(exodiko.startMonth, exodiko.endMonth).length *
          parseInt(value.trim()),
      });
    }
  };

  const handleExodikoInputChange = (e) => {
    const { name, value } = e.target;
    const newExodiko = { ...exodiko };
    newExodiko[name] = value;
    if (
      misthosi.misthoma.trim() !== "" &&
      newExodiko.startMonth.trim() !== "" &&
      newExodiko.endMonth.trim() !== "" &&
      name !== "ofeilomeno"
    ) {
      newExodiko.ofeilomeno =
        getMonthRange(newExodiko.startMonth, newExodiko.endMonth).length *
        parseInt(misthosi.misthoma.trim());
    }
    setExodiko(newExodiko);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await misthosiPost("misthosi");
    onTemplateChosen();
    setShowSend(true);
    setShowDownload(false);
    setMsg("");
  };

  const handleExodikoSubmit = async (e) => {
    e.preventDefault();
    await misthosiPost("exodiko");
    onTemplateChosenExodiko();
  };
  const handleDiatagiSubmit = async (e) => {
    e.preventDefault();
    await misthosiPost("diatagi");
    onTemplateChosenDiatagi();
  };
  const handleAitisiSubmit = async (e) => {
    e.preventDefault();
    await misthosiPost("aitisi");
    onTemplateChosenAitisi();
  };

  return (
    <div>
      <div className="create_post">
        <section className="lease-intro">
          <p>
            Εδώ μπορείτε να καταχωρήσετε μια σύντομη περιγραφή μια μίσθωσης
            κατοικίας, και η εφαρμογή θα δημιουργήσει ένα ιδιωτικό συμφωνητικό.
          </p>
          <p>
            Παίρνει γύρω στα 30 δευτερόλεπτα από την στιγμή που θα πατήσετε το
            κουμπί της Αποστολής και έπειτα{" "}
            <Bold>
              πατάτε Λήψη Μισθωτηρίου, και γίνεται λήψη του αρχείου Word.
            </Bold>
          </p>
          <p>
            Σε αυτήν την πρώιμη υλοποίηση της ιδέας{" "}
            <Bold>
              χρειάζεται να περιλάβετε στην περιγραφή κάποια απραίτητα στοιχεία
            </Bold>
            , κάποια "essentialia negotii", για να λειτουργήσει. Αυτά είναι ο
            τόπος και η ημερομηνία της μίσθωσης, το μίσθωμα και η διάρκεια της
            μίσθωσης, η ημερομηνία έναρξης, περιγραφή του μισθίου, και τα
            ονόματα, επώνυμα, πατρώνυμα, ΑΦΜ και διευθύνσεις των συμβαλλομένων.
          </p>
          <p>
            Παρατίθενται κάποια <Bold>παραδείγματα περιγραφών:</Bold>
          </p>
          <p>
            <em>
              -Υπογραφή μίσθωσης την 13.02.2023 στην Αθήνα, μεταξύ του εκμισθωτή
              Αθανάσιου Διάκου του Γεωργίου (ΑΦΜ 987654321), που μένει στον
              Πειραιά, στην οδό Σταδίου αρ. 78 και της μισθώτριας Μαρίας
              Παπαδοπούλου του Κωνσταντίνου (ΑΦΜ 123456789), κατοίκου Αθηνών.
              Μίσθιο: Διαμέρισμα του 4ου ορόφου στην Γλυφάδα, επί της οδού
              Μεταξά αρ. 34, εμβαδού 128 τ.μ. Ποσό 600 ευρώ. Διάρκεια Μίσθωσης:
              2 έτη, αρχή μίσθωσης την 1.3.2023.
            </em>
          </p>
          <p>
            <em>
              -Στην Καλαμάτα, την 27η Οκτωβρίου 2021, η Μαρία Παπαδοπούλου του
              Γεωργίου, κάτοικος Καλαμάτας οδός Κύπρου αρ. 28, με ΑΦΜ 112233445,
              εκμίσθωσε στον Χρήστο Καρρά του Ιωάννη (ΑΦΜ 33444555) και την
              Μαρία Καρρά του Μιχαήλ (ΑΦΜ 777888999), αμφότεροι κάτοικοι Νέας
              Ιωνίας, οδός Μιλήτου αρ. 33, ένα κατάστημα του ισογείου στην
              Γλυφάδα, επί της οδού Μεταξά αρ. 34, εμβαδού 128 τ.μ. Ποσό 1300
              ευρώ. Διάρκεια Μίσθωσης: 5 έτη, αρχή μίσθωσης την 1.11.2021.
            </em>
          </p>
          <p>Κάθε χρήστης έχει δικαίωμα για 10 λήψεις</p>
        </section>
        <form onSubmit={handleSubmit} className="misthosi-ai-form">
          <textarea
            id="new-misthosi-ai"
            value={msg}
            placeholder="Γράψτε την περιγραφή της μίσθωσης εδώ.."
            className="ekloges-post-create"
            maxLength={650}
            onChange={(e) => setMsg(e.target.value.slice(0, 650))}
          />

          {/* <Toggle value={value} onValueChange={setValue}>
            <ToggleItem value="1" text={"Γραμματέα"} />
            <ToggleItem value="2" text={"Δικ.Αντιπρόσωπο"} />
          </Toggle> */}
          <div className="post-buttons-misthosi-ai">
            {showSend && (
              <button
                onClick={handleSubmitMsg}
                className="addcase_modal check_now ekloges_buttons"
              >
                Αποστολή
              </button>
            )}

            {isLoading && (
              <section className="form-section misthosi-ai form-section-footer">
                <div className="form-section-inputs">
                  <RandomChars />
                </div>
              </section>
            )}
            {showDownload && (
              <section className="form-section misthosi-ai form-section-footer">
                <div className="form-section-inputs">
                  <button
                    type="submit"
                    className="form-section-button form-section-button-download"
                  >
                    Λήψη Μισθωτηρίου
                  </button>
                </div>
              </section>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default LeaseFormAi;
