import React, { useState } from 'react'
import Pagination from './Pagination';


export const Paginated = ({ Component, data, functions }) => {
  console.log(data);
  const [page, setPage] = useState(0)

  function dividePages(arr) {
    const res = []
    for (let index = 0; index < arr.length; index++) {
      const el = []
      if (index % 5 === 0) {
        res.push(el)
      }

      let i = Math.floor(index / 5)
      res[i].push(arr[index])
    }
    return res

  }
  const handlePage = async (e) => {
    if (e.target.getAttribute("data-key") === 'next') {
      setPage(page + 1)
    }
    if (e.target.getAttribute("data-key") === 'end') {
      setPage(dividePages(data).length - 1)
    }
    if (e.target.getAttribute("data-key") === 'previous') {
      setPage(page - 1)
    }
    if (e.target.getAttribute("data-key") === 'start') {
      setPage(0)
    }



  }
  const props = {}
  props.page = page
  props.length = dividePages(data).length
  props.handlePage = handlePage
  return (
    <><Pagination {...props} />
      {dividePages(data)[page]?.map((r, i) => (<Component key={i} pageItem={r} functions={functions} />))}
      <Pagination {...props} />

    </>


  )
}
