import React, { useState, useCallback, useContext } from "react";
import { ProgressBar } from "@tremor/react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Cropper from "react-easy-crop";
import { getCroppedImg } from "./canvasUtils";
import AuthContext from "../../context/AuthProvider";

// A component containing a form to upload a photo
const UploadPhoto = ({ setCroppedImage }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { setAvatarUploaded, auth, setAuth } = useContext(AuthContext);

  const axiosPrivate = useAxiosPrivate();
  const [file, setFile] = useState();
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const showCroppedImage = useCallback(async () => {
    try {
      setUploading(true);

      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
      setImageSrc(null);
      const response = await fetch(croppedImage);
      const blob = await response.blob();
      const formData = new FormData();
      formData.append("image", blob, "image.jpg");
      const { data } = await axiosPrivate.post("/profile/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(progress);
          if (progress === 100) {
            // set uploading to false after 1 second
            setTimeout(() => {
              setUploading(false);
              setProgress(0);
            }, 1500);
          }
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
          console.log(progress);
          setProgress(progress);
        },
      });
      if (data.status === "success") {
        setAvatarUploaded(true);
        setAuth({ ...auth, avatar: data.uuid });
      }
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      setImageSrc(imageDataUrl);
    }
  };
  // Handle the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Get the file from the form
    // Create a new FormData object
    const formData = new FormData();
    // Add the file to the form data
    formData.append("file", file);
    // Send the form data to the server
    await axiosPrivate.post("/profile/upload", formData, {
      headers: { "Content-Type": "multipart/form-data" },
      withCredentials: true,
    });
  };
  return (
    <div>
      {imageSrc ? (
        <>
          <div className="cropContainer">
            <Cropper
              cropShape="round"
              image={imageSrc}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={3 / 3}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="profile-photo-upload-buttons">
            <button className="custom-file-upload" onClick={showCroppedImage}>
              Αποθήκευση
            </button>
            <button
              className="custom-file-upload red"
              onClick={() => {
                setImageSrc(null);
              }}
            >
              Ακύρωση
            </button>
          </div>
        </>
      ) : (
        <label className="custom-file-upload">
          <input
            type="file"
            onChange={onFileChange}
            accept="image/*"
            style={{ display: "none" }}
          />
          Επεξεργασία Εικόνας
        </label>
      )}
      {uploading && (
        <ProgressBar
          percentageValue={progress}
          color="emerald"
          className="mt-6"
        />
      )}
    </div>
  );
};

export default UploadPhoto;

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
