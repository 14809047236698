import { useState, useEffect } from "react";
import MultiEtairies from './MultiEtairies';
import AddEtairia from './AddEtairia';
import AddEtairiaModal from './AddEtairiaModal';
import { useInterval } from "../../utils/useInterval";


import axios from 'axios'
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const Etairies = () => {
  const [etairies, setEtairies] = useState([]);
  const [etairiesFirst, setEtairiesFirst] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  // const [state, updateState] = useState();
  const [search, setSearch] = useState();

  // const forceUpdate = useCallback(() => updateState({}), []);


  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getEtairies = async () => {
      try {
        const response = await axiosPrivate.get('/etairies', {
          signal: controller.signal
        });
        isMounted && setEtairies(response.data);
        isMounted && setEtairiesFirst(response.data);

        // isMounted && setCasesFirst(response.data);

      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate('/login', { state: { from: location }, replace: true });

        }
      }
    }

    getEtairies();
    // const interval = setInterval(() => {
    //   getCases()
    // }, 10000)
    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (search) {
      setEtairies(etairiesFirst?.filter(r => r?.eponymia?.toUpperCase().includes(search?.toUpperCase())))
    } else { setEtairies(etairiesFirst) }

  }, [search])



  const [showModal, setShowModal] = useState(true)
  const handleClickAddEtairiesModal = () => { setShowModal(!showModal) }
  const handleAddEtairia = (singleEtairia) => { setEtairies([...etairies, singleEtairia]) }
  const handleDeleteEtairia = (singleCase) => {
    setEtairies(etairies.filter(e => e.id.toString() !== singleCase))
  }

  useInterval(async () => {
    let isMounted = true;
    const controller = new AbortController();

    const getEtairies = async () => {
      try {
        const response = await axiosPrivate.get('/etairies', {
          signal: controller.signal
        });
        isMounted && setEtairies(response.data);

      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate('/login', { state: { from: location }, replace: true });

        }
      }
    }

    getEtairies();

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)


    return () => {
      isMounted = false;
      controller.abort();
    }
  }, 30000)




  return (

    <section className="etairies_section">
      {/* <section className="container_search" >

        <form className="form_search" >
          {/* <label htmlFor="username">Email:</label> */}
      {/* <div className="input-container ic1">
            <input
              className='input'
              placeholder=' '
              type="text"
              id="Αναζήτηση"
              autoComplete="on"
              onChange={(e) => setSearch(e.target.value.toUpperCase())}
              value={search || ''}
              required
            />
            <div className="cut"></div>
            <label htmlFor="Αναζήτηση" className="placeholder">🔎 Αναζήτηση Εταιρίας με επωνυμία ή ΑΦΜ</label></div> */}

      {/* <label htmlFor="password">Password:</label> */}



      {/* </form> */}

      {/* </section > */}
      < div className="under-construction" > Η συγκεκριμένη εφαρμογή είναι προσωρινά εκτός λειτουργίας</div >

      {/* //     {showModal ? <AddEtairiaModal handleClickAddEtairiesModal={handleClickAddEtairiesModal} /> : <AddEtairia handleClickAddEtairiesModal={handleClickAddEtairiesModal} etairies={etairies} handleAddEtairia={handleAddEtairia} />}
// <MultiEtairies etairies={etairies} handleDeleteEtairia={handleDeleteEtairia} /> */}


    </section >
  )
}

export default Etairies