import { removeDiacriticsAndCapitalize } from "../../utils/checkMisthosi";

export const eirinodikia = [
  "Αγίας Άννας",
  "Αγίου Νικολάου",
  "Αγραίων",
  "Αγρινίου",
  "Αθηνών",
  "Αιγιαλείας",
  "Αίγινας",
  "Αιτωλικού",
  "Ακράτας",
  "Αλεξάνδρειας Ημαθίας",
  "Αλεξανδρούπολης",
  "Αλιάρτου",
  "Αλμυρού",
  "Αλμωπίας",
  "Αμαλιάδας",
  "Αμαρίου",
  "Αμαρουσίου",
  "Αμβρυσσού",
  "Αμοργού",
  "Αμυνταίου",
  "Αμφίκλειας",
  "Άμφισσας",
  "Ανδρίτσαινας",
  "Άνδρου",
  "Άνω Καλεντίνης",
  "Απεραντίων",
  "Αποδοτίας",
  "Απολλωνίων",
  "Αράχωβας",
  "Αργαλαστής",
  "Αργοστολίου",
  "Αργους",
  "Αρήνης",
  "Αρκαλοχωρίου",
  "Αρναίας Χαλκιδικής",
  "Αροανίας",
  "Άρτας",
  "Άστρους",
  "Αταλάντης",
  "Αυλώνος-Καρυστίας",
  "Αχαρνών",
  "Βάλτου Αμφιλοχίας ",
  "Βάμου",
  "Βάρδας",
  "Βαρθολομιού",
  "Βασιλικών",
  "Βέροιας",
  "Βιάννου",
  "Βολισσού (Χίου)",
  "Βόλου",
  "Βόνιτσας",
  "Γαστούνης",
  "Γιαννιτσών",
  "Γουμένισσας",
  "Γρεβενών",
  "Γυθείου",
  "Δαύλειας",
  "Δελφών",
  "Δερβενίου",
  "Δεσκάτης",
  "Διδυμότειχου",
  "Δομοκού",
  "Δράμας",
  "Δροσοπηγής",
  "Δύμης",
  "Δωρίδος",
  "Δωριέων",
  "Δωρίου",
  "Έδεσσας",
  "Ελασσόνας",
  "Ελάτειας",
  "Ελευσίνας",
  "Εορδαίας",
  "Επιδαύρου Λιμηράς",
  "Εράνης",
  "Ερεσσού",
  "Ερινεού",
  "Ερμούπολης",
  "Ερυθρών",
  "Ευδήλου Ικαρίας",
  "Ευπαλίου",
  "Ευρυτανίας",
  "Εχίνου",
  "Ζαγοράς",
  "Ζαγορίου",
  "Ζακύνθου",
  "Ζίτσας",
  "Ηγουμενίτσας",
  "Ηράκλειας",
  "Ηρακλείου Κρήτης",
  "Θάσος",
  "Θερμοπυλών",
  "Θέρμου",
  "Θεσπιών",
  "Θεσπρωτικού",
  "Θεσσαλονίκης",
  "Θηβών",
  "Θήρας",
  "Ιεράπετρας",
  "Ιερισσού",
  "Ιθάκης",
  "Ικαρίας",
  "Ιλίου",
  "Ιστιαίας",
  "Ιτέας",
  "Ιωαννίνων",
  "Καβάλας",
  "Καλαβρύτων",
  "Καλαμάτας",
  "Καλαμπάκας",
  "Καλαυρίας",
  "Καλλιθέας",
  "Καλλονής",
  "Καλύμνου",
  "Κανδάνου",
  "Καρδίτσας",
  "Καρλοβασίου Σάμου",
  "Καρπάθου",
  "Καρύστου",
  "Καρύταινας",
  "Κασσάνδρας",
  "Καστελίου",
  "Καστοριάς",
  "Καστορείου",
  "Κατερίνης",
  "Κάτω Νευροκοπίου",
  "Κέρκυρας",
  "Κιλκίς",
  "Κισάμου",
  "Κισσάβου",
  "Κλειτορίας",
  "Κοζάνης",
  "Κολινδρού",
  "Κολυμβαρίου",
  "Κομοτηνής",
  "Κόνιτσας",
  "Κοντοβάζαινας (Ελευσίνος Επιδαύρου)",
  "Κορίνθου",
  "Κουφαλίων",
  "Κρεστένων",
  "Κροκεών",
  "Κρύας Βρύσης",
  "Κρωπίας",
  "Κυθήρων",
  "Κύθνου",
  "Κύμης",
  "Κυπαρισσίας",
  "Κω",
  "Λαγκαδά",
  "Λαμίας",
  "Λαμπείας",
  "Λάρισας",
  "Λαυρίου",
  "Λέρου",
  "Λευκάδας",
  "Λευκίμμης",
  "Λήμνου",
  "Ληξουρίου Κεφαλληνίας",
  "Λιβαδειάς",
  "Λίμνης ",
  "Μακρακώμης",
  "Μακρυνείας",
  "Μαραθώνα",
  "Μάσσητος",
  "Μεγαλόπολης",
  "Μεγάρων",
  "Μεσολογγίου",
  "Μετσόβου",
  "Μηλεών",
  "Μήλου",
  "Μοιρών",
  "Μουζακίου",
  "Μουριών",
  "Μυκόνου",
  "Μυλοποτάμου",
  "Μυρτουντίων",
  "Μυτιλήνης",
  "Νάξου",
  "Νάουσας",
  "Ναυπάκτου",
  "Ναυπλίου",
  "Νεαπόλεως Λακωνίας",
  "Νεάπολης Κοζάνης ",
  "Νεάπολης Λασιθίου",
  "Νέας Επιδαύρου",
  "Νέας Ιωνίας Αττικής ",
  "Νεμέας",
  "Νεστορίου",
  "Νευροκοπίου",
  "Νέων Μουδανιών",
  "Νιγρίτας",
  "Νίκαιας",
  "Νυμφασίας",
  "Ξάνθης",
  "Ξηρομέρου",
  "Ξυλοκάστρου",
  "Οιχαλίας",
  "Ολυμπίων",
  "Ορεστιάδας",
  "Όρους ",
  "Ορχομενού Αρκαδίας",
  "Ορχομενού Βοιωτίας",
  "Παγγαίου",
  "Παλαμά",
  "Παμίσου",
  "Παξών",
  "Παπάδου Λέσβου",
  "Παραμυθιάς",
  "Παραχελωΐτιδος",
  "Πάργας",
  "Πάρου",
  "Πάτμου",
  "Πατρών",
  "Πειραιώς",
  "Πενταλόφου",
  "Περισσού Χαλκιδικής",
  "Περιστερίου",
  "Πλαταμώδους",
  "Πλωμαρίου Λέσβου",
  "Πολιχνίτου",
  "Πολυγύρου Χαλκιδικής",
  "Πολυκάστρου",
  "Ποροΐων",
  "Πρασίων",
  "Πρέβεζας",
  "Πρεσπών",
  "Προσοτσάνης",
  "Προσχίου",
  "Πύλου",
  "Πύργου Ηλείας",
  "Πύργου Ηρακλείου",
  "Πύρρας",
  "Πωγωνίου",
  "Ρεθύμνης",
  "Ροδολίβους",
  "Ρόδου",
  "Σαλαμίνος",
  "Σαμαίων Κεφαλληνίας",
  "Σαμοθράκης ",
  "Σάμου",
  "Σαπών",
  "Σερβίων",
  "Σερίφου",
  "Σερρών",
  "Σητείας",
  "Σιάτιστας",
  "Σικύωνος (Κιάτου)",
  "Σιντικής",
  "Σίφνου",
  "Σκιάθου",
  "Σκοπέλου",
  "Σκύδρας",
  "Σκύρου",
  "Σολλίου",
  "Σουφλίου",
  "Σοφάδων",
  "Σοχού (Λαγκαδάς)",
  "Σπάρτης",
  "Σπερχειάδας",
  "Σπετσών",
  "Σπηλίου",
  "Σταυρούπολης Ξάνθης",
  "Στυμφαλίας",
  "Συκιάς Χαλκιδικής",
  "Σφακίων",
  "Ταμυνέων",
  "Τανίας Δολιανών",
  "Τευθίδος",
  "Τζερμιάδων Λασιθίου",
  "Τζουμέρκων ",
  "Τήνου",
  "Τολοφώνας",
  "Τραγαίας",
  "Τρικάλων",
  "Τριπόλεως",
  "Τριταίας",
  "Τροπαίων",
  "Τυρνάβου",
  "Υπάτης",
  "Φαλάρων",
  "Φαρκαδόνας",
  "Φαρρών",
  "Φαρσάλων",
  "Φενεού",
  "Φιγαλείας",
  "Φιλιατών",
  "Φιλιππιάδας",
  "Φλεσσιάδος",
  "Φλώρινας",
  "Φυλλίδας",
  "Χαλανδρίου",
  "Χαλκίδας",
  "Χανίων",
  "Χερσονήσου Κρήτης",
  "Χίου",
  "Χρυσούπολης",
  "Ψωφίδος",
  "Ωλένης",
]
  .map((dim) => removeDiacriticsAndCapitalize(dim))
  .sort();
