import { useState } from "react";
import createReport from "docx-templates";
import fileDownload from "react-file-download";
import EkmisthotesRow from "./components/EkmisthotesRow";
import MisthotesRow from "./components/MisthotesRow";
import MisthosiRow from "./components/MisthosiRow";
import "../../form.css";
import { checkIfFemale } from "../../utils/checkMisthosi";
import { misthosiTransform } from "./utils/misthosiTransform";
import {
  getFirstDateOfNextMonth,
  addYearsToDate,
  capitalizeFirstLetter,
  getMonthRange,
  formatMonthRange,
} from "./utils/addYears";
import DiatagiRow from "./components/DiatagiRow";
import ExodikoRow from "./components/ExodikoRow";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const downloadURL = (data, fileName) => {
  fileDownload(data, fileName);
};
function LeaseForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const misthosiPost = async (eidos) => {
    try {
      await axiosPrivate.post("/templates", JSON.stringify({ eidos }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const onTemplateChosen = async () => {
    const template = await fetch("/templates/misthosi.docx").then((res) =>
      res.arrayBuffer()
    );
    const report = await createReport({
      template,
      data: misthosiTransform({
        misthosi,
        ekmisthotes,
        misthotes,
        exodiko,
        diatagi,
      }),
    });
    downloadURL(
      report,
      `ΜΙΣΘΩΣΗ_${ekmisthotes[0].eponymo_ek}_${misthotes[0].eponymo_mi}.docx`
    );
  };

  const onTemplateChosenExodiko = async () => {
    const template = await fetch("/templates/exodiko.docx").then((res) =>
      res.arrayBuffer()
    );
    const report = await createReport({
      template,
      data: misthosiTransform({
        misthosi,
        ekmisthotes,
        misthotes,
        exodiko,
        diatagi,
      }),
    });
    downloadURL(
      report,
      `ΕΞΩΔΙΚΟ_${ekmisthotes[0].eponymo_ek}_${misthotes[0].eponymo_mi}.docx`
    );
  };
  const onTemplateChosenDiatagi = async () => {
    const template = await fetch("/templates/diatagi.docx").then((res) =>
      res.arrayBuffer()
    );
    const report = await createReport({
      template,
      data: misthosiTransform({
        misthosi,
        ekmisthotes,
        misthotes,
        exodiko,
        diatagi,
      }),
    });
    downloadURL(report, `ΔΙΑΤΑΓΗ_${ekmisthotes[0].eponymo_ek}.docx`);
  };
  const onTemplateChosenAitisi = async () => {
    const template = await fetch("/templates/aitisi.docx").then((res) =>
      res.arrayBuffer()
    );
    const report = await createReport({
      template,
      data: misthosiTransform({
        misthosi,
        ekmisthotes,
        misthotes,
        exodiko,
        diatagi,
      }),
    });
    downloadURL(
      report,
      `ΑΙΤΗΣΗ_ΑΠΟΔΟΣΗΣ_${ekmisthotes[0].eponymo_ek}_${misthotes[0].eponymo_mi}.docx`
    );
  };

  const [ekmisthotes, setEkmisthotes] = useState([
    {
      onoma_ek: "",
      eponymo_ek: "",
      patronymo_ek: "",
      AFM_ek: "",
      katoikia_ek: "",
      fylo_ek: "Άνδρας",
    },
  ]);
  const [misthotes, setMisthotes] = useState([
    {
      onoma_mi: "",
      eponymo_mi: "",
      patronymo_mi: "",
      AFM_mi: "",
      katoikia_mi: "",
      fylo_mi: "Άνδρας",
    },
  ]);
  const [misthosi, setMisthosi] = useState({
    misthoma: "",
    topos_symvasis: "",
    diarkeia: "",
    enarxi: getFirstDateOfNextMonth(),
    lixi: "",
    perigrafi: "",
    imnia_ypografis: "",
    anaprosarmogi: false,
    timarithmiki: false,
    stathero_epitokio: "0",
    eggyisi: null,
  });

  const [diatagi, setDiatagi] = useState({
    edra: "",
    edra_topos: "",
  });

  const [exodiko, setExodiko] = useState({
    startMonth: "",
    endMonth: "",
    ofeilomeno: "",
  });

  const addEkmisthotis = () => {
    setEkmisthotes([
      ...ekmisthotes,
      {
        onoma_ek: "",
        eponymo_ek: "",
        patronymo_ek: "",
        AFM_ek: "",
        katoikia_ek: "",
        fylo_ek: "Άνδρας",
      },
    ]);
  };

  const removeEkmisthotis = (i) => {
    const newEkmisthotes = [...ekmisthotes];
    newEkmisthotes.splice(i, 1);
    setEkmisthotes(newEkmisthotes);
  };

  const handleEkInputChange = (i, e) => {
    const { name, value } = e.target;
    const newEkmisthotes = [...ekmisthotes];
    newEkmisthotes[i][name] = capitalizeFirstLetter(value);
    setEkmisthotes(newEkmisthotes);
  };

  const handleEkFyloChange = (i, e) => {
    const { value } = e.target;
    const newEkmisthotes = [...ekmisthotes];
    newEkmisthotes[i].fylo_ek = checkIfFemale(value.trim())
      ? "Γυναίκα"
      : "Άνδρας";
    setEkmisthotes(newEkmisthotes);
  };

  const addMisthotis = () => {
    setMisthotes([
      ...misthotes,
      {
        onoma_mi: "",
        eponymo_mi: "",
        patronymo_mi: "",
        AFM_mi: "",
        katoikia_mi: "",
        fylo_mi: "Άνδρας",
      },
    ]);
  };

  const removeMisthotis = (i) => {
    const newMisthotes = [...misthotes];
    newMisthotes.splice(i, 1);
    setMisthotes(newMisthotes);
  };

  const handleMiInputChange = (i, e) => {
    const { name, value } = e.target;
    const newMisthotes = [...misthotes];
    newMisthotes[i][name] = capitalizeFirstLetter(value);
    setMisthotes(newMisthotes);
  };

  const handleMiFyloChange = (i, e) => {
    const { value } = e.target;
    const newMisthotes = [...misthotes];
    newMisthotes[i].fylo_mi = checkIfFemale(value.trim())
      ? "Γυναίκα"
      : "Άνδρας";
    setMisthotes(newMisthotes);
  };

  const handleMisthosiInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, parseFloat(value.trim()));

    if (name === "diarkeia" && value.trim() !== "") {
      setMisthosi({
        ...misthosi,
        [name]: value.trim(),
        lixi: addYearsToDate(misthosi.enarxi, parseInt(value.trim())),
      });
    } else if (name === "enarxi" && value.trim() !== "") {
      console.log("enarxi", value);
      setMisthosi({
        ...misthosi,
        [name]: value.trim(),
        lixi: addYearsToDate(value.trim(), parseInt(misthosi.diarkeia)),
      });
    } else if (name === "perigrafi" && value.trim() !== "") {
      setMisthosi({ ...misthosi, [name]: capitalizeFirstLetter(value) });
    } else if (name === "anaprosarmogi") {
      setMisthosi({ ...misthosi, [name]: !misthosi.anaprosarmogi });
    } else if (name === "timarithmiki") {
      setMisthosi({ ...misthosi, [name]: !misthosi.timarithmiki });
    } else if (name === "stathero_epitokio") {
      setMisthosi({ ...misthosi, [name]: parseFloat(value.trim()) });
    } else if (name === "eggyisi") {
      setMisthosi({ ...misthosi, [name]: value });
    } else {
      setMisthosi({ ...misthosi, [name]: value });
    }
  };

  const handleDiatagiInputChange = (e) => {
    const { name, value } = e.target;
    const newDiatagi = { ...diatagi };
    newDiatagi[name] = capitalizeFirstLetter(value);
    setDiatagi(newDiatagi);
  };

  const handleMisthomaChange = (e) => {
    const { value } = e.target;
    if (value.trim() !== "" && parseInt(value.trim()) > 600) {
      setDiatagi({ ...diatagi, edra: "Πρωτοδικείο" });
    } else {
      setDiatagi({ ...diatagi, edra: "Ειρηνοδικείο" });
    }
    if (
      value.trim() !== "" &&
      exodiko.startMonth.trim() !== "" &&
      exodiko.endMonth.trim() !== ""
    ) {
      setExodiko({
        ...exodiko,
        ofeilomeno:
          getMonthRange(exodiko.startMonth, exodiko.endMonth).length *
          parseInt(value.trim()),
      });
    }
  };

  const handleExodikoInputChange = (e) => {
    const { name, value } = e.target;
    const newExodiko = { ...exodiko };
    newExodiko[name] = value;
    if (
      misthosi.misthoma.trim() !== "" &&
      newExodiko.startMonth.trim() !== "" &&
      newExodiko.endMonth.trim() !== "" &&
      name !== "ofeilomeno"
    ) {
      newExodiko.ofeilomeno =
        getMonthRange(newExodiko.startMonth, newExodiko.endMonth).length *
        parseInt(misthosi.misthoma.trim());
    }
    setExodiko(newExodiko);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await misthosiPost("misthosi");
    onTemplateChosen();
  };

  const handleExodikoSubmit = async (e) => {
    e.preventDefault();
    await misthosiPost("exodiko");
    onTemplateChosenExodiko();
  };
  const handleDiatagiSubmit = async (e) => {
    e.preventDefault();
    await misthosiPost("diatagi");
    onTemplateChosenDiatagi();
  };
  const handleAitisiSubmit = async (e) => {
    e.preventDefault();
    await misthosiPost("aitisi");
    onTemplateChosenAitisi();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <section className="form-section">
          <div className="form-section-title">
            <h2 className="form-section-title-text">Εκμισθωτές</h2>
          </div>

          {ekmisthotes.map((ekmisthotis, i) => (
            <EkmisthotesRow
              key={i}
              handleEkInputChange={(e) => handleEkInputChange(i, e)}
              handleEkFyloChange={(fylo) => handleEkFyloChange(i, fylo)}
              removeEkmisthotis={() => removeEkmisthotis(i)}
              ekmisthotis={ekmisthotis}
              i={i}
              length={ekmisthotes.length}
            />
          ))}

          <div className="form-section-row">
            <div className="form-section-single-input button-row">
              <button
                type="button"
                className="form-section-button form-section-button-add-row"
                onClick={addEkmisthotis}
              >
                Προσθήκη Επιπλέον Εκμισθωτή
              </button>
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="form-section-title">
            <h2 className="form-section-title-text">Μισθωτές</h2>
          </div>

          {misthotes.map((misthotis, i) => (
            <MisthotesRow
              key={i}
              handleMiInputChange={(e) => handleMiInputChange(i, e)}
              handleMiFyloChange={(fylo) => handleMiFyloChange(i, fylo)}
              removeMisthotis={() => removeMisthotis(i)}
              misthotis={misthotis}
              i={i}
              length={misthotes.length}
            />
          ))}

          <div className="form-section-row">
            <div className="form-section-single-input button-row">
              <button
                type="button"
                className="form-section-button form-section-button-add-row"
                onClick={addMisthotis}
              >
                Προσθήκη Επιπλέον Μισθωτή
              </button>
            </div>
          </div>
        </section>

        <section className="form-section">
          <div className="form-section-title">
            <h2 className="form-section-title-text">Στοιχεία Μίσθωσης</h2>
          </div>

          <MisthosiRow
            handleMisthosiInputChange={handleMisthosiInputChange}
            handleMisthomaChange={handleMisthomaChange}
            misthosi={misthosi}
          />
        </section>

        <section className="form-section form-section-footer">
          <div className="form-section-inputs">
            <button
              type="submit"
              className="form-section-button form-section-button-download"
            >
              Λήψη Μισθωτηρίου
            </button>
          </div>
        </section>
        <section className="form-section">
          <div className="form-section-title">
            <h2 className="form-section-title-text">
              Εξώδικο Προ Διαταγής Απόδοσης
            </h2>
          </div>

          <ExodikoRow
            handleExodikoInputChange={handleExodikoInputChange}
            exodiko={exodiko}
          />
        </section>
        <section className="form-section form-section-footer">
          <div className="form-section-inputs">
            <button
              type="button"
              className="form-section-button form-section-button-download"
              onClick={handleExodikoSubmit}
            >
              Λήψη Εξωδίκου
            </button>
          </div>
        </section>
        <section className="form-section">
          <div className="form-section-title">
            <h2 className="form-section-title-text">
              Διαταγή Πληρωμής και Απόδοσης
            </h2>
          </div>

          <DiatagiRow
            handleDiatagiInputChange={handleDiatagiInputChange}
            diatagi={diatagi}
          />
        </section>
        <section className="form-section form-section-footer diatagi-buttons">
          <div className="form-section-inputs">
            <button
              type="button"
              className="form-section-button form-section-button-download"
              onClick={handleDiatagiSubmit}
            >
              Λήψη Διαταγής
            </button>
          </div>
          <div className="form-section-inputs">
            <button
              type="button"
              className="form-section-button form-section-button-download"
              onClick={handleAitisiSubmit}
            >
              Λήψη Αίτησης
            </button>
          </div>
        </section>
      </form>
    </div>
  );
}

export default LeaseForm;
