import { useState, useEffect, useCallback } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import CaseCard from "../CaseCard";
import VSolonCard from "../Cards/VSolonCard";
import ScrollToTop from "../ScrollToTop";

const ViewCase = () => {
  const [singleCase, setSingleCase] = useState();
  let params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [singleUpdates, setSingleUpdates] = useState();
  const [state, updateState] = useState();
  const [loading, setLoading] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  useEffect(() => {
    let title = "";
    if (singleCase?.note !== undefined && singleCase?.note?.trim() !== "") {
      title = title + singleCase.note + " | ";
    }
    title =
      title +
      singleCase?.court +
      " | " +
      singleCase?.gak +
      "/" +
      singleCase?.year +
      " | MySolon.gr";

    if (!singleCase) {
      title = "Δεν βρέθηκε η υπόθεση | MySolon.gr";
    }
    document.title = title;
  }, [location, singleCase]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCase = async () => {
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`/cases/${params.caseId}`, {
          signal: controller.signal,
        });
        const updates = await axiosPrivate.get(`/gakupd/${params.caseId}`, {
          signal: controller.signal,
        });
        isMounted && setSingleCase(response.data);
        isMounted && setSingleUpdates(updates.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getCase();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (
    singleCase && // 👈 null and undefined check
    Object.keys(singleCase).length !== 0 &&
    Object.getPrototypeOf(singleCase) === Object.prototype
  ) {
  }

  return loading ? (
    <h2>Ανακτώνται τα δεδομένα... Παρακαλώ περιμένετε...</h2>
  ) : singleCase ? (
    <div>
      <ScrollToTop />
      <VSolonCard singleCase={singleCase} singleUpdates={singleUpdates} />
    </div>
  ) : (
    <h2>Δεν βρέθηκε η υπόθεση.</h2>
  );
};

export default ViewCase;
