import { Outlet } from "react-router-dom"
import Header from "./Header"
import Footer from "./Footer"

// import Announcement from "./Announcement"

const Layout = () => {


  return (<>

    <Header />
    {/* <Announcement /> */}
    <main className="App">

      <Outlet />
    </main>
    <Footer />
  </>

  )
}

export default Layout