import React from "react";
import TimeAgo from "javascript-time-ago";
import el from "javascript-time-ago/locale/el";
import { getAdjCourt } from "../../utils/getAdjCourts";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import HeaderAvatar from "../Visual/HeaderAvatar";
import { eidiToGrMap } from "../Social/Ekloges";
import { Link } from "react-router-dom";
TimeAgo.addDefaultLocale(el);
const timeAgo = new TimeAgo("el-GR");

function parseDateString(dateString) {
  const dateParts = dateString?.split(" ");
  const date = dateParts[0]?.split("-").map((part) => parseInt(part));
  const time = dateParts[1]?.split(":").map((part) => parseInt(part));
  return new Date(date[0], date[1] - 1, date[2], time[0], time[1], time[2]);
}

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}
function fixCourt(court) {
  if (court === "protpatras") {
    return "Πρωτ. Πάτρας";
  }
  if (court === "eirpatras") {
    return "Ειρ. Πάτρας";
  }
  if (court === "protkos") {
    return "Πρωτ. Κω";
  }
  if (court === "eirnikaias") {
    return "Ειρ. Νικαίας";
  }
  if (court === "protlarisas") {
    return "Πρωτ. Λάρισας";
  } else {
    return court;
  }
}

function NotifItem({
  notif,
  notifLength,
  notifIndex,
  handleShowNotif,
  closeNotif,
}) {
  const navigate = useNavigate();

  const handleOnClick = useCallback(() => {
    handleShowNotif();
    navigate(`/cases/${notif.gak_id}`, { replace: false });
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [navigate, notif, handleShowNotif]);
  const handleAdjOnClick = useCallback(() => {
    handleShowNotif();
    navigate(`/adjustice/${notif.adjustice_id}`, { replace: false });
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [navigate, notif, handleShowNotif]);

  if (notif?.apotelesmate) {
    return (
      <div
        onClick={handleAdjOnClick}
        className={
          notifLength > notifIndex ? "notif-item not-seen" : "notif-item"
        }
      >
        <h3 data-case="dioikitiki">Διοικητική</h3>
        <p>{notif?.note}</p>
        <p>Αρ.Κατ. {notif?.arkat}</p>
        <p>{getAdjCourt(notif?.court).toUpperCase()}</p>
        <p className="ago">
          {timeAgo.format(
            addMinutes(
              parseDateString(notif?.created_at),
              Math.abs(new Date().getTimezoneOffset())
            )
          )}
        </p>
      </div>
    );
  }
  if (notif?.gak) {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          handleOnClick();
          closeNotif();
        }}
        className={
          notifLength > notifIndex ? "notif-item not-seen" : "notif-item"
        }
      >
        <h3 data-case="astiki">Αστική</h3>
        <p>{notif?.note}</p>
        <p>
          ΓΑΚ {notif?.gak}/{notif?.year}
        </p>
        <p>{notif?.court}</p>
        <p className="ago">
          {timeAgo.format(
            addMinutes(
              parseDateString(notif?.created_at),
              Math.abs(new Date().getTimezoneOffset())
            )
          )}
        </p>
      </div>
    );
  }
  if (notif?.category) {
    return (
      <div
        className={
          notifLength > notifIndex ? "notif-item not-seen" : "notif-item"
        }
        onClick={closeNotif}
      >
        <h3 data-case="astiki">Αστική</h3>
        <p>{notif?.note}</p>
        <p>Αρ.Κατ. {notif?.arkat}</p>
        <p>{fixCourt(notif?.court)}</p>
        <p className="ago">
          {timeAgo.format(
            addMinutes(
              parseDateString(notif?.created_at),
              Math.abs(new Date().getTimezoneOffset())
            )
          )}
        </p>
      </div>
    );
  }
  if (notif?.like_id) {
    const path = `/post/${notif?.post_uuid}`;

    return (
      <HashLink
        to={path}
        className="hashlink"
        style={{ textDecoration: "none", color: "black" }}
      >
        <div
          className={
            notifLength > notifIndex ? "notif-item not-seen" : "notif-item"
          }
          onClick={closeNotif}
        >
          <h3 data-case="likes">❤️ Like!</h3>
          <div className="like-notif">
            <HeaderAvatar
              userAvatar={notif?.user?.avatar_uuid}
              avatarClass={"smd"}
              uuid={notif?.user?.uuid}
              avatarUploaded={false}
            />
            <p>
              Η ανάρτησή σας άρεσε στον χρήστη <em>{notif?.user?.email}</em>
            </p>
          </div>
          <p className="ago">
            {timeAgo.format(
              addMinutes(
                parseDateString(notif?.created_at),
                Math.abs(new Date().getTimezoneOffset())
              )
            )}
          </p>
        </div>
      </HashLink>
    );
  }
  if (notif?.comment_id) {
    const path = `/post/${notif?.post_uuid}`;
    return (
      // @eslint-disable-next-line
      <HashLink
        to={path}
        className="hashlink"
        style={{ textDecoration: "none", color: "black" }}
      >
        {" "}
        <div
          onClick={closeNotif}
          className={
            notifLength > notifIndex ? "notif-item not-seen" : "notif-item"
          }
        >
          <h3 data-case="likes">💬 Σχόλιο!</h3>
          <div className="like-notif">
            <HeaderAvatar
              userAvatar={notif?.user?.avatar_uuid}
              avatarClass={"smd"}
              uuid={notif?.user?.uuid}
              avatarUploaded={false}
            />
            <p>
              Ο χρήστης <em>{notif?.user?.email}</em> σχολίασε την ανάρτησή σας
            </p>
          </div>
          <p className="ago">
            {timeAgo.format(
              addMinutes(
                parseDateString(notif?.created_at),
                Math.abs(new Date().getTimezoneOffset())
              )
            )}
          </p>
        </div>
      </HashLink>
    );
  }
  if (notif?.periohi) {
    const path = `/post/${notif?.post_uuid}`;
    return (
      // @eslint-disable-next-line
      <HashLink
        to={path}
        className="hashlink"
        style={{ textDecoration: "none", color: "black" }}
      >
        {" "}
        <div
          onClick={closeNotif}
          className={
            notifLength > notifIndex ? "notif-item not-seen" : "notif-item"
          }
        >
          <h3 data-case="likes">
            💼 Ταίριασμα για {eidiToGrMap[notif.eidos]} - {notif.periohi}
          </h3>
          <div className="like-notif">
            <HeaderAvatar
              userAvatar={notif?.user?.avatar_uuid}
              avatarClass={"smd"}
              uuid={notif?.user?.uuid}
              avatarUploaded={false}
            />
            <p>
              Η ανάρτηση του/της <em>{notif?.user?.email}</em> έχει ταίριασμα με
              την καταχώρησή σας
            </p>
          </div>
          <p className="ago">
            {timeAgo.format(
              addMinutes(
                parseDateString(notif?.created_at),
                Math.abs(new Date().getTimezoneOffset())
              )
            )}
          </p>
        </div>
      </HashLink>
    );
  }
  if (notif?.afm_eauction_id) {
    return (
      <Link
        to={"/eauction"}
        className="hashlink"
        style={{ textDecoration: "none", color: "black" }}
      >
        <div
          className={
            notifLength > notifIndex ? "notif-item not-seen" : "notif-item"
          }
          onClick={closeNotif}
        >
          <h3 data-case="eauction">🏠 Αναρτήθηκε Πλειστηριασμός</h3>
          {notif?.note && <p>Για την υπόθεση: {notif?.note}</p>}

          <p className="ago">
            {timeAgo.format(
              addMinutes(
                parseDateString(notif?.created_at),
                Math.abs(new Date().getTimezoneOffset())
              )
            )}
          </p>
        </div>
      </Link>
    );
  }
  if (notif?.appeal_update) {
    return (
      <Link
        to={`/cases/${notif?.case_id}`}
        className="hashlink"
        style={{ textDecoration: "none", color: "black" }}
      >
        <div
          className={
            notifLength > notifIndex ? "notif-item not-seen" : "notif-item"
          }
          onClick={closeNotif}
        >
          <h3 data-case="appeal">🏛️ Ασκήθηκε Ένδικο Μέσο</h3>
          {notif?.note && (
            <p>
              Για την υπόθεση: {notif?.note} -{" "}
              {notif?.unique_case?.split("/")[0]}/
              {notif?.unique_case?.split("/")[1]} -{" "}
              {notif?.unique_case?.split("/")[2]}
            </p>
          )}
          {!notif?.note && (
            <p>
              Για την υπόθεση: {notif?.unique_case?.split("/")[0]}/
              {notif?.unique_case?.split("/")[1]} -{" "}
              {notif?.unique_case?.split("/")[2]}
            </p>
          )}

          <p className="ago">
            {timeAgo.format(
              addMinutes(
                parseDateString(notif?.created_at),
                Math.abs(new Date().getTimezoneOffset())
              )
            )}
          </p>
        </div>
      </Link>
    );
  }
}

export default NotifItem;
