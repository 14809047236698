import {
  Card,
  Metric,
  Text,
  Title,
  BarList,
  Flex,
  Button,
  Grid,
} from "@tremor/react";

import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { dioikCourts, dioikLinks } from "../Adjustice/JCaseCard";

const dataFormatter = (number) =>
  Intl.NumberFormat("us").format(number).toString();

export default function Example({ cases }) {
  // Regarding the array of objects of cases, for each different value of the court key, create an object with two properties: The first property has a key "name" and a value of the court name. The second property has a key "value" and a value of the number of cases for that court.
  const courts = cases.reduce((acc, curr) => {
    const court = acc.find((item) => item.name === curr.court);
    if (court) {
      court.value += 1;
    } else {
      acc.push({ name: curr.court, value: 1 });
    }
    return acc;
  }, []);
  // Regarding the array of objects of cases, for each different value of the eidos key, create an object with two properties: The first property has a key "name" and a value of the eidos. The second property has a key "value" and a value of the number of cases for that eidos.
  const eidos = cases.reduce((acc, curr) => {
    const katigoria = acc.find((item) => item.name === curr.katigoria);
    if (katigoria) {
      katigoria.value += 1;
    } else {
      acc.push({ name: curr.katigoria, value: 1 });
    }
    return acc;
  }, []);

  const data = [
    {
      category: "Διοικητικά Δικαστήρια",
      description: "Δικαστήριο",
      stat: cases.length,
      data: courts
        .sort((a, b) => b.value - a.value)
        .map((item) => {
          return {
            ...item,
            name: dioikCourts[
              dioikLinks.findIndex((link) => link === item.name)
            ],
          };
        }),
    },
    {
      category: "Είδη Υποθέσεων",
      description: "Κατηγορία",
      stat: cases.length,
      data: eidos.sort((a, b) => b.value - a.value),
    },
  ];
  return (
    <Grid numItemsSm={2} numItemsLg={2} className="mt-3.5 gap-x-6 gap-y-6">
      {data.map((item) => (
        <Card key={item.category}>
          <Title>{item.category}</Title>
          {/* <Flex justifyContent="justify-start" alignItems="items-baseline" spaceX="space-x-2">
            <Metric>{item.stat}</Metric>
            <Text>Υποθέσεις</Text>
          </Flex> */}
          <Flex className="mt-6">
            <Text>{item.description}</Text>
            <Text textAlignment="text-right">Αριθμός</Text>
          </Flex>
          <BarList
            color="yellow"
            data={item.data}
            valueFormatter={dataFormatter}
            className="mt-2"
          />
          {/* <Footer>
            <Button
              size="sm"
              variant="light"
              icon={ArrowNarrowRightIcon}
              iconPosition="right"
            >
              View details

            </Button>
          </Footer> */}
        </Card>
      ))}
    </Grid>
  );
}
