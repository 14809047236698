import { useState } from "react";
import CaseCard from "./ExsCaseCard";
import Pagination from "../Util/Pagination";


const Cases = ({ cases, handleDeleteCase }) => {
  const casesSorted = cases.sort((a, b) => {
    return b.created_at?.localeCompare(a.created_at);
  })
  const [page, setPage] = useState(0);
  function dividePages(arr) {
    const res = []
    for (let index = 0; index < arr.length; index++) {
      const el = []
      if (index % 5 === 0) {
        res.push(el)
      }

      let i = Math.floor(index / 5)
      res[i].push(arr[index])
    }
    return res

  }

  const handlePage = async (e) => {
    if (e.target.getAttribute("data-key") === 'next') {
      setPage(page + 1)
    }
    if (e.target.getAttribute("data-key") === 'end') {
      setPage(dividePages(casesSorted).length - 1)
    }
    if (e.target.getAttribute("data-key") === 'previous') {
      setPage(page - 1)
    }
    if (e.target.getAttribute("data-key") === 'start') {
      setPage(0)
    }



  }


  return (
    <article >


      {
        cases?.length
          ? (
            <>
              <Pagination page={page} length={dividePages(casesSorted).length} handlePage={handlePage} />

              {dividePages(casesSorted)[page].map((singleCase, i) => {
                singleCase.i = i

                return (<CaseCard key={singleCase.id} singleCase={singleCase} handleDeleteCase={handleDeleteCase} />)
              })}
              <Pagination page={page} length={dividePages(casesSorted).length} handlePage={handlePage} />

            </>
          ) : <p>Δεν έχετε καταχωρήσει υπόθεση προς έλεγχο</p>
      }
    </article >
  );
};

export default Cases;