import React from "react";

import { StatusOnlineIcon } from "@heroicons/react/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
} from "@tremor/react";

const StoixeiaDikasimou = ({ data }) => {
  return (
    <Card className="mt-3">
      <Title>Στοιχεία Δικασίμου</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Πινάκιο</TableHeaderCell>
            <TableHeaderCell>Ημ/νία</TableHeaderCell>
            <TableHeaderCell>Αίθουσα</TableHeaderCell>
            <TableHeaderCell>Αποτέλεσμα</TableHeaderCell>
            <TableHeaderCell>Τύπος</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => (
            <TableRow key={`stoix-${i}`}>
              <TableCell>{item.pinakio}</TableCell>
              <TableCell>
                <Text>{item.imerominia_pinakiou}</Text>
              </TableCell>
              <TableCell>
                <Text>{item.aithouse}</Text>
              </TableCell>
              <TableCell>{item.apotelesma}</TableCell>
              <TableCell>{item.typos}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default StoixeiaDikasimou;
