export const protodikeia = [
  "ΑΓΡΙΝΙΟΥ",
  "ΑΘΗΝΩΝ",
  "ΑΙΓΙΟΥ",
  "ΑΜΑΛΙΑΔΑΣ",
  "ΑΜΦΙΣΣΑΣ",
  "ΑΡΤΑΣ",
  "ΒΕΡΟΙΑΣ",
  "ΒΟΛΟΥ",
  "ΓΙΑΝΝΙΤΣΩΝ",
  "ΓΡΕΒΕΝΩΝ",
  "ΓΥΘΕΙΟΥ",
  "ΔΡΑΜΑΣ",
  "ΕΒΡΟΥ (ΑΛΕΞ/ΠΟΛΗΣ)",
  "ΕΔΕΣΣΑΣ",
  "ΕΥΡΥΤΑΝΙΑΣ",
  "ΖΑΚΥΝΘΟΥ",
  "ΗΛΕΙΑΣ",
  "ΗΡΑΚΛΕΙΟΥ",
  "ΘΕΣΠΡΩΤΙΑΣ",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΘΗΒΩΝ",
  "ΙΩΑΝΝΙΝΩΝ",
  "ΚΑΛΑΒΡΥΤΩΝ",
  "ΚΑΛΑΜΑΤΑΣ",
  "ΚΑΣΤΟΡΙΑΣ",
  "ΚΑΤΕΡΙΝΗΣ",
  "ΚΑΡΔΙΤΣΑΣ",
  "ΚΕΡΚΥΡΑΣ",
  "ΚΕΦΑΛΛΗΝΙΑΣ",
  "ΚΙΛΚΙΣ",
  "ΚΟΖΑΝΗΣ",
  "ΚΟΡΙΝΘΟΥ",
  "ΚΥΠΑΡΙΣΣΙΑΣ",
  "ΚΩ",
  "ΛΑΜΙΑΣ",
  "ΛΑΣΙΘΙΟΥ(Νεάπολης)",
  "ΛΑΡΙΣΑΣ",
  "ΛΕΙΒΑΔΙΑΣ",
  "ΛΕΥΚΑΔΑΣ",
  "ΜΕΣΟΛΟΓΓΙΟΥ",
  "ΜΥΤΙΛΗΝΗΣ",
  "ΝΑΞΟΥ",
  "ΝΑΥΠΛΙΟΥ",
  "ΞΑΝΘΗΣ",
  "ΟΡΕΣΤΙΑΔΑΣ",
  "ΠΑΤΡΩΝ",
  "ΠΕΙΡΑΙΩΣ",
  "ΠΡΕΒΕΖΑΣ",
  "ΡΟΔΟΠΗΣ (ΚΟΜΟΤΗΝΗΣ)",
  "ΡΟΔΟΥ",
  "ΡΕΘΥΜΝΟΥ",
  "ΣΑΜΟΥ",
  "ΣΠΑΡΤΗΣ",
  "ΣΕΡΡΩΝ",
  "ΣΥΡΟΥ",
  "ΤΡΙΚΑΛΩΝ",
  "ΤΡΙΠΟΛΕΩΣ",
  "ΦΛΩΡΙΝΗΣ",
  "ΧΑΛΚΙΔΑΣ",
  "ΧΑΛΚΙΔΙΚΗΣ",
  "ΧΑΝΙΩΝ",
  "ΧΙΟΥ",
];
