import React from 'react'

const AddCaseEirPatras = ({ eidos, arkat, handleChangeDiad, setArkat, year, setYear }) => {

  return (
    <>
      <div className="input-container ic2">
        <select value={eidos} onChange={handleChangeDiad} className='input'>
          <option key={'asfalistika'} value={'asfalistika'}>Ασφαλιστικά</option>
          <option key={'eidiki'} value={'eidiki'}>Ειδική</option>
          <option key={'ekousia'} value={'ekousia'}>Εκουσία (Ν.4055/12)</option>
          <option key={'mikr'} value={'mikr'}>Μικροδιαφορές</option>
          <option key={'taktiki'} value={'taktiki'}>Τακτική</option>
          <option key={'yper'} value={'yper'}>Υπερχρεωμένα (Ν.3869/10)</option>
          <option key={'ptox'} value={'ptox'}>Πτωχεύσεις (Ν.4738/20)</option>

        </select>
        <div className="cut"></div>
        <label htmlFor="eidos" className="placeholder">Διαδικασία</label>
      </div>

      <div className="input-container ic2">
        <input
          className='input'
          placeholder=' '
          type="text"
          id="arkat"
          autoComplete="on"
          onChange={(e) => setArkat(e.target.value)}
          value={arkat}
          required
        />
        <div className="cut"></div>
        <label htmlFor="arkat" className="placeholder">Αρ.Κατάθεσης</label></div>
      <div className="input-container ic2">
        <input
          className='input'
          placeholder=' '
          type="number"
          id="year"
          autoComplete="on"
          onChange={(e) => setYear(e.target.value)}
          value={year}
          required
        />
        <div className="cut"></div>
        <label htmlFor="arkat" className="placeholder">Έτος</label></div>
    </>
  )

}

export default AddCaseEirPatras