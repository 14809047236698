import React from "react";

import { StatusOnlineIcon } from "@heroicons/react/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
} from "@tremor/react";

const Lawyers = ({ data }) => {
  return (
    <Card>
      <Title>Δικηγόροι Κατάθεσης</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Δικ. Σύλλογος</TableHeaderCell>
            <TableHeaderCell>Α.Μ.</TableHeaderCell>
            <TableHeaderCell>Επώνυμο</TableHeaderCell>
            <TableHeaderCell>Όνομα</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => (
            <TableRow key={`$dik-${i}`}>
              <TableCell>{item.syllogos}</TableCell>
              <TableCell>
                <Text>{item.mitrwo}</Text>
              </TableCell>
              <TableCell>{item.eponymo}</TableCell>
              <TableCell>{item.onoma}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default Lawyers;
