import { useState, useEffect } from "react";
import MultiEauction from "./MultiEauction";
import AddEauction from "./AddEauction";
import AddEauctionModal from "./AddEauctionModal";
import MultiSingleEauction from "./MultiSingleAuction";
import { useInterval } from "../../utils/useInterval";

import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const Eauction = () => {
  let params = useParams();

  const [eauctions, setEauctions] = useState([]);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  // const [state, updateState] = useState();
  const [search, setSearch] = useState();
  const afmParam = params?.afm;

  // const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getEauction = async () => {
      try {
        const response = await axiosPrivate.get("/eauctions", {
          signal: controller.signal,
        });
        isMounted && setEauctions(response.data);
        console.log(response.data);
        // isMounted && setCasesFirst(response.data);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getEauction();
    console.log(eauctions);
    // const interval = setInterval(() => {
    //   getCases()
    // }, 10000)
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showModal, setShowModal] = useState(true);
  const handleClickAddEauctionModal = () => {
    setShowModal(!showModal);
  };
  const handleAddEauction = (singleEauction) => {
    setEauctions([...singleEauction, ...eauctions]);
  };
  const handleAddMultiEauctions = (MultiEauctions) => {
    setEauctions([...MultiEauctions]);
  };
  const handleDeleteEauction = (singleCase) => {
    setEauctions(eauctions.filter((e) => e.id.toString() !== singleCase));
  };

  useInterval(async () => {
    let isMounted = true;
    const controller = new AbortController();

    const getEauction = async () => {
      try {
        const response = await axiosPrivate.get("/eauctions", {
          signal: controller.signal,
        });
        isMounted && setEauctions(response.data);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getEauction();

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, 30000);

  return (
    <section>
      {location.pathname.includes("/eauction/") ? (
        <MultiSingleEauction
          eauctions={eauctions.filter((r) => r.afm === afmParam.toString())}
        />
      ) : showModal ? (
        <AddEauctionModal
          handleClickAddEauctionModal={handleClickAddEauctionModal}
        />
      ) : (
        <AddEauction
          handleClickAddEauctionModal={handleClickAddEauctionModal}
          eauctions={eauctions}
          handleAddEauction={handleAddEauction}
          handleAddMultiEauctions={handleAddMultiEauctions}
        />
      )}
      {!location.pathname.includes("/eauction/") && (
        <MultiEauction
          eauctions={eauctions}
          handleDeleteEauction={handleDeleteEauction}
        />
      )}
    </section>
  );
};

export default Eauction;
