import { axiosPrivate } from '../../api/axios';
import { useState } from 'react';
import { DateTime, Settings } from 'luxon'
import { getAdjCourt } from '../../utils/getAdjCourts'
import { Link, useLocation } from 'react-router-dom'
import Pagination from "../Util/Pagination";
import { Paginated } from '../Util/Pagination/Paginated'
import { Apofaseis } from './Diavgeia/Apofaseis';

Settings.defaultLocale = 'el'

const removeZeroes = (str) => {
  let res = str
  while (res.at(0) === '0') {
    res = res.replace('0', '')
  }
  return res
}
Date.createFromMysql = function (mysql_string) {
  var t, result = null;

  if (typeof mysql_string === 'string') {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
}
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({ hours: 3 }).toFormat('ff')
  return formatedDate
}
const VDiavgeiaCard = ({ singleEtairia, handleDeleteCase }) => {
  const [note, setNote] = useState(singleEtairia?.note)

  const [showEdit, setShowEdit] = useState(false)
  const location = useLocation();
  const [page, setPage] = useState(0);
  function dividePages(arr) {
    const res = []
    for (let index = 0; index < arr.length; index++) {
      const el = []
      if (index % 5 === 0) {
        res.push(el)
      }

      let i = Math.floor(index / 5)
      res[i].push(arr[index])
    }
    return res

  }

  const handlePage = async (e) => {
    if (e.target.getAttribute("data-key") === 'next') {
      setPage(page + 1)
    }
    if (e.target.getAttribute("data-key") === 'end') {
      setPage(dividePages(singleEtairia.eggrafa).length - 1)
    }
    if (e.target.getAttribute("data-key") === 'previous') {
      setPage(page - 1)
    }
    if (e.target.getAttribute("data-key") === 'start') {
      setPage(0)
    }



  }

  const handleEdit = () => {
    setShowEdit(!showEdit)
  }
  const handleUpdateNote = async (e) => {
    setShowEdit(!showEdit)
    try {
      const id = e.target.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.post('/adjustice/note',
        JSON.stringify({ id, note }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );


    } catch (err) {
      console.error(err);
    }
  }
  const handleKeyPress = async (e) => {

    if (e.key === 'Enter') {
      console.log('ji');
      console.log(e.target.getAttribute('data-key'));

      setShowEdit(!showEdit)
      try {
        const id = e.target.getAttribute('data-key')
        // console.log(JSON.stringify({ key: id }));
        await axiosPrivate.post('/adjustice/note',
          JSON.stringify({ id, note }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );


      } catch (err) {
        console.error(err);
      }
    }
  }

  const handleDelete = async (e) => {

    try {
      const id = e.target.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.patch('/etairies',
        JSON.stringify({ key: id }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log(id);
      handleDeleteCase(id)

    } catch (err) {
      console.error(err);
    }
  }
  console.log(singleEtairia);
  const katigoriesEggrafon = singleEtairia.map((r, i) => {
    return { ...JSON.parse(r.data), no: i }
  });
  console.log(katigoriesEggrafon);

  return (
    <section className='kartela-solon'>

      < section className='stoixeia-dikasimos-prothesmies' >
        <article className='adj-stoixeia-ypothesis'>
          {katigoriesEggrafon.length ? <><p>Τελευταίες Αποφάσεις στην Διαύγεια</p>
            <Paginated data={katigoriesEggrafon} Component={Apofaseis} /></> : <p>Δεν υπάρχουν προς το παρόν σχετικές αποφάσεις στην Διαύγεια</p>}

        </article>

      </section >

    </section >
  )

}

export default VDiavgeiaCard