import { useState, useEffect, useCallback } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import VEtairiaCard from "../Cards/VEtairiaCard";
import VDiavgeiaCard from "../Cards/VDiavgeiaCard";
import VEauctionCard from "../Cards/VEauctionCard";
import EtairiesHeader from "../Cards/EtairiesHeader";
import VMitrwoCard from "../Cards/VMitrwoCard";
import '../Etairies/etairies.css'

const ViewEtairia = () => {
  const [singleEtairia, setSingleEtairia] = useState();
  let params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  useEffect(() => {
    let title = ''
    if (singleEtairia?.gemi?.diakritikos !== undefined && singleEtairia?.gemi?.diakritikos?.trim() !== '') {
      title = title + singleEtairia?.gemi?.diakritikos + ' | '
    }
    if (singleEtairia?.gemi?.diakritikos === undefined || singleEtairia?.gemi?.diakritikos?.trim() === '') {
      title = title + singleEtairia?.gemi?.eponymia + ' | '
    }
    title = title + ' MySolon.gr'


    document.title = title


  }, [location, singleEtairia])

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getEtairia = async () => {
      try {
        const response = await axiosPrivate.get(`/etairies/${params.caseId}`, {
          signal: controller.signal
        });
        isMounted && setSingleEtairia(response.data);
      } catch (err) {
        console.error(err);
        navigate('/login', { state: { from: location }, replace: true });
      }
    }

    getEtairia();

    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, location])


  if (singleEtairia // 👈 null and undefined check
    && Object.keys(singleEtairia).length !== 0
    && Object.getPrototypeOf(singleEtairia) === Object.prototype) {
    console.log(singleEtairia);
  }

  return (
    <>
      <EtairiesHeader />
      {(params.page === 'gemi' && singleEtairia) && <div><VEtairiaCard singleEtairia={{ ...singleEtairia?.gemi, eggrafa: singleEtairia?.eggrafa }} /></div>}
      {(params.page === 'diavgeia' && singleEtairia) && <div><VDiavgeiaCard singleEtairia={singleEtairia?.diavgeia} /></div>}
      {(params.page === 'eauction' && singleEtairia) && <div><VEauctionCard singleEtairia={singleEtairia?.eauction} /></div>}
      {(params.page === 'mitrwo' && singleEtairia) && <div><VMitrwoCard singleEtairia={singleEtairia?.mitrwo} /></div>}


    </>
  )
}

export default ViewEtairia