const Pagination = (page) => {

  if (page.length > 1 && page.page !== 0 && page.page !== page.length - 1) {

    return (
      <div className="pagination">
        <button className="pagination-button" onClick={page.handlePage} data-key={'start'} disabled={page === 0}>&lt;&lt;</button>
        <button className="pagination-button" onClick={page.handlePage} data-key={'previous'}>&lt;</button>
        <span className="pagination-text">Σελ.{page.page + 1} από {page.length}</span>
        <button className="pagination-button" onClick={page.handlePage} data-key={'next'}>&gt;</button>
        <button className="pagination-button" onClick={page.handlePage} data-key={'end'}>&gt;&gt;</button>
      </div>
    )

  };
  if (page.length === 1) {

    return (
      <div className="pagination">
        <span className="pagination-text">Σελ.{page.page + 1} από {page.length}</span>
      </div>
    )

  };
  if (page.length > 1 && page.page === 0) {

    return (
      <div className="pagination">
        <button className="pagination-button" onClick={page.handlePage} data-key={'start'} disabled>&lt;&lt;</button>
        <button className="pagination-button" onClick={page.handlePage} data-key={'previous'} disabled>&lt;</button>
        <span className="pagination-text">Σελ.{page.page + 1} από {page.length}</span>
        <button className="pagination-button" onClick={page.handlePage} data-key={'next'}>&gt;</button>
        <button className="pagination-button" onClick={page.handlePage} data-key={'end'}>&gt;&gt;</button>
      </div>
    )

  };
  if (page.length > 1 && page.page === page.length - 1) {

    return (
      <div className="pagination">

        <button className="pagination-button" onClick={page.handlePage} data-key={'start'}>&lt;&lt;</button>
        <button className="pagination-button" onClick={page.handlePage} data-key={'previous'}>&lt;</button>
        <span className="pagination-text">Σελ.{page.page + 1} από {page.length}</span>
        <button className="pagination-button" onClick={page.handlePage} data-key={'next'} disabled>&gt;</button>
        <button className="pagination-button" onClick={page.handlePage} data-key={'end'} disabled>&gt;&gt;</button>
      </div>
    )

  };
}

export default Pagination