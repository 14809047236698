import React, { useEffect } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";
import LinkParser from "react-link-parser";
import HeaderAvatar from "../Visual/HeaderAvatar";
import { HeartComment } from "./Hearts/HeartComment";
import { formatDate } from "./Ekloges";
import AuthContext from "../../context/AuthProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { watchers } from "./Post";

function Comment({ comment, handleDeleteComment, handleWriteReplyClick }) {
  comment?.new && console.log("new", JSON.stringify(comment));

  const [isLiked, setIsLiked] = React.useState(comment?.liked);
  const [likes, setLikes] = React.useState(comment?.likes ? comment?.likes : 0);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const handleCommentLike = async () => {
    if (isLiked) {
      setLikes(likes - 1);
    } else {
      setLikes(likes + 1);
    }
    setIsLiked(!isLiked);
    const currentLikes = isLiked ? likes - 1 : likes + 1;
    const addedLikes = isLiked ? false : true;
    try {
      await axiosPrivate.post(
        `/posts/comments/${comment?.uuid}`,
        JSON.stringify({
          body: comment?.body,
          likes: currentLikes,
          id: comment?.id,
          addedLikes,
          uuid: comment?.uuid,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const { auth } = React.useContext(AuthContext);
  return (
    <div
      className="post-comment"
      id={`comment-${comment?.uuid}`}
      key={`comment-${comment?.uuid}`}
    >
      <div className="post-comment-header">
        <Link to={`/profile/${comment?.user?.uuid}`}>
          <HeaderAvatar
            userAvatar={comment?.user?.avatar_uuid}
            avatarClass={"smd"}
            uuid={comment?.user?.uuid}
            avatarUploaded={false}
            badge={comment?.user?.badge}
            rank={comment?.user?.rank}
          />
        </Link>
        <div className="post-comment-header-details">
          <span className="post-comment-header-time">{comment?.timeSince}</span>
        </div>
      </div>
      <div className="post-comment-author-content">
        <Link to={`/profile/${comment?.user?.uuid}`}>
          <span className="post-comment-header-poster">
            {comment?.user?.email}
          </span>
        </Link>
        <div className="post-comment-content">
          <p className="post-comment-text" style={{ whiteSpace: "pre-line" }}>
            <LinkParser watchers={watchers}>{comment?.body}</LinkParser>
          </p>
        </div>
        <HeartComment commentLikes={likes} />
        <div className="post-comment-header-details">
          <span className="post-comment-header-time">
            {formatDate(comment?.created_at)}
          </span>
          <button
            className={"post-comment-like"}
            onClick={() => handleWriteReplyClick(comment?.user?.email)}
          >
            Απαντήστε
          </button>

          <button
            className={isLiked ? "post-comment-liked" : "post-comment-like"}
            onClick={() => handleCommentLike()}
          >
            {isLiked ? "Μου Αρέσει" : "Μου Αρέσει"}
          </button>

          {(comment?.user?.id === auth.id || comment?.new) && (
            <button
              className="post-comment-delete"
              onClick={() => handleDeleteComment(comment?.uuid)}
            >
              Διαγραφή
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Comment;
