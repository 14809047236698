import React, { useEffect, useRef } from "react";
import { nanoid } from "nanoid";
import { BookmarkIcon } from "@heroicons/react/solid";
import {
  Bold,
  Callout,
  Card,
  Flex,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Divider,
  TextInput,
  Badge,
  Icon,
  Button,
} from "@tremor/react";
import LinkParser from "react-link-parser";
import parse from "html-react-parser";
import {
  SearchIcon,
  PaperAirplaneIcon,
  StatusOnlineIcon,
  EyeIcon,
  BriefcaseIcon,
} from "@heroicons/react/solid";
import { SwitchHorizontalIcon } from "@heroicons/react/outline";
import AuthContext from "../../context/AuthProvider";

import Like from "./Like";
import HeaderAvatar from "../Visual/HeaderAvatar";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Comment from "./Comment";
import { LikesModal } from "./LikesModal";
import { Heart } from "./Hearts/Heart";
import { eidiToGrMap, formatDate } from "./Ekloges";
import {
  getCurrentDateTime,
  getCurrentUTCDateTime,
} from "../Util/getCurrentDateTime";
import { DeletePostModal } from "../Profile/DeleteUserModal";
import { useInterval } from "../../utils/useInterval";
export const watchers = [
  {
    type: "startsWith",
    watchFor: "@",
    render: (url) => (
      <a
        href={`mailto:${url.replace("@", "")}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {url}
      </a>
    ),
  },
  {
    type: "startsWith",
    watchFor: "https://scontent",
    render: (url) => (
      <img src={url} alt="post" loading="lazy" className="w-full h-auto" />
    ),
  },
  {
    type: "startsWith",
    watchFor: "https://lh3.googleusercontent.com/",
    render: (url) => (
      <img src={url} alt="post" loading="lazy" className="w-full h-auto" />
    ),
  },
  {
    type: "startsWith",
    watchFor: "https://media1.giphy.com/",
    render: (url) => (
      <img src={url} alt="post" loading="lazy" className="w-full h-auto" />
    ),
  },
  {
    type: "endsWith",
    watchFor: ".webp",
    render: (url) => (
      <img src={url} alt="post" loading="lazy" className="w-full h-auto" />
    ),
  },
  {
    type: "endsWith",
    watchFor: ".gif",
    render: (url) => (
      <img src={url} alt="post" loading="lazy" className="w-full h-auto" />
    ),
  },
  {
    type: "endsWith",
    watchFor: "&t=0",
    render: (url) => (
      <iframe
        src={url}
        width="450"
        height="476"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        className="w-full h-auto"
      ></iframe>
    ),
  },

  {
    watchFor: "link",
    render: (url) => (
      <a href={url} target="_blank" rel="noreferrer noopener nofollow">
        {url}
      </a>
    ),
  },
  {
    type: "endsWith",
    watchFor: ".jpeg",
    render: (url) => (
      <img src={url} alt="post" loading="lazy" className="w-full h-auto" />
    ),
  },

  {
    watchFor: "link",
    render: (url) => (
      <a href={url} target="_blank" rel="noreferrer noopener nofollow">
        {url}
      </a>
    ),
  },
  {
    watchFor: "email",
    render: (url) => (
      <a href={`mailto:${url}`} target="_blank" rel="noreferrer noopener">
        {url}
      </a>
    ),
  },
];

const SimplePost = ({
  creator,
  timeSince,
  body,
  avatar,
  uuid,
  postUuid,
  postCreatorId,
  deletePost,
  grammateas,
  email,
  post,
  getPosts,
  singlePost,
  activeUuid,
}) => {
  const postRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [sameCreator, setSameCreator] = React.useState(false);

  const { auth } = React.useContext(AuthContext);
  const { avatarUploaded } = React.useContext(AuthContext);
  const [likes, setLikes] = React.useState(post?.likes);
  const [isLiked, setIsLiked] = React.useState(post?.liked);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [likesList, setLikesList] = React.useState([]);
  const [comments, setComments] = React.useState(post?.comments);
  const [currentComment, setCurrentComment] = React.useState("");
  const [truncatedBody, setTruncatedBody] = React.useState(
    body?.slice(0, 320) + "..."
  );
  const [showMore, setShowMore] = React.useState(false);
  const [lastCommentElement, setLastCommentElement] = React.useState(null);
  const [showComments, setShowComments] = React.useState(!!singlePost);
  const [isModalDeletePostOpen, SetIsModalDeletePostOpen] =
    React.useState(false);

  const [isPostingViews, setIsPostingViews] = React.useState(false);

  useEffect(() => {
    setLikes(post?.likes);
    setIsLiked(post?.liked);
    setComments(post?.comments);
  }, [post]);

  const handleWriteReplyClick = (mention) => {
    const commentsTextarea = document.getElementById(`comment-${postUuid}`);
    setCurrentComment(`@${mention} `);
    if (commentsTextarea) {
      commentsTextarea.focus();
    }
  };
  const handleNewCommentSubmit = async (e) => {
    const uuid = nanoid();
    e.preventDefault();
    try {
      await axiosPrivate.post(`/posts/comments/${postUuid}`, {
        body: currentComment,
        post_id: post?.id,
        uuid,
        initial: true,
      });
      setCurrentComment("");
      // Add the new comment to the list of comments
      setComments([
        {
          body: currentComment,
          user: { avatar_uuid: auth.avatar, uuid: auth.uuid, email: auth.user },
          created_at: getCurrentUTCDateTime(),
          uuid,
          new: true,
          likes: 0,
        },
        ...comments,
      ]);
      singlePost && getPosts();
      // fetchComments();
      setLastCommentElement(`comment-${uuid}`);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  useEffect(() => {
    if (lastCommentElement) {
      const element = document.getElementById(lastCommentElement);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }
  }, [lastCommentElement]);
  const handleDeleteComment = async (comment_uuid) => {
    try {
      await axiosPrivate.patch(`/comments/${comment_uuid}`);
      // Remove the comment from the list of comments
      setComments(comments.filter((c) => c.uuid !== comment_uuid));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleClickLikes = async () => {
    if (isLiked) {
      setLikes(likes - 1);
    } else {
      setLikes(likes + 1);
    }
    setIsLiked(!isLiked);
    const currentLikes = isLiked ? likes - 1 : likes + 1;
    const addedLikes = isLiked ? false : true;
    try {
      await axiosPrivate.post(
        `/posts/${postUuid}`,
        JSON.stringify({ body, likes: currentLikes, id: post?.id, addedLikes }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const fetchLikes = async () => {
    try {
      const response = await axiosPrivate.get(`/posts/likes/${postUuid}`);
      setLikesList(response.data);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const handleLikesClick = async () => {
    // Open the modal
    setIsModalOpen(true);

    // Here you can make a request to your backend to get the list of users who liked the post?.
    // For now, let's just use a static list
    fetchLikes();
  };
  const handleDelete = async (e) => {
    console.log("delete");
    e?.preventDefault();

    try {
      await axiosPrivate.patch(`/posts/${postUuid}`, JSON.stringify({ body }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });

      // forceUpdate()
      //console.log(JSON.stringify(response));
      deletePost(postUuid);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  useEffect(() => {
    if (postCreatorId === auth.id) {
      setSameCreator(true);
    }
    if (postCreatorId !== auth.id) {
      setSameCreator(false);
    }
  }, [postCreatorId, auth.id]);

  useEffect(() => {
    const handleScroll = () => {
      const element = postRef.current;
      if (element && isElementInView(element)) {
        // Store the element in localStorage
        // if key doesn't exist, add it to localStorage
        if (!localStorage.getItem(postRef.current.id)) {
          localStorage.setItem(postRef.current.id, Date.now());
        }
      }
    };

    // Add event listener to track scrolling
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const element = postRef.current;
    if (element && isElementInView(element)) {
      // Store the element in localStorage
      // if key doesn't exist, add it to localStorage
      if (!localStorage.getItem(postRef.current.id)) {
        localStorage.setItem(postRef.current.id, Date.now());
      }
    }
  }, [activeUuid]);

  // Helper function to check if an element is in the viewport
  const isElementInView = (element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <=
        (window.innerWidth || document.documentElement.clientWidth) &&
      activeUuid === post?.uuid
    );
  };

  function handleAllCommentsClick() {
    setShowComments(true);
  }
  return (
    <>
      {body && (
        <>
          <Card
            ref={postRef}
            id={`post-${post?.id}`}
            className="max-w-md p-1 cursor-pointer"
            onClick={() => {
              navigate(`/post/${activeUuid}`);
            }}
          >
            <div className="post-header" id={post?.uuid}>
              <HeaderAvatar
                userAvatar={avatar}
                avatarClass={"md"}
                uuid={uuid}
                avatarUploaded={false}
                badge={post?.user?.badge}
                rank={post?.user?.rank}
              />

              <div className="post-header-details">
                <div className="post-header-details-top">
                  {creator?.trim() === "null null" && (
                    <>
                      <Link to={`/profile/${uuid}`}>
                        <span className="post-header-poster">{email}</span>
                      </Link>
                    </>
                  )}
                  {creator?.trim() !== "null null" && (
                    <>
                      <Link to={`/profile/${uuid}`}>
                        <span className="post-header-poster">{creator}</span>
                      </Link>
                    </>
                  )}
                </div>
                {!singlePost ? (
                  <Link to={`/post/${postUuid}`}>
                    <span className="post-header-time">{timeSince}</span>
                  </Link>
                ) : (
                  <span className="post-header-time">{timeSince}</span>
                )}
                {post?.eidos && post?.periohi && (
                  <div className="post-ergasia pt-0">
                    <Callout
                      color="blue"
                      size="sm"
                      icon={BriefcaseIcon}
                      title={`${eidiToGrMap[post?.eidos]} - ${post?.periohi}`}
                    >
                      {post?.daterange && (
                        <span className="post-ergasia-daterange">
                          Διάστημα από{" "}
                          {new Date(
                            post?.daterange.split("_")[0].replaceAll("-", "/")
                          ).toLocaleDateString("el-GR")}{" "}
                          έως{" "}
                          {new Date(
                            post?.daterange.split("_")[1].replaceAll("-", "/")
                          ).toLocaleDateString("el-GR")}
                        </span>
                      )}
                    </Callout>
                  </div>
                )}
              </div>
            </div>
            {/* if the body is more than 300 characters, truncate and add an option to show more  */}

            {/* {!singlePost && body.length > 330 && !showMore ? (
              <>
                <div className="post-content">
                  <p className="post-text" style={{ whiteSpace: "pre-line" }}>
                    <LinkParser
                      watchers={watchers}
                      parseNewLine={true}
                      newLineWatcher="\n"
                    >
                      {truncatedBody}
                    </LinkParser>
                    <span
                      className="post-text-showmore"
                      onClick={() => setShowMore(true)}
                    >
                      Διάβασε περισσότερα
                    </span>
                  </p>
                </div>
              </>
            ) : (
              <div className="post-content">
                <p className="post-text" style={{ whiteSpace: "pre-line" }}>
                  <LinkParser
                    watchers={watchers}
                    parseNewLine={true}
                    newLineWatcher="\n"
                  >
                    {body}
                  </LinkParser>
                </p>
              </div>
            )} */}
          </Card>
        </>
      )}
    </>
  );
};

export default SimplePost;
