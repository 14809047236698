import React from "react";

const MisthotesRow = ({
  handleMiInputChange,
  handleMiFyloChange,
  misthotis,
  i,
  removeMisthotis,
  length,
}) => {
  const {
    onoma_mi = "",
    eponymo_mi = "",
    patronymo_mi = "",
    AFM_mi = "",
    katoikia_mi = "",
    fylo_mi = "Άνδρας",
  } = misthotis;

  return (
    <div className="all">
      <div className="vertical-line-focus"></div>
      <div className="form-section-row">
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="ekmisthotes-name">
            Όνομα
          </label>
          <input
            className="form-section-input"
            type="text"
            name="onoma_mi"
            value={misthotis.onoma_mi}
            onChange={(e) => handleMiInputChange(e, i)}
            onBlur={(e) => handleMiFyloChange(e, i)}
            placeholder="πχ. Ιωάννης"
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="ekmisthotes-surname">
            Επώνυμο
          </label>
          <input
            className="form-section-input"
            type="text"
            name="eponymo_mi"
            value={misthotis.eponymo_mi}
            onChange={(e) => handleMiInputChange(e, i)}
            placeholder="πχ. Παπαδόπουλος"
          />
        </div>
        <div className="form-section-single-input">
          <label
            className="form-section-label"
            htmlFor="ekmisthotes-fathername"
          >
            Πατρώνυμο
          </label>
          <input
            className="form-section-input"
            type="text"
            name="patronymo_mi"
            value={misthotis.patronymo_mi}
            onChange={(e) => handleMiInputChange(e, i)}
            placeholder="πχ. Κωνσταντίνος"
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="ekmisthotes-afm">
            ΑΦΜ
          </label>
          <input
            className="form-section-input"
            type="text"
            id="ekmisthotes-afm"
            name="AFM_mi"
            value={misthotis.AFM_mi}
            onChange={(e) => handleMiInputChange(e, i)}
            placeholder="πχ. 123456789"
          />
        </div>
        <div className="form-section-single-input double-input-width">
          <label className="form-section-label" htmlFor="ekmisthotes-katoikos">
            Κάτοικος
          </label>
          <input
            className="form-section-input"
            type="text"
            id="ekmisthotes-katoikos"
            value={misthotis.katoikia_mi}
            name="katoikia_mi"
            onChange={(e) => handleMiInputChange(e, i)}
            placeholder="πχ. Αθηνών, οδός Ομήρου. αρ.73"
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="misthotis-gender">
            Φύλο
          </label>
          <select
            className="form-section-input"
            id="misthotis-gender"
            value={misthotis.fylo_mi}
            name="fylo_mi"
            onChange={(e) => handleMiFyloChange(e, i)}
          >
            <option value="Άνδρας">Άνδρας</option>
            <option value="Γυναίκα">Γυναίκα</option>
          </select>
        </div>
        <div className="form-section-single-input button-row">
          {length > 1 && (
            <button
              type="button"
              className="form-section-button"
              onClick={(e) => {
                e.preventDefault();
                removeMisthotis(e, i);
              }}
            >
              <img
                src="/images/trash-can-solid.svg"
                alt="Delete"
                className="icon"
              />
              Διαγραφή
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MisthotesRow;
