import { useRef, useState, useEffect } from "react";
import useAuth from '../hooks/useAuth';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from '../api/axios';
import { Link, useNavigate } from "react-router-dom";

const USER_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
const PWD_REGEX = /^(?=.*\d).{8,55}$/;
const REGISTER_URL = '/register';

const Register = () => {
  const { setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();
  const navigate = useNavigate();


  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [info, setInfo] = useState({ astikes: '', users: '', decisions: '' });


  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);



  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user])

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(REGISTER_URL,
        JSON.stringify({ email: user.toLowerCase(), password: pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      const accessToken = response?.data?.accessToken;
      const role = response?.data?.role;
      const uuid = response?.data?.uuid;
      const avatar = response?.data?.avatar;
      const id = response?.data?.id;

      // console.log(response);
      // console.log(accessToken);
      setAuth({ user, pwd, accessToken, role, uuid, avatar, id });
      setSuccess(true);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUser('');
      setPwd('');

      navigate('/', { replace: true });
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 409) {
        setErrMsg('Username Taken');
      } else {
        setErrMsg(err?.response?.data?.message)
      }
      errRef.current.focus();
    }
  }


  return (
    <>
      {success ? (
        <section>
          <h1>Εγγραφήκατε επιτυχώς!</h1>
          <p>
            {/* <a href="#">Sign In</a> */}
          </p>
        </section>
      ) : (
        <section className="container">
          <div className="solon-info"><p>To <span className='bold'>MySolon.gr</span> απευθύνεται <span className='bold'>αποκλειστικά σε δικηγόρους</span></p></div>
          <br />
          <div className="solon-info"><p>Παρακαλώ δηλώστε κατά την εγγραφή σας, το email που χρησιμοποιείτε στην <span className='bold'>επαγγελματική σας δραστηριότητα</span>, για να μπορέσει να γίνει η επαλήθευση της δικηγορικής σας ιδιότητας.</p></div>
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <form className="form" onSubmit={handleSubmit}>

            {/* <label htmlFor="username">
              Username:
              <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
              <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
            </label> */}
            <div className="input-container ic1">
              <input
                className={userFocus && user && !validName ? "input input_error" : "input"} type="email"
                id="email"
                ref={userRef}
                autoComplete="on"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                placeholder=' '
                required
                aria-invalid={validName ? "false" : "true"}
                aria-describedby="uidnote"
                onFocus={() => setUserFocus(true)}
                onBlur={() => setUserFocus(false)}
              />
              <div className="cut"></div>
              <label htmlFor="email" className="placeholder">email</label>

              <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                Μη έγκυρο email<br />
              </p>
            </div>
            <div className="input-container ic2">
              <input
                className={pwdFocus && pwd && !validPwd ? "input input_error" : "input"}
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                autoComplete="on"
                required
                placeholder=' '
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <div className="cut"></div>
              <label htmlFor="password" className="placeholder">password</label>
              <p id="pwdnote" className={pwdFocus && pwd && !validPwd ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                Τουλάχιστον 8 χαρακτήρες εκ των οποίων ένας αριθμός και ένα γράμμα.
              </p>


            </div>

            <button type="text" className="submit" disabled={!validName || !validPwd ? true : false}>Εγγραφή</button>
          </form>

          <p>
            Έχετε ήδη εγγραφεί; &nbsp;
            <span className="line">
              <Link to="/"> Σύνδεση</Link>
            </span>
          </p>
        </section>
      )
      }
    </>
  )
}

export default Register