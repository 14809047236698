import React from "react";
import { ktimat as ktimatUnfilt } from "../Ktimat";
import { XCircleIcon } from "@heroicons/react/solid";
import {
  MultiSelect,
  MultiSelectItem,
  Icon,
  List,
  ListItem,
} from "@tremor/react";
import {
  removeDiacriticsAndCapitalize,
  removeDuplicates,
} from "../../../utils/checkMisthosi";

const ChooseKtimat = ({ ktimatSelected, setKtimatSelected }) => {
  const ktimat = removeDuplicates(
    ktimatUnfilt.map((dim) => removeDiacriticsAndCapitalize(dim)).sort()
  );
  const handleDeleteListItem = (e) => {
    const value =
      e.target.parentNode.parentNode.parentNode.firstChild.textContent;
    const newKtimatSelected = ktimatSelected.filter((dim) => dim !== value);
    setKtimatSelected(newKtimatSelected);
  };

  return (
    <div className="form-group">
      <label htmlFor="ktimat">Κτημ.Γραφεία:</label>
      <MultiSelect
        placeholder="Επιλέξτε Κτημ.Γραφείο"
        onValueChange={setKtimatSelected}
        value={ktimatSelected}
      >
        {ktimat.map((dim, i) => {
          return (
            <MultiSelectItem key={`${i}-${dim}`} value={dim}>
              {dim}
            </MultiSelectItem>
          );
        })}
      </MultiSelect>
      <List>
        {ktimatSelected?.map((dim, i) => {
          return (
            <ListItem className="dimoi-list" key={`${i}-${dim}`}>
              <span>{dim}</span>
              <span>
                <Icon
                  onClick={handleDeleteListItem}
                  className={"cursor-pointer"}
                  icon={XCircleIcon}
                  size={"sm"}
                  color="red"
                  tooltip="Διαγραφή"
                ></Icon>
              </span>
              <ListItem />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ChooseKtimat;
