import { removeDiacriticsAndCapitalize } from "../../utils/checkMisthosi";

export const dioikProtodikeia = [
  "ΑΘΗΝΩΝ",
  "ΠΕΙΡΑΙΩΣ",
  "ΛΑΜΙΑΣ",
  "ΛΙΒΑΔΕΙΑΣ",
  "ΜΥΤΙΛΗΝΗΣ",
  "ΡΟΔΟΥ",
  "ΣΥΡΟΥ",
  "ΧΑΛΚΙΔΑΣ",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΒΕΡΟΙΑΣ",
  "ΚΟΖΑΝΗΣ",
  "ΣΕΡΡΩΝ",
  "ΙΩΑΝΝΙΝΩΝ",
  "ΚΕΡΚΥΡΑΣ",
  "ΚΟΜΟΤΗΝΗΣ",
  "ΑΛΕΞΑΝΔΡΟΥΠΟΛΗΣ",
  "ΚΑΒΑΛΑΣ",
  "ΛΑΡΙΣΑΣ",
  "ΒΟΛΟΥ",
  "ΠΑΤΡΩΝ",
  "ΑΓΡΙΝΙΟΥ",
  "ΜΕΣΟΛΟΓΓΙΟΥ",
  "ΠΥΡΓΟΥ",
  "ΤΡΙΠΟΛΗΣ",
  "ΚΑΛΑΜΑΤΑΣ",
  "ΚΟΡΙΝΘΟΥ",
  "ΝΑΥΠΛΙΟΥ",
  "ΧΑΝΙΩΝ",
  "ΗΡΑΚΛΕΙΟΥ",
]
  .map((dim) => removeDiacriticsAndCapitalize(dim))
  .sort();
