import React from "react";

import { StatusOnlineIcon } from "@heroicons/react/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
} from "@tremor/react";

const Appeals = ({ data }) => {
  return (
    <Card className="mt-3">
      <Title>Ένδικα Μέσα</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>ΓΑΚ</TableHeaderCell>
            <TableHeaderCell>ΕΤΟΣ</TableHeaderCell>
            <TableHeaderCell>Είδος</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => (
            <TableRow key={`appeals-${i}`}>
              <TableCell>{item.gak_endikou}</TableCell>
              <TableCell>
                <Text>{item.etos_endikou}</Text>
              </TableCell>
              <TableCell>{item.eidos}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default Appeals;
