import { axiosPrivate } from "../../api/axios";
import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faCircleCheck,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import AuthContext from "../../context/AuthProvider";
import { StarIcon } from "@heroicons/react/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/solid";
import { Icon } from "@tremor/react";

import { DateTime, Settings } from "luxon";
import { getAdjCourt } from "../../utils/getAdjCourts";
import { formatNowDate } from "../CaseCard";
Settings.defaultLocale = "el";
export const dioikCourts = [
  "ΣτΕ",
  "ΔΕφ Αθηνών",
  "ΔΕφ Θεσσαλονίκης",
  "ΔΕφ Πειραιά",
  "ΔΠρ Αθηνών",
  "ΔΠρ Θεσσαλονίκης",
  "ΔΠρ Πειραιά",
  "ΔΕφ Κομοτηνής",
  "ΔΠρ Αλεξανδρούπολης",
  "ΔΠρ Καβάλας",
  "ΔΠρ Κομοτηνής",
  "ΔΠρ Βέροιας",
  "ΔΠρ Σερρών",
  "ΔΠρ Κοζάνης",
  "ΔΕφ Ιωαννίνων",
  "ΔΠρ Ιωαννίνων",
  "ΔΕφ Λάρισας",
  "ΔΠρ Βόλου",
  "ΔΠρ Λάρισας",
  "ΔΠρ Τρικάλων",
  "ΔΠρ Κέρκυρας",
  "ΔΕφ Πάτρας",
  "ΔΠρ Αγρινίου",
  "ΔΠρ Μεσολογγίου",
  "ΔΠρ Πάτρας",
  "ΔΠρ Πύργου",
  "ΔΠρ Λαμίας",
  "ΔΠρ Λιβαδειάς",
  "ΔΠρ Χαλκίδας",
  "ΔΕφ Τρίπολης",
  "ΔΠρ Καλαμάτας",
  "ΔΠρ Κορίνθου",
  "ΔΠρ Ναυπλίου",
  "ΔΠρ Τρίπολης",
  "ΔΠρ Μυτιλήνης",
  "ΔΠρ Ρόδου",
  "ΔΠρ Σύρου",
  "ΔΕφ Χανίων",
  "ΔΠρ Ηρακλείου",
  "ΔΠρ Χανίων",
];
export const dioikLinks = [
  "ste/pageste/stepage",
  "defeteioath",
  "defeteiothe",
  "defeteiopei",
  "dprotodikeioath",
  "dprotodikeiothe",
  "dprotodikeiopei",
  "defeteiokom",
  "dprotodikeioale",
  "dprotodikeiokav",
  "dprotodikeiokom",
  "dprotodikeiover",
  "dprotodikeioser",
  "dprotodikeiokoz",
  "defeteioiwa",
  "dprotodikeioiwa",
  "defeteiolar",
  "dprotodikeiovol",
  "dprotodikeiolar",
  "dprotodikeiotri",
  "dprotodikeioker",
  "defeteiopat",
  "dprotodikeioagr",
  "dprotodikeiomes",
  "dprotodikeiopat",
  "dprotodikeiopyr",
  "dprotodikeiolam",
  "dprotodikeioliv",
  "dprotodikeioxal",
  "defeteiotrp",
  "dprotodikeiokal",
  "dprotodikeiokor",
  "dprotodikeionaf",
  "dprotodikeiotrp",
  "dprotodikeiomyt",
  "dprotodikeiorod",
  "dprotodikeiosyr",
  "defeteioxan",
  "dprotodikeiohra",
  "dprotodikeioxan",
];

const removeZeroes = (str) => {
  let res = str;
  while (res.at(0) === "0") {
    res = res.replace("0", "");
  }
  return res;
};
Date.createFromMysql = function (mysql_string) {
  var t,
    result = null;

  if (typeof mysql_string === "string") {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
};
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString())
    .plus({ hours: 3 })
    .toFormat("ff");
  return formatedDate;
}
const JCaseCard = ({ singleCase, handleDeleteCase }) => {
  const [note, setNote] = useState(singleCase?.note);
  const { checking, setChecking } = useContext(AuthContext);
  const [checkingStatus, setCheckingStatus] = useState("notstarted");
  const [last_checked, setLast_checked] = useState(
    formatDate(singleCase.last_checked)
  );

  const [showEdit, setShowEdit] = useState(false);
  const [faved, setFaved] = useState(singleCase.fav === 1 ? true : false);

  // const [showProth, setShowProth] = useState(false)
  // const [checkAppeal, setCheckAppeal] = useState(singleEtairia.check_appeal === 1 ? true : false)
  // console.log(prothesmiesSingle);
  const handleClicks = async (e) => {
    if (e.target.parentNode.getAttribute("data-icon") === "pen-to-square") {
      setShowEdit(!showEdit);
    }
    if (e.target.parentNode.getAttribute("data-icon") === "circle-check") {
      setShowEdit(!showEdit);
      try {
        const id = e.target.parentNode.getAttribute("data-key");
        await axiosPrivate.post(
          "/adjustice/note",
          JSON.stringify({ id, note }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
      } catch (err) {
        console.error(err);
      }
    }
    if (
      e.currentTarget?.firstChild?.getAttribute("data-icon") === "trash-can"
    ) {
      try {
        const id = e.currentTarget.getAttribute("data-key");
        await axiosPrivate.patch("/adjustice", JSON.stringify({ key: id }), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        handleDeleteCase(id);
      } catch (err) {
        console.error(err);
      }
    }
    // if (e.target?.parentNode?.getAttribute('data-icon') === null) {
    //   handleOnClick()
    // }
  };
  const handleEdit = () => {
    setShowEdit(!showEdit);
  };
  const handleUpdateNote = async (e) => {
    setShowEdit(!showEdit);
    try {
      const id = e.target.getAttribute("data-key");
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.post("/adjustice/note", JSON.stringify({ id, note }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleCheck = async (e) => {
    setChecking([
      ...checking,
      { case_id: singleCase.id, category: "adj_check" },
    ]);
    setCheckingStatus("started");

    try {
      const id = e.target.getAttribute("data-key");
      await axiosPrivate.post("/adjustice/check", JSON.stringify({ id }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      const id = e.target.parentNode.parentNode.getAttribute("data-key");
      console.log(id);

      setShowEdit(!showEdit);
      try {
        // console.log(JSON.stringify({ key: id }));
        await axiosPrivate.post(
          "/adjustice/note",
          JSON.stringify({ id, note }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
      } catch (err) {
        console.error(err);
      }
    }
  };
  const handleFav = async () => {
    setFaved(!faved);
    try {
      const id = singleCase.id;
      await axiosPrivate.post(
        "/adjustice/fav",
        JSON.stringify({ id, fav: !faved }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = async (e) => {
    try {
      const id = e.target.getAttribute("data-key");
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.patch("/etairies", JSON.stringify({ key: id }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      console.log(id);
      handleDeleteCase(id);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (checkingStatus === "started" && checking.length === 0) {
      setCheckingStatus("finished");
      setLast_checked(formatNowDate());
    }
  }, [checking, checkingStatus]);
  // const handleAppeal = async (e) => {
  //   setCheckAppeal(!checkAppeal)

  //   try {
  //     const id = e.target.getAttribute('data-key')
  //     // console.log(JSON.stringify({ key: id }));
  //     await axiosPrivate.post('/appeals',
  //       JSON.stringify({ id, check: !checkAppeal }),
  //       {
  //         headers: { 'Content-Type': 'application/json' },
  //         withCredentials: true
  //       }
  //     );

  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  // const handleProth = async (e) => {

  //   setShowProth(!showProth)
  // }
  return (
    <article
      className="case_card"
      onKeyDownCapture={handleKeyPress}
      data-key={singleCase.id}
    >
      {/* <div key={`ed${singleCase.id}`} className="edit_case"><p ><span onClick={handleDelete} data-key={singleCase.id}>✏️</span></p></div> */}
      <span className="number">{singleCase?.i + 1}</span>
      <div key={singleCase.id} className="close_case">
        <p>
          <div
            className="fav-icon"
            onClick={() => {
              handleFav();
            }}
          >
            {faved && (
              <Icon
                icon={StarIconSolid}
                color="yellow"
                className={`cursor-pointer fav-star ml-[-50px]`}
                size="md"
              />
            )}

            <Icon
              icon={StarIcon}
              color="yellow"
              tooltip="Προσθήκη στα Σημαντικά"
              className="cursor-pointer ml-[-36px]"
              size="md"
            />
          </div>
          <span
            style={{
              border: "none",
              cursor: "pointer",
              color: "red",
              backgroundColor: "white",
              paddingLeft: "5px",
              fontSize: "1.3em",
            }}
            title="Διαγράψτε την υπόθεση"
            onClick={handleClicks}
            data-key={singleCase.id}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
        </p>
      </div>
      {/* {singleCase?.note?.length > 2 && <h4><em>Σημείωση:</em> {singleCase.note}</h4>}
      <h4><em>ΓΑΚ:</em> {singleCase?.gak}/{singleCase?.year}</h4>
      <h4><em>Δικαστήριο:</em> {singleCase?.court}</h4> */}
      <h4>
        <em>Σημείωση:</em>{" "}
        {showEdit ? (
          <>
            <input
              className="edit-note"
              placeholder={note}
              value={note}
              size={note.length}
              onChange={(e) => setNote(e.target.value)}
            />
            <button
              style={{
                border: "none",
                cursor: "pointer",
                color: "green",
                backgroundColor: "white",
                paddingLeft: "5px",
                fontSize: "1.3em",
              }}
              onClick={handleClicks}
              data-key={singleCase.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} />
            </button>
          </>
        ) : (
          <span>
            {note}
            <button
              data-icon="pen-to-square"
              title="Επεξεργαστείτε την σημείωση"
              style={{
                border: "none",
                cursor: "pointer",
                color: "green",
                backgroundColor: "white",
                paddingLeft: "5px",
                fontSize: "1.3em",
              }}
              onClick={handleClicks}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
          </span>
        )}
      </h4>
      {singleCase?.arkat?.length > 2 && (
        <h4>
          <em>Αρ.Καταθ.: </em>
          {singleCase?.arkat}
        </h4>
      )}
      {singleCase?.court?.length > 2 && (
        <h4>
          <em>Δικαστήριο: </em>
          {getAdjCourt(singleCase?.court)}
        </h4>
      )}
      {singleCase?.tmima?.length > 0 && (
        <h4>
          <em>Τμήμα: </em>
          {singleCase?.tmima}
        </h4>
      )}
      {singleCase?.synthesi?.length > 0 && (
        <h4>
          <em>Σύνθεση: </em>
          {singleCase?.synthesi}
        </h4>
      )}
      {singleCase?.katigoria?.length > 0 && (
        <h4>
          <em>Κατηγορία: </em>
          {singleCase?.katigoria}
        </h4>
      )}
      {singleCase?.apotelesma?.length > 0 && (
        <h4>
          <em>Αποτέλεσμα: </em>
          {singleCase?.apotelesma}
        </h4>
      )}
      {singleCase?.diataktiko?.length > 0 && (
        <h4>
          <em>Διατακτικό: </em>
          {singleCase?.diataktiko}
        </h4>
      )}
      {singleCase?.dikasimos?.length > 0 && (
        <h4>
          <em>Δικάσιμος: </em>
          {singleCase?.dikasimos}
        </h4>
      )}

      {singleCase?.created_at?.length > 2 && (
        <h4>
          <em>Δημιουργία: </em>
          {formatDate(singleCase?.created_at)}
        </h4>
      )}
      {singleCase?.last_checked?.length > 2 && (
        <h4 data-case="last_checked">
          <em>Τελευταίος Έλεγχος: </em>
          {formatDate(singleCase?.last_checked)}
        </h4>
      )}
      {singleCase?.last_checked && (
        <div className="add_case_buttons">
          <Link
            to={`/adjustice/${singleCase?.id}`}
            className="addcase_modal analytika"
          >
            Αναλυτικά
          </Link>
          <button
            className={`addcase_modal check_now button--loading`}
            data-key={singleCase?.id}
            onClick={handleCheck}
            disabled={
              Array.isArray(checking) &&
              checking.some(
                (e) => e.case_id === singleCase.id && e.category === "adj_check"
              )
            }
          >
            Ελέγξτε ξανά
          </button>
        </div>
      )}

      {/* {singleEtairia?.argemi?.length > 2 && <h4><em>Αρ.ΓΕΜΗ: </em>{singleEtairia?.argemi}</h4 >}
      {singleEtairia?.eponymia?.length > 2 && <h4><em>Επωνυμία: </em>{singleEtairia?.eponymia}</h4 >}
      {singleEtairia?.diakritikos?.length > 2 && <h4><em>Διακρ.Τίτλος: </em>{singleEtairia?.diakritikos}</h4 >}
      {singleEtairia?.aggliki?.length > 2 && <h4><em>Αγγλ.Επωνυμία: </em>{singleEtairia?.aggliki}</h4 >}
      {singleEtairia?.imnia_systasis?.length > 2 && <h4><em>Σύσταση: </em>{singleEtairia?.imnia_systasis.replace('Διεύθυνση Επιχείρησης', '')}</h4 >}
      {singleEtairia?.nomiki_morfi?.length > 2 && <h4><em>Νομική Μορφή: </em>{singleEtairia?.nomiki_morfi.replace('Διεύθυνση Επιχείρησης', '')}</h4 >}
      {singleEtairia?.katatastasi?.length > 2 && <h4><em>Κατάσταση: </em>{singleEtairia?.katatastasi.replace('Διεύθυνση Επιχείρησης', '')}</h4 >}
      {singleEtairia?.diefthinsi?.length > 2 && <h4><em>Διεύθυνση: </em>{singleEtairia?.diefthinsi.replace('Διεύθυνση Επιχείρησης', '')}</h4 >}
      {singleEtairia?.istoselida?.length > 2 && <h4><em>Ιστοσελίδα: </em><a href={'http://' + singleEtairia?.istoselida} target="_blank" rel="noreferrer">{singleEtairia?.istoselida}</a></h4 >}
      {singleEtairia?.dioikisi?.length > 2 && <h4><em>Διοίκηση: </em>{singleEtairia?.dioikisi}</h4 >}
      {singleEtairia?.argemi?.length > 2 && <h4><em>Link στο ΓΕΜΗ: </em><a href={'https://www.businessregistry.gr/publicity/show/' + removeZeroes(singleEtairia?.argemi)} target="_blank" rel="noreferrer">{singleEtairia?.argemi}</a></h4 >} */}

      {/* {singleCase.arithmos_pinakiou?.length > 2 && <h4><em>Αριθμός Πινακίου:</em> {singleCase.arithmos_pinakiou}</h4>}
      {singleCase.diadikasia?.length > 2 && <h4><em>Διαδικασία:</em> {singleCase.diadikasia}</h4>}
      {singleCase.antikeimeno?.length > 2 && <h4><em>Αντικείμενο:</em> {singleCase.antikeimeno}</h4>}
      {singleCase.apotelesma?.length > 2 && <h4><em>Αποτέλεσμα:</em> {singleCase.apotelesma}</h4>}
      {singleCase.arithmos_apofasis?.length > 2 && <h4><em>Αρ.Απόφασης:</em> {singleCase.arithmos_apofasis}</h4>}
      {singleCase.appeal && <h4><em>Ένδικο μέσο: </em> {singleCase.appeal}</h4>}
      {prothesmiesSingle.filter(r => r.eidos !== 'dikasimos').length > 0 && <h4 className='prothesmies' onClick={handleProth}>ΠΡΟΘΕΣΜΙΕΣ</h4>}
      <div className={showProth ? '' : 'offscreen'}><ProthCard prothesmiesSingle={prothesmiesSingle} /></div> */}

      {/* {(singleCase.arithmos_apofasis?.includes('/') && !singleCase.appeal && !singleCase.antikeimeno?.includes('ΔΙΑΤΑΓ') && !singleCase.diadikasia?.includes('ΔΙΑΤΑΓ')) && <div>
        <h4 >Έλεγχος για άσκηση ενδίκου μέσου <input
          data-key={singleCase.id}
          type="checkbox"
          id="checkappeal"
          onChange={handleAppeal}
          checked={checkAppeal}
        /></h4>


      </div>} */}
    </article>
  );
};

export default JCaseCard;
