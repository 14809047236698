export const Heart = ({ heartNumber }) => {
  if (heartNumber === 1) {
    return (
      <div className="hearts-block">
        <img src="/images/myheart.png" alt="heart" className="heart"></img>
      </div>
    );
  }
  if (heartNumber === 2) {
    return (
      <div className="hearts-block">
        <img src="/images/myheart.png" alt="heart" className="heart2"></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart1 heart-icon"
        ></img>
      </div>
    );
  }
  if (heartNumber === 3) {
    return (
      <div className="hearts-block">
        <img src="/images/myheart.png" alt="heart" className="heart3"></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart2 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart1 heart-icon"
        ></img>
      </div>
    );
  }
  if (heartNumber === 4) {
    return (
      <div className="hearts-block" style={{ marginTop: "4px" }}>
        <img src="/images/myheart.png" alt="heart" className="heart4"></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart3 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart2 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart1 heart-icon"
        ></img>
      </div>
    );
  }
  // if (heartNumber === 5) {
  //   return (
  //     <div className="hearts-block">
  //       <img src="/images/myheart.png" alt="heart" className="heart5"></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart4 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart3 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart2 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart1 heart-icon"
  //       ></img>
  //     </div>
  //   );
  // }
  // if (heartNumber === 6) {
  //   return (
  //     <div className="hearts-block">
  //       <img src="/images/myheart.png" alt="heart" className="heart6"></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart5 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart4 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart3 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart2 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart1 heart-icon"
  //       ></img>
  //     </div>
  //   );
  // }
  // if (heartNumber === 7) {
  //   return (
  //     <div className="hearts-block">
  //       <img src="/images/myheart.png" alt="heart" className="heart7"></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart6 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart5 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart4 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart3 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart2 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart1 heart-icon"
  //       ></img>
  //     </div>
  //   );
  // }
  // if (heartNumber === 8) {
  //   return (
  //     <div className="hearts-block">
  //       <img src="/images/myheart.png" alt="heart" className="heart8"></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart7 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart6 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart5 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart4 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart3 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart2 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart1 heart-icon"
  //       ></img>
  //     </div>
  //   );
  // }
  // if (heartNumber === 9) {
  //   return (
  //     <div className="hearts-block">
  //       <img src="/images/myheart.png" alt="heart" className="heart9"></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart8 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart7 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart6 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart5 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart4 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart3 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart2 heart-icon"
  //       ></img>
  //       <img
  //         src="/images/myheart.png"
  //         alt="heart"
  //         className="heart1 heart-icon"
  //       ></img>
  //     </div>
  //   );
  // }
  if (heartNumber > 4) {
    return (
      <div className="hearts-block" style={{ marginTop: "13px" }}>
        {/* <img src="/images/myheart.png" alt="heart" className="heart10"></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart9 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart8 heart-icon"
        ></img> */}
        {/* <img
          src="/images/myheart.png"
          alt="heart"
          className="heart7 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart6 heart-icon"
        ></img> */}
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart5 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart4 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart3 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart2 heart-icon"
        ></img>
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart1 heart-icon"
        ></img>
      </div>
    );
  }
};
