import { useState, useRef } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const AddCase = ({ handleClickAddCaseModal, cases, handleAddCase }) => {
  const [arkat, setArkat] = useState("");
  const [kwdikos, setKwdikos] = useState("");
  const [court, setCourt] = useState("ste/pageste/stepage");
  const [note, setNote] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  const courts = [
    "ΣτΕ",
    "ΔΕφ Αθηνών",
    "ΔΕφ Θεσσαλονίκης",
    "ΔΕφ Πειραιά",
    "ΔΠρ Αθηνών",
    "ΔΠρ Θεσσαλονίκης",
    "ΔΠρ Πειραιά",
    "ΔΕφ Κομοτηνής",
    "ΔΠρ Αλεξανδρούπολης",
    "ΔΠρ Καβάλας",
    "ΔΠρ Κομοτηνής",
    "ΔΠρ Βέροιας",
    "ΔΠρ Σερρών",
    "ΔΠρ Κοζάνης",
    "ΔΕφ Ιωαννίνων",
    "ΔΠρ Ιωαννίνων",
    "ΔΕφ Λάρισας",
    "ΔΠρ Βόλου",
    "ΔΠρ Λάρισας",
    "ΔΠρ Τρικάλων",
    "ΔΠρ Κέρκυρας",
    "ΔΕφ Πάτρας",
    "ΔΠρ Αγρινίου",
    "ΔΠρ Μεσολογγίου",
    "ΔΠρ Πάτρας",
    "ΔΠρ Πύργου",
    "ΔΠρ Λαμίας",
    "ΔΠρ Λιβαδειάς",
    "ΔΠρ Χαλκίδας",
    "ΔΕφ Τρίπολης",
    "ΔΠρ Καλαμάτας",
    "ΔΠρ Κορίνθου",
    "ΔΠρ Ναυπλίου",
    "ΔΠρ Τρίπολης",
    "ΔΠρ Μυτιλήνης",
    "ΔΠρ Ρόδου",
    "ΔΠρ Σύρου",
    "ΔΕφ Χανίων",
    "ΔΠρ Ηρακλείου",
    "ΔΠρ Χανίων",
  ];
  const links = [
    "ste/pageste/stepage",
    "defeteioath",
    "defeteiothe",
    "defeteiopei",
    "dprotodikeioath",
    "dprotodikeiothe",
    "dprotodikeiopei",
    "defeteiokom",
    "dprotodikeioale",
    "dprotodikeiokav",
    "dprotodikeiokom",
    "dprotodikeiover",
    "dprotodikeioser",
    "dprotodikeiokoz",
    "defeteioiwa",
    "dprotodikeioiwa",
    "defeteiolar",
    "dprotodikeiovol",
    "dprotodikeiolar",
    "dprotodikeiotri",
    "dprotodikeioker",
    "defeteiopat",
    "dprotodikeioagr",
    "dprotodikeiomes",
    "dprotodikeiopat",
    "dprotodikeiopyr",
    "dprotodikeiolam",
    "dprotodikeioliv",
    "dprotodikeioxal",
    "defeteiotrp",
    "dprotodikeiokal",
    "dprotodikeiokor",
    "dprotodikeionaf",
    "dprotodikeiotrp",
    "dprotodikeiomyt",
    "dprotodikeiorod",
    "dprotodikeiosyr",
    "defeteioxan",
    "dprotodikeiohra",
    "dprotodikeioxan",
  ];

  const options = courts.map((e, i) => {
    let label = e;

    return { label, value: links[i] };
  });

  // const gakRef = useRef();

  // useEffect(() => {
  //   gakRef.current.focus();
  // }, [])

  // const handleChange = (event) => {
  //   setCourt(event.target.value);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (cases.find((e) => e.arkat === arkat && e.court === court)) {
        setErrMsg("Έχετε ξανακαταχωρήσει την υπόθεση");
        return;
      }
      if (
        cases.length < 550 &&
        !cases.find((e) => e.arkat === arkat && e.court === court)
      ) {
        await axiosPrivate.post(
          "/adjustice",
          JSON.stringify({ arkat, kwdikos, court, note }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        handleClickAddCaseModal();
        handleAddCase({ arkat, kwdikos, court, note });
      }

      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const handleChange = (event) => {
    setCourt(event.target.value);
  };

  return (
    <section className="container_ac">
      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <div className="close_addcase">
        <p onClick={() => handleClickAddCaseModal()}>❌</p>
      </div>
      {/* <span></span> */}
      {/* <h1>Sign In</h1> */}
      <form className="form" onSubmit={handleSubmit}>
        {/* <label htmlFor="username">Email:</label> */}
        <div className="input-container ic1">
          <input
            className="input"
            placeholder=" "
            type="text"
            id="arkat"
            autoComplete="on"
            onChange={(e) => setArkat(e.target.value)}
            value={arkat}
            required
          />
          <div className="cut"></div>
          <label htmlFor="arkat" className="placeholder">
            Αρ.Κατάθεσης
          </label>
        </div>
        <div className="input-container ic2">
          <input
            className="input"
            placeholder=" "
            type="number"
            id="kwdikos"
            autoComplete="on"
            onChange={(e) => setKwdikos(e.target.value)}
            value={kwdikos}
            required
          />
          <div className="cut"></div>
          <label htmlFor="kwdikos" className="placeholder">
            Κωδικός
          </label>
        </div>
        <div className="input-container ic2">
          <select value={court} onChange={handleChange} className="input">
            {options.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <div className="cut"></div>
          <label htmlFor="court" className="placeholder">
            Δικαστήριο
          </label>
        </div>
        <div className="input-container ic2">
          <input
            className="input"
            type="text"
            id="note"
            placeholder=" "
            autoComplete="on"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <div className="cut"></div>
          <label htmlFor="note" className="placeholder">
            Σημείωση
          </label>
        </div>

        <button type="text" className="submit">
          Προσθήκη
        </button>
      </form>
    </section>
  );
};

export default AddCase;
