import { removeDiacriticsAndCapitalize } from "../../utils/checkMisthosi";

export const efeteia = [
  "ΑΘΗΝΩΝ",
  "ΠΕΙΡΑΙΩΣ",
  "ΑΝΑΤΟΛΙΚΗΣ ΚΡΗΤΗΣ (ΗΡΑΚΛΕΙΟ)",
  "ΑΙΓΑΙΟΥ (ΣΥΡΟΣ)",
  "ΒΟΡΕΙΟΥ ΑΙΓΑΙΟΥ (ΜΥΤΙΛΗΝΗ)",
  "ΔΥΤΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ (ΚΟΖΑΝΗ)",
  "ΔΥΤΙΚΗΣ ΣΤΕΡΕΑΣ ΕΛΛΑΔΑΣ (ΑΓΡΙΝΙΟ)",
  "ΔΩΔΕΚΑΝΗΣΟΥ (ΡΟΔΟΣ)",
  "ΕΥΒΟΙΑΣ (ΧΑΛΚΙΔΑ)",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΘΡΑΚΗΣ (ΚΟΜΟΤΗΝΗ)",
  "ΙΩΑΝΝΙΝΩΝ",
  "ΚΑΛΑΜΑΤΑΣ",
  "ΚΕΡΚΥΡΑΣ",
  "ΚΡΗΤΗΣ (ΧΑΝΙΑ)",
  "ΛΑΜΙΑΣ",
  "ΛΑΡΙΣΑΣ",
  "ΝΑΥΠΛΙΟΥ",
  "ΠΑΤΡΩΝ",
]
  .map((dim) => removeDiacriticsAndCapitalize(dim))
  .sort();
