import React from "react";
import { DOY } from "../DOY";
import { XCircleIcon } from "@heroicons/react/solid";
import {
  MultiSelect,
  MultiSelectItem,
  Icon,
  List,
  ListItem,
} from "@tremor/react";

const ChooseDOY = ({ DOYSelected, setDOYSelected }) => {
  const handleDeleteListItem = (e) => {
    const value =
      e.target.parentNode.parentNode.parentNode.firstChild.textContent;
    const newDOYSelected = DOYSelected.filter((dim) => dim !== value);
    setDOYSelected(newDOYSelected);
  };

  return (
    <div className="form-group">
      <label htmlFor="DOY">ΔΟΥ:</label>
      <MultiSelect
        placeholder="Επιλέξτε ΔΟΥ"
        onValueChange={setDOYSelected}
        value={DOYSelected}
      >
        {DOY.map((dim, i) => {
          return (
            <MultiSelectItem key={`${i}-${dim}`} value={dim}>
              {dim}
            </MultiSelectItem>
          );
        })}
      </MultiSelect>
      <List>
        {DOYSelected?.map((dim, i) => {
          return (
            <ListItem className="dimoi-list" key={`${i}-${dim}`}>
              <span>{dim}</span>
              <span>
                <Icon
                  onClick={handleDeleteListItem}
                  className={"cursor-pointer"}
                  icon={XCircleIcon}
                  size={"sm"}
                  color="red"
                  tooltip="Διαγραφή"
                ></Icon>
              </span>
              <ListItem />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ChooseDOY;
