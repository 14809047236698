import React from 'react'
import { useState } from "react";

import './css/ExtraInfo.css'

const ExtraInfo = ({ handleChange, userInput }) => {


  return (
    <article className='solon-article-info'>
      <fieldset className='extra-info-fieldset'>
        <legend className='extra-info-legend'>Κάτοικος Εξωτερικού</legend>
        <div className="select-wrapper">
          <select className='extra-info-input' onChange={handleChange} name="exoterikou" id="exoterikou" value={userInput.exoterikou ? userInput.exoterikou : "Όχι"}>
            <option value="Όχι">Όχι</option>
            <option value="Ναι">Ναι</option>

          </select>
        </div>
      </fieldset>
      <fieldset className='extra-info-fieldset'>
        <legend className='extra-info-legend'>Διάδικος Δημόσιο</legend>
        <div className="select-wrapper">
          <select className='extra-info-input' onChange={handleChange} name="dimosio" id="dimosio" value={userInput.dimosio ? userInput.dimosio : "Όχι"}>
            <option value="Όχι">Όχι</option>
            <option value="Ναι">Ναι</option>

          </select>
        </div>
      </fieldset>
      <fieldset className='extra-info-fieldset'>
        <legend className='extra-info-legend'>Αφορά κλήση</legend>
        <div className="select-wrapper">
          <select className='extra-info-input' onChange={handleChange} name="klisi" id="klisi" value={userInput.klisi ? userInput.klisi : "Όχι"}>
            <option value="Όχι">Όχι</option>
            <option value="Ναι">Ναι</option>

          </select>
        </div>
      </fieldset>
      {/* <fieldset className='extra-info-fieldset'>
        <legend className='extra-info-legend'>Ημερομηνία Επίδοσης Απόφασης</legend>
        <div className="select-wrapper">
          <input className='extra-info-input' onChange={handleChange} name="imnia" id="imnia" type={'date'}
            value={userInput.imnia && userInput.imnia}
          />

        </div>
      </fieldset> */}
    </article>
  )
}

export default ExtraInfo