import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebook } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <div className="footer">
      <a href="https://mysolon.gr">MySolon.gr</a> - {new Date().getFullYear()} |{" "}
      <Link to={"/prothesmies/neataktiki"}>Υπολογισμός Προθεσμιών</Link>{" "}
      <a href="https://klironomiko.mysolon.gr">Υπολογισμός Κληρονομιάς</a>
      <br /> <a href="https://eauctionstats.mysolon.gr">Eauction Stats</a>
      <br />{" "}
      <a href="https://yperimeria.mysolon.gr">
        Αναπροσαρμογές Μισθωματών και Υπολογισμός Τόκων Υπερημερίας
      </a>
      <br />{" "}
      <Link to={"/myportal"}>
        MyPortal3 - Εφαρμογή για την παρακολούθηση ποινικών υποθέσεων
      </Link>{" "}
      <br />
      <a
        className="twitter"
        href="https://www.facebook.com/MySolonGR"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon title="facebook" icon={faFacebook} />
      </a>
      <a
        className="twitter"
        href="https://twitter.com/ymertzanis"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon title="twitter" icon={faTwitter} />
      </a>
    </div>
  );
};

export default Footer;
