import {
  Badge,
  Card,
  DonutChart,
  Select,
  SelectItem,
  Flex,
  Legend,
  List,
  ListItem,
  Title,
} from "@tremor/react";
import { useEffect, useState } from "react";
import { adjCourtMap } from "../../Templates/utils/adjCourtMap";

export default function AdjDikastiria({ data }) {
  const [initData, setInitData] = useState([]);

  useEffect(() => {
    data.adjCStats.length > 0 &&
      setInitData(
        data.adjCStats.map((r) => ({
          court: adjCourtMap(r.court),
          case_count: r.case_count,
        }))
      );
  }, [data]);

  return (
    <Card className="max-w-md mx-auto">
      <Flex className="space-x-8" justifyContent="start" alignItems="center">
        <Title>Διοικητικά Δικαστήρια</Title>
      </Flex>

      <DonutChart
        data={initData}
        category="case_count"
        index="court"
        valueFormatter={(number) => number}
        className="mt-6"
      />
      <List className="mt-6">
        {initData
          .sort((a, b) => b.case_count - a.case_count)
          .slice(0, 5)
          .map((city) => (
            <ListItem key={city.court}>
              {city.court}
              <Badge size="xs">{city.case_count}</Badge>
            </ListItem>
          ))}
      </List>
    </Card>
  );
}
