import React from "react";
import { dioikEfeteia as dioikEfeteiaUnfilt } from "../DioikEfeteia";
import { XCircleIcon } from "@heroicons/react/solid";
import {
  MultiSelect,
  MultiSelectItem,
  Icon,
  List,
  ListItem,
} from "@tremor/react";
import { removeDiacriticsAndCapitalize } from "../../../utils/checkMisthosi";

const ChooseDioikEfeteia = ({
  dioikEfeteiaSelected,
  setDioikEfeteiaSelected,
}) => {
  const dioikEfeteia = dioikEfeteiaUnfilt
    .map((dim) => removeDiacriticsAndCapitalize(dim))
    .sort();
  const handleDeleteListItem = (e) => {
    const value =
      e.target.parentNode.parentNode.parentNode.firstChild.textContent;
    const newDioikEfeteiaSelected = dioikEfeteiaSelected.filter(
      (dim) => dim !== value
    );
    setDioikEfeteiaSelected(newDioikEfeteiaSelected);
  };

  return (
    <div className="form-group">
      <label htmlFor="dioikEfeteia">Διοικ.Εφετεία:</label>
      <MultiSelect
        placeholder="Επιλέξτε Διοικ.Εφετεία"
        onValueChange={setDioikEfeteiaSelected}
        value={dioikEfeteiaSelected}
      >
        {dioikEfeteia.map((dim, i) => {
          return (
            <MultiSelectItem key={`${i}-${dim}`} value={dim}>
              {dim}
            </MultiSelectItem>
          );
        })}
      </MultiSelect>
      <List>
        {dioikEfeteiaSelected?.map((dim, i) => {
          return (
            <ListItem className="dimoi-list" key={`${i}-${dim}`}>
              <span>{dim}</span>
              <span>
                <Icon
                  onClick={handleDeleteListItem}
                  className={"cursor-pointer"}
                  icon={XCircleIcon}
                  size={"sm"}
                  color="red"
                  tooltip="Διαγραφή"
                ></Icon>
              </span>
              <ListItem />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ChooseDioikEfeteia;
