import React from 'react'

const ProtLarisas = ({ singleCase, handleKeyPress, handleClicks, showEdit, note, setNote }) => {
  const data = singleCase?.data && JSON.parse(singleCase?.data)
  return (

    <>

      {(singleCase?.data && data.ardimosiefsis) &&
        <><h4><em>Αρ. Κατάθεσης: </em>{data.arkat}</h4 >
          <h4><em>Διαδικασία: </em>{data.diadikasia}</h4 >
          <h4><em>Ημ/νία Δικασίμου: </em>{data.imniadikasimou}</h4 >
          <h4><em>Αρ.Απόφασης: </em>{data.ardimosiefsis}</h4 >
          <h4><em>Ημ/νία Δημοσίευσης: </em>{data.imniadimosiefsis}</h4 >
        </>}
      {(singleCase?.data && !data.ardimosiefsis) &&

        <>
          <h4><em>Αρ. Κατάθεσης: </em>{data.arkat}</h4 ><h4>Δεν έχει εκδοθεί απόφαση έως τώρα</h4 >
        </>}






    </>
  )
}

export default ProtLarisas