import { useEffect, useState } from "react";
import CaseCard from "./CaseCard";
import Pagination from "./Util/Pagination";
import ToggleView from "./Visual/ToggleView";
import { useNavigate, useLocation } from "react-router-dom";
import ToggleSyzitisi from "./Visual/ToggleSyzitisi";

const Cases = ({
  cases,
  prothesmies,
  handleDeleteCase,
  handleAddSelectedDiadikasia,
  credits,
  setCredits,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const decisions = urlParams.get("decisions");
  const syzitisi = urlParams.get("syzitisi");
  const diadikasia = urlParams.get("diadikasia");
  const court = urlParams.get("court");
  const casesSorted = cases
    .sort((a, b) => {
      return b.created_at?.localeCompare(a.created_at);
    })
    // sort by fav first
    .sort((a, b) => {
      return b.fav - a.fav;
    });
  const [page, setPage] = useState(0);
  function dividePages(arr) {
    const res = [];
    for (let index = 0; index < arr.length; index++) {
      const el = [];
      if (index % 5 === 0) {
        res.push(el);
      }

      let i = Math.floor(index / 5);
      res[i].push(arr[index]);
    }
    return res;
  }

  const handlePage = async (e) => {
    if (e.target.getAttribute("data-key") === "next") {
      setPage(page + 1);
    }
    if (e.target.getAttribute("data-key") === "end") {
      setPage(dividePages(casesSorted).length - 1);
    }
    if (e.target.getAttribute("data-key") === "previous") {
      setPage(page - 1);
    }
    if (e.target.getAttribute("data-key") === "start") {
      setPage(0);
    }
  };

  const handleSelect = (e) => {
    const urlParams = new URLSearchParams(location.search);

    const mapObj = { 2: "all", 0: "yes", 1: "no" };
    const combineParams = new URLSearchParams({
      decisions: mapObj[e],
      syzitisi: urlParams.get("syzitisi"),
      court: urlParams.get("court"),
      diadikasia: urlParams.get("diadikasia"),
    });
    navigate(location.pathname + "?" + combineParams.toString(), {
      replace: true,
    });
  };
  const handleSyzitisi = (e) => {
    const urlParams = new URLSearchParams(location.search);

    const mapObj = { 2: "all", 0: "yes", 1: "no" };
    const combineParams = new URLSearchParams({
      decisions: urlParams.get("decisions"),
      syzitisi: mapObj[e],
      court: urlParams.get("court"),
      diadikasia: urlParams.get("diadikasia"),
    });
    navigate(location.pathname + "?" + combineParams.toString(), {
      replace: true,
    });
  };
  const filteredCases = casesSorted
    .filter((singleCase) => {
      if (decisions === "no") {
        return !singleCase.arithmos_apofasis?.includes("/");
      } else if (decisions === "yes") {
        return singleCase.arithmos_apofasis?.includes("/");
      } else {
        return singleCase;
      }
    })
    .filter((singleCase) => {
      if (syzitisi === "no") {
        return !singleCase.apotelesma?.toLowerCase().includes("συζητ");
      } else if (syzitisi === "yes") {
        return singleCase.apotelesma?.toLowerCase().includes("συζητ");
      } else {
        return singleCase;
      }
    })
    .filter((singleCase) => {
      if (diadikasia && diadikasia !== "null") {
        return singleCase.diadikasia?.includes(diadikasia);
      } else {
        return singleCase;
      }
    })
    .filter((singleCase) => {
      if (court && court !== "null") {
        return singleCase.court?.includes(court);
      } else {
        return singleCase;
      }
    });

  useEffect(() => {
    page !== 0 &&
      page >= dividePages(filteredCases).length - 1 &&
      setPage(dividePages(filteredCases).length - 1);
  }, [filteredCases, page]);

  // string to lowercase
  // string to uppercase
  // string to lowercase

  return (
    <article>
      {cases?.length ? (
        <>
          <div className="toggle-view">
            <ToggleView handleSelect={handleSelect} />
          </div>
          <div className="toggle-view">
            <ToggleSyzitisi handleSelect={handleSyzitisi} />
          </div>
          <h4 className="total-cases">
            Σύνολο Υποθέσεων: {filteredCases.length}
          </h4>

          <Pagination
            page={page}
            length={dividePages(filteredCases).length}
            handlePage={handlePage}
          />

          {filteredCases &&
            dividePages(filteredCases)[page]?.map((singleCase, i) => {
              const no = page * 5 + i;
              const {
                id,
                gak,
                year,
                court,
                diadikasia,
                antikeimeno,
                eak,
                arithmos_pinakiou,
                arithmos_apofasis,
                apotelesma,
                note,
                imerominia_katathesis,
                check_appeal,
                appeal,
                created_at,
                last_checked,
                userInput,
              } = singleCase;
              const selectedDiadikasia =
                singleCase?.userInput?.filter(
                  (e) => e.category === "select_diadikasia"
                ).length > 0
                  ? JSON.parse(
                      singleCase?.userInput?.filter(
                        (e) => e.category === "select_diadikasia"
                      )[0]?.data
                    )
                  : "";
              const prothesmiesSingle = prothesmies?.filter(
                (r) => r.gak_id === id
              );
              return (
                <CaseCard
                  key={id}
                  singleCase={{
                    no,
                    i,
                    id,
                    gak,
                    year,
                    court,
                    diadikasia,
                    antikeimeno,
                    eak,
                    arithmos_pinakiou,
                    arithmos_apofasis,
                    apotelesma,
                    imerominia_katathesis,
                    note,
                    check_appeal,
                    appeal,
                    created_at,
                    last_checked,
                    userInput,
                    selectedDiadikasia,
                    fav: singleCase.fav,
                  }}
                  prothesmiesSingle={prothesmiesSingle}
                  handleDeleteCase={handleDeleteCase}
                  handleAddSelectedDiadikasia={handleAddSelectedDiadikasia}
                  credits={credits}
                  setCredits={setCredits}
                />
              );
            })}
          <Pagination
            page={page}
            length={dividePages(filteredCases).length}
            handlePage={handlePage}
          />
        </>
      ) : (
        <p>Δεν έχετε καταχωρήσει υποθέσεις</p>
      )}
    </article>
  );
};

export default Cases;
