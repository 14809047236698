import { removeDiacriticsAndCapitalize } from "../../utils/checkMisthosi";

export const ypothikofylakia = [
  "Αθηνών",
  "Πειραιώς",
  "Θεσσαλονίκης",
  "Πατρών",
  "Αρτάς",
  "Ηρακλείου",
  "Ιωαννίνων",
  "Κατερίνης",
  "Ναυπλίου",
  "Νεάπολης",
  "Χαλανδρίου",
  "Χανίων",
  "Χίου",
  "Σαλαμίνας",
  "Σάμου",
  "Σκύρου",
  "Ευδήλου",
  "Άγιου Κήρυκου",
  "Κύθνου",

  "Αγίας Παρασκευής",
  "Αιγάλεω",
  "Ζωγράφου",
  "Αμαρουσίου",
  "Κηφισιάς",
  "Βύρωνος",
  "Ηλιουπόλεως",
  "Καλλιθέας",
  "Νέας Ιωνίας",
  "Νέας Φιλαδελφείας",
  "Νέων Λιοσίων",
  "Περιστερίου",
  "Παλαιού Φαλήρου",
  "Παπάγου",
  "Νέας Σμύρνης",
  "Αχαρνών",
  "Κερατέας",
  "Σπάτων",
  "Λαυρίου",
  "Κρωπίας",
  "Ωρωπού",
  "Ερυθραίας",
  "Ελευσίνας",
  "Γλυφάδας",
  "Βούλας",
  "Μαραθώνος",
  "Ειδυλλίας (Βίλλια)",
  "Μεγάρων",
  "Χαϊδαρίου",
  "Κέας",
  "Αγρινίου Α",
  "Αγρινίου Β",
  "Θέρμου",
  "Βάλτου (Αμφιλοχία)",
  "Αμαλιάδας",
  "Μυρτουντίων (Λεχαινά)",
  "Γαστούνης",
  "Βαρθολομιού",
  "Βάρδας",
  "Άμφισσας",
  "Ιτέας",
  "Ευπαλίου",
  "Γαλαξειδίου",
  "Δωριέων",
  "Φιλιππιάδος",
  "Βέροιας",
  "Νάουσας",
  "Αλεξανδρείας",
  "Βόλου",
  "Σκοπέλου",
  "Σκιάθου",
  "Αλμυρού",
  "Φερρών (Βελεστίνο)",
  "Νηλείας",
  "Γιαννιτσών",
  "Κρύας Βρύσης",
  "Γρεβενών",
  "Γυθείου",
  "Νεαπόλεως Βοϊών",
  "Δράμας",
  "Αλεξανδρούπολης",
  "Διδυμοτείχου",
  "Ορεστιάδος",
  "Έδεσσας",
  "Σκύδρας",
  "Αλμωπίας (Αριδαία)",
  "Καρπενησίου",
  "Ζακύνθου",
  "Πύργου",
  "Ολυμπίων (Πελόπιον)",
  "Ωλένης (Καράτουλα)",
  "Κρεστένων",
  "Βώλακος (Επιτάλιον)",
  "Ανδρίτσαινας",
  "Μοιρών",
  "Χερσονήσου",
  "Καλαμαριάς",

  "Βασιλικών",
  "Νεάπολης",

  "Λαγκαδά",
  "Κουφαλίων",

  "Ηγουμενίτσας",
  "Φιλιατών",
  "Παραμυθιάς",
  "Θηβών",
  "Θίσβης",
  "Θεσπιών",
  "Κόνιτσας",
  "Καβάλας",
  "Παγγαίου",
  "Θάσου",
  "Χρυσουπόλεως",
  "Καλαμάτας",
  "Οιχαλία (Μελιγαλάς)",
  "Παμίσου (Μεσσήνη)",
  "Πύλου",
  "Θουρίας",
  "Αβίας",
  "Κορώνης",
  "Μεθώνης",
  "Καλαβρύτων",
  "Κλειτορίας",
  "Ακράτας",
  "Καρδίτσας",
  "Σοφάδων",
  "Παλαμά",
  "Καστοριάς",
  "Κερκύρας",
  "Μέσης",
  "Επιλιμνίων (Σκριπερό)",
  "Όρους (Αγ. Δούλοι)",
  "Λευκίμης",
  "Αργοστολίου",
  "Ληξουρίου",
  "Κοζάνης",
  "Εορδαίας (Πτολεμαΐδα)",
  "Σερβίων",
  "Ανασελίτσας",
  "Σιάτιστας",
  "Κιλκίς",
  "Κορίνθου Α",
  "Κορίνθου Β",
  "Νεμέας",
  "Δερβενίου",
  "Ξυλοκάστρου",
  "Σικυώνος (Κιάτο)",
  "Σολυγείας (Σοφικό)",
  "Κυπαρισσίας",
  "Δωρίου",
  "Εράνης (Φιλιατρά)",
  "Πλαταμώδους (Γαργαλιάνοι)",
  "Φλεσιάδος (Χώρα)",
  "Αρήνης (Ζαχάρω)",
  "Καλύμνου",
  "Λέρου",
  "Πάτμου",
  "Λαμίας",
  "Αταλάντης",
  "Σπερχειάδος",
  "Θερμοπυλών",
  "Δομοκού",
  "Φαλάρων (Στυλίς)",
  "Ελατείας",
  "Λάρισας",
  "Τυρνάβου",
  "Φαρσάλων",
  "Ελασσόνας",
  "Αγιάς",
  "Αγ. Νικολάου",
  "Σητείας",
  "Ιεράπετρας",
  "Λιβαδειάς",
  "Ορχομενού",
  "Αλιάρτου",
  "Λευκάδας",
  "Καρυάς",
  "Βονίτσης",
  "Μεσολογγίου",
  "Ναυπάκτου",
  "Παραχελωΐτιδος",
  "Αιτωλικού (Άνω Χώρα)",
  "Μυτιλήνης",
  "Αγιάσου",
  "Καλλονής",
  "Μανδαμάδου",
  "Παπάδου (Γέρα)",
  "Πλωμαρίου",
  "Λήμνου",
  "Άργους",
  "Άστρους",
  "Μάσσητος (Κρανίδι)",
  "Πρασιών (Λεωνίδιο)",
  "Νάξου",
  "Θήρας",
  "Ξάνθης",
  "Αιγιαλείας (Αίγιο)",
  "Δύμης (Κ. Αχαΐα)",
  "Ερινεού (Καμάρες)",
  "Φαρρών (Χαλανδρίτσα)",
  "Πόρου",
  "Νίκαιας",
  "Αίγινας",
  "Σπετσών",
  "Ύδρας",
  "Πρέβεζας",
  "Πάργας",
  "Ρεθύμνης",
  "Μυλοποτάμου (Πάνορμος)",
  "Κομοτηνής",
  "Καρπάθου",
  "Καρλοβασίου",
  "Χώρας",
  "Σερρών",
  "Νιγρίτης",
  "Ροδολίβους",
  "Συντικής",
  "Φυλλίδος (Ν. Ζίχνη)",
  "Ηράκλειας",
  "Σπάρτης",
  "Κροκεών",
  "Επιδαύρου Λιμηράς (Μολάοι)",
  "Ερμουπόλεως",
  "Κορθίου",
  "Χώρας Άνδρου",
  "Γαυρίου Άνδρου",
  "Πάρου",
  "Μυκόνου",
  "Τήνου",
  "Μήλου",
  "Τρικάλων",
  "Πύρρας (Πύλη)",
  "Καλαμπάκας",
  "Φαρκαδόνος",
  "Τριπόλεως (Βορ. Πλευράς)",
  "Τριπόλεως (Νοτ. Πλευράς)",
  "Καριταίνης",
  "Ορχομενού (Λεβίδι)",
  "Τανίας (Δαλιανών)",
  "Μεγαλόπολης",
  "Φλώρινας",
  "Αμυνταίου",
  "Καστελίου Κισσάμου",
  "Κανδάνου",
  "Αλικιανού",
  "Ελ. Βενιζέλου",
  "Πολυγύρου",
  "Νέων Μουδανιών",
  "Κασσάνδρας",
  "Άρνης",
  "Ιερισσού",
  "Συκιάς",
  "Χαλκίδας",
  "Ιστιαίας",
  "Κύμης",
  "Ταμυναίων",
  "Ερέτριας",
  "Αγίας Άννης",
  "Καρύστου",
  "Στύρων",
  "Αυλώνος",
]
  .map((dim) => removeDiacriticsAndCapitalize(dim))
  .sort();
