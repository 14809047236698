const Ypologismos = ({ details }) => {


  return (
    <article className="prothesmia_text">
      <p className="prothesmia_text">{details.imeres}</p>
      {
        details.ypologismos.map((r, i) => {
          return (<p key={i} className="prothesmia_text">{r}</p>)
        })
      }


    </article >
  )
}

export default Ypologismos