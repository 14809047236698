import React from "react";
import ChooseDimo from "./ChooseDimo";
import ChooseDOY from "./ChooseDOY";
import ChooseYpoth from "./ChooseYpoth";
import ChooseEirin from "./ChooseEirin";
import ChooseProtod from "./ChooseProtod";
import { Card } from "@tremor/react";
import ChooseMetaf from "./ChooseMetaf";
import ChooseEfeteia from "./ChooseEfeteia";
import ChooseKtimat from "./ChooseKtimat";
import ChooseDioik from "./ChooseDioik";
import ChooseDioikEfeteia from "./ChooseDioikEfeteia";

const Choose = ({ fields }) => {
  console.log("fields", fields);
  return (
    <>
      <Card className="flex-1 min-w-[300px]">
        <ChooseEirin
          eirinSelected={fields.eirinSelected}
          setEirinSelected={fields.setEirinSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseProtod
          protodSelected={fields.protodSelected}
          setProtodSelected={fields.setProtodSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseEfeteia
          efeteiaSelected={fields.efeteiaSelected}
          setEfeteiaSelected={fields.setEfeteiaSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseDioik
          dioikSelected={fields.dioikSelected}
          setDioikSelected={fields.setDioikSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseDioikEfeteia
          dioikEfeteiaSelected={fields.dioikEfeteiaSelected}
          setDioikEfeteiaSelected={fields.setDioikEfeteiaSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseYpoth
          ypothSelected={fields.ypothSelected}
          setYpothSelected={fields.setYpothSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseKtimat
          ktimatSelected={fields.ktimatSelected}
          setKtimatSelected={fields.setKtimatSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseDimo
          dimoiSelected={fields.dimoiSelected}
          setDimoiSelected={fields.setDimoiSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseDOY
          DOYSelected={fields.DOYSelected}
          setDOYSelected={fields.setDOYSelected}
        />
      </Card>
      <Card className="flex-1 min-w-[300px]">
        <ChooseMetaf
          metafSelected={fields.metafSelected}
          setMetafSelected={fields.setMetafSelected}
        />
      </Card>
    </>
  );
};

export default Choose;
