import React from 'react'

const ProtKos = ({ singleCase, handleKeyPress, handleClicks, showEdit, note, setNote }) => {
  const data = singleCase?.data && JSON.parse(singleCase?.data)
  return (

    <>

      {singleCase?.data && <><h4><em>Αρ. Κατάθεσης: </em>{data.arkat}</h4 >
        <h4><em>Διαδικασία: </em>{data.diadikasia}</h4 >
        <h4><em>Αντικείμενο: </em>{data.antikeimeno}</h4 >
        <h4><em>Ημ/νία Δικασίμου: </em>{data.dikasimos}</h4 >
        <h4><em>Αρ.Απόφασης: </em>{data.arapof}</h4 >
        <h4><em>Ημ/νία Δημοσίευσης: </em>{data.imniadimosief}</h4 >
        <h4><em>Αποτέλεσμα: </em>{data.apotelesma}</h4 >

      </>}
      {(singleCase?.data && !data.ardimosiefsis) &&

        <>
          <h4><em>Αρ. Κατάθεσης: </em>{data.arkat}</h4 ><h4>Δεν έχει εκδοθεί απόφαση έως τώρα</h4 >
        </>}






    </>
  )
}

export default ProtKos