import { useState, useEffect } from "react";
import { prothesmiesMikrodiaforon } from 'prothesmies'
import { dikastiria } from "../Util/dikastiria";
// import prothDep from '../../package.alias.json';
import ProthesmiaCard from "./ProthesmiaCard";
import ProthesmiesHeader from "../Prothesmies/ProthesmiesHeader";



const transformProthToObjects = (proth) => {
  const arr = []
  Object.keys(proth).forEach(r => {
    const obj = {}
    if (r !== 'katathesi' && !r.includes('Details')) {
      obj.prothesmia = proth[r]
      Object.keys(proth).forEach(k => {
        if (k === `${r}Details`) {
          obj.details = proth[k]
          obj.eidos = r
          arr.push(obj)
        }
      })

    }
  })
  return arr
}





const Mikrodiafores = () => {
  const [input, setInput] = useState('');
  const [edra, setEdra] = useState('Αθηνών');
  const [proth, setProth] = useState('');



  const [perifereia, setPerifereia] = useState('Αθηνών');


  const [dimosio, setDimosio] = useState(false);
  const [exoterikou, setexoterikou] = useState(false);






  const handleEdraChange = (event) => {
    setEdra(event.target.value);
  };
  const handlePerifereiaChange = (event) => {
    setPerifereia(event.target.value);
  };




  useEffect(() => {
    if (input?.slice(0, 2) === '20' && input.split('-')[0].length === 4) {
      const timeOutId = setTimeout(() => setProth(prothesmiesMikrodiaforon(input, { topiki: edra, dimosio, exoterikou })), 100);
      return () => clearTimeout(timeOutId);
    }
  }, [input, edra, dimosio, exoterikou]);

  console.log(proth);

  return (

    <section>
      <ProthesmiesHeader />

      <section className="container_search" >
        <h3 className="h3_prothesmia">Υπολογισμός Προθεσμιών Μικροδιαφορών</h3>
        <h4 className="h3_prothesmia">Χρησιμοποιεί το <a href="https://github.com/imertz/prothesmies" target="_blank" rel="noreferrer" >prothesmies</a> έκδοση 0.7.10</h4>
        <form className="form_search" >
          {/* <label htmlFor="username">Email:</label> */}
          <div className="input-container ic1">
            <input
              className='input'
              placeholder=' '
              type="date"
              id="Αναζήτηση"
              autoComplete="on"
              onChange={(e) => setInput(e.target.value)}
              value={input || ''}
              required
            />
            <div className="cut"></div>
            <label htmlFor="Αναζήτηση" className="placeholder">Ημερομηνία Κατάθεσης</label>
          </div>

          <div className="input-container ic2">
            <select value={dimosio ? 'ναι' : 'όχι'} onChange={(e) => e.target.value === 'ναι' ? setDimosio(true) : setDimosio(false)} className='input'>

              <option key='Όχι' value='όχι' >Όχι</option>
              <option key='Ναι' value='ναι' >Ναι</option>


            </select>

            <div className="cut"></div>
            <label htmlFor="Dimosio" className="placeholder">Διάδικος Δημόσιο;</label>
          </div>
          <div className="input-container ic2">
            <select value={exoterikou ? 'ναι' : 'όχι'} onChange={(e) => e.target.value === 'ναι' ? setexoterikou(true) : setexoterikou(false)} className='input'>

              <option key='Όχι' value='όχι' >Όχι</option>
              <option key='Ναι' value='ναι' >Ναι</option>


            </select>

            <div className="cut"></div>
            <label htmlFor="exoterikou" className="placeholder">Κάτοικος Εξωτερικού;</label>
          </div>

        </form>
      </section>

      <ProthesmiaCard proth={transformProthToObjects(proth)} input={input} />


    </section>
  )
}

export default Mikrodiafores