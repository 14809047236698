import React from 'react'

export const Apofaseis = ({ pageItem }) => {
  const status = { PUBLISHED: "Αναρτηθείσα", REVOKED: 'Αποσυρθείσα', PENDING_REVOCATION: "Εκκρεμεί Απόσυρση", SUBMITTED: 'Υποβληθείσα' }
  return (
    <article className='diavgeia-apofaseis'>
      <p className='enimerwseis-allages-head'>{pageItem?.no + 1}. {pageItem?.decisionType?.label} - {pageItem?.submissionTimestamp?.split(' ')[0]}</p>

      <h3 className='enimerwseis-h3'>ΑΔΑ</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.ada}</p>
      <h3 className='enimerwseis-h3'>Κατάσταση</h3>
      <p className='enimerwseis-allages-title'>{status[pageItem?.status]}</p>
      <h3 className='enimerwseis-h3'>Ημερομηνία Ανάρτησης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.submissionTimestamp}</p>
      <h3 className='enimerwseis-h3'>Τελευταία τροποποίηση</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.publishTimestamp}</p>
      <h3 className='enimerwseis-h3'>Ημερομηνία Έκδοσης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.issueDate}</p>
      <h3 className='enimerwseis-h3'>Λήψη Αρχείου</h3>
      <p><a className='enimerwseis-allages-title' href={pageItem?.documentUrl} target="_blank" rel="noreferrer" >{pageItem?.documentUrl}</a></p>
      <h3 className='enimerwseis-h3'>Είδος</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.decisionType?.label}</p>
      <h3 className='enimerwseis-h3'>Θέμα</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.subject}</p>
      <h3 className='enimerwseis-h3'>Θεματικές Κατηγορίες</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.thematicCategories[0]?.label}</p>
      <h3 className='enimerwseis-h3'>Αρ. πρωτοκόλλου</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.protocolNumber}</p>
      <h3 className='enimerwseis-h3'>Φορέας</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.organization?.label}</p>
    </article>
  )
}
