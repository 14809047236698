import React from "react";
import { protodikeia as protodUnfilt } from "../Protodikeia";
import { XCircleIcon } from "@heroicons/react/solid";
import {
  MultiSelect,
  MultiSelectItem,
  Icon,
  List,
  ListItem,
} from "@tremor/react";
import { removeDiacriticsAndCapitalize } from "../../../utils/checkMisthosi";

const ChooseProtod = ({ setProtodSelected, protodSelected }) => {
  const protod = protodUnfilt
    .map((dim) => removeDiacriticsAndCapitalize(dim))
    .sort();
  const handleDeleteListItem = (e) => {
    const value =
      e.target.parentNode.parentNode.parentNode.firstChild.textContent;

    const newProtodSelected = protodSelected.filter((dim) => dim !== value);
    setProtodSelected(newProtodSelected);
  };

  return (
    <div className="form-group">
      <label htmlFor="protodikeio">Πρωτοδικεία:</label>
      <MultiSelect
        placeholder="Επιλέξτε Πρωτοδικείο"
        onValueChange={setProtodSelected}
        value={protodSelected}
      >
        {protod.map((dim, i) => {
          return (
            <MultiSelectItem key={`${i}-${dim}`} value={dim}>
              {dim}
            </MultiSelectItem>
          );
        })}
      </MultiSelect>
      <List>
        {protodSelected?.map((dim, i) => {
          return (
            <ListItem className="dimoi-list" key={`${i}-${dim}`}>
              <span>{dim}</span>
              <span>
                <Icon
                  onClick={handleDeleteListItem}
                  className={"cursor-pointer"}
                  icon={XCircleIcon}
                  size={"sm"}
                  color="red"
                  tooltip="Διαγραφή"
                ></Icon>
              </span>
              <ListItem />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ChooseProtod;
