import {
  Card,
  Text,
  Flex,
  Metric,
  CategoryBar,
  BadgeDelta,
  Grid,
  Title,
  Divider,
} from "@tremor/react";
import { useEffect, useState } from "react";

const categories = [
  {
    title: "Sales",
    metric: "$ 456,000",
  },
  {
    title: "Transactions",
    metric: "89,123",
  },
  {
    title: "Merchants",
    metric: "22",
  },
  {
    title: "Orders",
    metric: "678",
  },
];

export default function EaucStats({ data }) {
  const [initData, setInitData] = useState({});

  useEffect(() => {
    data?.eaucStats?.total && setInitData({ ...data.eaucStats });
  }, [data]);

  return (
    <Card className="max-w-lg mx-auto">
      <Flex className="space-x-8" justifyContent="start" alignItems="center">
        <Title>Πλειστηριασμοί</Title>
      </Flex>
      <Text className="mt-8">Καταχωρημένα ΑΦΜ</Text>
      <Metric>{initData?.total}</Metric>
      <Divider />
      <Text>Χρήστες</Text>
      <Metric>{initData?.distinct}</Metric>
      <Divider />

      <Text>Σταλθείσες Ενημερώσεις</Text>
      <Metric>{initData?.updates}</Metric>
    </Card>
  );
}
