import { useState, useEffect } from "react";
import MultiPoiniko from "./MultiPoiniko";
import AddEauction from "./AddPoiniko";
import AddEauctionModal from "./AddPoinikoModal";
import MultiSingleEauction from "./MultiSinglePoiniko";
import { useInterval } from "../../utils/useInterval";
import {
  TextInput,
  SearchSelect,
  SearchSelectItem,
  Button,
} from "@tremor/react";

import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  acceptedABMChars,
  delay,
  removeDiacriticsAndCapitalize,
} from "../../utils/checkMisthosi";

const Poinika = () => {
  let params = useParams();

  const [eauctions, setEauctions] = useState([]);
  const [abms, setAbms] = useState([]);

  const [abm, setAbm] = useState("");
  const [note, setNote] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  // const [state, updateState] = useState();
  const [search, setSearch] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const afmParam = params?.afm;

  // const forceUpdate = useCallback(() => updateState({}), []);

  const getAbms = async (controller, isMounted) => {
    // if isMounted is undefined then set it to true
    if (isMounted === undefined) {
      isMounted = true;
    }
    // if controller is undefined then set it to new AbortController
    if (controller === undefined) {
      controller = new AbortController();
    }

    try {
      const response = await axiosPrivate.get("/poinpin", {
        signal: controller.signal,
      });
      isMounted && setAbms(response.data);
      console.log(response.data);
      // isMounted && setCasesFirst(response.data);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        // do something
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  useEffect(() => {
    // if abm contains not accepted characters then setErrorMessage

    if (abm) {
      let error = false;
      for (let i = 0; i < abm.length; i++) {
        if (!acceptedABMChars.includes(abm[i])) {
          error = true;
          break;
        }
      }
      if (error) {
        setErrorMessage("Μη αποδεκτοί χαρακτήρες");
      } else {
        setErrorMessage("");
      }
    }
  }, [abm]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    getAbms(controller, isMounted);

    // const interval = setInterval(() => {
    //   getCases()
    // }, 10000)
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showModal, setShowModal] = useState(true);
  const handleClickAddEauctionModal = () => {
    setShowModal(!showModal);
  };
  const handleAddEauction = (singleEauction) => {
    setEauctions([...singleEauction, ...eauctions]);
  };
  const handleAddMultiEauctions = (MultiEauctions) => {
    setEauctions([...MultiEauctions]);
  };
  const handleDeleteEauction = (singleCase) => {
    setAbms(abms.filter((e) => e.id.toString() !== singleCase));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const eisag = document.getElementById("eisag").value;

    try {
      if (true) {
        const response = await axiosPrivate.post(
          "/poinpin",
          JSON.stringify({ abm, eisag, note }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        setAbm("");
        setNote("");
        document.getElementById("eisag").value = "";
        getAbms();
        // wait 1 second
        await delay(1000);
        getAbms();

        // if (response?.data?.message === 'Δεν βρέθηκε κωδικός') {
        //   setErrMsg('Δεν βρέθηκε ο κωδικός')
        //   setAfm('')
        // }
        // if (response?.data?.message === 'Ο κωδικός έχει καταχωρηθεί παλαιότερα') {
        //   setErrMsg(response.data.message)
        //   setAfm('')
        // } if (Object.keys(response?.data).includes('data')) {

        //   handleClickAddEauctionModal()
        //   console.log(response.data.data);
        //   handleAddEauction(response.data.data.map(r => { return { ...r, created_at: getCurrentDateTimeString(), note: r.unique_id.includes('-') ? `${note} ${r.unique_id.split('-')[1]}` : note } }))

        // }
        // const response = await axiosPrivate.get('/eausingle');
        // handleAddMultiEauctions(response.data)
      }

      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  useInterval(async () => {
    let isMounted = true;
    const controller = new AbortController();

    getAbms(controller, isMounted);

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, 30000);

  return (
    <section>
      {location.pathname.includes("/poinika/") && (
        <MultiSingleEauction
          eauctions={eauctions.filter((r) => r.afm === afmParam.toString())}
        />
      )}

      {/* {location.pathname.includes("/eauction/") ? (
      //   <AddEauction
      //     handleClickAddEauctionModal={handleClickAddEauctionModal}
      //     eauctions={eauctions}
      //     handleAddEauction={handleAddEauction}
      //     handleAddMultiEauctions={handleAddMultiEauctions}
      //   />
      // )}
      */}
      <form className="poinika-add-form min-w-[300px]" onSubmit={handleSubmit}>
        <div className="poinika-add-modal">
          <div className="grow w-1/3">
            <TextInput
              error={errorMessage !== ""}
              errorMessage={errorMessage}
              placeholder="ΑΒΜ"
              value={abm}
              onValueChange={(e) => setAbm(removeDiacriticsAndCapitalize(e))}
              className=""
            />
          </div>
          <div className="grow w-1/2 ml-1">
            <SearchSelect
              id="eisag"
              name="eisag"
              className="grow-0"
              placeholder="Εισαγγελία"
            >
              <SearchSelectItem value="ΕΙΣΑΓΓΕΛΙΑ ΕΦΕΤΩΝ ΑΘΗΝΩΝ">
                ΕΙΣ.ΕΦ.ΑΘΗΝΩΝ
              </SearchSelectItem>
              <SearchSelectItem value="ΕΙΣΑΓΓΕΛΙΑ ΕΦΕΤΩΝ ΕΥΒΟΙΑΣ">
                ΕΙΣ.ΕΦ.ΕΥΒΟΙΑΣ
              </SearchSelectItem>
              <SearchSelectItem value="ΕΙΣΑΓΓΕΛΙΑ ΕΦΕΤΩΝ ΘΕΣΣΑΛΟΝΙΚΗΣ">
                ΕΙΣ.ΕΦ.ΘΕΣΣΑΛΟΝΙΚΗΣ
              </SearchSelectItem>
              <SearchSelectItem value="ΕΙΣΑΓΓΕΛΙΑ ΕΦΕΤΩΝ ΠΕΙΡΑΙΑ">
                ΕΙΣ.ΕΦ.ΠΕΙΡΑΙΑ
              </SearchSelectItem>
              <SearchSelectItem value="ΕΙΣΑΓΓΕΛΙΑ ΠΡΩΤΟΔΙΚΩΝ ΑΘΗΝΩΝ">
                ΕΙΣ.ΠΡ.ΑΘΗΝΩΝ
              </SearchSelectItem>

              <SearchSelectItem value="ΕΙΣΑΓΓΕΛΙΑ ΠΡΩΤΟΔΙΚΩΝ ΘΕΣΣΑΛΟΝΙΚΗΣ">
                ΕΙΣ.ΠΡ.ΘΕΣΣΑΛΟΝΙΚΗΣ
              </SearchSelectItem>
              <SearchSelectItem value="ΕΙΣΑΓΓΕΛΙΑ ΠΡΩΤΟΔΙΚΩΝ ΠΕΙΡΑΙΑ">
                ΕΙΣ.ΠΡ.ΠΕΙΡΑΙΑ
              </SearchSelectItem>
              <SearchSelectItem value="ΕΙΣΑΓΓΕΛΙΑ ΠΡΩΤΟΔΙΚΩΝ ΧΑΛΚΙΔΑΣ">
                ΕΙΣ.ΠΡ.ΧΑΛΚΙΔΑΣ
              </SearchSelectItem>
            </SearchSelect>
          </div>
        </div>
        <TextInput
          placeholder="Σημείωση μέχρι 50 χαρακτήρες"
          value={note}
          onValueChange={(e) => setNote(e.slice(0, 50))}
          className="flex-none w-3/4 mt-2"
        />

        <Button
          className="flex-none w-3/4 mt-4"
          onClick={handleClickAddEauctionModal}
          color="emerald"
          size="lg"
        >
          Προσθήκη
        </Button>
      </form>

      {!location.pathname.includes("/eauction/") && (
        <MultiPoiniko
          eauctions={abms}
          handleDeleteEauction={handleDeleteEauction}
        />
      )}
    </section>
  );
};

export default Poinika;
