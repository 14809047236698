import { Card, List, ListItem } from "@tremor/react";
export const Show = ({ fields, desc }) => {
  console.log("fields", fields);
  return (
    <Card className="flex-1 min-w-[300px]">
      <h3>{desc}</h3>
      <List>
        {fields.map((field, i) => (
          <ListItem key={`${field}-${i}`}>{field}</ListItem>
        ))}
      </List>
    </Card>
  );
};
