import { axiosPrivate } from '../../api/axios';
import { useState } from 'react';
import { DateTime, Settings } from 'luxon'
import { getAdjCourt } from '../../utils/getAdjCourts'
Settings.defaultLocale = 'el'

Date.createFromMysql = function (mysql_string) {
  var t, result = null;

  if (typeof mysql_string === 'string') {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
}
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({ hours: 3 }).toFormat('ff')
  return formatedDate
}


const UpdateCard = ({ singleUpdate }) => {
  function UpdateCardTranform(singleUpdate) {
    if (singleUpdate.gak) {
      return (<article className='case_card'>
        {/* <div key={`ed${singleUpdate.id}`} className="edit_case"><p ><span onClick={handleDelete} data-key={singleUpdate.id}>✏️</span></p></div> */}
        <h3 data-case='astiki'>Αστική</h3>
        {singleUpdate?.note?.length > 0 && <h4><em>Σημείωση: </em>{singleUpdate?.note}</h4 >}
        <h4><em>ΓΑΚ:</em> {singleUpdate?.gak}/{singleUpdate?.year}</h4>
        <h4><em>Δικαστήριο:</em> {singleUpdate?.court}</h4>
        {singleUpdate?.eak?.length > 2 && <h4><em>Ημ/νία κατάθεσης: </em>{singleUpdate?.eak?.split("-")[1]}</h4 >}
        {singleUpdate?.arithmos_pinakiou?.length > 2 && (singleUpdate.updated_parts.includes('arithmos_pinakiou') ? <h4 className='updated'>☞<em>Αριθμός Πινακίου:</em> {singleUpdate?.arithmos_pinakiou}</h4> : <h4><em>Αριθμός Πινακίου:</em> {singleUpdate?.arithmos_pinakiou}</h4>)}
        {singleUpdate?.apotelesma?.length > 2 && <h4 className={singleUpdate.updated_parts.includes('apotelesma') && 'blue'}><em>Αποτέλεσμα:</em> {singleUpdate?.apotelesma}</h4>}
        {singleUpdate?.arithmos_apofasis?.length > 2 && <h4><em>Αρ.Απόφασης:</em> {singleUpdate?.arithmos_apofasis}</h4>}
        {singleUpdate?.last_checked && <h4 data-case='last_checked'><em>Ενημερώθηκε στις: </em> {formatDate(singleUpdate?.created_at)}</h4>}


      </article >)
    }
    if (singleUpdate.apotelesmate) {
      return (<article className='case_card'>
        {/* <div key={`ed${singleUpdate.id}`} className="edit_case"><p ><span onClick={handleDelete} data-key={singleUpdate.id}>✏️</span></p></div> */}
        <h3 data-case='dioikitiki'>Διοικητική</h3>
        {singleUpdate?.note?.length > 0 && <h4><em>Σημείωση: </em>{singleUpdate?.note}</h4 >}
        <h4><em>Αρ.Κατ:</em> {singleUpdate?.arkat}</h4>
        <h4><em>Δικαστήριο:</em> {getAdjCourt(singleUpdate?.court)}</h4>
        {singleUpdate?.imnia_eisagwgis?.length > 2 && <h4><em>Ημ/νια Εισαγωγής:</em> {singleUpdate?.imnia_eisagwgis}</h4>}
        {singleUpdate?.diadikasia?.length > 2 && <h4><em>Διαδικασία:</em> {singleUpdate?.diadikasia}</h4>}
        {singleUpdate?.antikeimeno?.length > 0 && <h4><em>Αντικείμενο:</em> {singleUpdate?.antikeimeno}</h4>}
        {singleUpdate?.katigoria?.length > 0 && <h4><em>Κατηγορία:</em> {singleUpdate?.katigoria}</h4>}
        {singleUpdate?.tmima?.length > 0 && <h4><em>Τμήμα:</em> {singleUpdate?.tmima}</h4>}
        {singleUpdate?.diataktiko?.length > 0 && (singleUpdate.updated_parts.includes('diataktiko') ? <h4 className='updated'>☞<em>Διατακτικό:</em> {singleUpdate?.diataktiko}</h4> : <h4 className='updated'>☞<em>Διατακτικό:</em> {singleUpdate?.diataktiko}</h4>)}
        {singleUpdate?.apotelesma?.length > 0 && (singleUpdate.updated_parts.includes('apotelesma') ? <h4 className='updated'>☞<em>Αποτέλεσμα:</em> {singleUpdate?.apotelesma}</h4> : <h4><em>Αποτέλεσμα:</em> {singleUpdate?.apotelesma}</h4>)}

        {singleUpdate?.arithmos_apofasis?.length > 0 && (singleUpdate.updated_parts.includes('arithmos_apofasis') ? <h4 className='updated'>☞<em>Αποτέλεσμα:</em> {singleUpdate?.arithmos_apofasis}</h4> : <h4 className='updated'>☞<em>Αποτέλεσμα:</em> {singleUpdate?.arithmos_apofasis}</h4>)}
        {singleUpdate?.dikasimos?.length > 0 && (singleUpdate.updated_parts.includes('dikasimos') ? <h4 className='updated'>☞<em>Δικάσιμος:</em> {singleUpdate?.dikasimos}</h4> : <h4><em>Δικάσιμος:</em> {singleUpdate?.dikasimos}</h4>)}
        {singleUpdate?.last_checked && <h4 data-case='last_checked'><em>Τελευταία ενημέρωση: </em> {formatDate(singleUpdate?.last_checked)}</h4>}


      </article >)
    }
  }

  return (
    UpdateCardTranform(singleUpdate)
  )
}

export default UpdateCard