import { axiosPrivate } from "../../api/axios";
import { useState, useCallback, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
import { DateTime, Settings } from "luxon";
import { mergeDates, normalizeAuctionDates } from "../../utils/checkMisthosi";
Settings.defaultLocale = "el";
// import ProthCard from './ProthCard';

const removeZeroes = (str) => {
  let res = str;
  while (res.at(0) === "0") {
    res = res.replace("0", "");
  }
  return res;
};
Date.createFromMysql = function (mysql_string) {
  var t,
    result = null;

  if (typeof mysql_string === "string") {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
};
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString())
    .plus({ minutes: Math.abs(new Date().getTimezoneOffset()) })
    .toFormat("ff");
  return formatedDate;
}
const EauctionCard = ({ singleEauction, handleDeleteEauction }) => {
  const [note, setNote] = useState(
    singleEauction?.note ? singleEauction.note : ""
  );
  const [showEdit, setShowEdit] = useState(false);
  const btnRef = createRef();
  // const inputRef = createRef();

  // const [showProth, setShowProth] = useState(false)
  // const [checkAppeal, setCheckAppeal] = useState(singleEauction.check_appeal === 1 ? true : false)
  // console.log(prothesmiesSingle);
  useEffect(() => {
    setNote(singleEauction.note);
  }, [singleEauction]);

  const handleClicks = async (e) => {
    if (
      e.target.getAttribute("data-icon") === "pen-to-square" ||
      e.target.parentNode.getAttribute("data-icon") === "pen-to-square"
    ) {
      setShowEdit(!showEdit);
    }
    if (
      e.target.getAttribute("data-icon") === "circle-check" ||
      e.target.parentNode.getAttribute("data-icon") === "circle-check"
    ) {
      setShowEdit(!showEdit);
      try {
        const id = e.target.parentNode.getAttribute("data-key")
          ? e.target.parentNode.getAttribute("data-key")
          : e.target.getAttribute("data-key");
        await axiosPrivate.post(
          "/eauctions/note",
          JSON.stringify({ id, note }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
      } catch (err) {
        console.error(err);
      }
    }
    if (
      e.currentTarget?.firstChild?.getAttribute("data-icon") === "trash-can"
    ) {
      try {
        const id = e.currentTarget.getAttribute("data-key");
        await axiosPrivate.patch("/eauctions", JSON.stringify({ key: id }), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        handleDeleteEauction(id);
      } catch (err) {
        console.error(err);
      }
    }
  };
  const inputRef = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      console.log("hey");
      console.log(btnRef.current);
      btnRef.current.click();
    }
  };
  return (
    <article className="case_card">
      <span title="Αύξων Αριθμός" className="number">
        {singleEauction?.i ? singleEauction?.i + 1 : "1"}
      </span>
      <div key={singleEauction.id} className="close_case">
        <p>
          <span
            style={{
              border: "none",
              cursor: "pointer",
              color: "red",
              backgroundColor: "white",
              paddingLeft: "5px",
              fontSize: "1.3em",
            }}
            title="Διαγράψτε την υπόθεση"
            onClick={handleClicks}
            data-key={singleEauction.id}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
        </p>
      </div>
      <h4>
        <em>Σημείωση:</em>{" "}
        {showEdit ? (
          <>
            <input
              ref={inputRef}
              defaultValue={note}
              className="edit-note"
              onChange={(e) => setNote(e.target.value)}
              onKeyDown={(e) => handleKeypress(e)}
            />
            <button
              ref={btnRef}
              style={{
                border: "none",
                cursor: "pointer",
                color: "green",
                backgroundColor: "white",
                paddingLeft: "5px",
                fontSize: "1.3em",
              }}
              onClick={handleClicks}
              data-key={singleEauction.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon
                data-key={singleEauction.id}
                icon={faCircleCheck}
              />
            </button>
          </>
        ) : (
          <span>
            {note}
            <button
              data-icon="pen-to-square"
              title="Επεξεργαστείτε την σημείωση"
              style={{
                border: "none",
                cursor: "pointer",
                color: "green",
                backgroundColor: "white",
                paddingLeft: "5px",
                fontSize: "1.3em",
              }}
              onClick={handleClicks}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
          </span>
        )}
      </h4>
      <h4>
        <em>Ημ/νίες Πλειστηριασμών: </em>
        {singleEauction?.auction_dates?.length > 2
          ? mergeDates(
              normalizeAuctionDates(singleEauction?.auction_dates)
                .map((e) => e.imerominia_diexagogis)
                .join(", ")
            )
          : " - "}
      </h4>
      <h4>
        <em>Τελευταία Ημ/νία Ανάρτησης: </em>
        {singleEauction?.auction_dates?.length > 2
          ? normalizeAuctionDates(singleEauction?.auction_dates).at(-1)
              .imerominia_anartisis
          : " - "}
      </h4>

      {singleEauction?.created_at && (
        <h4>
          <em>Δημιουργία: </em> {formatDate(singleEauction?.created_at)}
        </h4>
      )}
      {singleEauction?.last_checked && (
        <h4 data-case="last_checked">
          <em>Τελευταία ενημέρωση: </em>{" "}
          {formatDate(singleEauction?.last_checked)}
        </h4>
      )}
    </article>
  );
};

export default EauctionCard;
