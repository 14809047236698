// A function that takes a court and truncates the first word,eg ΕΦΕΤΕΙΟ ΑΘΗΝΩΝ -> ΕΦ. ΑΘΗΝΩΝ
export const truncateCourt = (court) => {
  let label = court
  if (court.includes('ΑΡΕΙΟΣ')) {
    label = court.replace('ΑΡΕΙΟΣ', 'ΑΡ.')
  }
  if (court.includes('ΕΙΡΗΝΟΔΙΚΕΙΟ')) {
    label = court.replace('ΕΙΡΗΝΟΔΙΚΕΙΟ', 'ΕΙΡ.')
  }
  if (court.includes('ΕΦΕΤΕΙΟ')) {
    label = court.replace('ΕΦΕΤΕΙΟ', 'ΕΦ.')
  }
  if (court.includes('ΠΡΩΤΟΔΙΚΕΙΟ')) {
    label = court.replace('ΠΡΩΤΟΔΙΚΕΙΟ', 'ΠΡ.')
  }
  return label
}