import { useState, useRef, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import AddCaseEirNikaias from "./AddCaseCourt/AddCaseEirNikaias";
import AddCaseProtPatras from "./AddCaseCourt/AddCaseProtPatras";
import AddCaseEirPatras from "./AddCaseCourt/AddCaseEirPatras";
import AddCaseProtLarisas from "./AddCaseCourt/AddCaseProtLarisas";
import AddCaseProtKo from "./AddCaseCourt/AddCaseProtKo";


const AddCase = ({ handleClickAddCaseModal, cases, handleAddCase }) => {

  const [arkat, setArkat] = useState('');
  const [kwdikos, setKwdikos] = useState('');
  const [court, setCourt] = useState('eirnikaias');
  const [eidos, setEidos] = useState('epanaprosd');
  const [year, setYear] = useState('');


  const [note, setNote] = useState('');
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState('');


  useEffect(() => {
    if (court === 'protpatras') {
      setEidos('politiko')
    }
    if (court === 'eirnikaias') {
      setEidos('epanaprosd')
    }
    if (court === 'protkos') {
      setEidos('all')
    }
    if (court === 'eirpatras') {
      setEidos('asfalistika')
    }
    if (court === 'protlarisas') {
      setEidos('asfalistika')
    }

  }, [court]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {}
    if (court === 'eirnikaias' && eidos === 'epanaprosd') {
      obj.search_key = 'codeplatform'
      obj.search_value = arkat
      obj.court = court
      obj.note = note
      obj.eidos = eidos
    }
    if (court === 'eirnikaias' && (eidos === 'diatages' || eidos === 'dimosiefseis')) {
      obj.search_key = 'arkat'
      obj.search_value = `${arkat}/${year}`
      obj.court = court
      obj.note = note
      obj.eidos = eidos
    }
    if (court === 'protpatras') {
      obj.search_key = 'arkat'
      obj.search_value = `${arkat}/${year}`
      obj.court = court
      obj.note = note
      obj.eidos = eidos
    }
    if (court === 'protlarisas') {
      obj.search_key = 'arkat'
      obj.search_value = `${arkat}/${year}`
      obj.court = court
      obj.note = note
      obj.eidos = eidos
    }
    if (court === 'eirpatras') {
      obj.search_key = 'arkat'
      obj.search_value = `${arkat}/${year}`
      obj.court = court
      obj.note = note
      obj.eidos = eidos
    }
    if (court === 'protkos') {
      obj.search_key = 'arkat'
      obj.search_value = arkat
      obj.court = court
      obj.note = note
      obj.eidos = eidos
    }
    try {
      console.log(cases.find(e => `${arkat}/${year}` === e.search_value && e.court === court && e.eidos === eidos));

      if (cases.find(e => `${arkat}/${year}` === e.search_value && e.court === court && e.eidos === eidos)) {
        setErrMsg('Έχετε ξανακαταχωρήσει την υπόθεση');
        return
      }
      if (cases.length >= 31) {
        setErrMsg('Ξεπεράσατε το όριο των 30 υποθέσεων');
        return
      }
      if (cases.length < 31 && !cases.find(e => `${arkat}/${year}` === e.search_value && e.court === court && e.eidos === eidos)) {
        console.log(obj);

        await axiosPrivate.post('/exscases',
          JSON.stringify(obj),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );
        handleClickAddCaseModal()
        handleAddCase({ arkat, kwdikos, court, note, year, eidos })
      }

      // forceUpdate()
      //console.log(JSON.stringify(response));

    } catch (err) {
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  }
  const handleChange = (event) => {
    setCourt(event.target.value);
  };
  const handleChangeDiad = (event) => {
    setEidos(event.target.value);
  };


  return (
    <section className="container_ac" >
      <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

      <div className="close_addcase"><p onClick={() => handleClickAddCaseModal()}>❌</p></div>
      {/* <span></span> */}
      {/* <h1>Sign In</h1> */}
      <form className="form" onSubmit={handleSubmit}>
        {/* <label htmlFor="username">Email:</label> */}
        <div className="input-container ic2">
          <select value={court} onChange={handleChange} className='input'>
            <option key={'eirnikaias'} value={'eirnikaias'}>Ειρην. Νικαίας</option>
            <option key={'protkos'} value={'protkos'}>Πρωτ. Κω</option>
            <option key={'protpatras'} value={'protpatras'}>Πρωτ. Πατρών</option>
            <option key={'eirpatras'} value={'eirpatras'}>Ειρ. Πατρών</option>
            <option key={'protlarisas'} value={'protlarisas'}>Πρωτ. Λάρισας</option>

          </select>
          <div className="cut"></div>
          <label htmlFor="court" className="placeholder">Δικαστήριο</label>
        </div>

        {court === 'eirnikaias' && <AddCaseEirNikaias eidos={eidos} handleChangeDiad={handleChangeDiad} setArkat={setArkat} arkat={arkat} year={year} setYear={setYear} />}
        {court === 'protpatras' && <AddCaseProtPatras eidos={eidos} handleChangeDiad={handleChangeDiad} setArkat={setArkat} arkat={arkat} year={year} setYear={setYear} />}
        {court === 'eirpatras' && <AddCaseEirPatras eidos={eidos} handleChangeDiad={handleChangeDiad} setArkat={setArkat} arkat={arkat} year={year} setYear={setYear} />}
        {court === 'protlarisas' && <AddCaseProtLarisas eidos={eidos} handleChangeDiad={handleChangeDiad} setArkat={setArkat} arkat={arkat} year={year} setYear={setYear} />}
        {court === 'protkos' && <AddCaseProtKo eidos={eidos} handleChangeDiad={handleChangeDiad} setArkat={setArkat} arkat={arkat} year={year} setYear={setYear} />}


        <div className="input-container ic2">
          <input
            className='input'
            type="text"
            id="note"
            placeholder=' '
            autoComplete="on"
            onChange={(e) => setNote(e.target.value)}
            value={note}

          />
          <div className="cut"></div>
          <label htmlFor="note" className="placeholder">Σημείωση</label>
        </div>


        <button type="text" className="submit" >Προσθήκη</button>

      </form>

    </section>
  )
}

export default AddCase