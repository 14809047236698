// '2022-08-18'=>'18-08-2022'
// or
// '18-08-2022'=>'2022-08-18'
export function reverseDate(date) {
  if (date.includes("/")) {
    const dateArr = date.split("/");
    return `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
  }

  const dateArr = date.split("-");
  return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
}

const details = {
  katathesi: "Κατάθεση",
  epidosi: "Επίδοση",
  paremvasi: "Παρέμβαση, προσεπίκληση, ανακοίνωση ή ανταγωγή",
  paremvasiProsek: "Παρέμβαση του προσεπικαλούμενου",
  protaseis: "Κατάθεση προτάσεων",
  prosthiki: "Κατάθεση προσθήκης",
  opsigeneis: "Οψιγενείς ή παραχρήμα αποδεικνυόμενοι ισχυρισμοί",
  opsigeneisAntikrousi: "Αντίκρουση σε οψιγενείς",
  proskomidi:
    "Προσκομιδή των αποδεικτικών από τους διαδίκους και η υποβολή του έγγραφου υπομνήματος του εναγομένου",
  proskomidiParemv:
    "Προσκομιδή των αποδεικτικών και η υποβολή του έγγραφου υπομνήματος της παρέμβασης ή της ανταγωγής",
  prosthikiParemv: "Κατάθεση προσθήκης επί της παρέμβασης ή της ανταγωγής",
  dikasimos: "Δικάσιμος",
  dimosiefsi: "Δημοσίευση",
};
const codes = {
  katathesi: "1",
  epidosi: "2",
  paremvasi: "3",
  paremvasiProsek: "4",
  protaseis: "5",
  prosthiki: "6",
  opsigeneis: "7",
  opsigeneisAntikrousi: "8",
  proskomidi: "9",
  proskomidiParemv: "10",
  prosthikiParemv: "11",
  dikasimos: "12",
  dimosiefsi: "13",
};

export function returnProthArray(proth) {
  const keys = Object.keys(proth);
  const arr = [];
  keys.forEach((r) => {
    const obj = {
      prothesmia: proth[r],
      onoma: details[r],
      code: codes[r],
    };
    arr.push(obj);
  });
  return arr;
}

// a function that takes a code and returns the corresponding detail
export function returnDetail(code) {
  const keys = Object.keys(codes);
  const arr = [];
  keys.forEach((r) => {
    if (codes[r] === code) arr.push(details[r]);
  });
  return arr[0];
}
