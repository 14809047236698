import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";

import axios from "../api/axios";
const FORGOT_URL = "/reset";

const Forgot = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  // useEffect(() => {
  //   userRef.current.focus();
  // }, [])

  useEffect(() => {
    setErrMsg("");
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(FORGOT_URL, JSON.stringify({ email: user }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      //console.log(JSON.stringify(response));

      setSuccess(true);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      {success ? (
        <section>
          <h1>
            Εστάλη email στην διεύθυνση που δηλώσατε (θα μπορέσετε να
            ξαναζητήσετε επαναφορά κωδικού μετά από 10 λεπτά).
          </h1>
        </section>
      ) : (
        <section className="container">
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
          {/* <h1>Sign In</h1> */}
          <form className="form" onSubmit={handleSubmit}>
            {/* <label htmlFor="username">Email:</label> */}
            <div className="input-container ic1">
              <input
                className="input"
                placeholder=" "
                type="text"
                id="username"
                ref={userRef}
                autoComplete="on"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
              <div className="cut"></div>
              <label htmlFor="email" className="placeholder">
                email
              </label>
            </div>

            {/* <label htmlFor="password">Password:</label> */}

            <button type="text" className="submit">
              Υποβολή
            </button>
          </form>
        </section>
      )}
    </>
  );
};

export default Forgot;
