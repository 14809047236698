import { axiosPrivate } from '../../api/axios';
import { useState } from 'react';
import { DateTime, Settings } from 'luxon'
import { getAdjCourt } from '../../utils/getAdjCourts'
import { Link, useLocation } from 'react-router-dom'
import Pagination from "../Util/Pagination";
import { Eggrafa } from './Gemi/Eggrafa';
import { Paginated } from '../Util/Pagination/Paginated'
import { redact } from '../Util/redact';

Settings.defaultLocale = 'el'

const removeZeroes = (str) => {
  let res = str
  while (res.at(0) === '0') {
    res = res.replace('0', '')
  }
  return res
}
Date.createFromMysql = function (mysql_string) {
  var t, result = null;

  if (typeof mysql_string === 'string') {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
}
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({ hours: 3 }).toFormat('ff')
  return formatedDate
}
const VEtairiaCard = ({ singleEtairia, handleDeleteCase }) => {
  const [note, setNote] = useState(singleEtairia?.note)

  const [showEdit, setShowEdit] = useState(false)
  const location = useLocation();
  const [page, setPage] = useState(0);
  function dividePages(arr) {
    const res = []
    for (let index = 0; index < arr.length; index++) {
      const el = []
      if (index % 5 === 0) {
        res.push(el)
      }

      let i = Math.floor(index / 5)
      res[i].push(arr[index])
    }
    return res

  }

  const handlePage = async (e) => {
    if (e.target.getAttribute("data-key") === 'next') {
      setPage(page + 1)
    }
    if (e.target.getAttribute("data-key") === 'end') {
      setPage(dividePages(singleEtairia.eggrafa).length - 1)
    }
    if (e.target.getAttribute("data-key") === 'previous') {
      setPage(page - 1)
    }
    if (e.target.getAttribute("data-key") === 'start') {
      setPage(0)
    }



  }

  const handleEdit = () => {
    setShowEdit(!showEdit)
  }
  const handleUpdateNote = async (e) => {
    setShowEdit(!showEdit)
    try {
      const id = e.target.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.post('/adjustice/note',
        JSON.stringify({ id, note }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );


    } catch (err) {
      console.error(err);
    }
  }
  const handleKeyPress = async (e) => {

    if (e.key === 'Enter') {
      console.log('ji');
      console.log(e.target.getAttribute('data-key'));

      setShowEdit(!showEdit)
      try {
        const id = e.target.getAttribute('data-key')
        // console.log(JSON.stringify({ key: id }));
        await axiosPrivate.post('/adjustice/note',
          JSON.stringify({ id, note }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );


      } catch (err) {
        console.error(err);
      }
    }
  }

  const handleDelete = async (e) => {

    try {
      const id = e.target.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.patch('/etairies',
        JSON.stringify({ key: id }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log(id);
      handleDeleteCase(id)

    } catch (err) {
      console.error(err);
    }
  }
  console.log(singleEtairia);
  const katigoriesEggrafon = [...new Set(singleEtairia?.eggrafa?.map(r => r.eidos))]
  console.log(katigoriesEggrafon);

  return (
    <section className='kartela-solon'>

      < section className='stoixeia-dikasimos-prothesmies' >
        <article className='adj-stoixeia-ypothesis'>
          <p>Καρτέλα Εταιρίας</p>
          <h1>{singleEtairia?.diakritikos}</h1>
          <h2>{singleEtairia?.eponymia}</h2>
          <h3>ΑΦΜ</h3>
          {singleEtairia?.afm?.trim() ? <p className='stoixeia-text'>{singleEtairia?.afm}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Αρ.ΓΕΜΗ</h3>
          {singleEtairia?.argemi?.trim() ? <p className='stoixeia-text'>{singleEtairia?.argemi}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Διεύθυνση</h3>
          {singleEtairia?.diefthinsi?.trim() ? <p className='stoixeia-text'>{singleEtairia?.diefthinsi.replace('Διεύθυνση Επιχείρησης\n', '')}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Ιστοσελίδα</h3>
          {singleEtairia?.istoselida?.trim() ? <p className='stoixeia-text'><a href='singleEtairia?.istoselida' target="_blank" rel="noreferrer">{singleEtairia?.istoselida}</a></p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Κατάσταση</h3>
          {singleEtairia?.katastasi?.trim() ? <p className='stoixeia-text'>{singleEtairia?.katastasi}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Ημερομηνία Σύστασης</h3>
          {singleEtairia?.imnia_systasis?.trim() ? <p className='stoixeia-text'>{singleEtairia?.imnia_systasis.replaceAll('/', '.')}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Νομική Μορφή</h3>
          {singleEtairia?.nomiki_morfi?.trim() ? <p className='stoixeia-text'>{singleEtairia?.nomiki_morfi}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Διοίκηση</h3>
          {singleEtairia?.dioikisi?.trim() ? <p className='stoixeia-text'>{redact(singleEtairia?.dioikisi).map(r => (<p>{r}</p>))}</p> : <p className='stoixeia-text empty'>---</p>}

          <h3>Δημιουργήθηκε στις:</h3>
          {singleEtairia?.created_at?.trim() ? <p className='stoixeia-text'>{formatDate(singleEtairia?.created_at)}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Τελευταίος έλεγχος για ενημερώσεις στις:</h3>
          {singleEtairia?.last_checked?.trim() ? <p className='stoixeia-text'>{formatDate(singleEtairia?.last_checked)}</p> : <p className='stoixeia-text empty'>---</p>}

        </article>
        {/* <section className='dikasimos-prothesmies'>
          <article className='dikasimos'>
            <h2>Δικάσιμος</h2>
            {/* {imerominia?.trim() ? <p className='stoixeia-text'>{imerominia}</p> : <p className='stoixeia-text empty'>---</p>} */}
        {/* </article>
          <article className='prothesmies-kartela'>
            <h2>Προθεσμίες</h2> */}
        {/* {prothesmies.length ? prothesmies.map(r => (
              (r.prothesmia && r.onoma) && <div key={r.onoma}><p className='prothesmies-text'>{r.onoma}</p><p className='prothesmies-date'>{reverseDate(r.prothesmia).replaceAll('-', '.')}</p>  </div>
            )) : <p className='prothesmies-text'>---</p>} */}

        {/* {imerominia.trim() ? <p className='stoixeia-text'>{imerominia}</p> : <p className='stoixeia-text empty'>---</p>} */}
        {/* </article> */}
        {/* </section> * /} */}
      </section >
      <section className='solon-enimerwseis'>
        <article className='enimerwseis'>
          <h2 className='enimerwseis-title'>Ανακοινώσεις</h2>
          {katigoriesEggrafon.map((r) => (<div key={r}>
            <h3 className='gemi-eidos'>{r}</h3>
            <Paginated data={singleEtairia.eggrafa.filter((i) => i.eidos === r).map((k, x) => { return { ...k, no: x + 1 } })} Component={Eggrafa} />

          </div>
          ))}

          {/* {singleEtairia?.eggrafa?.length ? <ol>
            {singleEtairia?.eggrafa.map(r => r.updated_parts !== ' ' && <li key={r.id} className='enimerwseis-list-item'>
              <span className='enimerwseis-date'>{r.created_at}</span>
              <p className='enimerwseis-allages-title'>Αλλαγές στα πεδία:</p>

            </li>)}

          </ol> : <p>Καμμία ενημέρωση προς το παρόν</p>} */}
        </article></section>
    </section >
  )

}

export default VEtairiaCard