import React, { useEffect, useContext, useState, useRef } from "react";
import { nanoid } from "nanoid";
import axios from "axios";
import { DateTime, Settings } from "luxon";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import el from "javascript-time-ago/locale/el";
import Post from "./Post";
import { Tab, TabGroup, TabList, Text } from "@tremor/react";
import { useInterval } from "../../utils/useInterval";

import AuthContext from "../../context/AuthProvider";
import RotatingElements from "../rotating";

TimeAgo.addLocale(el);
const timeAgo = new TimeAgo("el-GR");

Settings.defaultLocale = "el";

Date.createFromMysql = function (mysql_string) {
  var t,
    result = null;

  if (typeof mysql_string === "string") {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
};
export function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString())
    .plus({ minutes: Math.abs(new Date().getTimezoneOffset()) })
    .toUnixInteger();
  const unixInt = new Date(formatedDate * 1000);
  return timeAgo.format(unixInt);
}

const Ekloges = () => {
  const [value, setValue] = React.useState("1");
  const { avatarUploaded, notifLength } = React.useContext(AuthContext);
  const [body, setBody] = React.useState("");
  const [uuid, setUuid] = React.useState(nanoid());
  const [initialPosts, setInitialPosts] = React.useState([]);
  const [posts, setPosts] = React.useState([]);
  const [showCreatePost, setShowCreatePost] = React.useState(true);
  const [page, setPage] = useState(1);
  const [lastId, setLastId] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [grammateisCount, setGrammateisCount] = useState(0);
  const [dikastikoiCount, setDikastikoiCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isPostingViews, setIsPostingViews] = useState(false);

  const sentryRef = useRef(null);
  const postViewCounter = async (isMounted, controller) => {
    try {
      const allViewsFromLocalStorage = getPostsFromLocalStorage();
      if (allViewsFromLocalStorage.length === 0) return;
      setIsPostingViews(true);

      const res = await axiosPrivate.post(
        "/viewCounter",
        {
          viewCounts: allViewsFromLocalStorage,
        },
        { signal: controller.signal }
      );
      // console.log(res.data);
      isMounted && setIsPostingViews(false);
      isMounted && markPostsAsSent(allViewsFromLocalStorage);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        // do something
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };
  useInterval(async () => {
    if (isPostingViews) return;
    let isMounted = true;
    const controller = new AbortController();
    await postViewCounter(isMounted, controller);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, 1000);
  const getPostsFromLocalStorage = () => {
    const posts = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("post-")) {
        const item = localStorage.getItem(key);
        if (item === "sent") continue;
        posts.push({
          [key]: item,
        });
      }
    }
    return posts;
  };

  const markPostsAsSent = (posts) => {
    posts.forEach((post) => {
      const key = Object.keys(post)[0];
      localStorage.setItem(key, "sent");
    });
  };
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && posts.length > 0) {
          // #sentry element is in view
          console.log("#sentry is in view");
          getPosts(false);
          setLastId(posts[posts.length - 1].id);
        }
      });
    });

    if (sentryRef.current) {
      observer.observe(sentryRef.current);
    }

    return () => {
      if (sentryRef.current) {
        observer.unobserve(sentryRef.current);
      }
    };
  }, [posts]);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  function deletePost(uuid) {
    setInitialPosts(initialPosts.filter((post) => post.uuid !== uuid));
    setPosts(posts.filter((post) => post.uuid !== uuid));
  }

  useEffect(() => {
    const newPostElement = document.getElementById("new-post");
    const newPostHeight = newPostElement.offsetHeight;
    const count = body.split("\n").length - 1;

    newPostElement.style.height = `${
      (Math.ceil(body.length / 56) + count) * 25
    }px`;
    console.log(newPostHeight);
  }, [body]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getPosts = async () => {
      try {
        const response = await axiosPrivate.get("/posts/all", {
          signal: controller.signal,
          params: { ekloges: "true" },
        });
        isMounted &&
          setInitialPosts((prevPosts) => [...prevPosts, ...response?.data]);
        isMounted && setPosts((prevPosts) => [...prevPosts, ...response?.data]);
        isMounted && setLastId(response.data[response?.data.length - 1]?.id);
        isMounted && setTotalCount(response?.data[0]?.count);
        isMounted && setGrammateisCount(response?.data[0]?.grammateisCount);
        isMounted && setDikastikoiCount(response?.data[0]?.dikastikoiCount);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getPosts();

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifLength]);

  const getPosts = async (created) => {
    console.log("getPosts");
    try {
      let response;
      if (!created) {
        response = await axiosPrivate.get("/posts/all", {
          params: { page: lastId, ekloges: "true" },
        });
      }
      if (created) {
        response = await axiosPrivate.get("/posts/all", {
          params: { page: undefined, ekloges: "true" },
        });
      }

      setInitialPosts((prevPosts) => [...prevPosts, ...response.data]);
      setPosts((prevPosts) => [...prevPosts, ...response.data]);
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();

    try {
      await axiosPrivate.post("/posts", JSON.stringify({ body, uuid }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await handleCreate(e);
      await axiosPrivate.post(
        `/posts/${uuid}`,
        JSON.stringify({ body, grammateas: value === "1" ? "1" : "0" }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setBody("");
      setUuid(nanoid());
      getPosts(true);
      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  function showGrammateisPosts() {
    const grammateisPosts = initialPosts.filter(
      (post) => post.grammateas === 1
    );
    setPosts(grammateisPosts);
  }
  function showDikastikoiPosts() {
    const dikastikoiPosts = initialPosts.filter(
      (post) => post.grammateas === 0
    );
    setPosts(dikastikoiPosts);
  }
  // if post.pinned === 1 then it is pinned and it should be at the top
  const filteredPosts = posts
    .filter((post, index, self) => {
      return index === self.findIndex((t) => t.uuid === post.uuid);
    })
    .sort((a, b) => {
      return new Date(b.created_at) - new Date(a.created_at);
    })
    .sort((a, b) => {
      return b.pinned - a.pinned;
    });
  const handleSelect = (e) => {
    const mapObj = { 0: "1", 1: "2" };
    setValue(mapObj[e]);
  };

  const unfilteredPosts = initialPosts.filter((post, index, self) => {
    return index === self.findIndex((t) => t.uuid === post.uuid);
  });

  return (
    <div className="ekloges-section">
      {showCreatePost && (
        <div className="create_post">
          <textarea
            id="new-post"
            value={body}
            placeholder="Γράψτε το μήνυμά σας εδώ..."
            onChange={(e) => setBody(e.target.value)}
            className="ekloges-post-create"
          />

          {/* <Text>Ψάχνω</Text>
          <Toggle value={value} onValueChange={setValue}>
            <ToggleItem value="1" text={"Γραμματέα"} />
            <ToggleItem value="2" text={"Δικ.Αντιπρόσωπο"} />
          </Toggle> */}
          <Text>Ψάχνω</Text>
          <TabGroup
            defaultIndex={0}
            onIndexChange={handleSelect}
            className="w-fit mt-1"
          >
            <TabList variant="solid">
              <Tab>Γραμματέα</Tab>
              <Tab>Δικ.Αντιπρόσωπο</Tab>
            </TabList>
          </TabGroup>
          <div className="post-buttons">
            <button
              onClick={handleSubmit}
              className="addcase_modal check_now ekloges_buttons"
            >
              Αποστολή
            </button>
          </div>
        </div>
      )}

      <div className="show-buttons">
        <button
          onClick={() => showDikastikoiPosts()}
          className="post-footer-badge-grammateas"
        >
          Γραμματείς: {grammateisCount}
        </button>
        <button
          onClick={() => showGrammateisPosts()}
          className="post-footer-badge"
        >
          Δικαστικοί: {dikastikoiCount}
        </button>
      </div>

      {/* show posts with unique uuid */}
      {filteredPosts.map((post, i) => (
        <div className="ekloges-post" key={`${lastId}-${post.uuid}`}>
          <Post
            creator={`${post.user?.first_name} ${post.user?.last_name}`}
            email={post.user?.email}
            timeSince={formatDate(post?.created_at)}
            body={post.body}
            postUuid={post.uuid}
            avatar={post.user?.avatar_uuid}
            uuid={post.user?.uuid}
            avatarUploaded={avatarUploaded}
            postCreatorId={post.user?.id}
            deletePost={deletePost}
            grammateas={post.grammateas}
            post={post}
            getPosts={getPosts}
          />
        </div>
      ))}
      {unfilteredPosts.length < totalCount && (
        <div ref={sentryRef} id="sentry">
          Loading...
        </div>
      )}
    </div>
  );
};

export default Ekloges;
