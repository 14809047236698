
import ExtraInfo from "./ExtraInfo";

const Container = ({ handleChange, userInput }) => {

  return (

    <ExtraInfo handleChange={handleChange} userInput={userInput} />
  )
}

export default Container