export const HeartComment = ({ commentLikes }) => {
  if (commentLikes === 0) {
    return <></>;
  }
  if (commentLikes === 1) {
    return (
      <div className="hearts-comment-block">
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart-comment"
        ></img>
      </div>
    );
  }
  if (commentLikes > 1) {
    return (
      <div className="hearts-comment-block">
        <img
          src="/images/myheart.png"
          alt="heart"
          className="heart-comment"
        ></img>
        <span className="comment-likes">{commentLikes}</span>
      </div>
    );
  }
};
