import { axiosPrivate } from '../../api/axios';
import { useState } from 'react';
import { DateTime, Settings } from 'luxon'
import { getAdjCourt } from '../../utils/getAdjCourts'
import './Adjustice/adjCard.css'
Settings.defaultLocale = 'el'

const pedia = { apotelesma: 'Αποτέλεσμα', 'keimeno_apof': 'Κείμενο Απόφασης', diataktiko: 'Διατακτικό', dikasimos: "Δικάσιμος", '1': 'Πληροφορίες', '2': 'Κατάσταση', '3': 'Αποτελέσματα', '4': 'Έγγραφα' }

Date.createFromMysql = function (mysql_string) {
  var t, result = null;

  if (typeof mysql_string === 'string') {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
}
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({ hours: 3 }).toFormat('ff')
  return formatedDate
}

function formatDateWithWeekDay(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({ minutes: Math.abs(new Date().getTimezoneOffset()) })
  return `${formatedDate.toFormat('EEEE')}, ${formatedDate.toLocaleString(DateTime.DATE_FULL)}`
}
const VAdjCard = ({ singleCase, handleDeleteCase, singleUpdates }) => {
  const [note, setNote] = useState(singleCase?.note)

  const [showEdit, setShowEdit] = useState(false)

  const courts = [
    'ΣτΕ',
    "ΔΕφ Αθηνών",
    "ΔΕφ Θεσσαλονίκης",
    "ΔΕφ Πειραιά",
    "ΔΠρ Αθηνών",
    "ΔΠρ Θεσσαλονίκης",
    "ΔΠρ Πειραιά",
    "ΔΕφ Κομοτηνής",
    "ΔΠρ Αλεξανδρούπολης",
    "ΔΠρ Καβάλας",
    "ΔΠρ Κομοτηνής",
    "ΔΠρ Βέροιας",
    "ΔΠρ Σερρών",
    "ΔΠρ Κοζάνης",
    "ΔΕφ Ιωαννίνων",
    "ΔΠρ Ιωαννίνων",
    "ΔΕφ Λάρισας",
    "ΔΠρ Βόλου",
    "ΔΠρ Λάρισας",
    "ΔΠρ Τρικάλων",
    "ΔΠρ Κέρκυρας",
    "ΔΕφ Πάτρας",
    "ΔΠρ Αγρινίου",
    "ΔΠρ Μεσολογγίου",
    "ΔΠρ Πάτρας",
    "ΔΠρ Πύργου",
    "ΔΠρ Λαμίας",
    "ΔΠρ Λιβαδειάς",
    "ΔΠρ Χαλκίδας",
    "ΔΕφ Τρίπολης",
    "ΔΠρ Καλαμάτας",
    "ΔΠρ Κορίνθου",
    "ΔΠρ Ναυπλίου",
    "ΔΠρ Τρίπολης",
    "ΔΠρ Μυτιλήνης",
    "ΔΠρ Ρόδου",
    "ΔΠρ Σύρου",
    "ΔΕφ Χανίων",
    "ΔΠρ Ηρακλείου",
    "ΔΠρ Χανίων"
  ]
  const links = ['ste/pageste/stepage', 'defeteioath', 'defeteiothe', 'defeteiopei', 'dprotodikeioath', 'dprotodikeiothe', 'dprotodikeiopei', 'defeteiokom', 'dprotodikeioale', 'dprotodikeiokav', 'dprotodikeiokom', 'dprotodikeiover', 'dprotodikeioser', 'dprotodikeiokoz', 'defeteioiwa', 'dprotodikeioiwa', 'defeteiolar', 'dprotodikeiovol', 'dprotodikeiolar', 'dprotodikeiotri', 'dprotodikeioker', 'defeteiopat', 'dprotodikeioagr', 'dprotodikeiomes', 'dprotodikeiopat', 'dprotodikeiopyr', 'dprotodikeiolam', 'dprotodikeioliv', 'dprotodikeioxal', 'defeteiotrp', 'dprotodikeiokal', 'dprotodikeiokor', 'dprotodikeionaf', 'dprotodikeiotrp', 'dprotodikeiomyt', 'dprotodikeiorod', 'dprotodikeiosyr', 'defeteioxan', 'dprotodikeiohra', 'dprotodikeioxan']

  // const [showProth, setShowProth] = useState(false)
  // const [checkAppeal, setCheckAppeal] = useState(singleEtairia.check_appeal === 1 ? true : false)
  // console.log(prothesmiesSingle);
  const handleEdit = () => {
    setShowEdit(!showEdit)
  }
  const handleUpdateNote = async (e) => {
    setShowEdit(!showEdit)
    try {
      const id = e.target.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.post('/adjustice/note',
        JSON.stringify({ id, note }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );


    } catch (err) {
      console.error(err);
    }
  }
  const handleKeyPress = async (e) => {

    if (e.key === 'Enter') {
      console.log('ji');
      console.log(e.target.getAttribute('data-key'));

      setShowEdit(!showEdit)
      try {
        const id = e.target.getAttribute('data-key')
        // console.log(JSON.stringify({ key: id }));
        await axiosPrivate.post('/adjustice/note',
          JSON.stringify({ id, note }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );


      } catch (err) {
        console.error(err);
      }
    }
  }

  const handleDelete = async (e) => {

    try {
      const id = e.target.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.patch('/etairies',
        JSON.stringify({ key: id }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log(id);
      handleDeleteCase(id)

    } catch (err) {
      console.error(err);
    }
  }
  return (
    <section className='kartela-solon'>

      < section className='stoixeia-dikasimos-prothesmies' >
        <article className='adj-stoixeia-ypothesis'>
          <p>Καρτέλα Υπόθεσης</p>
          {singleCase?.note?.trim() ? <h1>{singleCase?.note}</h1> : <h1>Αρ. Κατάθεσης {singleCase.arkat} {getAdjCourt(singleCase?.court)}</h1>}
          {singleCase?.note?.trim() && <h2>Αρ. Κατάθεσης {singleCase.arkat} {getAdjCourt(singleCase?.court)}{singleCase?.eak ? singleCase.eak.split('-')[1].replaceAll('/', '.') : singleCase.year} </h2>}
          <h3>Ημερομηνία Εισαγωγής</h3>
          {singleCase?.imnia_eisagwgis?.trim() ? <p className='stoixeia-text'>{singleCase?.imnia_eisagwgis.replaceAll('/', '.')}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Κατηγορία</h3>
          {singleCase?.katigoria?.trim() ? <p className='stoixeia-text'>{singleCase?.katigoria}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Σύνθεση</h3>
          {singleCase?.synthesi?.trim() ? <p className='stoixeia-text'>{singleCase?.synthesi}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Τμήμα</h3>
          {singleCase?.tmima?.trim() ? <p className='stoixeia-text'>{singleCase?.tmima}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Αντικείμενο</h3>
          {singleCase?.antikeimeno?.trim() ? <p className='stoixeia-text'>{singleCase?.antikeimeno}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Αποτέλεσμα</h3>
          {singleCase?.apotelesma?.trim() ? <p className='stoixeia-text'>{singleCase?.apotelesma}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Διατακτικό</h3>
          {singleCase?.diataktiko?.trim() ? <p className='stoixeia-text'>{singleCase?.diataktiko}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Δημιουργήθηκε στις:</h3>
          {singleCase?.created_at?.trim() ? <p className='stoixeia-text'>{formatDate(singleCase?.created_at)}</p> : <p className='stoixeia-text empty'>---</p>}
          <h3>Τελευταίος έλεγχος για ενημερώσεις στις:</h3>
          {singleCase?.last_checked?.trim() ? <p className='stoixeia-text'>{formatDate(singleCase?.last_checked)}</p> : <p className='stoixeia-text empty'>---</p>}

        </article>
        {/* <section className='dikasimos-prothesmies'>
          <article className='dikasimos'>
            <h2>Δικάσιμος</h2>
            {/* {imerominia?.trim() ? <p className='stoixeia-text'>{imerominia}</p> : <p className='stoixeia-text empty'>---</p>} */}
        {/* </article>
          <article className='prothesmies-kartela'>
            <h2>Προθεσμίες</h2> */}
        {/* {prothesmies.length ? prothesmies.map(r => (
              (r.prothesmia && r.onoma) && <div key={r.onoma}><p className='prothesmies-text'>{r.onoma}</p><p className='prothesmies-date'>{reverseDate(r.prothesmia).replaceAll('-', '.')}</p>  </div>
            )) : <p className='prothesmies-text'>---</p>} */}

        {/* {imerominia.trim() ? <p className='stoixeia-text'>{imerominia}</p> : <p className='stoixeia-text empty'>---</p>} */}
        {/* </article> */}
        {/* </section> * /} */}
      </section >
      <section className='solon-enimerwseis'>
        <article className='enimerwseis'>
          <h2 className='enimerwseis-title'>Ενημερώσεις</h2>
          {singleUpdates?.length ? <ol>
            {singleUpdates.map(r => r.updated_parts !== ' ' && <li key={r.id} className='enimerwseis-list-item'>
              <span className='enimerwseis-date'>{formatDateWithWeekDay(r.created_at)}</span>
              <p className='enimerwseis-allages-title'>Αλλαγές στα πεδία:</p>
              {r.updated_parts.split(',').map(k => r[k]?.trim() !== '' && <p className='enimerwseis-pedia-enimerwsi' key={r[k]}><span className='enimerwseis-pedia'>{pedia[k]} </span><span className='enimerwseis-enimerwsi'>{!['1', '2', '3', '4', 'keimeno_apof'].includes(k) && r[k]}</span></p>)}
            </li>)}

          </ol> : <p>Καμμία ενημέρωση προς το παρόν</p>}
        </article></section>
    </section >
  )
}

// return (
//   <article className='case_card' onKeyDownCapture={handleKeyPress} data-key={singleCase.id}>

//     {/* <div key={`ed${singleCase.id}`} className="edit_case"><p ><span onClick={handleDelete} data-key={singleCase.id}>✏️</span></p></div> */}
//     <span className='number'>{singleCase?.no ? singleCase?.no + 1 + '/' : '1/'}</span>
//     <div key={singleCase.id} className="close_case"><p ><span onClick={handleDelete} data-key={singleCase.id}>❌</span></p></div>


//     <h4><em>Σημείωση:</em>  {showEdit ? <><input placeholder={note} value={note} onChange={(e) => setNote(e.target.value)} data-key={singleCase.id} /><button style={{ border: "none" }} onClick={handleUpdateNote} data-key={singleCase.id}>✅</button></> : <span>{note}<button style={{ border: "none" }} onClick={handleEdit}>✏️</button></span>}</h4>
//     {singleCase?.arkat?.length > 2 && <h4><em>Αρ.Καταθ.: </em>{singleCase?.arkat}</h4 >}
//     {singleCase?.court?.length > 2 && <h4><em>Δικαστήριο: </em>{getAdjCourt(singleCase?.court)}</h4 >}
//     {singleCase?.tmima?.length > 0 && <h4><em>Τμήμα: </em>{singleCase?.tmima}</h4 >}
//     {singleCase?.synthesi?.length > 0 && <h4><em>Σύνθεση: </em>{singleCase?.synthesi}</h4 >}
//     {singleCase?.katigoria?.length > 0 && <h4><em>Κατηγορία: </em>{singleCase?.katigoria}</h4 >}
//     {singleCase?.apotelesma?.length > 0 && <h4><em>Αποτέλεσμα: </em>{singleCase?.apotelesma}</h4 >}
//     {singleCase?.diataktiko?.length > 0 && <h4><em>Διατακτικό: </em>{singleCase?.diataktiko}</h4 >}
//     {singleCase?.dikasimos?.length > 0 && <h4><em>Δικάσιμος: </em>{singleCase?.dikasimos}</h4 >}


//     {singleCase?.created_at?.length > 2 && <h4><em>Δημιουργία: </em>{formatDate(singleCase?.created_at)}</h4 >}
//     {singleCase?.last_checked?.length > 2 && <h4 data-case='last_checked'><em>Τελευταίος Έλεγχος: </em>{formatDate(singleCase?.last_checked)}</h4 >}


//   </article >
// )


export default VAdjCard