export function extractLastDate(inputString) {
  const regex = /\d{2}\/\d{2}\/\d{4}/g;
  const matches = inputString.match(regex);
  if (matches) {
    return matches[matches.length - 1];
  } else {
    return null; // or throw an error, or handle the missing date in some other way
  }
}

export function formatDate(inputDate) {
  const parts = inputDate?.split('/');
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];
  return year + month + day;
}

export function isDateAfterToday(dateString) {
  // Create a Date object from the input string
  const [day, month, year] = dateString?.split('/');
  const inputDate = new Date(year, month - 1, day);

  // Get the current date
  const today = new Date();

  // Compare the input date to the current date
  return inputDate > today;
}