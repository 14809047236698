
import React from 'react'

export const Mitrwo = ({ pageItem }) => {
  // const status = { PUBLISHED: "Αναρτηθείσα", REVOKED: 'Αποσυρθείσα', PENDING_REVOCATION: "Εκκρεμεί Απόσυρση", SUBMITTED: 'Υποβληθείσα' }
  return (
    <article className='diavgeia-apofaseis'>
      <p className='enimerwseis-allages-head'>{pageItem?.no + 1}. {pageItem?.eiddim}</p>

      <h3 className='enimerwseis-h3'>Αρ.Δημοσίευσης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.ardim}</p>
      <h3 className='enimerwseis-h3'>Πρωτ/Ειρ Κατάθεσης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.court}</p>
      <h3 className='enimerwseis-h3'>Ημ/νία Κατάθεσης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.hmkatath}</p>
      <h3 className='enimerwseis-h3'>Ημ/νία Καταχώρισης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.hmkatach}</p>
      {pageItem?.gak !== '0' && <><h3 className='enimerwseis-h3'>ΓΑΚ</h3>
        <p className='enimerwseis-allages-title'>{pageItem?.gak}</p></>}
      {pageItem?.eak !== '0' && <><h3 className='enimerwseis-h3'>ΕΑΚ</h3>
        <p className='enimerwseis-allages-title'>{pageItem?.eak}</p></>}
    </article >
  )
}
