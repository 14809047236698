
const Unconfirmed = () => {


  return (
    <section className="container">

      <h1>Πρέπει πρώτα να επιβεβαιώσετε το email σας.</h1>
      <br />
      <p>Παρακαλώ ελέγξτε και στον φάκελο των spam.</p>
    </section>
  )
}

export default Unconfirmed