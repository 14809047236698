import React, { useEffect } from "react";

import { Link, useNavigate, useLocation } from "react-router-dom";

import AuthContext from "../../context/AuthProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const AiMisthosi = () => {
  const [msg, setMsg] = React.useState("");
  const [response, setResponse] = React.useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmitMsg = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosPrivate.post(
        `/ai/`,
        JSON.stringify({
          msg: msg,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      //   wait for the response
      const data = await response.data;
      console.log(data);
      setResponse(JSON.stringify(data));

      setMsg("");
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  return (
    <div className="post-comment">
      <form onSubmit={handleSubmitMsg}>
        <textarea
          type="text"
          placeholder="Γράψε το μήνυμα σου"
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
        <button type="submit">Αποστολή</button>
      </form>
      <div>{response}</div>
    </div>
  );
};

export default AiMisthosi;
