import React, { useEffect, useContext, useState, useRef } from "react";
import { nanoid } from "nanoid";
import axios from "axios";
import { DateTime, Settings } from "luxon";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import el from "javascript-time-ago/locale/el";
import Post from "./Post";
import { Text } from "@tremor/react";
import { formatDate } from "./Ekloges";

import AuthContext from "../../context/AuthProvider";
import ScrollToTop from "../ScrollToTop";

const SinglePost = () => {
  const [singlePost, setSinglePost] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const { avatarUploaded } = useContext(AuthContext);

  const postUuid = useLocation().pathname.split("/")[2];

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getPosts = async () => {
      try {
        const response = await axiosPrivate.get(`/posts/${postUuid}`, {
          signal: controller.signal,
        });
        isMounted && setSinglePost(response.data);
        isMounted && setLoading(false);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getPosts();

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ekloges-section">
      <ScrollToTop />
      {loading ? (
        <div className="loading">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="ekloges-post">
          <Post
            post={singlePost}
            creator={`${
              singlePost.user?.first_name && singlePost.user?.last_name
                ? singlePost.user?.first_name + " " + singlePost.user?.last_name
                : singlePost.user?.email
            }`}
            email={singlePost.user?.email}
            timeSince={formatDate(singlePost.created_at)}
            body={singlePost.body}
            postUuid={singlePost.uuid}
            avatarUploaded={avatarUploaded}
            postCreatorId={singlePost.user?.id}
            grammateas={singlePost.grammateas}
            getPosts={() => {}}
            singlePost={true}
            avatar={singlePost.user?.avatar_uuid}
            uuid={singlePost.user?.uuid}
          />
        </div>
      )}
    </div>
  );
};

export default SinglePost;
