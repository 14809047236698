import {
  Card,
  Metric,
  Text,
  Title,
  BarList,
  Flex,
  Button,
  Grid,
  ListItem,
  Bold,
  List,
  Col,
} from "@tremor/react";

import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { useLocation } from "react-router-dom";
import { truncateCourt } from "../../utils/truncateCourt";
import DashboardDikasimoi from "./DashboardDikasimoi/DashboardDikasimoi";
import DashboardSyzititheises from "./DashboardSyzititheises/DashboardSyzititheises";

const dataFormatter = (number) =>
  Intl.NumberFormat("us").format(number).toString();

export default function Example({ cases, columns = 2 }) {
  const location = useLocation();

  // A function that takes two parameters (a key and a value) and returns a link to the search page with the key and value as query parameters. The function uses the URLSearchParams API to set the key and value and then returns the link. If the value is undefined, the function returns the link to the search page without any query parameters.
  const getLink = (key, value) => {
    const searchParams = new URLSearchParams();
    if (value) {
      searchParams.set(key, value);
    } else {
      searchParams.delete(key);
    }
    return `${location.pathname}?${searchParams.toString()}`;
  };

  // Regarding the array of objects of cases, for each different value of the court key, create an object with two properties: The first property has a key "name" and a value of the court name. The second property has a key "value" and a value of the number of cases for that court.
  const courts = cases.reduce((acc, curr) => {
    const court = acc.find((item) => item.name === curr.court);
    if (court) {
      court.value += 1;
    } else {
      acc.push({
        name: curr.court,
        value: 1,
        href: getLink("court", curr.court),
      });
    }
    return acc;
  }, []);

  // Regarding the array of objects of cases, for each different value of the eidos key, create an object with two properties: The first property has a key "name" and a value of the eidos. The second property has a key "value" and a value of the number of cases for that eidos.
  const eidos = cases.reduce((acc, curr) => {
    const diadikasia = acc.find((item) => item.name === curr.diadikasia);
    if (diadikasia) {
      diadikasia.value += 1;
    } else {
      acc.push({
        name: curr.diadikasia,
        value: 1,
        href: getLink("diadikasia", curr.diadikasia),
      });
    }
    return acc;
  }, []);

  const data = [
    {
      category: "Αστικά Δικαστήρια",
      description: "Δικαστήριο",
      stat: cases.length,
      data: courts.sort((a, b) => b.value - a.value),
    },
    {
      category: "Είδη Υποθέσεων",
      description: "Διαδικασία",
      stat: cases.length,
      data: eidos.sort((a, b) => b.value - a.value),
    },
  ];
  return (
    <Grid
      numItemsSm={columns}
      numItemsLg={columns}
      className="mt-0 gap-x-6 gap-y-6"
    >
      <DashboardDikasimoi cases={cases} />
      <DashboardSyzititheises cases={cases} />

      {data.map((item) => (
        <Card key={item.category}>
          {/* <Title>{item.category}</Title> */}
          {/* <Flex justifyContent="justify-start" alignItems="items-baseline" spaceX="space-x-2">
            <Metric>{item.stat}</Metric>
            <Text>Υποθέσεις</Text>
          </Flex> */}
          <Flex className="mt-0">
            <Text>{item.description}</Text>
            <Text textAlignment="text-right">Αριθμός</Text>
          </Flex>
          <BarList
            data={item.data}
            valueFormatter={dataFormatter}
            className="mt-2"
          />
          {/* <Footer>
            <Button
              size="sm"
              variant="light"
              icon={ArrowNarrowRightIcon}
              iconPosition="right"
            >
              View details

            </Button>
          </Footer> */}
        </Card>
      ))}
    </Grid>
  );
}
