import { useState, useRef } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { getCurrentDateTimeString } from "../../utils/getCurrentDateTimeString";
import { id } from "date-fns/locale";
function maskString(str) {
  return str + printTimes("•", 9 - str.length);
}

function printTimes(str, times) {
  let newStr = "";
  for (let i = 0; i < times; i++) {
    newStr = newStr + str;
  }
  return newStr;
}

function removeNonNumeric(str) {
  if (str) {
    return removeMoreThan9Digits(str.replace(/[^0-9]/g, ""));
  }
  return "";
}

function removeMoreThan9Digits(str) {
  if (str) {
    return str.slice(0, 9);
  }
  return "";
}

const AddEauction = ({
  handleClickAddEauctionModal,
  eauctions,
  handleAddEauction,
  handleAddMultiEauctions,
}) => {
  const [afm, setAfm] = useState("");
  const [note, setNote] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (afm.length !== 9) {
        setErrMsg("Ο ΑΦΜ πρέπει να είναι 9 ψηφία");
        return;
      }
      if (eauctions.length < 81 && !eauctions.find((e) => e.afm === afm)) {
        const response = await axiosPrivate.post(
          "/eauctions",
          JSON.stringify({ afm, note }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        handleClickAddEauctionModal();
        handleAddEauction([
          {
            created_at: getCurrentDateTimeString(),
            id: response.data.data.id,
            note,
          },
        ]);

        // const response = await axiosPrivate.get('/eausingle');
        // handleAddMultiEauctions(response.data)
      }

      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      if (err.response?.status === 400) {
        setErrMsg(err.response?.data?.message);
      } else {
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  return (
    <section className="container_ac">
      <p
        ref={errRef}
        className={errMsg ? "errmsg" : "offscreen"}
        aria-live="assertive"
      >
        {errMsg}
      </p>

      <div className="close_addcase">
        <p onClick={() => handleClickAddEauctionModal()}>❌</p>
      </div>
      {/* <span></span> */}
      {/* <h1>Sign In</h1> */}
      <form className="form" onSubmit={handleSubmit}>
        {/* <label htmlFor="username">Email:</label> */}
        <div className="input-container ic1">
          <input
            className="input"
            placeholder=" "
            type="text"
            id="afm"
            autoComplete="on"
            onChange={(e) => setAfm(removeNonNumeric(e.target.value?.trim()))}
            value={afm}
            title="Παρακαλώ εισάγετε 9 ψηφία"
            required
          />
          <div className="cut"></div>
          <label htmlFor="afm" className="placeholder">
            ΑΦΜ
          </label>
        </div>
        <div className="input-container ic2">
          <input
            className="input"
            type="text"
            id="note"
            placeholder=" "
            autoComplete="on"
            onChange={(e) => setNote(e.target.value)}
            value={note}
          />
          <div className="cut"></div>
          <label htmlFor="note" className="placeholder">
            Σημείωση
          </label>
        </div>

        <button type="text" className="submit">
          Προσθήκη
        </button>
      </form>
    </section>
  );
};

export default AddEauction;
