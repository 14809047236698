import { useState, useEffect } from "react";
import JCases from "./JCases";
import AddJCase from "./AddJCase";
import AddJCaseModal from "./AddJCaseModal";
import { useInterval } from "../../utils/useInterval";

import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import { getAdjCourt } from "../../utils/getAdjCourts";
import RotatingElements from "../rotating";

const Adjustice = () => {
  const [cases, setCases] = useState([]);
  const [casesFirst, setCasesFirst] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getJCases = async () => {
      try {
        const response = await axiosPrivate.get("/adjustice", {
          signal: controller.signal,
        });
        isMounted && setCases(response.data);
        isMounted && setCasesFirst(response.data);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getJCases();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      setCases(
        casesFirst.filter(
          (r) =>
            getAdjCourt(r.court).toUpperCase().includes(search.toUpperCase()) ||
            r.note.toUpperCase().includes(search.toUpperCase()) ||
            r.arkat.toUpperCase().includes(search.toUpperCase())
        )
      );
    } else {
      setCases(casesFirst);
    }
  }, [search]);

  const [showModal, setShowModal] = useState(true);
  const handleClickAddCaseModal = () => {
    setShowModal(!showModal);
  };
  const handleAddCase = (singleCase) => {
    setCases([singleCase, ...cases]);
  };
  const handleDeleteCase = (singleCase) => {
    setCases(cases.filter((e) => e.id.toString() !== singleCase));
  };

  return (
    <section>
      {/* <RotatingElements /> */}
      {/* <div className="adjustice-notes" style={{ maxWidth: '350px', textAlign: 'justify', margin: '0 auto', fontSize: '0.7rem' }}>(Δυστυχώς τις τελευταίες μέρες το site του Υπουργείου Δικαιοσύνης υπολειτουργεί, και αυτό επηρεάζει τον έλεγχο που κάνει το MySolon. Πάντως οι ημερήσιοι έλεγχοι - που γίνονται μη εργάσιμες ώρες - λειτουργούν κανονικά.)</div> */}
      <section className="container_search">
        {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}

        {/* <div className="close_addcase"><p onClick={() => handleClickAddCaseModal()}>❌</p></div> */}
        {/* <span></span> */}
        {/* <h1>Sign In</h1> */}
        <form className="form_search">
          {/* <label htmlFor="username">Email:</label> */}
          <div className="input-container ic1">
            <input
              className="input"
              placeholder=" "
              type="text"
              id="Αναζήτηση"
              autoComplete="on"
              onChange={(e) => setSearch(e.target.value)}
              value={search || ""}
              required
            />
            <div className="cut"></div>
            <label htmlFor="Αναζήτηση" className="placeholder">
              🔎 Αναζήτηση (πχ. Αθηνών)
            </label>
          </div>

          {/* <label htmlFor="password">Password:</label> */}
        </form>
      </section>

      {showModal ? (
        <AddJCaseModal handleClickAddCaseModal={handleClickAddCaseModal} />
      ) : (
        <AddJCase
          handleClickAddCaseModal={handleClickAddCaseModal}
          cases={cases}
          handleAddCase={handleAddCase}
        />
      )}
      <JCases cases={cases} handleDeleteCase={handleDeleteCase} />
    </section>
  );
};

export default Adjustice;
