export const dikastiriaLower = (dikastirio) => {
  const lowerCaseMap = {
    "ΑΡΕΙΟΣ ΠΑΓΟΣ": 'Άρειος Πάγος',
    'ΕΦΕΤΕΙΟ': 'Εφετείο',
    'ΠΡΩΤΟΔΙΚΕΙΟ': 'Πρωτοδικείο',
    'ΕΙΡΗΝΟΔΙΚΕΙΟ': 'Ειρηνοδικείο',


  }
  return `${lowerCaseMap[dikastirio.split(' ')[0]]} ${dikastirio.replace(dikastirio.split(' ')[0], '')}`
};

