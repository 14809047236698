import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { XIcon } from "@heroicons/react/outline";
import {
  useNavigate,
  useLocation,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Card,
  Metric,
  Text,
  Flex,
  ProgressBar,
  Grid,
  Bold,
  Title,
  Divider,
  Button,
  Icon,
  Callout,
  SearchSelect,
  SearchSelectItem,
  Select,
  SelectItem,
  TextInput,
  DatePicker,
} from "@tremor/react";
import { el } from "date-fns/locale";

import { useState, useMemo, useEffect } from "react";
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/solid";
import { returnDetail } from "../../utils/reverseDate";

import { removeDiacriticsAndCapitalize } from "../../utils/checkMisthosi";
import { DateRangePickerGreek } from "./DatePicker";
import { formatDateMinus } from "../CaseCard";
import {
  getAllEirinodikeia,
  getAllProtodikeia,
  getAllEfeteia,
} from "../Util/dikastiria";

import efeteiaDiadikasies from "./utils/efeteiaDiadikasies.json";
import eirinodikeiaDiadikasies from "./utils/eirinodikeiaDiadikasies.json";
import protodikeiaDiadikasies from "./utils/protodikeiaDiadikasies.json";

export function formatDate(date) {
  if (!date) return;
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${day}`;
}

function getCurrentDateTime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default function OutSolon({ handleAddCase, handleClickAddCaseModal }) {
  const [calendar, setCalendar] = useState([]);
  const [cases, setCases] = useState([]);
  const [uniqueImerominies, setUniqueImerominies] = useState([]);
  const [topos, setTopos] = useState("");
  const [toposSelected, setToposSelected] = useState(false);

  const [dikastirio, setDikastirio] = useState("");
  const [katathesi, setKatathesi] = useState(new Date());
  const [diadikasia, setDiadikasia] = useState("");
  const [antikeimeno, setAntikeimeno] = useState("");
  const [dikasimos, setDikasimos] = useState(undefined);
  const [eidos, setEidos] = useState("");
  const [note, setNote] = useState("");
  const [errorNote, setErrorNote] = useState(false);

  const [diadikasies, setDiadikasies] = useState([]);
  const [antikeimena, setAntikeimena] = useState([]);
  const [eidi, setEidi] = useState([]);

  // get current week's working days

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (topos) {
      setToposSelected(true);
      if (dikastirio === "Ειρηνοδικείο")
        setDiadikasies(eirinodikeiaDiadikasies);
      if (dikastirio === "Πρωτοδικείο") setDiadikasies(protodikeiaDiadikasies);
      if (dikastirio === "Εφετείο") setDiadikasies(efeteiaDiadikasies);
    } else {
      setToposSelected(false);
    }
    setDiadikasia("");
    setAntikeimeno("");
    setEidos("");
  }, [topos]);

  useEffect(() => {
    setAntikeimeno("");
    setEidos("");
    if (diadikasia !== "") {
      setAntikeimena([
        ...new Set(
          diadikasies
            .filter((r) => r.diadikasia === diadikasia)[0]
            ?.antikeimena.map((k) => k.antikeimeno)
        ),
      ]);
    }
  }, [diadikasies, diadikasia]);

  useEffect(() => {
    setEidos("");
    if (antikeimeno !== "") {
      setEidi(
        diadikasies
          .filter((r) => r.diadikasia === diadikasia)[0]
          ?.antikeimena?.filter((k) => k.antikeimeno === antikeimeno)[0]?.eidi
      );
    }
  }, [antikeimeno]);

  const postCase = async () => {
    try {
      const data = await axiosPrivate.post(
        "/outsolon/",
        JSON.stringify({
          dikastirio,
          topos,
          note,
          katathesi: formatDate(katathesi),
          diadikasia,
          extra_info: {
            antikeimeno,
            eidos,
            dikasimos: dikasimos !== "" ? formatDate(dikasimos) : undefined,
          },
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const id = data.data.last_inserted;
      handleAddCase({
        dikastirio,
        topos,
        note,
        katathesi: formatDate(katathesi),
        created_at: formatDateMinus(getCurrentDateTime()),
        diadikasia,
        id,
      });
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        // do something
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  function handleSubmit(e) {
    e.preventDefault();
    // If dikastirio is not set, return
    if (dikastirio === "") return;

    postCase();
    handleClickAddCaseModal();
  }

  // useEffect(() => {
  //   let isMounted = true;
  //   const controller = new AbortController();

  // const getCases = async () => {
  //   try {
  //     const response = await axiosPrivate.get("/calendar", {
  //       signal: controller.signal,
  //     });
  //     isMounted &&
  //       setCalendar(
  //         response.data.imerominies.sort((a, b) => {
  //           return a.imerominia > b.imerominia ? 1 : -1;
  //         })
  //       );
  //     isMounted && setCases(response.data.cases);
  //     isMounted &&
  //       setUniqueImerominies(getUniqueImerominia(response.data.imerominies));
  //   } catch (err) {
  //     console.error(err);
  //     if (!axios.isCancel(err)) {
  //       // do something
  //       navigate("/login", { state: { from: location }, replace: true });
  //     }
  //   }
  // };

  //   getCases();

  //   return () => {
  //     isMounted = false;
  //     controller.abort();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Card className="outsolon-card">
      <Icon
        className="absolute top-2 right-2 cursor-pointer"
        icon={XIcon}
        onClick={handleClickAddCaseModal}
        color="red"
      />

      <form onSubmit={handleSubmit}>
        <label htmlFor="note">Τίτλος Υπόθεσης:</label>

        <TextInput
          className="mt-3 mb-3"
          error={errorNote}
          errorMessage="Ξεπεράσατε τους 100 χαρακτήρες"
          placeholder="Εισάγετε σημείωση..."
          onChange={(e) => {
            setNote(e.target.value);
            if (e.target.value.length > 100) setErrorNote(true);
            else setErrorNote(false);
          }}
          value={note}
        />
        <label htmlFor="eirinodikeio">Δικαστήριο:</label>
        <Select
          placeholder="Επιλέξτε Δικαστήριο"
          onValueChange={setDikastirio}
          value={dikastirio}
        >
          <SelectItem value="Ειρηνοδικείο">Ειρηνοδικείο</SelectItem>
          <SelectItem value="Πρωτοδικείο">Πρωτοδικείο</SelectItem>
          <SelectItem value="Εφετείο">Εφετείο</SelectItem>
        </Select>
        {dikastirio === "Ειρηνοδικείο" && (
          <>
            <label htmlFor="eirinodikeio">Ειρηνοδικεία:</label>
            <SearchSelect
              placeholder="Επιλέξτε Ειρηνοδικείο"
              onValueChange={setTopos}
              value={topos}
            >
              {getAllEirinodikeia()
                .filter((r) => {
                  return ![
                    "Αθηνών",
                    "Θεσσαλονίκης",
                    "Πειραιά",
                    "Καλλιθέας",
                    "Αμαρoυσίoυ",
                    "Αχαρνών",
                    "Βασιλικών",
                    "Ελευσίνας",
                    "Ν. Λιoσίων",
                    "Κουφαλίων",
                    "Κρωπίας",
                    "Λαγκαδά",
                    "Λαυρίου",
                    "Μαραθώνος",
                    "Μεγάρων",
                    "Ν. Iωνίας",
                    "Περιστερίου",
                    "Χαλανδρίoυ",
                    "Χαλκίδας",
                  ].includes(r);
                })
                .sort((a, b) => {
                  return removeDiacriticsAndCapitalize(a) >
                    removeDiacriticsAndCapitalize(b)
                    ? 1
                    : -1;
                })
                .map((dim, i) => {
                  return (
                    <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                      {removeDiacriticsAndCapitalize(dim)}
                    </SearchSelectItem>
                  );
                })}
            </SearchSelect>
          </>
        )}
        {dikastirio === "Πρωτοδικείο" && (
          <>
            <label htmlFor="protodikeio">Πρωτοδικεία:</label>
            <SearchSelect
              placeholder="Επιλέξτε Πρωτοδικείο"
              onValueChange={setTopos}
              value={topos}
            >
              {getAllProtodikeia()
                .filter((r) => {
                  return ![
                    "Αθηνών",
                    "Θεσσαλονίκης",
                    "Πειραιά",
                    "Χαλκίδας",
                  ].includes(r);
                })
                .sort((a, b) => {
                  return removeDiacriticsAndCapitalize(a) >
                    removeDiacriticsAndCapitalize(b)
                    ? 1
                    : -1;
                })
                .map((dim, i) => {
                  return (
                    <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                      {removeDiacriticsAndCapitalize(dim)}
                    </SearchSelectItem>
                  );
                })}
            </SearchSelect>
          </>
        )}
        {dikastirio === "Εφετείο" && (
          <>
            <label htmlFor="efeteio">Εφετεία:</label>
            <SearchSelect
              placeholder="Επιλέξτε Εφετείο"
              onValueChange={setTopos}
              value={topos}
            >
              {getAllEfeteia()
                .filter((r) => {
                  return ![
                    "Αθηνών",
                    "Θεσσαλονίκης",
                    "Πειραιά",
                    "Εύβοιας (Χαλκίδα)",
                  ].includes(r);
                })
                .sort((a, b) => {
                  return removeDiacriticsAndCapitalize(a) >
                    removeDiacriticsAndCapitalize(b)
                    ? 1
                    : -1;
                })
                .map((dim, i) => {
                  return (
                    <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                      {removeDiacriticsAndCapitalize(dim)}
                    </SearchSelectItem>
                  );
                })}
            </SearchSelect>
          </>
        )}

        {toposSelected && (
          <>
            <label htmlFor="diadikasia">Διαδικασία:</label>
            <SearchSelect
              placeholder="Επιλέξτε Διαδικασία"
              onValueChange={setDiadikasia}
              value={diadikasia}
            >
              {diadikasies
                .sort((a, b) => {
                  return removeDiacriticsAndCapitalize(a.diadikasia) >
                    removeDiacriticsAndCapitalize(b.diadikasia)
                    ? 1
                    : -1;
                })
                .map((dim, i) => {
                  return (
                    <SearchSelectItem
                      key={`${i}-${dim.diadikasia}`}
                      value={dim.diadikasia}
                    >
                      {removeDiacriticsAndCapitalize(dim.diadikasia)}
                    </SearchSelectItem>
                  );
                })}
            </SearchSelect>
            {diadikasia !== "" && (
              <>
                <label htmlFor="antikeimeno">Αντικείμενο:</label>
                <SearchSelect
                  placeholder="Επιλέξτε Αντικείμενο"
                  onValueChange={setAntikeimeno}
                  value={antikeimeno}
                >
                  {antikeimena
                    .sort((a, b) => {
                      return removeDiacriticsAndCapitalize(a) >
                        removeDiacriticsAndCapitalize(b)
                        ? 1
                        : -1;
                    })
                    .map((dim, i) => {
                      return (
                        <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                          {removeDiacriticsAndCapitalize(dim)}
                        </SearchSelectItem>
                      );
                    })}
                </SearchSelect>
              </>
            )}
            {antikeimeno !== "" && (
              <>
                <label htmlFor="eidos">Είδος:</label>
                <SearchSelect
                  placeholder="Επιλέξτε Είδος"
                  onValueChange={setEidos}
                  value={eidos}
                >
                  {eidi
                    ?.sort((a, b) => {
                      return removeDiacriticsAndCapitalize(a) >
                        removeDiacriticsAndCapitalize(b)
                        ? 1
                        : -1;
                    })
                    ?.map((dim, i) => {
                      return (
                        <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                          {removeDiacriticsAndCapitalize(dim)}
                        </SearchSelectItem>
                      );
                    })}
                </SearchSelect>
              </>
            )}
            <label htmlFor="katathesi" className="mt-3">
              Ημ/νία Κατάθεσης:
            </label>
            <DatePicker
              className="max-w-md mx-auto"
              value={katathesi}
              onValueChange={setKatathesi}
              locale={el}
              color="rose"
            ></DatePicker>
            <label htmlFor="dikasimos" className="mt-3">
              Δικάσιμος:
            </label>
            <DatePicker
              className="max-w-md mx-auto"
              value={dikasimos}
              onValueChange={setDikasimos}
              locale={el}
              placeholder="Επιλέξτε Δικάσιμο εάν υπάρχει"
            ></DatePicker>
          </>
        )}
        <Text className="mt-2">
          Απαραίτητα Δεδομένα:{" "}
          <Bold>Τίτλος Υπόθεσης, Δικαστήριο, Τόπος, Διαδικασία</Bold>
        </Text>
        <Button
          color="emerald"
          className="mt-3"
          type="submit"
          disabled={
            dikastirio === "" ||
            topos === "" ||
            diadikasia === "" ||
            note === "" ||
            errorNote
          }
        >
          Προσθήκη
        </Button>
      </form>
    </Card>
  );
}
