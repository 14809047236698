import React, { useEffect, useState, useMemo } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Card, Button, TextInput, Divider } from "@tremor/react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { syllogoi } from "../Social/Syllogoi";
import {
  SearchSelect,
  SearchSelectItem,
  Select,
  SelectItem,
} from "@tremor/react";

import Choose from "../Social/Choose/Choose";

const ProfileUpdate = ({
  initUserData,
  setEditProfile,
  updateUser,
  fieldsProps,
  dimoiSelected,
  DOYSelected,
  ypothSelected,
  eirinSelected,
  protodSelected,
  metafSelected,
  efeteiaSelected,
  dioikSelected,
  dioikEfeteiaSelected,
  ktimatSelected,
  authUuid,
}) => {
  console.log("fieldsProps", fieldsProps);
  const fields = useMemo(
    () => ({
      dimoi: fieldsProps.dimoiSelected,
      DOY: fieldsProps.DOYSelected,
      ypoth: fieldsProps.ypothSelected,
      eirin: fieldsProps.eirinSelected,
      protod: fieldsProps.protodSelected,
      metaf: fieldsProps.metafSelected,
      efeteia: fieldsProps.efeteiaSelected,
      dioik: fieldsProps.dioikSelected,
      dioikEfeteia: fieldsProps.dioikEfeteiaSelected,
      ktimat: fieldsProps.ktimatSelected,
    }),
    [fieldsProps]
  );

  const axiosPrivate = useAxiosPrivate();
  const [userData, setUserData] = useState(initUserData);
  let params = useParams();
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    setUserData(initUserData);
  }, [initUserData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const profileId = params.profileId || authUuid;

    try {
      await axiosPrivate.post(
        `/profile/${profileId}`,
        JSON.stringify({ extraInfo: userData, fields }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      updateUser(userData);
      setEditProfile();

      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };
  function onInput() {
    setUserData((prev) => ({
      ...prev,
      syllogos: document.getElementById("browser").value,
    }));

    var val = document.getElementById("browser").value;
    var opts = document.getElementById("browsers").childNodes;
    for (var i = 0; i < opts.length; i++) {
      if (opts[i].value === val) {
        // An item was selected from the list!
        // yourCallbackHere()
        opts[i].value &&
          setUserData((prev) => ({ ...prev, syllogos: opts[i].value }));

        break;
      }
    }
  }

  return (
    <Card>
      <form className="profile-edit-form" onSubmit={handleSubmit}>
        <div className="profile-form-inputs">
          <div className="form-group">
            <label htmlFor="first_name">Όνομα</label>
            <TextInput
              type="text"
              className="form-control"
              id="first_name"
              name="first_name"
              value={userData.first_name}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Επώνυμο</label>
            <TextInput
              type="text"
              className="form-control"
              id="last_name"
              name="last_name"
              value={userData.last_name}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="browser">Σύλλογος:</label>
            <SearchSelect
              placeholder="Επιλέξτε Σύλλογο"
              value={userData.syllogos}
              onValueChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  syllogos: e,
                }));
              }}
            >
              {syllogoi.map((dim, i) => {
                return (
                  <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                    {dim}
                  </SearchSelectItem>
                );
              })}
            </SearchSelect>
          </div>
          {/* <div className="form-group">
            <label htmlFor="browser">Σύλλογος:</label>
            <div className="tr-relative tr-w-full tr-flex tr-items-center tr-overflow-hidden tr-min-w-[10rem] tr-max-w-none tr-mt-0 tr-bg-white tr-border-gray-300 tr-rounded-md tr-border tr-shadow-sm">
              <input
                list="browsers"
                name="browser"
                id="browser"
                onInput={onInput}
                value={userData.syllogos}
                className={
                  "tremor-base input-elem tr-w-full focus:tr-outline-0 focus:tr-ring-0 tr-bg-inherit tr-text-gray-700 tr-pl-4 tr-pr-4 tr-pt-2 tr-pb-2 tr-text-sm tr-font-medium tr-border-0 placeholder:tr-text-gray-500"
                }
                placeholder={"Επιλέξτε Σύλλογο"}
              />

              <datalist id="browsers">
                {syllogoi.map((dim, i) => {
                  return <option key={`${i}-${dim}`} value={dim} />;
                })}
              </datalist>
            </div>
          </div> */}
          <div className="form-group">
            <label htmlFor="am">Αρ.Μητρώου</label>
            <TextInput
              type="number"
              className="form-control"
              id="am"
              name="am"
              value={userData.am}
              onChange={handleChange}
              placeholder=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="edra">Έδρα</label>
            <TextInput
              type="text"
              className="form-control"
              id="edra"
              name="edra"
              value={userData.edra}
              onChange={handleChange}
              placeholder=""
            />
          </div>

          {/* Add a select options */}
          <div className="form-group">
            <label htmlFor="browser">Ασκούμενος:</label>
            <Select
              placeholder="Ασκούμενος"
              value={userData.askoumenos}
              onValueChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  askoumenos: e,
                }));
              }}
            >
              <SelectItem key={`oxi`} value={"0"}>
                Όχι
              </SelectItem>
              <SelectItem key={`nai`} value={"1"}>
                Ναι
              </SelectItem>
            </Select>
          </div>
          {/* <div className="form-group">
            <label htmlFor="browser">Ασκούμενος:</label>
            <div className="tr-relative tr-w-full tr-flex tr-items-center tr-overflow-hidden tr-min-w-[10rem] tr-max-w-none tr-mt-0 tr-bg-white tr-border-gray-300 tr-rounded-md tr-border tr-shadow-sm">
              <select
                name="askoumenos"
                id="askoumenos"
                onChange={handleChange}
                value={userData.askoumenos}
                className={
                  "tremor-base input-elem tr-w-full focus:tr-outline-0 focus:tr-ring-0 tr-bg-inherit tr-text-gray-700 tr-pl-4 tr-pr-4 tr-pt-2 tr-pb-2 tr-text-sm tr-font-medium tr-border-0 placeholder:tr-text-gray-500"
                }
                placeholder={"Ασκούμενος"}
              >
                <option value={"0"}>Όχι</option>
                <option value={"1"}>Ναι</option>
              </select>
            </div>
          </div> */}
          {/* <div className="form-group">
            <label htmlFor="syllogos_link">Σύνδεσμος στο Μητρώο</label>
            <TextInput
              type="text"
              className="form-control"
              id="syllogos_link"
              name="syllogos_link"
              value={userData.syllogos_link}
              onChange={handleChange}
              placeholder=""
            />
          </div> */}
        </div>
        <Divider />
        <section className="profile-drastiriopoiisi">
          <h2>
            <strong>
              {" "}
              Θέλω να λαμβάνω υπενθύμιση με email, 7 ημέρες πριν την δικάσιμο,
              προθεσμία κτλ.
            </strong>
            <Select
              placeholder=""
              value={userData.notify}
              onValueChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  notify: e,
                }));
              }}
            >
              <SelectItem key={`oxi`} value={"0"}>
                Όχι
              </SelectItem>
              <SelectItem key={`nai`} value={"1"}>
                Ναι
              </SelectItem>
            </Select>
          </h2>
        </section>
        <button type="submit" className="button-profile">
          Αποθήκευση
        </button>
        <Divider />
        <section className="profile-drastiriopoiisi">
          <h2>
            <strong>Περιοχές Δραστηριοποίησης</strong>
          </h2>
        </section>
        <div className="profile-form-inputs">
          <Choose
            fields={{
              ...fieldsProps,
              dimoiSelected,
              DOYSelected,
              ypothSelected,
              eirinSelected,
              protodSelected,
            }}
          />
        </div>
        <button type="submit" className="button-profile">
          Αποθήκευση
        </button>
      </form>
    </Card>
  );
};

export default ProfileUpdate;
