import React from 'react'
import { useState, useEffect, useRef, useMemo, createRef } from 'react'
import axios from '../../api/axios';
import './search.css'

export const Search2 = () => {
  const ulRef = useRef(null);

  const [gak, setGak] = useState('')
  const [arrTokens, setArrTokens] = useState([])

  const [firstGak, setFirstGak] = useState('')

  const [searchVisibility, setSearchVisibility] = useState()
  const [resIndex, setResIndex] = useState(-1)
  const [filteredArray, setFilteredArray] = useState([])
  const [results, setResults] = useState([])

  const [keyboard, setKeyboard] = useState(true)


  const inputEl = useRef(null);
  const handleClick = async (event) => {
    event.preventDefault()
    setFirstGak(event.target.innerText)
    setSearchVisibility(false)
    inputEl.current.focus();
    const controller = new AbortController();
    try {
      const response = await axios.get(`/gemires/${firstGak}`, {
        signal: controller.signal
      })

      setResults(response.data);
      controller.abort();
    } catch (err) {
      console.error(err);
      controller.abort();
    }

  };
  useEffect(() => {
    if (firstGak.length === 3) {
      setFilteredArray(arrTokens)

    } if (firstGak.length > 3 && keyboard) {
      setFilteredArray(arrTokens.filter(r => r.startsWith(firstGak)))
    }
    // if (firstGak.split(' ').length > 0 && firstGak.split(' ')[1]?.length === 0) {
    //   setResIndex(-1)
    // }
  }, [firstGak, arrTokens]);


  const handleArrowKeys = async (e) => {

    if (e?.key === 'ArrowDown') {
      setKeyboard(false)
      e.preventDefault();
      arrTokens.length - 1 > resIndex && setResIndex(resIndex + 1)

    }

    if (e?.key === 'ArrowUp') {
      setKeyboard(false)
      e.preventDefault();
      resIndex !== 0 && setResIndex(resIndex - 1)
    }
    if (e?.key === 'Enter') {
      e.preventDefault();

      setSearchVisibility(false)
      const controller = new AbortController();
      try {
        const response = await axios.get(`/gemires/${firstGak}`, {
          signal: controller.signal
        })

        setResults(response.data);
        controller.abort();
      } catch (err) {
        console.error(err);
        controller.abort();
      }

    }
    if (e?.keyCode === 32) {

      setSearchVisibility(false)

    }
    if (e?.key !== 'ArrowUp' && e?.key !== 'ArrowDown' && e?.key !== 'Enter' && e?.keyCode !== 32) {
      setKeyboard(true)
      setResIndex(-1)
      setSearchVisibility(true)
    }
  }
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCase = async () => {
      try {
        const response = await axios.get(`/gemisearch/${firstGak}`, {
          signal: controller.signal
        })

        isMounted && setArrTokens(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    if (firstGak.length === 3 || (firstGak.length > 3 && firstGak.split(' ')[1]?.length === 2)) {
      getCase();
    }

    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstGak])

  let refs = useRef(Array.from({ length: 30 }).map(() => createRef()));
  useEffect(() => {
    refs?.current[resIndex]?.current?.innerText !== undefined && setFirstGak(refs?.current[resIndex]?.current?.innerText);
  }, [resIndex]);
  useEffect(() => {
    inputEl.current.focus();
  }, []);

  return (
    <div className='search'>
      <section className='search-input'>
        <input
          ref={inputEl}
          className='search-input-box'
          placeholder=' '
          type="text"
          id="gak"
          onKeyDown={handleArrowKeys}
          autoComplete="off"
          onChange={(e) => setFirstGak(e.target.value.toUpperCase())}
          value={firstGak}
          required
        />
        <section className={((searchVisibility === true && firstGak.split(' ').length === 1 && firstGak.length > 2) || (searchVisibility === true && firstGak.split(' ').length > 1 && firstGak.split(' ')[1].length > 1)) ? 'search-panel visible' : 'search-panel invisible'}>
          <ul className='search-list'>
            {filteredArray.slice(0, 30).map((r, i) => (
              <li ref={refs.current[i]} className={resIndex === i ? 'search-list-item search-list-item-focus' : 'search-list-item'} key={i} onClick={handleClick}>{r}</li>
            ))}
          </ul>
        </section>
        <section><h2 className='search-slogan'>Αναζητήστε τα στοιχεία 1.435.258 επιχειρήσεων</h2></section>
      </section>
      <section className='results'>
        {results.map((r, i) => (
          <article key={`${i}card`} className='result-card'>
            <div className='result-text'>{r.eponymia}</div>
            <div className='result-text'>{r.dt}</div>
            <div className='result-text'>ΑΦΜ {r.afm}</div>
            <div className='result-text'>ΑΡ.ΓΕΜΗ{r.argemi}</div>
            <div className='result-text'>{r.katastasi}</div>
          </article>
        ))}
      </section>
    </div >
  )
}
