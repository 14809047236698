import React from 'react'

export const Auctions = ({ pageItem }) => {
  // const status = { PUBLISHED: "Αναρτηθείσα", REVOKED: 'Αποσυρθείσα', PENDING_REVOCATION: "Εκκρεμεί Απόσυρση", SUBMITTED: 'Υποβληθείσα' }
  return (
    <article className='diavgeia-apofaseis'>
      <p className='enimerwseis-allages-head'>{pageItem?.no + 1}. {pageItem?.imerominia_diexagogis.split(' ')[0]} - {pageItem?.timi_1is}</p>

      <h3 className='enimerwseis-h3'>Κατάσταση</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.katastasi}</p>
      <h3 className='enimerwseis-h3'>Είδος</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.eidos}</p>
      <h3 className='enimerwseis-h3'>Χαρακτηριστικά</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.xaraktiristika}</p>
      <h3 className='enimerwseis-h3'>Τιμή 1ης Προσφοράς</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.timi_1is}</p>
      <h3 className='enimerwseis-h3'>Ημ/νία Ανάρτησης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.imerominia_anartisis.split(' ')[0]}</p>
      <h3 className='enimerwseis-h3'>Ημ/νία Δημοσίευσης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.imerominia_dimosiefsis}</p>
      <h3 className='enimerwseis-h3'>Σελίδα Πλειστηριασμού</h3>
      <p><a className='enimerwseis-allages-title' href={pageItem?.link} target="_blank" rel="noreferrer" >{pageItem?.link}</a></p>
      <h3 className='enimerwseis-h3'>Οφειλέτης</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.onoma_ofeileti}</p>
      <h3 className='enimerwseis-h3'>ΑΦΜ Οφειλέτη</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.afm_ofeileti}</p>
      <h3 className='enimerwseis-h3'>Επισπεύδων</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.epispevdon}</p>
      <h3 className='enimerwseis-h3'>ΑΦΜ Επισπεύδοντα</h3>
      <p className='enimerwseis-allages-title'>{pageItem?.afm_epispevdon}</p>

    </article>
  )
}
