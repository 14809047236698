import { Button, Card, Flex, Divider, Title, Text } from "@tremor/react";
import { useState } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  useNavigate,
  useLocation,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { DateDetails } from "./DateDetails";
ReactModal.setAppElement("#root");

function MonthlyCalendar({ dates }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [dateData, setDateData] = useState([]);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  async function getDateData(date) {
    console.log(date);
    try {
      const response = await axiosPrivate.get(
        "/calendar/date/" + date.replace(/\//g, "")
      );
      setDateData(response.data);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        // do something
      }
    }
  }

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1).getDay();
  };

  const getMonthDays = (year, month) => {
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = getFirstDayOfMonth(year, month);
    const monthDays = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      monthDays.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      monthDays.push(i);
    }

    return monthDays;
  };

  const tileContent = (day) => {
    const dateStr = `${selectedDate.getFullYear()}/${(
      selectedDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${day?.toString().padStart(2, "0")}`;
    const hasDate = dates.map((r) => r.imerominia).includes(dateStr);
    const amber =
      dates.filter((r) => r.imerominia === dateStr && r.t === "a").length > 0;

    const emerald =
      dates.filter(
        (r) => r.imerominia === dateStr && (r.t === "o" || r.t === "s")
      ).length > 0;

    const purple =
      dates.filter((r) => r.imerominia === dateStr && r.t === "e").length > 0;

    return hasDate ? (
      <div
        className="dots"
        onClick={async () => {
          getDateData(dateStr);
          setModalIsOpen(true);
        }}
      >
        {emerald && <div className="cdot-emerald" />}
        {purple && <div className="cdot-purple" />}
        {amber && <div className="cdot-amber" />}
      </div>
    ) : (
      <div className="no-cdot" />
    );
  };

  const monthDays = getMonthDays(
    selectedDate.getFullYear(),
    selectedDate.getMonth()
  );

  return (
    <div className="monthly-calendar">
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          content: {
            height: "fit-content",
            margin: "0 auto",
            width: "fit-content",
            zIndex: "1000",
            padding: "0",
            maxHeight: "85vh",
            maxWidth: "85vw",
            minWidth: "270px",
          },
          overlay: {
            zIndex: "99999999",
          },
        }}
      >
        <Card className="max-w-md mx-auto date-details">
          <Flex
            justifyContent="start"
            alignItems="baseline"
            className="space-x-3 truncate"
          >
            <Title>
              {new Date(dateData[0]?.imerominia).toLocaleDateString("el-GR", {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
            </Title>
            <Text>
              {new Date(dateData[0]?.imerominia).toLocaleDateString("el-GR", {
                weekday: "long",
              })}
            </Text>
          </Flex>
          <Divider className="mt-3" />
          {dateData.map((r) => (
            <DateDetails singleCase={r.caseData} r={r} />
          ))}
          <div className="delete-user-btns">
            <button
              className="addcase_modal check_now post-footer-delete"
              onClick={() => {
                setModalIsOpen(false);
              }}
            >
              Κλείσιμο
            </button>
          </div>
        </Card>
      </ReactModal>
      <div>
        <Button
          size="xs"
          color="amber"
          onClick={() =>
            setSelectedDate(
              new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth() - 1,
                1
              )
            )
          }
        >
          {`<< Προηγ`}
        </Button>
        <span className="ml-2 mr-2">
          {selectedDate.toLocaleString("el-GR", {
            month: "long",
            year: "numeric",
          })}
        </span>
        <Button
          color="amber"
          size="xs"
          onClick={() =>
            setSelectedDate(
              new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth() + 1,
                1
              )
            )
          }
        >
          {`Επόμ >>`}
        </Button>
      </div>
      <table className="mt-1">
        <thead>
          <tr>
            <th>Κυρ</th>
            <th>Δευ</th>
            <th>Τρί</th>
            <th>Τετ</th>
            <th>Πέμ</th>
            <th>Παρ</th>
            <th>Σάβ</th>
          </tr>
        </thead>
        <tbody>
          {Array(Math.ceil(monthDays.length / 7))
            .fill()
            .map((_, rowIndex) => (
              <tr key={rowIndex}>
                {Array(7)
                  .fill()
                  .map((_, colIndex) => {
                    const dayIndex = rowIndex * 7 + colIndex;
                    const day = monthDays[dayIndex];
                    return (
                      <td key={colIndex}>
                        {day !== null && (
                          <div
                            onClick={async (e) => {
                              //  if it contains a child element with class "dots" then setModalIsOpen(true)
                              if (
                                e.target?.firstChild?.nextSibling?.classList.contains(
                                  "dots"
                                )
                              ) {
                                setModalIsOpen(true);
                                getDateData(
                                  `${selectedDate.getFullYear()}/${(
                                    selectedDate.getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${day
                                    .toString()
                                    .padStart(2, "0")}`
                                );
                              }
                            }}
                          >
                            {day}
                            {tileContent(day)}
                          </div>
                        )}
                      </td>
                    );
                  })}
              </tr>
            ))}
        </tbody>
      </table>
      {/* A legend explaining each color */}
      <div className="legend">
        <div className="legend-item">
          <div className="cdot-emerald" />
          <p>Αστικά</p>
        </div>
        <div className="legend-item">
          <div className="cdot-amber" />
          <p>Διοικητικά</p>
        </div>
        <div className="legend-item">
          <div className="cdot-purple" />
          <p>Πλειστηριασμοί</p>
        </div>
      </div>
    </div>
  );
}

export default MonthlyCalendar;
