import React, { useState, useEffect } from "react";
import {
  Button,
  SearchSelect,
  SearchSelectItem,
  TextInput,
  DatePicker,
} from "@tremor/react";
import { el } from "date-fns/locale";

import { removeDiacriticsAndCapitalize } from "../../../utils/checkMisthosi";
import {
  faPenToSquare,
  faTrashCan,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import eirinodikeiaDiadikasies from "../../OutSolon/utils/eirinodikeiaDiadikasies.json";
import protodikeiaDiadikasies from "../../OutSolon/utils/protodikeiaDiadikasies.json";
import efeteiaDiadikasies from "../../OutSolon/utils/efeteiaDiadikasies.json";
import { set } from "date-fns";
import { isValidDateFormat, transformDateFormat } from "../../../utils/various";
import { formatDate } from "../../OutSolon/OutSolon";

function reverseFormatDate(date) {
  if (!date) return;
  const [year, month, day] = date.split("/");
  return new Date(year, month - 1, day);
}

export const Detail = ({
  singleCase,
  name,
  property,
  modifySingleCase,
  diadikasies,
  antikeimena,
  saveCase,
  eidi,
}) => {
  const [edit, setEdit] = useState(false);

  if (name === "Διαδικασία") {
    return (
      <>
        <h3>{name}</h3>
        {singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργαστείτε την σημείωση"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text" onClick={() => setEdit(!edit)}>
              {singleCase[property]}
            </p>
          </div>
        )}
        {!singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργαστείτε την σημείωση"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text empty" onClick={() => setEdit(!edit)}>
              ---
            </p>
          </div>
        )}
        {edit && (
          <div className="flex flex-row ">
            <button
              className="edit-button"
              onClick={async () => {
                setEdit(!edit);
                await saveCase();
              }}
              data-key={singleCase.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} />
            </button>
            <SearchSelect
              className="ml-1"
              placeholder="Επιλέξτε Διαδικασία"
              onValueChange={(value) =>
                modifySingleCase({ name: property, value: value })
              }
              value={singleCase.diadikasia}
            >
              {diadikasies.map((dim, i) => {
                return (
                  <SearchSelectItem
                    key={`${i}-${dim.diadikasia}`}
                    value={dim.diadikasia}
                  >
                    {removeDiacriticsAndCapitalize(dim.diadikasia)}
                  </SearchSelectItem>
                );
              })}
            </SearchSelect>
          </div>
        )}
      </>
    );
  }
  if (name === "Αντικείμενο") {
    return (
      <>
        <h3>{name}</h3>
        {singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργασία"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text" onClick={() => setEdit(!edit)}>
              {singleCase[property]}
            </p>
          </div>
        )}
        {!singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργασία"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text empty" onClick={() => setEdit(!edit)}>
              ---
            </p>
          </div>
        )}
        {edit && (
          <div className="flex flex-row ">
            <button
              className="edit-button"
              onClick={async () => {
                setEdit(!edit);
                await saveCase();
              }}
              data-key={singleCase.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} />
            </button>
            <SearchSelect
              className="ml-1"
              placeholder="Επιλέξτε Διαδικασία"
              onValueChange={(value) =>
                modifySingleCase({ name: property, value: value })
              }
              value={singleCase.antikeimeno}
            >
              {antikeimena.map((dim, i) => {
                return (
                  <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                    {removeDiacriticsAndCapitalize(dim)}
                  </SearchSelectItem>
                );
              })}
            </SearchSelect>
          </div>
        )}
      </>
    );
  }

  if (name === "Είδος") {
    return (
      <>
        <h3>{name}</h3>
        {singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργαστείτε το είδος"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text" onClick={() => setEdit(!edit)}>
              {singleCase[property]}
            </p>
          </div>
        )}
        {!singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργαστείτε το είδος"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text empty" onClick={() => setEdit(!edit)}>
              ---
            </p>
          </div>
        )}
        {edit && (
          <div className="flex flex-row ">
            <button
              className="edit-button"
              onClick={async () => {
                setEdit(!edit);
                await saveCase();
              }}
              data-key={singleCase.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} />
            </button>
            <SearchSelect
              className="ml-1"
              placeholder="Επιλέξτε Διαδικασία"
              onValueChange={(value) =>
                modifySingleCase({ name: property, value: value })
              }
              value={singleCase.eidos}
            >
              {eidi?.map((dim, i) => {
                return (
                  <SearchSelectItem key={`${i}-${dim}`} value={dim}>
                    {removeDiacriticsAndCapitalize(dim)}
                  </SearchSelectItem>
                );
              })}
            </SearchSelect>
          </div>
        )}
      </>
    );
  }
  if (name === "Ημ/νία Κατάθεσης") {
    return (
      <>
        <h3>{name}</h3>
        {singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργασία"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text" onClick={() => setEdit(!edit)}>
              {transformDateFormat(singleCase[property])}
            </p>
          </div>
        )}
        {!singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργασία"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text empty" onClick={() => setEdit(!edit)}>
              ---
            </p>
          </div>
        )}
        {edit && (
          <div className="flex flex-row ">
            <button
              className="edit-button"
              onClick={async () => {
                setEdit(!edit);
                await saveCase();
              }}
              data-key={singleCase.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} />
            </button>
            <DatePicker
              className="max-w-md mx-auto"
              value={reverseFormatDate(singleCase.katathesi)}
              onValueChange={(value) =>
                modifySingleCase({ name: property, value: formatDate(value) })
              }
              locale={el}
              placeholder="Επιλέξτε ημ/νία Κατάθεσης"
            ></DatePicker>
          </div>
        )}
      </>
    );
  }
  if (name === "Δικάσιμος") {
    return (
      <>
        <h2>{name}</h2>
        {singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργασία"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text" onClick={() => setEdit(!edit)}>
              {transformDateFormat(singleCase[property])}
            </p>
          </div>
        )}
        {!singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργασία"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text empty" onClick={() => setEdit(!edit)}>
              ---
            </p>
          </div>
        )}
        {edit && (
          <div className="flex flex-row ">
            <button
              className="edit-button"
              onClick={async () => {
                setEdit(!edit);
                await saveCase();
              }}
              data-key={singleCase.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} />
            </button>
            <DatePicker
              className="max-w-md mx-auto"
              value={reverseFormatDate(singleCase.dikasimos)}
              onValueChange={(value) =>
                modifySingleCase({ name: property, value: formatDate(value) })
              }
              locale={el}
              placeholder="Επιλέξτε Δικάσιμο εάν υπάρχει"
            ></DatePicker>
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        <h3>{name}</h3>
        {singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργαστείτε"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text" onClick={() => setEdit(!edit)}>
              {isValidDateFormat(singleCase[property])
                ? transformDateFormat(singleCase[property])
                : singleCase[property]}
            </p>
          </div>
        )}
        {!singleCase[property]?.trim() && !edit && (
          <div className="flex flex-row items-start">
            <button
              className="edit-button"
              data-icon="pen-to-square"
              title="Επεξεργαστείτε"
              onClick={() => setEdit(!edit)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
            <p className="stoixeia-text empty">---</p>
          </div>
        )}
        {edit && (
          <div className="flex flex-row ">
            <button
              className="edit-button"
              onClick={async () => {
                setEdit(!edit);
                await saveCase();
              }}
              data-key={singleCase.id}
              data-icon="circle-check"
            >
              <FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} />
            </button>
            <TextInput
              className="ml-1"
              placeholder={`Εισάγετε ${name}`}
              onChange={(value) =>
                modifySingleCase({ name: property, value: value.target.value })
              }
              value={singleCase[property]}
            />
          </div>
        )}
      </>
    );
  }
};
