import { removeDiacriticsAndCapitalize } from "../../utils/checkMisthosi";

export const DOY = [
  "ΑΘΗΝΩΝ Α'",
  "ΑΘΗΝΩΝ Δ'",
  "ΑΘΗΝΩΝ Ε'",
  "ΑΘΗΝΩΝ ΣΤ'",
  "ΑΘΗΝΩΝ Ι'",
  "ΑΘΗΝΩΝ ΙΑ'",
  "ΑΘΗΝΩΝ ΙΒ'",
  "ΑΘΗΝΩΝ ΙΓ'",
  "ΑΘΗΝΩΝ ΙΔ'",
  "ΑΘΗΝΩΝ ΙΕ'",
  "ΑΘΗΝΩΝ ΙΣΤ'",
  "ΑΘΗΝΩΝ ΙΖ'",
  "ΑΘΗΝΩΝ ΦΑΒΕ",
  "ΑΘΗΝΩΝ ΙΗ'",
  "ΚΑΤΟΙΚΩΝ ΕΞΩΤΕΡΙΚΟΥ",
  "ΑΘΗΝΩΝ ΙΘ'",
  "ΑΓ. ΔΗΜΗΤΡΙΟΥ",
  "ΚΑΛΛΙΘΕΑΣ Α'",
  "ΝΕΑΣ ΙΩΝΙΑΣ",
  "ΝΕΑΣ ΣΜΥΡΝΗΣ",
  "ΠΑΛΑΙΟΥ ΦΑΛΗΡΟΥ",
  "ΧΑΛΑΝΔΡΙΟΥ",
  "ΑΜΑΡΟΥΣΙΟΥ",
  "ΑΓΙΩΝ ΑΝΑΡΓΥΡΩΝ",
  "ΑΙΓΑΛΕΩ",
  "ΠΕΡΙΣΤΕΡΙΟΥ Α'",
  "ΓΛΥΦΑΔΑΣ",
  "ΑΘΗΝΩΝ Κ'",
  "ΑΘΗΝΩΝ ΚΑ'",
  "ΑΘΗΝΩΝ ΚΒ'",
  "ΑΘΗΝΩΝ ΚΓ'",
  "ΔΑΦΝΗΣ",
  "ΗΡΑΚΛΕΙΟΥ ΑΤΤΙΚΗΣ",
  "ΑΓΙΑΣ ΠΑΡΑΣΚΕΥΗΣ",
  "ΒΥΡΩΝΑ",
  "ΚΗΦΙΣΙΑΣ",
  "ΙΛΙΟΥ",
  "ΝΕΑΣ ΦΙΛΑΔΕΛΦΕΙΑΣ",
  "ΧΑΙΔΑΡΙΟΥ",
  "ΠΕΡΙΣΤΕΡΙΟΥ Β'",
  "ΑΘΗΝΩΝ ΦΑΕΕ",
  "ΖΩΓΡΑΦΟΥ",
  "ΗΛΙΟΥΠΟΛΗΣ",
  "ΚΑΛΛΙΘΕΑΣ Β'",
  "ΨΥΧΙΚΟΥ",
  "ΧΟΛΑΡΓΟΥ",
  "ΑΡΓΥΡΟΥΠΟΛΗΣ",
  "ΠΕΤΡΟΥΠΟΛΗΣ",
  "ΓΑΛΑΤΣΙΟΥ",
  "ΑΝΩ ΛΙΟΣΙΩΝ",
  "ΠΕΙΡΑΙΑ Α'",
  "ΠΕΙΡΑΙΑ Γ'",
  "ΠΕΙΡΑΙΑ Δ'",
  "ΠΕΙΡΑΙΑ Ε'",
  "  ΠΕΙΡΑΙΑ ΦΑΕ",
  "ΠΕΙΡΑΙΑ ΠΛΟΙΩΝ",
  "ΠΕΙΡΑΙΑ ΣΤ'",
  "ΚΟΡΥΔΑΛΛΟΥ",
  "ΜΟΣΧΑΤΟΥ",
  "ΝΙΚΑΙΑΣ",
  "ΑΙΓΙΝΑΣ",
  "ΑΧΑΡΝΩΝ",
  "ΕΛΕΥΣΙΝΑΣ",
  "ΚΟΡΩΠΙΟΥ",
  "ΚΥΘΗΡΩΝ",
  "ΛΑΥΡΙΟΥ",
  "ΑΓΙΟΥ ΣΤΕΦΑΝΟΥ",
  "ΜΕΓΑΡΩΝ",
  "ΣΑΛΑΜΙΝΑΣ",
  "ΠΟΡΟΥ",
  "ΥΔΡΑΣ",
  "ΠΑΛΛΗΝΗΣ",
  "ΘΗΒΑΣ",
  "ΛΕΙΒΑΔΙΑΣ",
  "ΑΜΦΙΛΟΧΙΑΣ",
  "ΑΣΤΑΚΟΥ",
  "ΒΟΝΙΤΣΑΣ",
  "ΜΕΣΟΛΟΓΓΙΟΥ",
  "ΝΑΥΠΑΚΤΟΥ",
  "ΘΕΡΜΟΥ",
  "ΑΓΡΙΝΙΟΥ",
  "ΚΑΡΠΕΝΗΣΙΟΥ",
  "ΙΣΤΙΑΙΑΣ",
  "ΚΑΡΥΣΤΟΥ",
  "ΚΥΜΗΣ",
  "ΛΙΜΝΗΣ",
  "ΧΑΛΚΙΔΑΣ",
  "ΔΟΜΟΚΟΥ",
  "ΑΜΦΙΚΛΕΙΑΣ",
  "ΑΤΑΛΑΝΤΗΣ",
  "ΜΑΚΡΑΚΩΜΗΣ",
  "ΛΑΜΙΑΣ",
  "ΣΤΥΛΙΔΑΣ",
  "ΛΙΔΟΡΙΚΙΟΥ",
  "ΑΜΦΙΣΣΑΣ",
  "ΑΡΓΟΥΣ",
  "ΣΠΕΤΣΩΝ",
  "ΚΡΑΝΙΔΙΟΥ",
  "ΝΑΥΠΛΙΟΥ",
  "ΔΗΜΗΤΣΑΝΑΣ",
  "ΛΕΩΝΙΔΙΟΥ",
  "ΤΡΟΠΑΙΩΝ",
  "ΠΑΡΑΛΙΟΥ ΑΣΤΡΟΥΣ",
  "ΤΡΙΠΟΛΗΣ",
  "ΜΕΓΑΛΟΠΟΛΗΣ",
  "ΑΙΓΙΟΥ",
  "ΑΚΡΑΤΑΣ",
  "ΚΑΛΑΒΡΥΤΩΝ",
  "ΚΛΕΙΤΟΡΙΑΣ",
  "ΠΑΤΡΩΝ Α'",
  "ΠΑΤΡΩΝ Β'",
  "ΚΑΤΩ ΑΧΑΙΑΣ",
  "ΠΑΤΡΩΝ Γ'",
  "ΑΜΑΛΙΑΔΑΣ",
  "ΠΥΡΓΟΥ",
  "ΓΑΣΤΟΥΝΗΣ",
  "ΒΑΡΔΑ",
  "ΚΡΕΣΤΕΝΩΝ",
  "ΛΕΧΑΙΝΩΝ",
  "ΑΝΔΡΙΤΣΑΙΝΑΣ",
  "ΖΑΧΑΡΩΣ",
  "ΔΕΡΒΕΝΙΟΥ",
  "ΚΙΑΤΟΥ",
  "ΚΟΡΙΝΘΟΥ",
  "ΝΕΜΕΑΣ",
  "ΞΥΛΟΚΑΣΤΡΟΥ",
  "ΓΥΘΕΙΟΥ",
  "ΜΟΛΑΩΝ",
  "ΝΕΑΠΟΛΗΣ ΒΟΙΩΝ ΛΑΚΩΝΙΑΣ",
  "ΣΚΑΛΑ ΛΑΚΩΝΙΑΣ",
  "ΚΡΟΚΕΩΝ",
  "ΣΠΑΡΤΗΣ",
  "ΑΡΕΟΠΟΛΗΣ",
  "ΚΑΛΑΜΑΤΑΣ",
  "ΜΕΛΙΓΑΛΑ",
  "ΜΕΣΣΗΝΗΣ",
  "ΠΥΛΟΥ",
  "ΓΑΡΓΑΛΙΑΝΩΝ",
  "ΚΥΠΑΡΙΣΣΙΑΣ",
  "ΦΙΛΙΑΤΡΩΝ ΜΕΣΣΗΝΙΑΣ",
  "ΚΑΡΔΙΤΣΑΣ",
  "ΜΟΥΖΑΚΙΟΥ",
  "ΣΟΦΑΔΩΝ",
  "ΠΑΛΑΜΑ",
  "ΑΓΙΑΣ",
  "ΕΛΑΣΣΟΝΑΣ",
  "ΔΕΣΚΑΤΗΣ",
  "ΛΑΡΙΣΑΣ Α'",
  "ΛΑΡΙΣΑΣ Β'",
  "ΛΑΡΙΣΑΣ Γ'",
  "ΤΥΡΝΑΒΟΥ",
  "ΦΑΡΣΑΛΩΝ",
  "ΑΛΜΥΡΟΥ",
  "ΒΟΛΟΥ Α'",
  "ΒΟΛΟΥ Β'",
  "ΙΩΝΙΑΣ ΜΑΓΝΗΣΙΑΣ",
  "ΣΚΟΠΕΛΟΥ",
  "ΣΚΙΑΘΟΥ",
  "ΚΑΛΑΜΠΑΚΑΣ",
  "ΤΡΙΚΑΛΩΝ",
  "ΠΥΛΗΣ",
  "ΑΛΕΞΑΝΔΡΕΙΑΣ",
  "ΒΕΡΟΙΑΣ",
  "ΝΑΟΥΣΑΣ",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ Α'",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ Β'",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ Δ'",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ Ε'",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ ΣΤ'",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ Ζ'",
  "ΖΑΓΚΛΙΒΕΡΙΟΥ",
  "ΛΑΓΚΑΔΑ",
  "ΣΩΧΟΥ",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ ΦΑΕ",
  "ΝΕΑΠΟΛΗΣ ΘΕΣ/ΝΙΚΗΣ",
  "ΤΟΥΜΠΑΣ",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ Ι'",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ Η'",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ Θ'",
  "ΑΓ. ΑΘΑΝΑΣΙΟΥ",
  "ΚΑΛΑΜΑΡΙΑΣ",
  "ΑΜΠΕΛΟΚΗΠΩΝ",
  "Ν.ΙΩΝΙΑΣ ΘΕΣ/ΚΗΣ",
  "ΚΑΣΤΟΡΙΑΣ",
  "ΝΕΣΤΟΡΙΟΥ",
  "ΑΡΓΟΥΣ ΟΡΕΣΤΙΚΟΥ",
  "ΚΙΛΚΙΣ",
  "ΓΟΥΜΕΝΙΣΣΑΣ",
  "ΓΡΕΒΕΝΩΝ",
  "ΝΕΑΠΟΛΗΣ ΒΟΙΟΥ",
  "ΠΤΟΛΕΜΑΙΔΑΣ",
  "ΚΟΖΑΝΗ",
  "ΣΕΡΒΙΩΝ",
  "ΣΙΑΤΙΣΤΑΣ",
  "ΑΡΙΔΑΙΑΣ",
  "ΓΙΑΝΝΙΤΣΩΝ",
  "ΕΔΕΣΣΑΣ",
  "ΣΚΥΔΡΑΣ",
  "ΚΑΤΕΡΙΝΗΣ Α'",
  "ΚΑΤΕΡΙΝΗΣ Β'",
  "ΑΙΓΙΝΙΟΥ",
  "ΑΜΥΝΤΑΙΟΥ",
  "ΦΛΩΡΙΝΑΣ",
  "ΑΡΝΑΙΑΣ",
  "ΚΑΣΣΑΝΔΡΑΣ",
  "ΠΟΛΥΓΥΡΟΥ",
  "ΝΕΩΝ ΜΟΥΔΑΝΙΩΝ",
  "ΔΡΑΜΑΣ",
  "ΝΕΥΡΟΚΟΠΙΟΥ",
  "ΑΛΕΞΑΝΔΡΟΥΠΟΛΗΣ",
  "ΔΙΔΥΜΟΤΕΙΧΟΥ",
  "ΟΡΕΣΤΕΙΑΔΑΣ",
  "ΣΟΥΦΛΙΟΥ",
  "ΘΑΣΟΥ",
  "ΚΑΒΑΛΑΣ Α'",
  "ΚΑΒΑΛΑΣ Β'",
  "ΧΡΥΣΟΥΠΟΛΗΣ",
  "ΕΛΕΥΘΕΡΟΥΠΟΛΗΣ",
  "ΞΑΝΘΗΣ Α'",
  "ΞΑΝΘΗΣ Β'",
  "ΚΟΜΟΤΗΝΗΣ",
  "ΣΑΠΠΩΝ",
  "ΝΙΓΡΙΤΑΣ",
  "ΣΕΡΡΩΝ Α'",
  "ΣΕΡΡΩΝ Β'",
  "ΣΙΔΗΡΟΚΑΣΤΡΟΥ",
  "ΗΡΑΚΛΕΙΑΣ",
  "ΝΕΑΣ ΖΙΧΝΗΣ",
  "ΑΡΤΑΣ",
  "ΦΙΛΙΠΠΙΑΔΑΣ",
  "ΗΓΟΥΜΕΝΙΤΣΑΣ",
  "ΠΑΡΑΜΥΘΙΑΣ",
  "ΦΙΛΙΑΤΩΝ",
  "ΠΑΡΓΑΣ",
  "ΦΑΝΑΡΙΟΥ",
  "ΠΡΕΒΕΖΑΣ",
  "ΙΩΑΝΝΙΝΩΝ Α'",
  "ΙΩΑΝΝΙΝΩΝ Β'",
  "ΔΕΛΒΙΝΑΚΙΟΥ",
  "ΜΕΤΣΟΒΟΥ",
  "ΚΟΝΙΤΣΑΣ",
  "ΑΝΔΡΟΥ",
  "ΘΗΡΑΣ",
  "ΚΕΑΣ",
  "ΜΗΛΟΥ",
  "ΝΑΞΟΥ",
  "ΠΑΡΟΥ",
  "ΣΥΡΟΥ",
  "ΜΥΚΟΝΟΥ",
  "ΤΗΝΟΥ",
  "ΛΗΜΝΟΥ",
  "ΚΑΛΛΟΝΗΣ",
  "ΜΗΘΥΜΝΑΣ",
  "ΜΥΤΙΛΗΝΗΣ",
  "ΠΛΩΜΑΡΙΟΥ",
  "ΑΓ. ΚΗΡΥΚΟΥ ΙΚΑΡΙΑΣ",
  "ΚΑΡΛΟΒΑΣΙΟΥ",
  "ΣΑΜΟΥ",
  "ΧΙΟΥ",
  "ΚΑΛΥΜΝΟΥ",
  "ΛΕΡΟΥ",
  "ΚΑΡΠΑΘΟΥ",
  "ΚΩ",
  "ΡΟΔΟΥ",
  "ΗΡΑΚΛΕΙΟΥ Α'",
  "ΜΟΙΡΩΝ",
  "ΗΡΑΚΛΕΙΟΥ Β'",
  "ΤΥΜΠΑΚΙΟΥ",
  "ΛΙΜΕΝΑ ΧΕΡΣΟΝΗΣΟΥ",
  "ΚΑΣΤΕΛΙΟΥ ΠΕΔΙΑΔΟΣ",
  "ΑΡΚΑΛΟΧΩΡΙΟΥ",
  "ΙΕΡΑΠΕΤΡΑΣ",
  "ΑΓΙΟΥ ΝΙΚΟΛΑΟΥ",
  "ΝΕΑΠΟΛΗΣ ΚΡΗΤΗΣ",
  "ΣΗΤΕΙΑΣ",
  "ΡΕΘΥΜΝΟΥ",
  "ΚΙΣΣΑΜΟΥ",
  "ΧΑΝΙΩΝ Α'",
  "ΧΑΝΙΩΝ Β'",
  "ΖΑΚΥΝΘΟΥ",
  "ΚΕΡΚΥΡΑΣ Α'",
  "ΚΕΡΚΥΡΑΣ Β'",
  "ΠΑΞΩΝ",
  "ΑΡΓΟΣΤΟΛΙΟΥ",
  "ΛΗΞΟΥΡΙΟΥ",
  "ΙΘΑΚΗΣ",
  "ΛΕΥΚΑΔΑΣ",
]
  .map((dim) => removeDiacriticsAndCapitalize(dim))
  .sort();
