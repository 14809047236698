const courts = [
  "ΑΡΕΙΟΣ ΠΑΓΟΣ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΘΗΝΩΝ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΜΑΡΟΥΣΙΟΥ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΧΑΡΝΩΝ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΒΑΣΙΛΙΚΩΝ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΕΛΕΥΣΙΝΑΣ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΙΛΙΟΥ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΑΛΛΙΘΕΑΣ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΟΥΦΑΛΙΩΝ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΡΩΠΙΑΣ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΛΑΓΚΑΔΑ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΛΑΥΡΙΟΥ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΜΑΡΑΘΩΝΟΣ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΜΕΓΑΡΩΝ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΝΕΑΣ ΙΩΝΙΑΣ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΠΕΙΡΑΙΑ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΠΕΡΙΣΤΕΡΙΟΥ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΧΑΛΑΝΔΡΙΟΥ",
  "ΕΙΡΗΝΟΔΙΚΕΙΟ ΧΑΛΚΙΔΑΣ",
  "ΕΦΕΤΕΙΟ ΑΘΗΝΩΝ",
  "ΕΦΕΤΕΙΟ ΕΥΒΟΙΑΣ",
  "ΕΦΕΤΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΕΦΕΤΕΙΟ ΛΑΡΙΣΑΣ",
  "ΕΦΕΤΕΙΟ ΠΕΙΡΑΙΩΣ",
  "ΠΡΩΤΟΔΙΚΕΙΟ ΑΘΗΝΩΝ",
  "ΠΡΩΤΟΔΙΚΕΙΟ ΒΟΛΟΥ",
  "ΠΡΩΤΟΔΙΚΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΠΡΩΤΟΔΙΚΕΙΟ ΚΑΡΔΙΤΣΑΣ",
  "ΠΡΩΤΟΔΙΚΕΙΟ ΛΑΡΙΣΑΣ",
  "ΠΡΩΤΟΔΙΚΕΙΟ ΠΕΙΡΑΙΑ",
  "ΠΡΩΤΟΔΙΚΕΙΟ ΤΡΙΚΑΛΩΝ",
  "ΠΡΩΤΟΔΙΚΕΙΟ ΧΑΛΚΙΔΑΣ",
];
export function getTopiki(court) {
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΘΗΝΩΝ") return "Αθηνών";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΜΑΡΟΥΣΙΟΥ") return "Αμαρoυσίoυ";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΑΧΑΡΝΩΝ") return "Αχαρνών";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΒΑΣΙΛΙΚΩΝ") return "Βασιλικών";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΕΛΕΥΣΙΝΑΣ") return "Ελευσίνας";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ") return "Θεσσαλονίκης";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΙΛΙΟΥ") return "Ν. Λιoσίωv";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΑΛΛΙΘΕΑΣ") return "Καλλιθέας";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΟΥΦΑΛΙΩΝ") return "Κουφαλίων";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΚΡΩΠΙΑΣ") return "Κρωπίας";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΛΑΓΚΑΔΑ") return "Λαγκαδά";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΛΑΥΡΙΟΥ") return "Λαυρίoυ";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΜΑΡΑΘΩΝΟΣ") return "Μαραθώvoς";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΝΕΑΣ ΙΩΝΙΑΣ") return "Ν. Iωvίας";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΠΕΙΡΑΙΑ") return "Πειραιά";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΠΕΡΙΣΤΕΡΙΟΥ") return "Περιστερίoυ";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΧΑΛΑΝΔΡΙΟΥ") return "Χαλαvδρίoυ";
  if (court === "ΕΙΡΗΝΟΔΙΚΕΙΟ ΧΑΛΚΙΔΑΣ") return "Χαλκίδας";

  if (court === "ΠΡΩΤΟΔΙΚΕΙΟ ΑΘΗΝΩΝ") return "Αθηνών";
  if (court === "ΠΡΩΤΟΔΙΚΕΙΟ ΘΕΣΣΑΛΟΝΙΚΗΣ") return "Θεσσαλονίκης";
  if (court === "ΠΡΩΤΟΔΙΚΕΙΟ ΠΕΙΡΑΙΑ") return "Πειραιά";
  if (court === "ΠΡΩΤΟΔΙΚΕΙΟ ΧΑΛΚΙΔΑΣ") return "Χαλκίδας";
  else return "Αθηνών";
}
