const EkmisthotesRow = ({
  handleEkInputChange,
  handleEkFyloChange,
  ekmisthotis,
  i,
  removeEkmisthotis,
  length,
}) => {
  const {
    onoma_ek = "",
    eponymo_ek = "",
    patronymo_ek = "",
    AFM_ek = "",
    katoikia_ek = "",
    fylo_ek = "Άνδρας",
  } = ekmisthotis;

  return (
    <div className="all">
      <div className="vertical-line-focus"></div>
      <div className="form-section-row">
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="ekmisthotes-name">
            Όνομα
          </label>
          <input
            className="form-section-input"
            type="text"
            name="onoma_ek"
            value={ekmisthotis.onoma_ek}
            onChange={(e) => handleEkInputChange(e, i)}
            onBlur={(e) => {
              handleEkInputChange(e, i);
              handleEkFyloChange(e, i);
            }}
            placeholder="πχ. Ιωάννης"
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="ekmisthotes-surname">
            Επώνυμο
          </label>
          <input
            className="form-section-input"
            type="text"
            name="eponymo_ek"
            required
            value={ekmisthotis.eponymo_ek}
            onChange={(e) => handleEkInputChange(e, i)}
            onBlur={(e) => handleEkInputChange(e, i)}
            placeholder="πχ. Παπαδόπουλος"
          />
        </div>
        <div className="form-section-single-input">
          <label
            className="form-section-label"
            htmlFor="ekmisthotes-fathername"
          >
            Πατρώνυμο
          </label>
          <input
            className="form-section-input"
            type="text"
            name="patronymo_ek"
            value={ekmisthotis.patronymo_ek}
            onChange={(e) => handleEkInputChange(e, i)}
            onBlur={(e) => handleEkInputChange(e, i)}
            placeholder="πχ. Κωνσταντίνος"
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="ekmisthotes-afm">
            ΑΦΜ
          </label>
          <input
            className="form-section-input"
            type="text"
            id="ekmisthotes-afm"
            name="AFM_ek"
            value={ekmisthotis.AFM_ek}
            onChange={(e) => handleEkInputChange(e, i)}
            placeholder="πχ. 123456789"
          />
        </div>
        <div className="form-section-single-input double-input-width">
          <label className="form-section-label" htmlFor="ekmisthotes-katoikos">
            Κάτοικος
          </label>
          <input
            className="form-section-input"
            type="text"
            id="ekmisthotes-katoikos"
            name="katoikia_ek"
            value={ekmisthotis.katoikia_ek}
            onChange={(e) => handleEkInputChange(e, i)}
            placeholder="πχ. Αθηνών, οδός Ομήρου. αρ.73"
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="ekmisthotis-gender">
            Φύλο
          </label>
          <select
            className="form-section-input"
            id="ekmisthotis-gender"
            name="fylo_ek"
            value={ekmisthotis.fylo_ek}
            onChange={(e) => handleEkFyloChange(e, i)}
          >
            <option value="Άνδρας">Άνδρας</option>
            <option value="Γυναίκα">Γυναίκα</option>
          </select>
        </div>
        <div className="form-section-single-input button-row">
          {length > 1 && (
            <button
              type="button"
              className="form-section-button"
              onClick={(e) => {
                removeEkmisthotis(e, i);
              }}
              disabled={i === 0}
            >
              <img
                src="/images/trash-can-solid.svg"
                alt="Delete"
                className="icon"
              />
              Διαγραφή
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EkmisthotesRow;
