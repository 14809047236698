import { axiosPrivate } from '../../api/axios';
import { useState } from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faCircleCheck, faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { DateTime, Settings } from 'luxon'
import { getAdjCourt } from '../../utils/getAdjCourts'
import EirNikaias from './Courts/EirNikaias';
import ProtKos from './Courts/ProtKos';
import ProtPatras from './Courts/ProtPatras';
import EirPatras from './Courts/EirPatras';
import ProtLarisas from './Courts/ProtLarisas';
Settings.defaultLocale = 'el'

const removeZeroes = (str) => {
  let res = str
  while (res.at(0) === '0') {
    res = res.replace('0', '')
  }
  return res
}
Date.createFromMysql = function (mysql_string) {
  var t, result = null;

  if (typeof mysql_string === 'string') {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
}
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({ hours: 3 }).toFormat('ff')
  return formatedDate
}
const JCaseCard = ({ singleCase, handleDeleteCase }) => {
  const [note, setNote] = useState(singleCase?.note)

  const [showEdit, setShowEdit] = useState(false)


  const handleClicks = async (e) => {

    if (e.target.parentNode.getAttribute('data-icon') === 'pen-to-square') {
      setShowEdit(!showEdit)

    }
    if (e.target.parentNode.getAttribute('data-icon') === 'circle-check') {
      setShowEdit(!showEdit)
      try {
        const id = e.target.parentNode.getAttribute('data-key')
        await axiosPrivate.post('/exscases/note',
          JSON.stringify({ id, note }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );


      } catch (err) {
        console.error(err);
      }
    }
    if (e.currentTarget?.firstChild?.getAttribute('data-icon') === 'trash-can') {
      try {
        const id = e.currentTarget.getAttribute('data-key')
        await axiosPrivate.patch('/exscases',
          JSON.stringify({ key: id }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );
        handleDeleteCase(id)

      } catch (err) {
        console.error(err);
      }
    }
    // if (e.target?.parentNode?.getAttribute('data-icon') === null) {
    //   handleOnClick()
    // }


  }
  const handleEdit = () => {
    setShowEdit(!showEdit)
  }
  const handleUpdateNote = async (e) => {
    setShowEdit(!showEdit)
    try {
      const id = e.target.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.post('/adjustice/note',
        JSON.stringify({ id, note }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );


    } catch (err) {
      console.error(err);
    }
  }
  const handleKeyPress = async (e) => {

    if (e.key === 'Enter') {
      console.log(e.target.getAttribute('data-key'));

      setShowEdit(!showEdit)
      try {
        const id = e.target.getAttribute('data-key')
        // console.log(JSON.stringify({ key: id }));
        await axiosPrivate.post('/exscases/note',
          JSON.stringify({ id, note }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
          }
        );


      } catch (err) {
        console.error(err);
      }
    }
  }

  const handleDelete = async (e) => {

    try {
      const id = e.target.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.patch('/excases',
        JSON.stringify({ key: id }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      console.log(id);
      handleDeleteCase(id)

    } catch (err) {
      console.error(err);
    }
  }
  // const handleAppeal = async (e) => {
  //   setCheckAppeal(!checkAppeal)

  //   try {
  //     const id = e.target.getAttribute('data-key')
  //     // console.log(JSON.stringify({ key: id }));
  //     await axiosPrivate.post('/appeals',
  //       JSON.stringify({ id, check: !checkAppeal }),
  //       {
  //         headers: { 'Content-Type': 'application/json' },
  //         withCredentials: true
  //       }
  //     );


  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
  // const handleProth = async (e) => {

  //   setShowProth(!showProth)
  // }
  return (
    <article className='case_card' onKeyDownCapture={handleKeyPress} data-key={singleCase.id}>

      {/* <div key={`ed${singleCase.id}`} className="edit_case"><p ><span onClick={handleDelete} data-key={singleCase.id}>✏️</span></p></div> */}
      <span className='number'>{singleCase?.i + 1}</span>
      <div key={singleCase.id} className="close_case"><p ><span style={{ border: 'none', cursor: 'pointer', color: 'red', backgroundColor: 'white', paddingLeft: '5px', fontSize: "1.3em", }} title='Διαγράψτε την υπόθεση' onClick={handleClicks} data-key={singleCase.id}><FontAwesomeIcon icon={faTrashCan} /></span></p></div>
      {/* {singleCase?.note?.length > 2 && <h4><em>Σημείωση:</em> {singleCase.note}</h4>}
      <h4><em>ΓΑΚ:</em> {singleCase?.gak}/{singleCase?.year}</h4>
      <h4><em>Δικαστήριο:</em> {singleCase?.court}</h4> */}
      <h4><em>Σημείωση:</em>  {showEdit ? <><input className='edit-note' placeholder={note} value={note} size={note.length} onChange={(e) => setNote(e.target.value)} /><button style={{ border: 'none', cursor: 'pointer', color: 'green', backgroundColor: 'white', paddingLeft: '5px', fontSize: "1.3em", }} onClick={handleClicks} data-key={singleCase.id} data-icon='circle-check'><FontAwesomeIcon data-key={singleCase.id} icon={faCircleCheck} /></button></> : <span>{note}<button data-key={singleCase.id} data-icon='pen-to-square' title="Επεξεργαστείτε την σημείωση" style={{ border: 'none', cursor: 'pointer', color: 'green', backgroundColor: 'white', paddingLeft: '5px', fontSize: "1.3em", }} onClick={handleClicks}><FontAwesomeIcon icon={faPenToSquare} /></button></span>}</h4>
      {singleCase?.court?.length > 2 && <h4><em>Δικαστήριο: </em>
        {(singleCase?.court === 'eirnikaias' && 'Ειρ. Νικαίας')}
        {(singleCase?.court === 'protkos' && 'Πρωτ. Κω')}
        {(singleCase?.court === 'protpatras' && 'Πρωτ. Πάτρας')}
        {(singleCase?.court === 'eirpatras' && 'Ειρ. Πάτρας')}
        {(singleCase?.court === 'protlarisas' && 'Πρωτ. Λάρισας')}



      </h4 >}
      {singleCase?.arkat?.length > 0 && <h4><em>Αρ.Καταθ.: </em>{singleCase?.arkat}/{singleCase?.year}</h4 >}

      {singleCase?.court === 'eirnikaias' && <EirNikaias singleCase={singleCase} />}
      {singleCase?.court === 'protkos' && <ProtKos singleCase={singleCase} />}
      {singleCase?.court === 'protpatras' && <ProtPatras singleCase={singleCase} />}
      {singleCase?.court === 'eirpatras' && <EirPatras singleCase={singleCase} />}
      {singleCase?.court === 'protlarisas' && <ProtLarisas singleCase={singleCase} />}



      {singleCase?.created_at?.length > 2 && <h4><em>Δημιουργία: </em>{formatDate(singleCase?.created_at)}</h4 >}
      {singleCase?.last_checked?.length > 2 && <h4 data-case='last_checked'><em>Τελευταίος Έλεγχος: </em>{formatDate(singleCase?.last_checked)}</h4 >}


    </article >
  )
}

export default JCaseCard