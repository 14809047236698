import React, { useEffect } from 'react'
import { nanoid } from 'nanoid'
import axios from 'axios'
import { DateTime, Settings } from 'luxon'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useNavigate, useLocation } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago'
import el from 'javascript-time-ago/locale/el'
import Post from './Post';


TimeAgo.addLocale(el)
const timeAgo = new TimeAgo('el-GR')


Settings.defaultLocale = 'el'

Date.createFromMysql = function (mysql_string) {
  var t, result = null;

  if (typeof mysql_string === 'string') {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
}
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({ minutes: Math.abs(new Date().getTimezoneOffset()) }).toUnixInteger()
  const unixInt = new Date(formatedDate * 1000)
  return timeAgo.format(unixInt)
}


const CreatePost = () => {
  const [body, setBody] = React.useState('')
  const [uuid, setUuid] = React.useState(nanoid())
  const [posts, setPosts] = React.useState([])
  const [showCreatePost, setShowCreatePost] = React.useState(false)

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getPosts = async () => {
      try {
        const response = await axiosPrivate.get('/posts', {
          signal: controller.signal
        });
        isMounted && setPosts(response.data);

      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate('/login', { state: { from: location }, replace: true });

        }
      }
    }

    getPosts();

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)


    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCreatePost])
  const handleCreate = async (e) => {
    e.preventDefault();

    try {
      await axiosPrivate.post('/posts',
        JSON.stringify({ body, uuid }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      setShowCreatePost(true)
      // forceUpdate()
      //console.log(JSON.stringify(response));

    } catch (err) {
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axiosPrivate.post(`/posts/${uuid}`,
        JSON.stringify({ body }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      setShowCreatePost(false)
      setBody('')
      setUuid(nanoid())
      // forceUpdate()
      //console.log(JSON.stringify(response));

    } catch (err) {
      console.error(err);
      navigate('/login', { state: { from: location }, replace: true });
    }
  }
  return (
    <div>CreatePost
      <button onClick={handleCreate}>Create Post</button>
      {showCreatePost && <div>
        <textarea value={body} onChange={(e) => setBody(e.target.value)}></textarea>
        <button onClick={() => setShowCreatePost(!showCreatePost)}>Cancel</button>
        <button onClick={handleSubmit}>Post</button>
      </div>}
      {posts.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)

      }).map((post) => (
        <div key={post.uuid}>
          <Post creator={`${post.user.first_name} ${post.user.last_name}`} timeSince={formatDate(post.created_at)} body={post.body} />

        </div>
      ))}
      <Post creator={'Yiannis Mertzanis'} timeSince={'4h'} body={body} />
    </div>
  )
}

export default CreatePost