import * as React from "react";

import useInterval from "use-interval";

export default function Example() {
  let [aChar, setAChar] = React.useState(randomChar());
  let [bChar, setBChar] = React.useState(randomChar());
  let [cChar, setCChar] = React.useState(randomChar());
  let [dChar, setDChar] = React.useState(randomChar());
  let [eChar, setEChar] = React.useState(randomChar());
  let [fChar, setFChar] = React.useState(randomChar());
  let [gChar, setGChar] = React.useState(randomChar());
  let [hChar, setHChar] = React.useState(randomChar());

  useInterval(() => {
    setAChar(randomChar());
  }, 180);
  useInterval(() => {
    setBChar(randomChar());
  }, 220);
  useInterval(() => {
    setCChar(randomChar());
  }, 200);
  useInterval(() => {
    setDChar(randomChar());
  }, 190);
  useInterval(() => {
    setEChar(randomChar());
  }, 210);
  useInterval(() => {
    setFChar(randomChar());
  }, 170);
  useInterval(() => {
    setGChar(randomChar());
  }, 230);
  useInterval(() => {
    setHChar(randomChar());
  }, 240);

  return (
    <h1>
      <div className="char">{aChar}</div>
      <div className="char">{bChar}</div>
      <div className="char">{cChar}</div>
      <div className="char">{dChar}</div>
      <div className="char">{eChar}</div>
      <div className="char">{fChar}</div>
      <div className="char">{gChar}</div>
      <div className="char">{hChar}</div>
      <div className="char">{aChar}</div>
      <div className="char">{bChar}</div>
      <div className="char">{cChar}</div>
      <div className="char">{dChar}</div>
      <div className="char">{eChar}</div>
      <div className="char">{fChar}</div>
      <div className="char">{gChar}</div>
      <div className="char">{hChar}</div>
    </h1>
  );
}

// Function that returns a random character from A-Z, 0-9 or a symbol
function randomChar() {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZΩΦΨΞΠΦΣΓΔΘΛ0123456789!@#$%^&*()";
  return chars.charAt(Math.floor(Math.random() * chars.length));
}
