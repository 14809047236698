import React, { useEffect, useState, useMemo } from "react";
import { Card } from "@tremor/react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AuthContext from "../../context/AuthProvider";

import ProfilePhoto from "../UploadPhoto/Profile";
import HeaderAvatar from "../Visual/HeaderAvatar";
import ProfileUpdate from "./ProfileUpdate";
import ProfileData from "./ProfileData";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import ScrollToTop from "../ScrollToTop";
import { DeleteUserModal } from "./DeleteUserModal";

const Profile = () => {
  const [dimoiSelected, setDimoiSelected] = React.useState([]);
  const [DOYSelected, setDOYSelected] = React.useState([]);
  const [ypothSelected, setYpothSelected] = React.useState([]);
  const [eirinSelected, setEirinSelected] = React.useState([]);
  const [protodSelected, setProtodSelected] = React.useState([]);
  const [metafSelected, setMetafSelected] = React.useState([]);
  const [efeteiaSelected, setEfeteiaSelected] = React.useState([]);
  const [dioikSelected, setDioikSelected] = React.useState([]);
  const [dioikEfeteiaSelected, setDioikEfeteiaSelected] = React.useState([]);
  const [ktimatSelected, setKtimatSelected] = React.useState([]);

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const fields = useMemo(
    () => ({
      dimoi: dimoiSelected,
      DOY: DOYSelected,
      ypoth: ypothSelected,
      eirin: eirinSelected,
      protod: protodSelected,
      metaf: metafSelected,
      efeteia: efeteiaSelected,
      dioik: dioikSelected,
      dioikEfeteia: dioikEfeteiaSelected,
      ktimat: ktimatSelected,
    }),
    [
      dimoiSelected,
      DOYSelected,
      ypothSelected,
      eirinSelected,
      protodSelected,
      metafSelected,
      efeteiaSelected,
      dioikSelected,
      dioikEfeteiaSelected,
      ktimatSelected,
    ]
  );

  const { auth, avatarUploaded } = React.useContext(AuthContext);

  const axiosPrivate = useAxiosPrivate();
  const [email, setEmail] = useState("");
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    syllogos: "",
    am: "",
    edra: "",
    askoumenos: 0,
    notify: "1",
  });
  // const [assets, setAssets] = useState([])
  const [croppedImage, setCroppedImage] = useState(null);
  const [sameUuid, setSameUuid] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [badge, setBadge] = useState(null);
  const [rank, setRank] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  // if userData.first_name and userData.last_name are empty strings, use the email as the title
  useDocumentTitle(
    userData.first_name && userData.last_name
      ? `${userData.first_name} ${userData.last_name} | MySolon.gr`
      : `${email} | MySolon.gr`
  );

  useEffect(() => {
    console.log("location", params.profileId);
    if (
      params.profileId === auth.uuid ||
      auth.uuid === "6a798fe2-87a8-472f-bf4a-c62e18a62715" ||
      typeof params.profileId === "undefined"
    ) {
      console.log("same uuid");
      setSameUuid(true);
    }
    if (
      params.profileId !== auth.uuid &&
      auth.uuid !== "6a798fe2-87a8-472f-bf4a-c62e18a62715" &&
      typeof params.profileId !== "undefined"
    ) {
      setSameUuid(false);
    }

    let isMounted = true;
    const controller = new AbortController();

    const getProfile = async () => {
      const profileId = params.profileId || auth.uuid;
      try {
        const response = await axiosPrivate.get(`/profile/${profileId}`, {
          signal: controller.signal,
        });

        isMounted && setEmail(response.data.email);
        // isMounted && setAssets(response.data.assets);
        isMounted && setAvatar(response.data.avatar);
        isMounted && setDataLoaded(true);
        isMounted && setBadge(response.data.badge);
        isMounted && setRank(response.data.rank);
        if (response.data.extraInfo !== null) {
          isMounted && setUserData(JSON.parse(response.data.extraInfo));
        }
        if (response?.data?.fields) {
          const dimoi = response.data.fields
            .filter((field) => field.eidos === "dimoi")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const DOY = response.data.fields
            .filter((field) => field.eidos === "DOY")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const ypoth = response.data.fields
            .filter((field) => field.eidos === "ypoth")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const eirin = response.data.fields
            .filter((field) => field.eidos === "eirin")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const protod = response.data.fields
            .filter((field) => field.eidos === "protod")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const metaf = response.data.fields
            .filter((field) => field.eidos === "metaf")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const efeteia = response.data.fields
            .filter((field) => field.eidos === "efeteia")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const dioik = response.data.fields
            .filter((field) => field.eidos === "dioik")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const dioikEfeteia = response.data.fields
            .filter((field) => field.eidos === "dioikEfeteia")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));
          const ktimat = response.data.fields
            .filter((field) => field.eidos === "ktimat")
            .sort((a, b) => a.periohi.localeCompare(b.periohi));

          isMounted && setDimoiSelected(dimoi.map((field) => field.periohi));
          isMounted && setDOYSelected(DOY.map((field) => field.periohi));
          isMounted && setYpothSelected(ypoth.map((field) => field.periohi));
          isMounted && setEirinSelected(eirin.map((field) => field.periohi));
          isMounted && setProtodSelected(protod.map((field) => field.periohi));
          isMounted && setMetafSelected(metaf.map((field) => field.periohi));
          isMounted &&
            setEfeteiaSelected(efeteia.map((field) => field.periohi));
          isMounted && setDioikSelected(dioik.map((field) => field.periohi));
          isMounted &&
            setDioikEfeteiaSelected(dioikEfeteia.map((field) => field.periohi));
          isMounted && setKtimatSelected(ktimat.map((field) => field.periohi));
        }
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getProfile();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.profileId, croppedImage, auth.uuid]);

  useEffect(() => {
    if (croppedImage) {
      setTimeout(() => {
        setCroppedImage(null);
      }, 2000);
    }
  }, [croppedImage]);

  const handleEditProfile = () => {
    setEditProfile(!editProfile);
  };

  const handleDeleteUser = async () => {
    try {
      await axiosPrivate.patch(
        `/profile/${params.profileId}`,
        JSON.stringify({}),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      navigate("/login", { state: { from: location }, replace: true });

      // forceUpdate()
      //console.log(JSON.stringify(response));
    } catch (err) {
      console.error(err);
      navigate("/login", { state: { from: location }, replace: true });
    }
  };

  return (
    <div className="profile-all">
      <div className="profile-page">
        <ScrollToTop />
        {dataLoaded && (
          <Card maxWidth="95vw">
            <div className="avatar-card">
              <HeaderAvatar
                avatarUploaded={avatarUploaded}
                userAvatar={avatar}
                avatarClass={"lg"}
                badge={badge}
                rank={rank}
                uuid={params.profileId || auth.uuid}
              />
              {sameUuid && <ProfilePhoto setCroppedImage={setCroppedImage} />}

              <p className="header-card-top-email">{email}</p>
              {userData?.first_name && userData?.last_name && (
                <p className="header-card-top-email">
                  {userData?.first_name} {userData?.last_name}
                </p>
              )}
            </div>
          </Card>
        )}
        {/* If editProfile is true show ProfileUpdate else show ProfileData */}
        {editProfile && (
          <ProfileUpdate
            initUserData={userData}
            setEditProfile={handleEditProfile}
            updateUser={setUserData}
            fieldsProps={{
              dimoiSelected,
              DOYSelected,
              ypothSelected,
              eirinSelected,
              protodSelected,
              metafSelected,
              efeteiaSelected,
              dioikSelected,
              dioikEfeteiaSelected,
              ktimatSelected,

              setDimoiSelected,
              setDOYSelected,
              setYpothSelected,
              setEirinSelected,
              setProtodSelected,
              setMetafSelected,
              setEfeteiaSelected,
              setDioikSelected,
              setDioikEfeteiaSelected,
              setKtimatSelected,
            }}
            dimoiSelected={dimoiSelected}
            DOYSelected={DOYSelected}
            ypothSelected={ypothSelected}
            eirinSelected={eirinSelected}
            protodSelected={protodSelected}
            metafSelected={metafSelected}
            efeteiaSelected={efeteiaSelected}
            dioikSelected={dioikSelected}
            dioikEfeteiaSelected={dioikEfeteiaSelected}
            ktimatSelected={ktimatSelected}
            authUuid={auth.uuid}
          />
        )}
        {!editProfile && (
          <ProfileData
            setEditProfile={handleEditProfile}
            initUserData={userData}
            sameUuid={sameUuid}
            fields={fields}
          />
        )}

        {/* <p>{email}</p> */}
        {/* {croppedImage && <img className='avatar' src={croppedImage} alt='Profile' />} */}
        {/* {!croppedImage && assets.at(-1)?.uuid && <img className='avatar' src={`/assets/${assets.at(-1)?.uuid}.${assets.at(-1)?.type}`} alt='Profile' />} */}
        {/* {sameUuid && !showUpload && !croppedImage && <Button onClick={() => setShowUpload(true)}>Επεξεργαστείτε την εικόνα Προφίλ σας</Button>} */}
      </div>
      <div className="profile-down-section">
        {sameUuid && (
          <button
            className="delete-user-btn"
            onClick={() => setIsModalOpen(true)}
          >
            Διαγραφή Χρήστη
          </button>
        )}
        <DeleteUserModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          handleDeleteUser={handleDeleteUser}
        />
      </div>
    </div>
  );
};

export default Profile;
