import { axiosPrivate } from '../../api/axios';
import { useState } from 'react';
// import ProthCard from './ProthCard';

const removeZeroes = (str) => {
  let res = str
  while (res.at(0) === '0') {
    res = res.replace('0', '')
  }
  return res
}
const MultiEauctionCard = ({ singleEauction }) => {
  // const [showProth, setShowProth] = useState(false)
  // const [checkAppeal, setCheckAppeal] = useState(singleEauction.check_appeal === 1 ? true : false)
  // console.log(prothesmiesSingle);
  console.log(singleEauction);


  return (
    <article className='case_card'>

      {singleEauction?.katastasi?.length > 2 && <h4><em>Κατάσταση: </em>{singleEauction?.katastasi}</h4 >}
      {singleEauction?.imerominia_diexagogis?.length > 2 && <h4><em>Ημ/νία Διεξαγωγής: </em>{singleEauction?.imerominia_diexagogis}</h4 >}
      {singleEauction?.imerominia_anartisis?.length > 2 && <h4><em>Ημ/νία Ανάρτησης: </em>{singleEauction?.imerominia_anartisis}</h4 >}
      {singleEauction?.timi_1is?.length > 2 && <h4><em>Τιμή 1ης Προσφοράς: </em>{singleEauction?.timi_1is}</h4 >}
      {singleEauction?.eidos?.length > 2 && <h4><em>Είδος προς Πλειστηριασμό: </em>{singleEauction?.eidos}</h4 >}
      {singleEauction?.xaraktiristika?.length > 2 && <h4><em>Χαρακτηριστικά: </em>{singleEauction?.xaraktiristika}</h4 >}
      {singleEauction?.onoma_ofeileti?.length > 2 && <h4><em>Ονοματεπώνυμα Οφειλετών: </em>{singleEauction?.onoma_ofeileti}</h4 >}
      {singleEauction?.afm_ofeileti?.length > 2 && <h4><em>ΑΦΜ Οφειλετών: </em>{singleEauction?.afm_ofeileti}</h4 >}
      {singleEauction?.epispevdon?.length > 2 && <h4><em>Επισπεύδων: </em>{singleEauction?.epispevdon}</h4 >}
      {singleEauction?.afm_epispevdon?.length > 2 && <h4><em>ΑΦΜ Επισπεύδοντα
        : </em>{singleEauction?.afm_epispevdon}</h4 >}
      {singleEauction?.imerominia_dimosiefsis?.length > 2 && <h4><em>Ημ/νία Δημοσίευσης: </em>{singleEauction?.imerominia_dimosiefsis}</h4 >}




    </article >
  )
}

export default MultiEauctionCard