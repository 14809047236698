import { Text, Callout, Bold, Flex, Button, Divider } from "@tremor/react";
import { returnDetail } from "../../utils/reverseDate";
import { Link } from "react-router-dom";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";
import { getAdjCourt } from "../../utils/getAdjCourts";

export const DateDetails = ({ singleCase, r }) => {
  console.log(singleCase);
  if (
    !r.case_id.toString().includes("o") &&
    !singleCase?.topos &&
    !singleCase?.arkat &&
    !r.case_id.toString().includes("e")
  ) {
    return (
      <>
        {singleCase?.note?.length > 0 && (
          <>
            <Callout title={singleCase.note} color="emerald" className="mt-6">
              {singleCase.gak}/{singleCase.year} {singleCase.court}
            </Callout>
          </>
        )}
        {!singleCase?.note?.length > 0 && (
          <Callout
            title={`${singleCase.gak}/${singleCase.year} ${singleCase.court}`}
            color="emerald"
            className="mt-6"
          />
        )}

        <Text className="mt-2">
          <Bold>{returnDetail(r.code)}</Bold>
        </Text>
        <Flex justifyContent="end">
          <Link to={`/cases/${r.case_id}`}>
            <Button
              className="mt-1"
              size="xs"
              color="emerald"
              icon={ArrowCircleRightIcon}
            >
              Αναλυτικά
            </Button>
          </Link>
        </Flex>

        <Divider className="mt-1" />
      </>
    );
  }
  if (r.case_id.toString().includes("o") || singleCase?.topos) {
    return (
      <>
        {singleCase?.note?.length > 0 && (
          <>
            <Callout title={singleCase.note} color="emerald" className="mt-6">
              {singleCase.dikastirio} {singleCase.topos}
            </Callout>
          </>
        )}
        {!singleCase?.note?.length > 0 && (
          <Callout
            title={`${singleCase.dikastirio} ${singleCase.topos}`}
            color="emerald"
            className="mt-6"
          />
        )}

        <Text className="mt-2">
          <Bold>{returnDetail(r.code)}</Bold>
        </Text>
        <Flex justifyContent="end">
          <Link to={`/outsolon/${r.case_id.toString().replace("o", "")}`}>
            <Button
              className="mt-1"
              size="xs"
              color="emerald"
              icon={ArrowCircleRightIcon}
            >
              Αναλυτικά
            </Button>
          </Link>
        </Flex>

        <Divider className="mt-1" />
      </>
    );
  }
  if (singleCase.arkat) {
    return (
      <>
        {singleCase?.note?.length > 0 && (
          <>
            <Callout title={singleCase.note} color="amber" className="mt-6">
              {getAdjCourt(singleCase.court)} {singleCase.arkat}
            </Callout>
          </>
        )}
        {!singleCase?.note?.length > 0 && (
          <Callout
            title={`${getAdjCourt(singleCase.court)} ${singleCase.arkat}`}
            color="amber"
            className="mt-6"
          />
        )}

        <Text className="mt-2">
          <Bold>Δικάσιμος</Bold>
        </Text>
        <Flex justifyContent="end">
          <Link to={`/adjustice/${r.case_id.toString().replace("a", "")}`}>
            <Button
              className="mt-1"
              size="xs"
              color="amber"
              icon={ArrowCircleRightIcon}
            >
              Αναλυτικά
            </Button>
          </Link>
        </Flex>

        <Divider className="mt-1" />
      </>
    );
  }
  if (singleCase.auction_dates || r.case_id.toString().includes("e")) {
    return (
      <>
        {singleCase?.note?.length > 0 && (
          <>
            <Callout title={singleCase.note} color="purple" className="mt-6" />
          </>
        )}

        <Text className="mt-2">
          <Bold>Πλειστηριασμός</Bold>
        </Text>
        <Flex justifyContent="end">
          <Link to={`/eauction`}>
            <Button
              className="mt-1"
              size="xs"
              color="purple"
              icon={ArrowCircleRightIcon}
            >
              Αναλυτικά
            </Button>
          </Link>
        </Flex>

        <Divider className="mt-1" />
      </>
    );
  }
};
