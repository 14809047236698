import axios from "axios";
let BASE_URL = "/api";
if (import.meta.env.DEV) {
  BASE_URL = "http://localhost:4500/api";
}

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
