import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get('/refresh', {
      withCredentials: true
    });
    setAuth(prev => {
      // console.log(JSON.stringify(prev));
      // console.log(response.data.accessToken);
      return {
        ...prev,
        accessToken: response.data.accessToken,
        user: response.data.email,
        role: response.data.role,
        uuid: response.data.uuid,
        avatar: response.data.avatar,
        id: response.data.id
      }
    });
    return response.data.accessToken;
  }
  return refresh;
};

export default useRefreshToken;