import React, { useEffect, useState, useMemo } from "react";
import { createAvatar } from "@dicebear/core";
import { miniavs } from "@dicebear/collection";
import { Icon } from "@tremor/react";
import { AcademicCapIcon, BadgeCheckIcon } from "@heroicons/react/solid";

const HeaderAvatar = ({
  avatar,
  avatarUploaded,
  userAvatar,
  avatarClass,
  uuid,
  badge,
  rank,
}) => {
  const avatarDiceLocal = useMemo(() => {
    return createAvatar(miniavs, {
      size: 96,
      seed: uuid,
      backgroundType: ["gradientLinear", "solid"],
      backgroundColor: ["b6e3f4", "c0aede", "d1d4f9"],
      // ... other options
    }).toDataUriSync();
  }, [uuid]);

  const [imgSrc, setImgSrc] = useState(avatarDiceLocal);
  const avatarClassMap = {
    sm: "dicebear-sm",
    md: "dicebear-md",
    lg: "dicebear-lg",
    smd: "dicebear-smd",
  };
  const iconClassMap = {
    sm: "xs",
    smd: "xs",
    md: "md",
    lg: "lg",
  };

  useEffect(() => {
    if (avatarUploaded || userAvatar) {
      setImgSrc(`/assets/${userAvatar}-lg.jpg`);
    } else {
      setImgSrc(avatarDiceLocal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatarUploaded, userAvatar]);

  return (
    <>
      <div className="avatar-container">
        <img
          src={imgSrc}
          alt="Avatar"
          className={avatarClassMap[avatarClass]}
          title="This is your avatar"
        />
        {badge &&
          rank !== "askoumenos" &&
          (avatarClass === "md" ||
            avatarClass === "smd" ||
            avatarClass === "lg") && (
            <Icon
              icon={BadgeCheckIcon}
              size={iconClassMap[avatarClass]}
              className={`verified-${avatarClass}`}
              tooltip="Επιβεβαιωμένη Δικηγορική Ιδιότητα"
            />
          )}
        {badge &&
          rank === "askoumenos" &&
          (avatarClass === "md" ||
            avatarClass === "smd" ||
            avatarClass === "lg") && (
            <Icon
              icon={AcademicCapIcon}
              size={iconClassMap[avatarClass]}
              color="yellow"
              className={`verified-${avatarClass}`}
              tooltip="Ασκούμενος Δικηγόρος"
            />
          )}
      </div>
    </>
  );
};

export default HeaderAvatar;
