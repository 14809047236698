import React from 'react'

const EirNikaias = ({ singleCase }) => {
  const data = singleCase?.data && JSON.parse(singleCase?.data)

  if (singleCase?.eidos === 'epanaprosd') {
    return (
      <>
        {(singleCase?.data && singleCase?.eidos === 'epanaprosd') && <>
          <h4><em>Διαδικασία: </em>Επαναπροσδιορισμός</h4 >
          <h4><em>Κώδικας Πλατφόρμας: </em>{data.codeplatform}</h4 >
          <h4><em>Ημ/νία Κατάθεσης: </em>{data.imniakatath}</h4 >
          <h4><em>Νέο ΕΑΚ: </em>{data.neaeak}</h4 >
          <h4><em>Αρχική Αίτηση: </em>{data.arxikiaitisi}</h4 >
          <h4><em>Ημ/νία Δικασίμου: </em>{data.imniadikasimou}</h4 ></>}
      </>
    )

  }
  if (singleCase?.eidos === 'diatages') {
    return (
      <>
        {(singleCase?.data && singleCase?.eidos === 'diatages') && <>
          <h4><em>Διαδικασία: </em>{data.diadikasia}</h4 >
          <h4><em>Αρ.Κατάθεσης: </em>{data.arkat}</h4 >
          <h4><em>Ημ/νία Δημοσίευσης: </em>{data.imniadimosiefsis}</h4 >
          <h4><em>Αρ. Διαταγής: </em>{data.arapof}</h4 >
          <h4><em>Απότελεσμα: </em>{data.apotelesma}</h4 >
          <h4><em>Αιτών/Ενάγων: </em>{data.aiton}</h4 >
          <h4><em>Καθ'ου/Ενάγων: </em>{data.kathou}</h4 ></>}
        {(singleCase?.data && !data.ardimosiefsis) &&

          <>
            <h4><em>Αρ. Κατάθεσης: </em>{data.arkat}</h4 ><h4>Δεν έχει εκδοθεί απόφαση έως τώρα</h4 >
          </>}
      </>
    )

  }
  if (singleCase?.eidos === 'dimosiefseis') {
    return (
      <>
        {(singleCase?.data && singleCase?.eidos === 'dimosiefseis') && <>
          <h4><em>Διαδικασία: </em>{data.diadikasia}</h4 >
          <h4><em>Αρ.Κατάθεσης: </em>{data.arkat}</h4 >
          <h4><em>Ημ/νία Δικασίμου: </em>{data.imniadikasimou}</h4 >
          <h4><em>Ημ/νία Δημοσίευσης: </em>{data.imniadimosiefsis}</h4 >
          <h4><em>Αρ. Απόφασης: </em>{data.arapof}</h4 >
          <h4><em>Λοιποί Αριθμοί: </em>{data.loipoiarithmoi}</h4 >
          <h4><em>Αιτών/Ενάγων: </em>{data.aiton}</h4 >
          <h4><em>Καθ'ου/Ενάγων: </em>{data.kathou}</h4 ></>}
        {(singleCase?.data && !data.ardimosiefsis) &&

          <>
            <h4><em>Αρ. Κατάθεσης: </em>{data.arkat}</h4 ><h4>Δεν έχει εκδοθεί απόφαση έως τώρα</h4 >
          </>}
      </>
    )

  }

}

export default EirNikaias