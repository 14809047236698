import React, { useEffect } from "react";
import { axiosPrivate } from "../../api/axios";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";

const Administrator = () => {
  const [emails, setEmails] = React.useState([]);
  const [extraInfo, setExtraInfo] = React.useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getEmails = async () => {
      try {
        const response = await axiosPrivate.get("/admin", {
          signal: controller.signal,
        });
        isMounted && setEmails(response.data.emails);
        isMounted && setExtraInfo(response.data.extraInfo);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getEmails();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <h1>Administrator</h1>

      <ol>
        {emails.map((item) => (
          <li key={item}>
            {item.email} - {item.count}
          </li>
        ))}
      </ol>
      <ol>
        {extraInfo.map((item) => (
          <li key={item.uuid}>
            <Link to={`/profile/${item.uuid}`}>{item.email}</Link>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Administrator;
