import React from "react";
import { eirinodikeia as eirinUnfilt } from "../Eirinodikeia";
import { XCircleIcon } from "@heroicons/react/solid";
import {
  MultiSelect,
  MultiSelectItem,
  Icon,
  List,
  ListItem,
} from "@tremor/react";
import { removeDiacriticsAndCapitalize } from "../../../utils/checkMisthosi";

const ChooseEirin = ({ setEirinSelected, eirinSelected }) => {
  const eirin = eirinUnfilt
    .map((dim) => removeDiacriticsAndCapitalize(dim))
    .sort();
  const handleDeleteListItem = (e) => {
    const value =
      e.target.parentNode.parentNode.parentNode.firstChild.textContent;

    const newEirinSelected = eirinSelected.filter((dim) => dim !== value);
    setEirinSelected(newEirinSelected);
  };

  return (
    <div className="form-group">
      <label htmlFor="eirinodikeio">Ειρηνοδικεία:</label>
      <MultiSelect
        placeholder="Επιλέξτε Ειρηνοδικείο"
        onValueChange={setEirinSelected}
        value={eirinSelected}
      >
        {eirin.map((dim, i) => {
          return (
            <MultiSelectItem key={`${i}-${dim}`} value={dim}>
              {dim}
            </MultiSelectItem>
          );
        })}
      </MultiSelect>
      <List>
        {eirinSelected?.map((dim, i) => {
          return (
            <ListItem className="dimoi-list" key={`${i}-${dim}`}>
              <span>{dim}</span>
              <span>
                <Icon
                  onClick={handleDeleteListItem}
                  className={"cursor-pointer"}
                  icon={XCircleIcon}
                  size={"sm"}
                  color="red"
                  tooltip="Διαγραφή"
                ></Icon>
              </span>
              <ListItem />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ChooseEirin;
