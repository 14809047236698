import React, { useEffect, useContext, useState, useRef } from "react";
import { nanoid } from "nanoid";
import axios from "axios";
import { DateTime, Settings } from "luxon";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import el from "javascript-time-ago/locale/el";
import SimplePost from "./Social/SimplePost";
import { eidi, eidiMap, eidiToEngMap, eidiToGrMap } from "./Social/Ekloges";
import { useInterval } from "../utils/useInterval";
import AuthContext from "../context/AuthProvider";
import { is, te } from "date-fns/locale";

const RotatingElements = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPostingViews, setIsPostingViews] = React.useState(false);
  const [initialPosts, setInitialPosts] = React.useState([]);
  const [posts, setPosts] = React.useState([]);
  const [filteredPosts, setFilteredPosts] = React.useState([]);
  const { avatarUploaded, notifLength } = React.useContext(AuthContext);
  const [activeUuid, setActiveUuid] = React.useState(filteredPosts[0]?.uuid);

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const postViewCounter = async (isMounted, controller) => {
    try {
      console.log("postViewCounter");

      const allViewsFromLocalStorage = getPostsFromLocalStorage();
      if (allViewsFromLocalStorage.length === 0) return;
      setIsPostingViews(true);

      const res = await axiosPrivate.post(
        "/viewCounter",
        {
          viewCounts: allViewsFromLocalStorage,
        },
        { signal: controller.signal }
      );
      // console.log(res.data);
      isMounted && setIsPostingViews(false);
      isMounted && markPostsAsSent(allViewsFromLocalStorage);
    } catch (err) {
      console.error(err);
      if (!axios.isCancel(err)) {
        // do something
        navigate("/login", { state: { from: location }, replace: true });
      }
    }
  };

  useInterval(async () => {
    if (isPostingViews) return;
    let isMounted = true;
    const controller = new AbortController();
    await postViewCounter(isMounted, controller);
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, 1000);
  const getPostsFromLocalStorage = () => {
    const posts = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith("post-")) {
        const item = localStorage.getItem(key);
        if (item === "sent") continue;
        posts.push({
          [key]: item,
        });
      }
    }
    return posts;
  };

  const markPostsAsSent = (posts) => {
    posts.forEach((post) => {
      const key = Object.keys(post)[0];
      localStorage.setItem(key, "sent");
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex + 1) % filteredPosts.length);
      setActiveUuid(
        filteredPosts[(activeIndex + 1) % filteredPosts.length].uuid
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [activeIndex, filteredPosts.length]);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getPosts = async () => {
      try {
        const response = await axiosPrivate.get("/posts/all", {
          signal: controller.signal,
        });
        console.log(response.data);
        isMounted &&
          setInitialPosts((prevPosts) => [...prevPosts, ...response.data]);
        isMounted && setPosts((prevPosts) => [...prevPosts, ...response.data]);
        const tempFilteredPosts = response.data
          .filter((post, index, self) => {
            return index === self.findIndex((t) => t.uuid === post.uuid);
          })
          .filter((post) => {
            return post.eidos && post.body !== "";
          })
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          })
          .sort((a, b) => {
            return b.pinned - a.pinned;
          });

        isMounted && setFilteredPosts(tempFilteredPosts);
        isMounted && setActiveUuid(tempFilteredPosts[0]?.uuid);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getPosts();

    // const interval = setInterval(() => {
    //   getProthesmies()
    // }, 10000)

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifLength]);

  return (
    <>
      {" "}
      <div className="rotating-elements">
        {filteredPosts.map((element, index) => (
          <div
            key={`element-${index}`}
            className={`rotating-element ${
              index === activeIndex ? "active-rotating" : ""
            }`}
            hidden={index !== activeIndex}
          >
            <SimplePost
              {...element}
              post={element}
              activeUuid={activeUuid}
              avatar={element.user?.avatar_uuid}
              uuid={element.user?.uuid}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default RotatingElements;
