import { axiosPrivate } from '../../api/axios';
import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import TimeAgo from 'javascript-time-ago'
import el from 'javascript-time-ago/locale/el'
// import ProthCard from './ProthCard';

import '../Etairies/etairies.css'
import { Link } from 'react-router-dom';
TimeAgo.addLocale(el)
const timeAgo = new TimeAgo('el-GR')


const EtairiaSmallCard = ({ singleEtairia, handleDeleteEtairia }) => {
  // const [showProth, setShowProth] = useState(false)
  // const [checkAppeal, setCheckAppeal] = useState(singleEtairia.check_appeal === 1 ? true : false)
  // console.log(prothesmiesSingle);
  console.log(singleEtairia);


  const handleDelete = async (e) => {

    try {
      const id = e.currentTarget.getAttribute('data-key')
      // console.log(JSON.stringify({ key: id }));
      await axiosPrivate.patch('/etairies',
        JSON.stringify({ key: id }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      handleDeleteEtairia(id)

    } catch (err) {
      console.error(err);
    }
  }

  console.log(singleEtairia);
  return (
    <article className='etairia_small_card'>
      {/* <div key={`ed${singleCase.id}`} className="edit_case"><p ><span onClick={handleDelete} data-key={singleCase.id}>✏️</span></p></div> */}
      <div className='etairia-upper-part'>
        <div key={singleEtairia.id} className="close_case"><p ><span style={{ border: 'none', cursor: 'pointer', color: 'red', backgroundColor: 'white', paddingLeft: '5px', fontSize: "1.3em", }} title='Διαγράψτε την υπόθεση' onClick={handleDelete} data-key={singleEtairia.id}><FontAwesomeIcon icon={faTrashCan} /></span></p></div>


        {singleEtairia?.eponymia?.length > 2 && <h4 className='EPONYMIA'>{singleEtairia?.eponymia}</h4 >}
        {singleEtairia?.afm?.length > 2 && <h4 className='AFM'>ΑΦΜ {singleEtairia?.afm} | Αρ.ΓΕΜΗ {singleEtairia?.argemi}</h4 >}
      </div>
      <div className='etairia-lower-part'>
        {singleEtairia?.business_last_checked && <p className='etairia-checked'>Ελέχθηκε {timeAgo.format(new Date(singleEtairia?.business_last_checked))}<span className='span-checked-fa'><FontAwesomeIcon className='fa-border checked-fa' title='Ενημερώσεις' icon={faCircleCheck} /></span></p>}
        <Link to={`/etairies/${singleEtairia?.afm}/gemi`} className='addcase_modal analytika' >Αναλυτικά</Link>
      </div>
    </article >
  )
}

export default EtairiaSmallCard