import React from "react";

import { StatusOnlineIcon } from "@heroicons/react/outline";
import {
  Card,
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
  TableCell,
  Text,
  Title,
  Badge,
} from "@tremor/react";

const Diakinisi = ({ data }) => {
  return (
    <Card className="mt-3">
      <Title>Διακίνηση</Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Κίνηση</TableHeaderCell>
            <TableHeaderCell>Κωδικός</TableHeaderCell>
            <TableHeaderCell>Τμήμα Από</TableHeaderCell>
            <TableHeaderCell>Ημ/νία</TableHeaderCell>
            <TableHeaderCell>Τμήμα Προς</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, i) => (
            <TableRow key={`Diakinisi-${i}`}>
              <TableCell>{item.typos_kinisis}</TableCell>
              <TableCell>
                <Text>{item.kodikos_kinisis}</Text>
              </TableCell>
              <TableCell>{item.tmima_apo}</TableCell>
              <TableCell>{item.imnia_apostolis}</TableCell>
              <TableCell>{item.tmima_pros}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default Diakinisi;
