import { TabGroup, TabList, Tab } from "@tremor/react";
import {
  CheckIcon,
  CollectionIcon,
  ReceiptRefundIcon,
} from "@heroicons/react/outline";

const ToggleView = ({ handleSelect }) => (
  <>
    <TabGroup defaultIndex={2} onIndexChange={handleSelect} className="w-fit">
      <TabList className="mt-8" variant="solid">
        <Tab icon={CheckIcon}>Με απόφαση</Tab>
        <Tab icon={ReceiptRefundIcon}>Χωρίς Απόφαση</Tab>
        <Tab icon={CollectionIcon}>Όλες</Tab>
      </TabList>
    </TabGroup>
  </>
);

export default ToggleView;
