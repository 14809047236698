export const dikastiria = [
  {
    efeteio: "Αθηνών",
    protovathmia: [
      {
        protodikeio: "Αθηνών",
        eirinodikeia: [
          "Αθηνών",
          "Αμαρoυσίoυ",
          "Αχαρνών",
          "Ελευσίνας",
          "Καλλιθέας",
          "Κρωπίας",
          "Λαυρίoυ",
          "Ν. Iωνίας",
          "Ν. Λιoσίων",
          "Μαραθώνoς",
          "Μεγάρων",
          "Περιστερίoυ",
          "Χαλανδρίoυ",
        ],
      },
    ],
  },
  {
    efeteio: "Εύβοιας (Χαλκίδα)",
    protovathmia: [
      {
        protodikeio: "Θηβών",
        eirinodikeia: ["Θηβών", "Ερυθρών", "Θεσπιών", "Θίσβης"],
      },
      {
        protodikeio: "Χαλκίδας",
        eirinodikeia: [
          "Χαλκίδας",
          "Αγίας Άννας",
          "Αυλωναρίου",
          "Ιστιαίας",
          "Καρύστου",
          "Κύμης",
          "Λίμνης",
          "Σκύρου",
          "Ταμινέων",
        ],
      },
    ],
  },
  {
    efeteio: "Αιγαίου (Σύρος)",
    protovathmia: [
      {
        protodikeio: "Σύρου",
        eirinodikeia: [
          "Σύρου",
          "Άνδρου",
          "Ερμούπολης",
          "Κύθνου",
          "Μήλου",
          "Μυκόνου",
          "Πάρου",
          "Σερίφου",
          "Σίφνου",
          "Τήνου",
        ],
      },
      {
        protodikeio: "Σάμου",
        eirinodikeia: ["Σάμου", "Ευδήλου", "Ικαρίας", "Καρλοβασίου"],
      },
      {
        protodikeio: "Νάξου",
        eirinodikeia: ["Νάξου", "Αμοργού", "Θήρας", "Ίου", "Τραγαίας"],
      },
    ],
  },
  {
    efeteio: "Βορείου Αιγαίου (Μυτιλήνη)",
    protovathmia: [
      {
        protodikeio: "Μυτιλήνης",
        eirinodikeia: [
          "Μυτιλήνης",
          "Ερεσσού",
          "Καλλονής",
          "Παπάδου",
          "Πλωμαρίου",
          "Πολυχνίτου",
        ],
      },
      {
        protodikeio: "Χίου",
        eirinodikeia: ["Χίου", "Βολισσού"],
      },
    ],
  },
  {
    efeteio: "Δυτικής Μακεδονίας (Κοζάνη)",
    protovathmia: [
      {
        protodikeio: "Κοζάνης",
        eirinodikeia: [
          "Κοζάνης",
          "Ανασελίτσης",
          "Εορδαίας",
          "Σερβίων",
          "Σιατίστης",
        ],
      },
      {
        protodikeio: "Γρεβενών",
        eirinodikeia: ["Γρεβενών", "Δεσκάτης", "Πενταλόφου"],
      },
      {
        protodikeio: "Καστοριάς",
        eirinodikeia: ["Καστοριάς", "Νεστορίου", "Κλεισούρας"],
      },
      {
        protodikeio: "Φλώρινας",
        eirinodikeia: ["Φλώρινας", "Αμυνταίου", "Πρεσπών"],
      },
    ],
  },
  {
    efeteio: "Δωδεκανήσου (Ρόδος)",
    protovathmia: [
      {
        protodikeio: "Ρόδου",
        eirinodikeia: ["Ρόδου", "Καρπάθου", "Σύμης", "Μεγίστης"],
      },
      {
        protodikeio: "Κω",
        eirinodikeia: [
          "Κω",
          "Καλύμνου",
          "Λέρου",
          "Πάτμου",
          "Αστυπάλαιας",
          "Νισύρου",
        ],
      },
    ],
  },
  {
    efeteio: "Θεσσαλονίκης",
    protovathmia: [
      {
        protodikeio: "Θεσσαλονίκης",
        eirinodikeia: [
          "Θεσσαλονίκης",
          "Βασιλικών",
          "Κουφαλίων",
          "Λαγκαδά",
          "Σωχού",
        ],
      },
      {
        protodikeio: "Βέροιας",
        eirinodikeia: ["Βέροιας", "Αλεξάνδρειας", "Νάουσας"],
      },
      {
        protodikeio: "Έδεσσας",
        eirinodikeia: ["Έδεσσας", "Αλμωπίας", "Σκύδρας"],
      },
      {
        protodikeio: "Κατερίνης",
        eirinodikeia: ["Πιερίας", "Κολινδρού"],
      },
      {
        protodikeio: "Κιλκίς",
        eirinodikeia: ["Κιλκίς", "Γουμένισσας", "Μουριών", "Πολυκάστρου"],
      },
      {
        protodikeio: "Σερρών",
        eirinodikeia: [
          "Σερρών",
          "Ηράκλειας",
          "Νιγρίτας",
          "Πορροΐων",
          "Ροδολίβους",
          "Συντίκης",
          "Φυλλίδος",
        ],
      },
      {
        protodikeio: "Χαλκιδικής",
        eirinodikeia: [
          "Πολυγύρου",
          "Αρναίας",
          "Ιερισσού",
          "Κασσάνδρας",
          "Ν.Μουδανιών",
          "Συκιάς",
        ],
      },
      {
        protodikeio: "Γιαννιτσών",
        eirinodikeia: ["Γιαννιτσών", "Κρύας Βρύσης"],
      },
    ],
  },
  {
    efeteio: "Θράκης (Κομοτηνή)",
    protovathmia: [
      { protodikeio: "Ροδόπης", eirinodikeia: ["Κομοτηνής", "Σαππών"] },
      {
        protodikeio: "Δράμας",
        eirinodikeia: ["Δράμας", "Νευροκοπίου", "Νέστου", "Προσωτσάνης"],
      },
      {
        protodikeio: "Έβρου",
        eirinodikeia: ["Αλεξανδρούπολης", "Σαμοθράκης", "Σουφλίου"],
      },
      {
        protodikeio: "Καβάλας",
        eirinodikeia: ["Καβάλας", "Θάσου", "Παγγαίου", "Χρυσούπολη"],
      },
      {
        protodikeio: "Ξάνθης",
        eirinodikeia: ["Ξάνθης", "Σταυρούπολης"],
      },
      {
        protodikeio: "Ορεστιάδας",
        eirinodikeia: ["Ορεστιάδας", "Διδυμοτείχου", "Δικαίων"],
      },
    ],
  },
  {
    efeteio: "Ιωαννίνων",
    protovathmia: [
      {
        protodikeio: "Ιωαννίνων",
        eirinodikeia: [
          "Ιωαννίνων",
          "Ζαγορίου",
          "Ζίτσης",
          "Κονίτσης",
          "Μετσόβου",
          "Πωγωνίου",
        ],
      },
      {
        protodikeio: "Άρτας",
        eirinodikeia: [
          "Άρτας",
          "Καλεντίνης",
          "Τζουμέρκων",
          "Φιλιππιάδας",
          "Δροσοπηγής",
        ],
      },
      {
        protodikeio: "Πρέβεζας",
        eirinodikeia: ["Πρέβεζας", "Θεσπρωτικού", "Πάργας"],
      },
    ],
  },
  {
    efeteio: "Κέρκυρας",
    protovathmia: [
      {
        protodikeio: "Κέρκυρας",
        eirinodikeia: ["Κέρκυρας", "Λευκίμης", "Παξών"],
      },
      {
        protodikeio: "Θεσπρωτίας",
        eirinodikeia: ["Ηγουμενίτσας", "Παραμυθιάς", "Φιλιατών"],
      },
    ],
  },
  {
    efeteio: "Κρήτης (Χανιά)",
    protovathmia: [
      {
        protodikeio: "Χανίων",
        eirinodikeia: [
          "Χανίων",
          "Βάμου",
          "Καντάνου",
          "Καστελίου-Κισσάμου",
          "Κολυμβαρίου",
          "Χώρας Σφακίων",
        ],
      },
      {
        protodikeio: "Ρεθύμνης",
        eirinodikeia: ["Ρεθύμνης", "Αμαρίου", "Μυλοποτάμου", "Σπηλίου"],
      },
    ],
  },
  {
    efeteio: "Ανατολικής Κρήτης (Ηράκλειο)",
    protovathmia: [
      {
        protodikeio: "Ηρακλείου",
        eirinodikeia: [
          "Ηρακλείου",
          "Αρκαλοχωρίου",
          "Βιάννου",
          "Καστελίου-Πεδιάδος",
          "Μοιρών",
          "Πύργου Κρήτης",
          "Χερσονήσσου",
        ],
      },
      {
        protodikeio: "Λασιθίου",
        eirinodikeia: [
          "Αγίου Νικολάου",
          "Νεαπόλεως",
          "Ιεράπετρας",
          "Σητείας",
          "Τζερμιάδων",
        ],
      },
    ],
  },
  {
    efeteio: "Λαμίας",
    protovathmia: [
      {
        protodikeio: "Λαμίας",
        eirinodikeia: [
          "Λαμίας",
          "Αταλάντης",
          "Αμφίκλειας",
          "Δομοκού",
          "Ελάτειας",
          "Θερμοπυλών",
          "Μακρακώμης",
          "Σπερχειάδας",
          "Υπάτης",
          "Φαλάρων",
        ],
      },
      {
        protodikeio: "Άμφισσας",
        eirinodikeia: [
          "Άμφισσας",
          "Δελφών",
          "Δωρίδος",
          "Δωριέων",
          "Ευπαλίου",
          "Ιτέας",
          "Τολοφώνος",
        ],
      },
      {
        protodikeio: "Ευρυτανίας",
        eirinodikeia: ["Ευρυτανίας", "Αγραίων", "Απεραντίων"],
      },
      {
        protodikeio: "Λιβαδειάς",
        eirinodikeia: [
          "Λιβαδειάς",
          "Αλιάρτου",
          "Αμβρυσσού",
          "Αράχωβας",
          "Δαυλείας",
          "Ορχομενού",
        ],
      },
    ],
  },
  {
    efeteio: "Λάρισας",
    protovathmia: [
      {
        protodikeio: "Λάρισας",
        eirinodikeia: [
          "Λάρισας",
          "Ελασσόνας",
          "Αγυιάς",
          "Κισσάβου",
          "Ολύμπου",
          "Τυρνάβου",
          "Φαρσάλων",
        ],
      },
      {
        protodikeio: "Βόλου",
        eirinodikeia: [
          "Βόλου",
          "Αλμυρού",
          "Αργαλαστής",
          "Ζαγοράς",
          "Μηλέων",
          "Σκιάθου",
          "Σκοπέλου",
          "Φερών",
        ],
      },
      {
        protodikeio: "Καρδίτσας",
        eirinodikeia: ["Καρδίτσας", "Μουζακίου", "Παλαμά", "Σοφάδων"],
      },
      {
        protodikeio: "Τρικάλων",
        eirinodikeia: ["Τρικάλων", "Καλαμπάκας", "Πύρρας", "Φαρκαδόνος"],
      },
    ],
  },
  {
    efeteio: "Ναυπλίου",
    protovathmia: [
      {
        protodikeio: "Ναυπλίου",
        eirinodikeia: [
          "Ναυπλίου",
          "Άργους",
          "Άστρους",
          "Μάσσητος",
          "Ν.Επιδαύρου",
          "Πρασσιών",
        ],
      },
      {
        protodikeio: "Κορίνθου",
        eirinodikeia: [
          "Κορίνθου",
          "Σικυώνος",
          "Δερβενίου",
          "Νεμέας",
          "Ξυλοκάστρου",
          "Στυμφαλίας",
          "Φενεού",
        ],
      },
      {
        protodikeio: "Σπάρτης",
        eirinodikeia: ["Σπάρτης", "Επιδαύρου-Λιμηράς", "Καστορίου", "Κροκεών"],
      },
      {
        protodikeio: "Τρίπολης",
        eirinodikeia: [
          "Τρίπολης",
          "Καρυταίνης",
          "Μεγαλόπολης",
          "Νυμφασίας",
          "Ορχομενού",
          "Τανίας Δολανών",
          "Τευθίδος",
          "Τροπαίων",
          "Ψωφίδος",
        ],
      },
    ],
  },
  {
    efeteio: "Πατρών",
    protovathmia: [
      {
        protodikeio: "Πατρών",
        eirinodikeia: ["Πατρών", "Δύμης", "Τριταίας", "Φαρρών"],
      },
      {
        protodikeio: "Αιγίου",
        eirinodikeia: ["Αιγιαλείας", "Ερινεού"],
      },
      {
        protodikeio: "Αμαλιάδας",
        eirinodikeia: [
          "Αμαλιάδας",
          "Βάρδας",
          "Βαρθολομιού",
          "Γαστούνης",
          "Μυρτουντίων",
        ],
      },
      {
        protodikeio: "Ζακύνθου",
        eirinodikeia: ["Ζακύνθου"],
      },
      {
        protodikeio: "Ηλείας",
        eirinodikeia: [
          "Πύργου",
          "Ελευσίνας Πελοποννήσου",
          "Κρεστένων",
          "Λαμπείας",
          "Ολυμπίων",
          "Ωλένης",
          "Ανδρίτσαινης",
          "Αρήνης",
        ],
      },
      {
        protodikeio: "Καλαβρύτων",
        eirinodikeia: ["Καλαβρύτων", "Ακράτας", "Αροανίας", "Κλειτορίας"],
      },
      {
        protodikeio: "Κεφαλληνίας",
        eirinodikeia: ["Αργοστολίου", "Ιθάκης", "Ληξουρίου", "Σαμαίων"],
      },
    ],
  },
  {
    efeteio: "Δυτικής Στερεάς Ελλάδας (Αγρίνιο)",
    protovathmia: [
      {
        protodikeio: "Αγρινίου",
        eirinodikeia: ["Αγρινίου", "Βάλτου", "Εχίνου", "Θέρμου"],
      },
      {
        protodikeio: "Λευκάδας",
        eirinodikeia: ["Λευκάδας", "Απολλωνίων", "Βονίτσας", "Σολίου"],
      },
      {
        protodikeio: "Μεσολογίου",
        eirinodikeia: [
          "Μεσολογίου",
          "Αιτωλικού",
          "Αποδοτίας",
          "Μακρυνείας",
          "Ναυπάκτου",
          "Ξηρομέρου",
          "Παραχελωΐτιδος",
          "Προσχίου",
        ],
      },
    ],
  },
  {
    efeteio: "Πειραιά",
    protovathmia: [
      {
        protodikeio: "Πειραιά",
        eirinodikeia: [
          "Πειραιά",
          "Αιγίνης",
          "Καλαυρίας",
          "Κυθήρων",
          "Νίκαιας",
          "Σαλαμίνας",
          "Σπετσών",
        ],
      },
    ],
  },
  {
    efeteio: "Καλαμάτας",
    protovathmia: [
      {
        protodikeio: "Καλαμάτας",
        eirinodikeia: ["Καλαμάτας", "Οιχαλίας", "Παμίσου", "Πύλου"],
      },
      {
        protodikeio: "Κυπαρισσίας",
        eirinodikeia: [
          "Κυπαρισσίας",
          "Δωρίου",
          "Εράνης",
          "Πλαταμώδους",
          "Φλεσιάδος",
          "Φυγαλείας",
        ],
      },
      {
        protodikeio: "Γυθείου",
        eirinodikeia: ["Γυθείου", "Νεαπόλεως Βοιών"],
      },
    ],
  },
];

export function getAllEirinodikeia() {
  const eirinodikeiaArray = [];

  dikastiria.forEach((efeteioObj) => {
    efeteioObj.protovathmia.forEach((protovathmiaObj) => {
      eirinodikeiaArray.push(...protovathmiaObj.eirinodikeia);
    });
  });

  return eirinodikeiaArray;
}

export function getAllProtodikeia() {
  const protodikeiaArray = [];

  dikastiria.forEach((efeteioObj) => {
    efeteioObj.protovathmia.forEach((protovathmiaObj) => {
      protodikeiaArray.push(protovathmiaObj.protodikeio);
    });
  });

  return protodikeiaArray;
}

export function getAllEfeteia() {
  const efeteiaArray = [];

  dikastiria.forEach((efeteioObj) => {
    efeteiaArray.push(efeteioObj.efeteio);
  });

  return efeteiaArray;
}
