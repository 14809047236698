import { useState, useEffect } from "react";
import { prothesmiesNeasTaktikis } from "prothesmies";
import { dikastiria } from "../Util/dikastiria";
import ProthesmiaCard from "./ProthesmiaCard";
import ProthesmiesHeader from "./ProthesmiesHeader";

// import prothDep from "../../package.alias.json";

const transformProthToObjects = (proth) => {
  const arr = [];
  Object.keys(proth).forEach((r) => {
    const obj = {};
    if (r !== "dikasimos" && r !== "katathesi" && !r.includes("Details")) {
      obj.prothesmia = proth[r];
      Object.keys(proth).forEach((k) => {
        if (k === `${r}Details`) {
          obj.details = proth[k];
          obj.eidos = r;
          arr.push(obj);
        }
      });
    }
  });
  return arr;
};

const Prothesmies = () => {
  const [input, setInput] = useState("");
  const [court, setCourt] = useState("Ειρ");
  const [edra, setEdra] = useState("Αθηνών");
  const [proth, setProth] = useState("");
  const [dikasimos, setDikasimos] = useState(undefined);
  const [klisi, setKlisi] = useState(false);

  const [perifereia, setPerifereia] = useState("Αθηνών");

  const [dimosio, setDimosio] = useState(false);
  const [exoterikou, setExoterikou] = useState(false);

  const handleChange = (event) => {
    setCourt(event.target.value);
  };
  const handleEdraChange = (event) => {
    setEdra(event.target.value);
  };
  const handlePerifereiaChange = (event) => {
    setPerifereia(event.target.value);
    setEdra(
      dikastiria
        .filter((k) => k.efeteio === event.target.value)
        .map((r) => r.protovathmia)
        .flat()
        .map((i) => i.eirinodikeia)
        .flat()[0]
    );
  };
  const handleDikasimosChange = (event) => {
    setDikasimos(event.target.value);
  };

  useEffect(() => {
    if (input?.slice(0, 2) === "20" && input.split("-")[0].length === 4) {
      console.log("perifereia", perifereia);
      const options = {
        topiki: edra,
        yliki: court,
        dimosio,
        exoterikou,
        klisi,
        dikasimos,
      };
      console.log(options);
      console.log(input);
      console.log(proth);
      const timeOutId = setTimeout(
        () => setProth(prothesmiesNeasTaktikis(input, options)),
        100
      );
      return () => clearTimeout(timeOutId);
    }
  }, [input, dikasimos, edra, court, dimosio, exoterikou, klisi, perifereia]);

  return (
    <section>
      <ProthesmiesHeader />
      <section className="container_search">
        <h3 className="h3_prothesmia">Υπολογισμός Προθεσμιών Νέας Τακτικής</h3>
        <h4 className="h3_prothesmia">
          Χρησιμοποιεί το{" "}
          <a
            href="https://github.com/imertz/prothesmies"
            target="_blank"
            rel="noreferrer"
          >
            prothesmies
          </a>{" "}
          έκδοση 0.7.10
        </h4>
        <h4 className="prothesmia-updates">
          -Ενημερώθηκε την 08.02.2023, και προστέθηκαν οι προβλέψεις του ΦΕΚ
          598/Β/07.02.2023, σχετικά με την κακοκαιρία "Μπάρμπαρα"
        </h4>
        <h4 className="prothesmia-updates">
          -Ενημερώθηκε την 12.09.2023, και προστέθηκαν οι προβλέψεις του ΦΕΚ B’
          5384 και 5385/09.09.2023, σχετικά με την κακοκαιρία "Daniel"
        </h4>

        <form className="form_search">
          {/* <label htmlFor="username">Email:</label> */}
          <div className="input-container ic1">
            <input
              className="input"
              placeholder=" "
              type="date"
              id="Αναζήτηση"
              autoComplete="on"
              min="2022-01-01"
              onChange={(e) => {
                parseInt(input.split("-")[0]) < 2022
                  ? setInput("2022-01-01")
                  : setInput(e.target.value);
              }}
              value={input || ""}
              required
            />
            <div className="cut"></div>
            <label htmlFor="Αναζήτηση" className="placeholder">
              Ημερομηνία Κατάθεσης
            </label>
          </div>

          {/* <label htmlFor="password">Password:</label> */}

          <div className="input-container ic2">
            <select value={court} onChange={handleChange} className="input">
              <option key="Ειρ" value="Ειρ">
                Ειρηνοδικείο
              </option>
              <option key="Μον" value="Μον">
                Μον.Πρωτοδικείο
              </option>
              <option key="Πολ" value="Πολ">
                Πολ.Πρωτοδικείο
              </option>
            </select>

            <div className="cut"></div>
            <label htmlFor="court" className="placeholder">
              Δικαστήριο
            </label>
          </div>

          {court !== "Ειρ" && (
            <div className="input-container ic2">
              <select
                value={edra}
                onChange={handleEdraChange}
                className="input"
              >
                {dikastiria
                  .map((r) => r.protovathmia)
                  .flat()
                  .map((i) => i.protodikeio)
                  .sort()
                  .map((dikastirio, i) => (
                    <option key={i} value={dikastirio}>
                      {dikastirio}
                    </option>
                  ))}
              </select>

              <div className="cut"></div>
              <label htmlFor="court" className="placeholder">
                Έδρα
              </label>
            </div>
          )}
          {court === "Ειρ" && (
            <div className="input-container ic2">
              <select
                value={perifereia}
                onChange={handlePerifereiaChange}
                className="input"
              >
                {dikastiria
                  .map((r) => r.efeteio)
                  .sort()
                  .map((dikastirio, i) => (
                    <option key={i} value={dikastirio}>
                      {dikastirio}
                    </option>
                  ))}
              </select>

              <div className="cut"></div>
              <label htmlFor="court" className="placeholder">
                Περιφέρεια Ειρηνοδικείου
              </label>
            </div>
          )}
          {court === "Ειρ" && (
            <div className="input-container ic2">
              <select
                value={edra}
                onChange={handleEdraChange}
                className="input"
              >
                {dikastiria
                  .filter((k) => k.efeteio === perifereia)
                  .map((r) => r.protovathmia)
                  .flat()
                  .map((i) => i.eirinodikeia)
                  .flat()
                  .map((dikastirio, i) => (
                    <option key={i} value={dikastirio}>
                      {dikastirio}
                    </option>
                  ))}
              </select>

              <div className="cut"></div>
              <label htmlFor="court" className="placeholder">
                Έδρα Ειρηνοδικείου
              </label>
            </div>
          )}
          <div className="input-container ic2">
            <input
              className="input"
              placeholder=" "
              type="date"
              id="Δικάσιμος"
              autoComplete="on"
              onChange={handleDikasimosChange}
              value={dikasimos || ""}
              required
            />
            <div className="cut"></div>
            <label htmlFor="dikasimos" className="placeholder">
              Δικάσιμος
            </label>
          </div>

          <div className="input-container ic2">
            <select
              value={dimosio ? "ναι" : "όχι"}
              onChange={(e) =>
                e.target.value === "ναι" ? setDimosio(true) : setDimosio(false)
              }
              className="input"
            >
              <option key="Όχι" value="όχι">
                Όχι
              </option>
              <option key="Ναι" value="ναι">
                Ναι
              </option>
            </select>

            <div className="cut"></div>
            <label htmlFor="Dimosio" className="placeholder">
              Διάδικος Δημόσιο;
            </label>
          </div>
          <div className="input-container ic2">
            <select
              value={exoterikou ? "ναι" : "όχι"}
              onChange={(e) =>
                e.target.value === "ναι"
                  ? setExoterikou(true)
                  : setExoterikou(false)
              }
              className="input"
            >
              <option key="Όχι" value="όχι">
                Όχι
              </option>
              <option key="Ναι" value="ναι">
                Ναι
              </option>
            </select>

            <div className="cut"></div>
            <label htmlFor="exoterikou" className="placeholder">
              Κάτοικος Εξωτερικού;
            </label>
          </div>
          <div className="input-container ic2">
            <select
              value={klisi ? "ναι" : "όχι"}
              onChange={(e) =>
                e.target.value === "ναι" ? setKlisi(true) : setKlisi(false)
              }
              className="input"
            >
              <option key="Όχι" value="όχι">
                Όχι
              </option>
              <option key="Ναι" value="ναι">
                Ναι
              </option>
            </select>

            <div className="cut"></div>
            <label htmlFor="κλήση" className="placeholder">
              Αφορά κλήση (αρθ.237 π.3 ΚΠολΔ);
            </label>
          </div>
        </form>
      </section>

      <ProthesmiaCard proth={transformProthToObjects(proth)} input={input} />
    </section>
  );
};

export default Prothesmies;
