import React from 'react'

const Subscribe = () => {
  const handleSubscribe = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: 'BIGd-n2RKxtKxxWYteTNINf3IFz_TbbkwKAmIz2MO2yC-XTGUpejTQ9I-KW8SO5cOzjpVnZOlmRCLEX4fTKMdYk'
        }).then(function (subscription) {
          console.log('User is subscribed.');
          console.log(subscription);
        }).catch(function (err) {
          console.log('Failed to subscribe the user: ', err);
        });
      });
    }
  }



  return (
    <div>Subscribe to our notifications!
      <button onClick={handleSubscribe} >Subscribe</button>
    </div>
  )
}

export default Subscribe