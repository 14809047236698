import React, { useEffect, useRef } from 'react'
import NotifItem from './NotifItem'

function Notifications({ updates, notifLength, handleShowNotif, closeNotif, showNotif, bell }) {
  /**
 * Hook that alerts clicks outside of the passed ref
 */
  function useOutsideAlerter(ref, bell) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !bell.current.contains(event.target)) {
          closeNotif()
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, bell);

  return (
    <div ref={wrapperRef}>
      <span>Ενημερώσεις</span>

      {updates.map((r, i) => (
        <div key={`notif${i}`}>
          <NotifItem notif={r} notifLength={notifLength} notifIndex={i} handleShowNotif={handleShowNotif} closeNotif={closeNotif} />
        </div>))}
    </div>
  )
}

export default Notifications