export function getAdjCourt(court) {
  const courts = [
    "ΣτΕ",
    "ΔΕφ Αθηνών",
    "ΔΕφ Θεσσαλονίκης",
    "ΔΕφ Πειραιά",
    "ΔΠρ Αθηνών",
    "ΔΠρ Θεσσαλονίκης",
    "ΔΠρ Πειραιά",
    "ΔΕφ Κομοτηνής",
    "ΔΠρ Αλεξανδρούπολης",
    "ΔΠρ Καβάλας",
    "ΔΠρ Κομοτηνής",
    "ΔΠρ Βέροιας",
    "ΔΠρ Σερρών",
    "ΔΠρ Κοζάνης",
    "ΔΕφ Ιωαννίνων",
    "ΔΠρ Ιωαννίνων",
    "ΔΕφ Λάρισας",
    "ΔΠρ Βόλου",
    "ΔΠρ Λάρισας",
    "ΔΠρ Τρικάλων",
    "ΔΠρ Κέρκυρας",
    "ΔΕφ Πάτρας",
    "ΔΠρ Αγρινίου",
    "ΔΠρ Μεσολογγίου",
    "ΔΠρ Πάτρας",
    "ΔΠρ Πύργου",
    "ΔΠρ Λαμίας",
    "ΔΠρ Λιβαδειάς",
    "ΔΠρ Χαλκίδας",
    "ΔΕφ Τρίπολης",
    "ΔΠρ Καλαμάτας",
    "ΔΠρ Κορίνθου",
    "ΔΠρ Ναυπλίου",
    "ΔΠρ Τρίπολης",
    "ΔΠρ Μυτιλήνης",
    "ΔΠρ Ρόδου",
    "ΔΠρ Σύρου",
    "ΔΕφ Χανίων",
    "ΔΠρ Ηρακλείου",
    "ΔΠρ Χανίων",
  ];
  const links = [
    "ste/pageste/stepage",
    "defeteioath",
    "defeteiothe",
    "defeteiopei",
    "dprotodikeioath",
    "dprotodikeiothe",
    "dprotodikeiopei",
    "defeteiokom",
    "dprotodikeioale",
    "dprotodikeiokav",
    "dprotodikeiokom",
    "dprotodikeiover",
    "dprotodikeioser",
    "dprotodikeiokoz",
    "defeteioiwa",
    "dprotodikeioiwa",
    "defeteiolar",
    "dprotodikeiovol",
    "dprotodikeiolar",
    "dprotodikeiotri",
    "dprotodikeioker",
    "defeteiopat",
    "dprotodikeioagr",
    "dprotodikeiomes",
    "dprotodikeiopat",
    "dprotodikeiopyr",
    "dprotodikeiolam",
    "dprotodikeioliv",
    "dprotodikeioxal",
    "defeteiotrp",
    "dprotodikeiokal",
    "dprotodikeiokor",
    "dprotodikeionaf",
    "dprotodikeiotrp",
    "dprotodikeiomyt",
    "dprotodikeiorod",
    "dprotodikeiosyr",
    "defeteioxan",
    "dprotodikeiohra",
    "dprotodikeioxan",
  ];

  const index = links.findIndex((r) => r === court);
  return courts[index];
}
