import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [credits, setCredits] = useState(0);
  const [avatarUploaded, setAvatarUploaded] = useState(false);
  const [checking, setChecking] = useState([])
  const [persist, setPersist] = useState(JSON.parse(localStorage.getItem("persist")) || true);
  const [notifLength, setNotifLength] = useState(0);

  return (
    <AuthContext.Provider value={{ auth, setAuth, persist, setPersist, credits, setCredits, checking, setChecking, avatarUploaded, setAvatarUploaded, notifLength, setNotifLength }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext;