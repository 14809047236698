import React from 'react'

const AddCaseProtKo = ({ eidos, arkat, handleChangeDiad, setArkat, year, setYear }) => {

  return (
    <>
      <div className="input-container ic2">
        <input
          className='input'
          placeholder=' '
          type="text"
          id="arkat"
          autoComplete="on"
          onChange={(e) => setArkat(e.target.value)}
          value={arkat}
          required
        />
        <div className="cut"></div>
        <label htmlFor="arkat" className="placeholder">Αρ.Κατάθεσης (πχ. 316/ΜΤ 41/2020)</label></div>

    </>
  )

}

export default AddCaseProtKo