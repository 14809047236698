import React from 'react'

const Like = ({ handleClickLikes, isLiked }) => {


  return (
    <button className={isLiked ? 'heart active' : 'heart'} onClick={handleClickLikes}>
      <div className={isLiked ? 'heart-flip active' : 'heart-flip'}></div>
    </button>
  )
}

export default Like