import React from 'react'
import { useState, useEffect, useCallback, useRef } from "react";
import useAuth from '../hooks/useAuth';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAxiosPrivate from "../hooks/useAxiosPrivate";
import axios from '../api/axios';

import { useNavigate, useLocation, useParams, Link } from "react-router-dom";

const PWD_REGEX = /^(?=.*\d).{8,55}$/;
const REGISTER_URL = '/newpass';


const ResetPassword = () => {
  const [singleCase, setSingleCase] = useState();
  let params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const { setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();


  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pwd, setPwd] = useState('');
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);



  const [errMsg, setErrMsg] = useState('');
  const [success, setSuccess] = useState(false);





  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
  }, [pwd])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v2 = PWD_REGEX.test(pwd);
    if (!v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(REGISTER_URL,
        JSON.stringify({ uuid: params.uid, password: pwd }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true
        }
      );
      const accessToken = response?.data?.accessToken;
      // console.log(response);
      // console.log(accessToken);
      setAuth({ user, pwd, accessToken });
      setSuccess(true);
      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUser('');
      setPwd('');

      navigate('/', { replace: true });
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 409) {
        setErrMsg('Username Taken');
      } else {
        setErrMsg(err?.response?.data?.message)
      }
      errRef.current.focus();
    }
  }


  // useEffect(() => {
  //   let isMounted = true;
  //   const controller = new AbortController();

  //   const getCase = async () => {
  //     try {
  //       const response = await axiosPrivate.get(`/cases/${params.caseId}`, {
  //         signal: controller.signal
  //       });
  //       isMounted && setSingleCase(response.data);
  //     } catch (err) {
  //       console.error(err);
  //       navigate('/login', { state: { from: location }, replace: true });
  //     }
  //   }

  //   getCase();

  //   return () => {
  //     isMounted = false;
  //     controller.abort();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state])


  return (
    <>
      {success ? (
        <section>
          <h1>Αλλάξατε τον κωδικό σας επιτυχώς!</h1>
          <span className="line">
            <Link to="/">Σύνδεση</Link>
          </span>
        </section>
      ) : (
        <section className="container">
          <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <form className="form" onSubmit={handleSubmit}>

            {/* <label htmlFor="username">
                Username:
                <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
              </label> */}

            <div className="input-container ic1">
              <input
                className={pwdFocus && pwd && !validPwd ? "input input_error" : "input"}
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                autoComplete="on"
                required
                placeholder=' '
                aria-invalid={validPwd ? "false" : "true"}
                aria-describedby="pwdnote"
                onFocus={() => setPwdFocus(true)}
                onBlur={() => setPwdFocus(false)}
              />
              <div className="cut"></div>
              <label htmlFor="password" className="placeholder">password</label>
              <p id="pwdnote" className={pwdFocus && pwd && !validPwd ? "instructions" : "offscreen"}>
                <FontAwesomeIcon icon={faInfoCircle} />
                Τουλάχιστον 8 χαρακτήρες εκ των οποίων ένας αριθμός και ένα γράμμα.
              </p>


            </div>

            <button type="text" className="submit" disabled={!validPwd ? true : false}>Αλλαγή password</button>
          </form>
          <p>
            Έχετε ήδη εγγραφεί;<br />
            <span className="line">
              <Link to="/">Αλλαγή password</Link>
            </span>
          </p>
        </section>
      )
      }
    </>
  )
}

export default ResetPassword

