import React from "react";
import { ypothikofylakia as ypothUnfilt } from "../Ypothikofylakia";
import { XCircleIcon } from "@heroicons/react/solid";
import {
  MultiSelect,
  MultiSelectItem,
  Icon,
  List,
  ListItem,
} from "@tremor/react";
import { removeDiacriticsAndCapitalize } from "../../../utils/checkMisthosi";

const ChooseYpoth = ({ setYpothSelected, ypothSelected }) => {
  const ypoth = ypothUnfilt
    .map((dim) => removeDiacriticsAndCapitalize(dim))
    .sort();
  const handleDeleteListItem = (e) => {
    const value =
      e.target.parentNode.parentNode.parentNode.firstChild.textContent;

    const newYpothSelected = ypothSelected.filter((dim) => dim !== value);
    setYpothSelected(newYpothSelected);
  };

  return (
    <div className="form-group">
      <label htmlFor="ypothykofylakio">Υποθηκοφυλακεία:</label>
      <MultiSelect
        placeholder="Επιλέξτε Υποθηκοφυλακείο"
        onValueChange={setYpothSelected}
        value={ypothSelected}
      >
        {ypoth.map((dim, i) => {
          return (
            <MultiSelectItem key={`${i}-${dim}`} value={dim}>
              {dim}
            </MultiSelectItem>
          );
        })}
      </MultiSelect>
      <List>
        {ypothSelected?.map((dim, i) => {
          return (
            <ListItem className="dimoi-list" key={`${i}-${dim}`}>
              <span>{dim}</span>
              <span>
                <Icon
                  onClick={handleDeleteListItem}
                  className={"cursor-pointer"}
                  icon={XCircleIcon}
                  size={"sm"}
                  color="red"
                  tooltip="Διαγραφή"
                ></Icon>
              </span>
              <ListItem />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ChooseYpoth;
