import React from "react";

const ExodikoRow = ({ handleExodikoInputChange, exodiko }) => {
  const { ofeilomeno = "", startMonth = "", endMonth = "" } = exodiko;
  return (
    <div className="all">
      <div className="vertical-line-focus"></div>
      <div className="form-section-row">
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="startMonth">
            Πρώτος Μήνας Οφειλής
          </label>
          <input
            className="form-section-input"
            type="month"
            id="startMonth"
            name="startMonth"
            value={exodiko.startMonth}
            onChange={handleExodikoInputChange}
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="endMonth">
            Τελευταίος Μήνας Οφειλής
          </label>
          <input
            className="form-section-input"
            type="month"
            id="endMonth"
            name="endMonth"
            value={exodiko.endMonth}
            onChange={handleExodikoInputChange}
          />
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="ofeilomeno">
            Οφειλόμενο Ποσό
          </label>
          <input
            className="form-section-input"
            type="text"
            id="ofeilomeno"
            placeholder="πχ. 800"
            name="ofeilomeno"
            value={exodiko.ofeilomeno}
            onChange={handleExodikoInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ExodikoRow;
