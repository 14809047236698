import axios from "axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import {
  useNavigate,
  useLocation,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Card,
  Metric,
  Text,
  Flex,
  ProgressBar,
  Grid,
  Bold,
  Title,
  Divider,
  Button,
  Icon,
  Callout,
} from "@tremor/react";
import { useState, useMemo, useEffect } from "react";
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/solid";
import { returnDetail } from "../../utils/reverseDate";
import { is } from "date-fns/locale";
import { DateDetails } from "./DateDetails";

function getUniqueImerominia(data) {
  return [...new Set(data.map((item) => item.imerominia))];
}
function getWorkingWeekDates(weekOffset = 0) {
  const today = new Date();

  // Get today's day of the week (0 = Sunday, 6 = Saturday)
  const currentDayOfWeek = today.getDay();

  // Adjust the start date based on the week offset
  today.setDate(today.getDate() + 7 * weekOffset * 2);

  // Get start of the target week (assuming week starts on Monday)
  const startOfWeek = new Date(today);
  startOfWeek.setDate(
    today.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1)
  ); // Handle Sunday being day 0

  // Get start of the next week
  const startOfNextWeek = new Date(startOfWeek);
  startOfNextWeek.setDate(startOfWeek.getDate() + 7);

  const format = (date) =>
    `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(date.getDate()).padStart(2, "0")}`;

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  // Calculate the working dates for this week and next week
  const thisWeekDates = [];
  const nextWeekDates = [];

  for (let i = 0; i < 7; i++) {
    const thisWeekDate = new Date(
      startOfWeek.getFullYear(),
      startOfWeek.getMonth(),
      startOfWeek.getDate() + i
    );
    const nextWeekDate = new Date(
      startOfNextWeek.getFullYear(),
      startOfNextWeek.getMonth(),
      startOfNextWeek.getDate() + i
    );

    if (isWeekday(thisWeekDate)) {
      thisWeekDates.push(format(thisWeekDate));
    }
    if (isWeekday(nextWeekDate)) {
      nextWeekDates.push(format(nextWeekDate));
    }
  }

  return [...thisWeekDates, ...nextWeekDates];
}

export default function Example() {
  const [calendar, setCalendar] = useState([]);
  const [cases, setCases] = useState([]);
  const [uniqueImerominies, setUniqueImerominies] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams();
  // get current week's working days
  const [weekCounter, setWeekCounter] = useState(searchParams.get("week") || 0);
  const weekDays = useMemo(
    () => getWorkingWeekDates(weekCounter),
    [weekCounter]
  );

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  function handleIncrementWeek() {
    setWeekCounter(parseInt(weekCounter) + 1);
    setSearchParams({ week: parseInt(weekCounter) + 1 });
  }
  function handleDecrementWeek() {
    setWeekCounter(parseInt(weekCounter) - 1);
    setSearchParams({ week: parseInt(weekCounter) - 1 });
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCases = async () => {
      try {
        const response = await axiosPrivate.get("/calendar", {
          signal: controller.signal,
        });
        isMounted &&
          setCalendar(
            [
              ...response.data.imerominies,
              ...response.data.imerominiesOut.map((r) => ({
                ...r,
                case_id: `o${r.case_id}`,
              })),
              ...response.data.imerominiesAdj.map((r) => ({
                ...r,
                case_id: `a${r.case_id}`,
              })),
              ...response.data.imerominiesEau.map((r) => ({
                ...r,
                case_id: `e${r.case_id}`,
              })),
            ].sort((a, b) => {
              return a.imerominia > b.imerominia ? 1 : -1;
            })
          );
        isMounted &&
          setCases([
            ...response.data.cases,
            ...response.data.casesOut.map((r) => ({
              ...r,
              id: `o${r.id}`,
            })),
            ...response.data.adjCases.map((r) => ({
              ...r,
              id: `a${r.id}`,
            })),
            ...response.data.eauCases.map((r) => ({
              ...r,
              id: `e${r.case_id}`,
            })),
          ]);
        isMounted &&
          setUniqueImerominies(
            getUniqueImerominia([
              ...response.data.imerominies,
              ...response.data.imerominiesOut.map((r) => ({
                ...r,
                case_id: `o${r.case_id}`,
              })),
              ...response.data.imerominiesAdj.map((r) => ({
                ...r,
                case_id: `a${r.case_id}`,
              })),
              ...response.data.imerominiesEau.map((r) => ({
                ...r,
                case_id: `e${r.case_id}`,
              })),
            ])
          );
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getCases();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentImerominies = uniqueImerominies.filter((r) => {
    return r <= weekDays[9] && r >= weekDays[0];
  });

  const smGrid = currentImerominies.length > 2 ? 2 : currentImerominies.length;
  const lgGrid = currentImerominies.length > 2 ? 3 : currentImerominies.length;

  return (
    <div className="calendar-section">
      <Flex justifyContent="center" className="mt-3 flex-wrap">
        <Button
          size="sm"
          className="m-1"
          onClick={handleDecrementWeek}
          icon={ArrowCircleLeftIcon}
        >
          Προηγούμενες 2 εβδομάδες
        </Button>
        <Button
          size="sm"
          onClick={handleIncrementWeek}
          icon={ArrowCircleRightIcon}
          iconPosition="right"
        >
          Επόμενες 2 εβδομάδες
        </Button>
      </Flex>
      <Flex justifyContent="center" className="mt-2 flex-wrap">
        <Title>
          {new Date(weekDays[0]).toLocaleDateString("el-GR", {
            weekday: "long",
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}{" "}
          -{" "}
          {new Date(weekDays[9]).toLocaleDateString("el-GR", {
            weekday: "long",
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </Title>
      </Flex>
      <Grid numItemsSm={smGrid} numItemsLg={lgGrid} className="gap-6">
        {currentImerominies.map((item, i) => (
          <Card key={`imer-${i}`} className="max-w-md mx-auto">
            <Flex
              justifyContent="start"
              alignItems="baseline"
              className="space-x-3 truncate"
            >
              <Title>
                {new Date(item).toLocaleDateString("el-GR", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              </Title>
              <Text>
                {new Date(item).toLocaleDateString("el-GR", {
                  weekday: "long",
                })}
              </Text>
            </Flex>
            <Divider className="mt-3" />
            {calendar
              .filter((r) => r.imerominia === item)
              .map((r) => (
                <DateDetails
                  singleCase={cases.find((k) => k.id === r.case_id)}
                  r={r}
                />
              ))}
          </Card>
        ))}
      </Grid>
    </div>
  );
}
