const Nomothesia = ({ details }) => {


  return (
    <article>
      <p className="prothesmia_text">{details.nomothesia}</p>

    </article >
  )
}

export default Nomothesia