import { TabGroup, TabList, Tab } from "@tremor/react";
import {
  CheckIcon,
  CollectionIcon,
  ReceiptRefundIcon,
} from "@heroicons/react/outline";

const ToggleSyzitisi = ({ handleSelect }) => (
  <>
    <TabGroup
      defaultIndex={2}
      onIndexChange={handleSelect}
      className="w-fit mt-1"
    >
      <TabList variant="solid">
        <Tab icon={CheckIcon}>Συζητηθείσες</Tab>
        <Tab icon={ReceiptRefundIcon}>Μη Συζητηθείσες</Tab>
        <Tab icon={CollectionIcon}>Όλες</Tab>
      </TabList>
    </TabGroup>
  </>
);

export default ToggleSyzitisi;
