import { Card, TextInput } from "@tremor/react";
import React from "react";

const NewPost = () => {
  const [userData, setUserData] = React.useState({
    first_name: "",
    last_name: "",
    syllogos: "",
    am: "",
    edra: "",
  });
  const [syllogoi, setSyllogoi] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      first_name: userData.first_name,
      last_name: userData.last_name,
      syllogos: userData.syllogos,
      am: userData.am,
      edra: userData.edra,
    };

    console.log(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const txt =
    '<p><a href="https://mysolon.gr" rel="noopener noreferrer" target="_blank">MySolon.gr</a></p><p>	All this <strong>stuff!</strong></p> ';

  return (
    <div>
      NewPost
      <Card>
        <div dangerouslySetInnerHTML={{ __html: txt }} />
        <form className="profile-edit-form" onSubmit={handleSubmit}>
          <div className="profile-form-inputs">
            <div className="form-group">
              <label htmlFor="browser">Σύλλογος:</label>
              <div className="tr-relative tr-w-full tr-flex tr-items-center tr-overflow-hidden tr-min-w-[10rem] tr-max-w-none tr-mt-0 tr-bg-white tr-border-gray-300 tr-rounded-md tr-border tr-shadow-sm">
                <textarea
                  name="syllogos"
                  id="browser"
                  onChange={handleInput}
                  value={userData.syllogos}
                  className={
                    "tremor-base input-elem tr-w-full focus:tr-outline-0 focus:tr-ring-0 tr-bg-inherit tr-text-gray-700 tr-pl-4 tr-pr-4 tr-pt-2 tr-pb-2 tr-text-sm tr-font-medium tr-border-0 placeholder:tr-text-gray-500"
                  }
                  placeholder={"Επιλέξτε Σύλλογο"}
                />

                <datalist id="browsers">
                  {syllogoi.map((dim, i) => {
                    return <option key={`${i}-${dim}`} value={dim} />;
                  })}
                </datalist>
              </div>
            </div>
          </div>
          {/* <Divider />
        <div className="profile-form-inputs">
          <Choose fields={{ ...fieldsProps, dimoiSelected, DOYSelected }} />
        </div> */}
          <button type="submit" className="button-profile">
            Αποθήκευση
          </button>
        </form>
      </Card>
    </div>
  );
};

export default NewPost;
