import {
  Card,
  TabList,
  Tab,
  ProgressBar,
  Text,
  Flex,
  Button,
  Metric,
  BadgeDelta,
  AreaChart,
  TabGroup,
  Title,
  Bold
} from "@tremor/react";


import { useState, useEffect } from "react";

export default function UserStat({ data }) {
  const [initData, setInitData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);

  useEffect(() => {
    data?.userStats?.length > 0 &&
      setInitData(
        data?.userStats.map((r) => ({
          creation_date: r.creation_date,
          'Νέοι Χρήστες (Αθροιστικά)': r.user_count,
        }))
      );
    setTotalUsers(data?.users);
  }, [data]);

  return (
    <Card className="max-w-md mx-auto">
      <Flex alignItems="start">
        <Title>Μέλη MySolon.gr</Title>
        <BadgeDelta deltaType="moderateIncrease">
          {calculateMonthlyPercentageIncrease(
            
            totalUsers -
              initData[initData?.length - 1]?.['Νέοι Χρήστες (Αθροιστικά)'],totalUsers,
          )}%
        </BadgeDelta>
      </Flex>
      <Flex
        justifyContent="start"
        alignItems="baseline"
        className="space-x-3 truncate"
      >
        <Metric>{totalUsers}</Metric>
        <Text>Από <Bold>{totalUsers -
              initData[initData?.length - 1]?.['Νέοι Χρήστες (Αθροιστικά)']}</Bold> πριν 30 ημέρες</Text>
      </Flex>
      <AreaChart
        className="mt-10 h-48"
        data={initData}
        index="creation_date"
        categories={["Νέοι Χρήστες (Αθροιστικά)"]}
        colors={["blue"]}
        showYAxis={false}
        showLegend={false}
        startEndOnly={true}
      />
    </Card>
  );
}

function calculateMonthlyPercentageIncrease(previousUsers, currentUsers) {
  const percentageIncrease =
    ((currentUsers - previousUsers) / previousUsers) * 100;
  return percentageIncrease.toFixed(1); // Returns the result rounded to 2 decimal places
}
