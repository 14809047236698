export function addYearsToDate(dateString, yearsToAdd) {
  const date = new Date(dateString);
  date.setFullYear(date.getFullYear() + yearsToAdd);
  date.setDate(date.getDate() - 1);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export function addMonthsToDate(dateString, monthsToAdd) {
  const date = new Date(dateString);
  date.setMonth(date.getMonth() + monthsToAdd);
  date.setDate(date.getDate() - 1);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function getFirstDateOfNextMonth() {
  const today = new Date();
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  const year = nextMonth.getFullYear();
  const month = String(nextMonth.getMonth() + 1).padStart(2, "0");
  const day = String(nextMonth.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function yearsString(years) {
  if (years === 1) return "μονοετής (1 έτος)";
  else if (years === 2) return "διετής (2 έτη)";
  else if (years === 3) return "τριετής (3 έτη)";
  else if (years === 4) return "τετραετής (4 έτη)";
  else if (years === 5) return "πενταετής (5 έτη)";
  else if (years === 6) return "εξαετής (6 έτη)";
  else if (years === 7) return "επταετής (7 έτη)";
  else if (years === 8) return "οκταετής (8 έτη)";
  else if (years === 9) return "εννιαετής (9 έτη)";
  else if (years === 10) return "δεκαετής (10 έτη)";
  else if (years === 11) return "ενδεκαετής (11 έτη)";
  else if (years === 12) return "δωδεκαετής (12 έτη)";
  else if (years === 13) return "δεκατριετής (13 έτη)";
  else if (years === 14) return "δεκατετραετής (14 έτη)";
  else if (years === 15) return "δεκαπενταετής (15 έτη)";
  else if (years === 16) return "δεκαεξαετής (16 έτη)";
  else if (years === 17) return "δεκαεπταετής (17 έτη)";
  else if (years === 18) return "δεκαοκταετής (18 έτη)";
  else if (years === 19) return "δεκαεννιαετής (19 έτη)";
  else if (years === 20) return "εικοσαετής (20 έτη)";
  else {
    return `${years}ετής (${years} έτη)`;
  }
}
export function capitalizeFirstLetter(str) {
  if (str?.charAt(0) !== undefined)
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getMonthRange(startMonth, endMonth) {
  const start = new Date(startMonth + "-01");
  const end = new Date(endMonth + "-01");
  const months = [];
  for (let d = start; d <= end; d.setMonth(d.getMonth() + 1)) {
    months.push(d.toISOString().slice(0, 7));
  }
  return months;
}
export function formatMonthRange(months) {
  const monthsMap = {
    "01": "Ιανουαρίου",
    "02": "Φεβρουαρίου",
    "03": "Μαρτίου",
    "04": "Απριλίου",
    "05": "Μαΐου",
    "06": "Ιουνίου",
    "07": "Ιουλίου",
    "08": "Αυγούστου",
    "09": "Σεπτεμβρίου",
    10: "Οκτωβρίου",
    11: "Νοεμβρίου",
    12: "Δεκεμβρίου",
  };
  const yearsMap = {};
  let result = "";
  for (const month of months) {
    const [year, monthNumber] = month.split("-");
    if (!yearsMap[year]) {
      yearsMap[year] = [];
    }
    yearsMap[year].push(monthNumber);
  }
  for (const year in yearsMap) {
    const yearMonths = yearsMap[year];
    result += `${yearMonths
      .map((month) => monthsMap[month])
      .join(", ")} του έτους ${year}, `;
  }

  return result.slice(0, -2);
}

export function formatDate(dateString) {
  const [year, month, day] = dateString.split("-");
  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate;
}

export function reformatDate(dateString) {
  const [day, month, year] = dateString.split("/");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

// get current year string, eg. "2021"
export function getCurrentYear() {
  const today = new Date();
  return today.getFullYear();
}
