import { getDeclension } from "greek-name-declension";
import {
  addYearsToDate,
  capitalizeFirstLetter,
  formatDate,
  formatMonthRange,
  getCurrentYear,
  getMonthRange,
  reformatDate,
  yearsString,
} from "./addYears";
import { olografos } from "olografos";
import {
  checkIfFemale,
  removeDiacriticsAndCapitalize,
  transformEggyisi,
} from "../../../utils/checkMisthosi";

export const misthosiTransform = (data) => {
  console.log(data.misthosi);
  let templateObj = {
    ekmisthotes: data.ekmisthotes.map((ekmisthotis, i) => {
      return {
        ονόματος_ΕΚ: `${i + 1}) ${
          getDeclension(ekmisthotis.onoma_ek).possesive
        }`,
        επωνύμου_ΕΚ:
          ekmisthotis.fylo_ek === "Γυναίκα"
            ? ekmisthotis.eponymo_ek
            : getDeclension(ekmisthotis.eponymo_ek).possesive,
        πατρωνύμου_ΕΚ: getDeclension(ekmisthotis.patronymo_ek).possesive,
        διεύθυνση_ΕΚ: ekmisthotis.katoikia_ek,
        ΑΦΜ_ΕΚ: ekmisthotis.AFM_ek,
      };
    }),

    misthotes: data.misthotes.map((misthotis, i) => {
      return {
        ονόματος_ΜΙΣ: `${i + 1}) ${
          getDeclension(misthotis.onoma_mi).possesive
        }`,
        επωνύμου_ΜΙΣ:
          misthotis.fylo_mi === "Γυναίκα"
            ? misthotis.eponymo_mi
            : getDeclension(misthotis.eponymo_mi).possesive,
        πατρωνύμου_ΜΙΣ: getDeclension(misthotis.patronymo_mi).possesive,
        διεύθυνση_ΜΙΣ: misthotis.katoikia_mi,
        ΑΦΜ_ΜΙΣ: misthotis.AFM_mi,
      };
    }),
    ονόματος_ΕΚ: getDeclension(data.ekmisthotes[0].onoma_ek).possesive,
    επωνύμου_ΕΚ: data.ekmisthotes[0].eponymo_ek,
    πατρωνύμου_ΕΚ: data.ekmisthotes[0].patronymo_ek,
    διεύθυνση_ΕΚ: data.ekmisthotes[0].katoikia_ek,
    ΑΦΜ_ΕΚ: data.ekmisthotes[0].AFM_ek,
    ονόματος_ΜΙΣ: getDeclension(data.misthotes[0].onoma_mi).possesive,
    επωνύμου_ΜΙΣ: data.misthotes[0].eponymo_mi,
    πατρωνύμου_ΜΙΣ: getDeclension(data.misthotes[0].patronymo_mi).possesive,
    διεύθυνση_ΜΙΣ: data.misthotes[0].katoikia_mi,
    ΑΦΜ_ΜΙΣ: data.misthotes[0].AFM_mi,
    τόπος_υπογραφής: data.misthosi.topos_symvasis,
    του_ΕΚ: "του",
    καλουμένου_ΕΚ: "καλούμενου",
    Εκμισθωτής: "Εκμισθωτής",
    του_ΜΙΣ: "του",
    καλουμένου_ΜΙΣ: "καλούμενου",
    Μισθωτής: "Μισθωτής",
    ο_ΕΚ: "ο",
    Ο_ΕΚ: "Ο",
    αποκλειστικός_ΕΚ: "αποκλειστικός κύριος, νομέας και κάτοχος",
    εκμισθώνει_ΕΚ: "εκμισθώνει",
    στον_ΜΙΣ: "στον",
    τον_ΜΙΣ: "τον",

    μισθωτή: "μισθωτή",
    μισθωτού: "μισθωτή",
    εκμισθωτής: "εκμισθωτής",
    εκμισθωτού: "εκμισθωτή",
    περιγραφή: data.misthosi.perigrafi?.trim(),
    έναρξη: formatDate(data.misthosi.enarxi),
    λήξη: formatDate(data.misthosi.lixi),
    διάρκεια: yearsString(parseInt(data.misthosi.diarkeia)),
    μίσθωμα: `${olografos(parseFloat(data.misthosi.misthoma), {
      currency: true,
      klisi: "geniki",
    })} (${data.misthosi.misthoma} €)`,
    ποσό_εγγύησης: `${data.misthosi.misthoma * data.misthosi.eggyisi} €`,
    ε: `${transformEggyisi(data.misthosi.eggyisi)}`,
    Ο_μισθωτής: "Ο μισθωτής",
    στον_εκμισθωτή: "στον εκμισθωτή",
    του_εκμισθωτή: "του εκμισθωτή",
    στον_μισθωτή: "στον μισθωτή",
    τον_μισθωτή: "τον μισθωτή",
    ο_μισθωτής: "ο μισθωτής",
    του_μισθωτή: "του μισθωτή",
    παρέλαβε: "παρέλαβε",
    προορίζει: "προορίζει",
    υποχρεούται: "υποχρεούται",
    εκκενώσει: "εκκενώσει",
    παραδώσει: "παραδώσει",
    κάνει: "κάνει",
    ευθύνεται: "ευθύνεται",
    ο_ίδιος: "ο ίδιος",
    Ο_εκμισθωτής: "Ο εκμισθωτής",
    έχει_ΕΚ: "έχει",
    τον_εκμισθωτή: "τον εκμισθωτή",
    οφείλει_ΜΙΣ: "οφείλει",
    ενημερώνει_ΜΙΣ: "ενημερώνει",
    αυτού_ΕΚ: "αυτού",
    λάβει_ΜΙΣ: "λάβει",
    του_τους_ΜΙΣ: "του",
    αυτόν_ΜΙΣ: "αυτόν",
    συμμορφώνεται: "συμμορφώνεται",
    αναλαμβάνει: "αναλαμβάνει",
    προβεί: "προβεί",
    λαβει: "λάβει",

    // Μεταβλητές Εξωδίκου
    ΜΟΥ_ΕΚ: "ΜΟΥ",
    ημνια_μισθωτηρίου: formatDate(data.misthosi.imnia_ypografis),
    εκμίσθωσα: "εκμίσθωσα",
    εμένα_ΕΚ: "εμένα",
    μου_ΕΚ: "μου",
    το_μίσθωμα_μηνός:
      getMonthRange(data.exodiko.startMonth, data.exodiko.endMonth).length > 1
        ? "τα μίσθωματα των μηνών"
        : "το μίσθωμα του μήνα",
    μήνες: formatMonthRange(
      getMonthRange(data.exodiko.startMonth, data.exodiko.endMonth)
    ),
    υπολογισμός_μισθώματος: `(${
      getMonthRange(data.exodiko.startMonth, data.exodiko.endMonth).length
    }Χ${data.misthosi.misthoma}€=) ${
      getMonthRange(data.exodiko.startMonth, data.exodiko.endMonth).length *
      data.misthosi.misthoma
    } ευρώ`,
    διαμαρτύρομαι: "διαμαρτύρομαι",
    προσκαλώ: "προσκαλώ",
    οφειλόμενο: `${olografos(parseFloat(data.exodiko.ofeilomeno), {
      currency: true,
      klisi: "geniki",
    })} (${data.exodiko.ofeilomeno} €)`,
    δηλώνω: "δηλώνω",
    ΔΗΛΩΝΩ: "ΔΗΛΩΝΩ",
    προβώ: "προβώ",
    ασκήσω: "ασκήσω",
    ο_εξωδίκως_δηλών: "Ο Εξωδίκως Δηλών",
    // μεταβλητές αίτησης
    ΔΙΚΑΣΤΗΡΙΟΥ: `${
      data.diatagi.edra === "Ειρηνοδικείο"
        ? "ΕΙΡΗΝΟΔΙΚΕΙΟΥ"
        : "ΜΟΝΟΜΕΛΟΥΣ ΠΡΩΤΟΔΙΚΕΙΟΥ"
    } ${removeDiacriticsAndCapitalize(data.diatagi.edra_topos)}`,
    του_τους_ΕΚ: "του",
    Έδρα: `${data.diatagi.edra_topos}`,

    έτος: `${getCurrentYear()}`,
    στον_καθου: "στον καθ'ου",
    υποβάλω: "υποβάλω",
    χρησιμοποιεί: "χρησιμοποιεί",
    ο_καθου: "ο καθ'ου",
    εγκαταστάθηκε: "εγκαταστάθηκε",
    καθυστερεί: "καθυστερεί",
    έχει: "έχει",
    οφείλει: "οφείλει",
    κοινοποίησα: "κοινοποίησα",
    καλούσα: "καλούσα",
    καταβάλει: "καταβάλει",
    αρνείται: "αρνείται",
    συμμορφωθεί: "συμμορφωθεί",
    τον_καθου: "τον καθ'ου",
    νομιμοποιούμαι: "νομιμοποιούμαι",
    του_καθου: "του καθ'ου",
    καταδικαστεί: "καταδικαστεί",
    ΖΗΤΩ: "ΖΗΤΩ",
    // μεταβλητές διαταγής
    ΔΙΚΑΣΤΗΡΙΟ: `${
      data.diatagi.edra === "Ειρηνοδικείο"
        ? "ΕΙΡΗΝΟΔΙΚΕΙΟ"
        : "ΜΟΝΟΜΕΛΕΣ ΠΡΩΤΟΔΙΚΕΙΟ"
    } ${removeDiacriticsAndCapitalize(data.diatagi.edra_topos)}`,
    δικαστής: `${
      data.diatagi.edra === "Ειρηνοδικείο"
        ? "Ειρηνοδίκης"
        : "Δικαστής του Μονομελούς Πρωτοδικείου"
    }`,
    ο_αιτών: "ο αιτών",
    ζητεί: "ζητεί",
    Ο_αιτών: "Ο αιτών",
    επιδιώκει: "επιδιώκει",
    αναφέρει: "αναφέρει",
    εκμίσθωσε: "εκμίσθωσε",
    χρησιμοποιήσει: "χρησιμοποιήσει",
    συνεχίζει: "συνεχίζει",
    του_αιτούντος: "του αιτούντος",
    στον_αιτούντα: "στον αιτούντα",
    Ο_καθου: "Ο καθ'ου",
    του_τους_ΜΙ: "του",
    αυτόν_ΜΙ: "αυτόν",
    αποδώσει: "αποδώσει",
    ασκήσει: "ασκήσει",
  };

  let obj = {
    ekmisthotesPlural: false,
    misthotesPlural: false,
    ekmisthotesFemale: false,
    misthotesFemale: false,
  };
  obj.ekmisthotesPlural = data.ekmisthotes.length > 1;
  obj.misthotesPlural = data.misthotes.length > 1;
  obj.ekmisthotesFemale = data.ekmisthotes[0].fylo_ek === "Γυναίκα";
  obj.misthotesFemale = data.misthotes[0].fylo_mi === "Γυναίκα";

  if (obj.ekmisthotesFemale) {
    templateObj.του_ΕΚ = "της";
    templateObj.καλουμένου_ΕΚ = "καλούμενης";
    templateObj.Εκμισθωτής = "Εκμισθώτρια";
    templateObj.εκμισθωτής = "εκμισθώτρια";
    templateObj.ο_ΕΚ = "η";
    templateObj.Ο_ΕΚ = "Η";
    templateObj.αποκλειστικός_ΕΚ = "αποκλειστική κυρία, νομέας και κάτοχος";
    templateObj.εκμισθωτού = "εκμισθώτριας";
    templateObj.ο_εξωδίκως_δηλών = "Η Εξωδίκος Δηλούσα";
    templateObj.του_τους_ΕΚ = "της";
    templateObj.ο_αιτών = "η αιτούσα";
    templateObj.Ο_αιτών = "Η αιτούσα";
    templateObj.στον_εκμισθωτή = "στην εκμισθώτρια";
    templateObj.του_εκμισθωτή = "της εκμισθώτριας";
    templateObj.Ο_εκμισθωτής = "Η εκμισθώτρια";
    templateObj.τον_εκμισθωτή = "την εκμισθώτρια";
    templateObj.αυτού_ΕΚ = "αυτής";
  }
  if (obj.misthotesFemale) {
    templateObj.του_ΜΙΣ = "της";
    templateObj.καλουμένου_ΜΙΣ = "καλούμενης";
    templateObj.Μισθωτής = "Μισθώτρια";
    templateObj.στον_ΜΙΣ = "στην";
    templateObj.τον_ΜΙΣ = "την";
    templateObj.μισθωτή = "μισθώτρια";
    templateObj.μισθωτού = "μισθώτριας";
    templateObj.του_ΜΙΣ = "της";
    templateObj.Ο_μισθωτής = "Η μισθώτρια";
    templateObj.ο_μισθωτής = "η μισθώτρια";
    templateObj.του_μισθωτή = "της μισθώτριας";
    templateObj.στον_μισθωτή = "στην μισθώτρια";
    templateObj.ο_ίδιος = "η ίδια";

    templateObj.στον_καθου = "στην καθ'ης";
    templateObj.ο_καθου = "η καθ'ης";
    templateObj.τον_καθου = "την καθ'ης";
    templateObj.του_καθου = "της καθ'ης";
    templateObj.Ο_καθου = "Η καθ'ης";
    templateObj.του_τους_ΜΙ = "της";
    templateObj.αυτόν_ΜΙ = "αυτήν";
    templateObj.του_τους_ΜΙΣ = "της";
    templateObj.αυτόν_ΜΙΣ = "αυτήν";
  }
  if (obj.ekmisthotesPlural) {
    templateObj.του_ΕΚ = "των";
    templateObj.καλουμένου_ΕΚ = "καλούμενων";
    templateObj.Εκμισθωτής = "Εκμισθωτές";
    templateObj.ο_ΕΚ = "οι";
    templateObj.Ο_ΕΚ = "Οι";
    templateObj.μου_ΕΚ = "μας";
    templateObj.αποκλειστικός_ΕΚ = "αποκλειστικοί κύριοι, νομείς και κάτοχοι";
    templateObj.εκμισθωτής = "εκμισθωτές";
    templateObj.εκμισθωτού = "εκμισθωτών";
    templateObj.εκμισθώνει_ΕΚ = "εκμισθώνουν";
    templateObj.ο_εξωδίκως_δηλών = "Οι Εξωδίκως Δηλούντες";
    templateObj.διαμαρτύρομαι = "διαμαρτυρόμαστε";
    templateObj.προσκαλώ = "προσκαλούμε";
    templateObj.δηλώνω = "δηλώνουμε";
    templateObj.προβώ = "προβούμε";
    templateObj.ασκήσω = "ασκήσουμε";
    templateObj.ΔΗΛΩΝΩ = "ΔΗΛΩΝΟΥΜΕ";
    templateObj.υποβάλω = "υποβάλουμε";
    templateObj.εκμίσθωσα = "εκμισθώσαμε";
    templateObj.κοινοποίησα = "κοινοποιήσαμε";
    templateObj.καλούσα = "καλούσαμε";
    templateObj.νομιμοποιούμαι = "νομιμοποιούμαστε";
    templateObj.ΖΗΤΩ = "ΖΗΤΟΥΜΕ";
    templateObj.εμένα_ΕΚ = "εμάς";
    templateObj.του_τους_ΕΚ = "των";
    templateObj.ο_αιτών = "οι αιτούντες";
    templateObj.Ο_αιτών = "Οι αιτούντες";
    templateObj.του_τους_ΕΚ = "τους";
    templateObj.του_αιτούντος = "των αιτούντων";
    templateObj.στον_αιτούντα = "στους αιτούντες";
    templateObj.ζητεί = "ζητούν";
    templateObj.επιδιώκει = "επιδιώκουν";
    templateObj.αναφέρει = "αναφέρουν";
    templateObj.εκμίσθωσε = "εκμίσθωσαν";
    templateObj.στον_εκμισθωτή = "στους εκμισθωτές";
    templateObj.τον_εκμισθωτή = "τους εκμισθωτές";
    templateObj.του_εκμισθωτή = "των εκμισθωτών";
    templateObj.έχει_ΕΚ = "έχουν";
    templateObj.Ο_εκμισθωτής = "Οι εκμισθωτές";
    templateObj.αυτού_ΕΚ = "αυτών";
  }
  if (obj.misthotesPlural) {
    templateObj.του_ΜΙΣ = "των";
    templateObj.καλουμένου_ΜΙΣ = "καλούμενων";
    templateObj.Μισθωτής = "Μισθωτές";
    templateObj.στον_ΜΙΣ = "στους";
    templateObj.τον_ΜΙΣ = "τους";
    templateObj.μισθωτή = "μισθωτές";
    templateObj.μισθωτού = "μισθωτών";
    templateObj.του_ΜΙΣ = "των";
    templateObj.στον_καθου = "στους καθ'ων";
    templateObj.ο_καθου = "οι καθ'ων";
    templateObj.τον_καθου = "τους καθ'ων";
    templateObj.του_καθου = "των καθ'ων";
    templateObj.χρησιμοποιεί = "χρησιμοποιούν";
    templateObj.εγκαταστάθηκε = "εγκαταστάθηκαν";
    templateObj.καθυστερεί = "καθυστερούν";
    templateObj.έχει = "έχουν";
    templateObj.οφείλει = "οφείλουν";
    templateObj.καταβάλει = "καταβάλουν";
    templateObj.αρνείται = "αρνούνται";
    templateObj.συμμορφωθεί = "συμμορφωθούν";
    templateObj.καταδικαστεί = "καταδικαστούν";
    templateObj.Ο_καθου = "Οι καθ'ων";
    templateObj.χρησιμοποιήσει = "χρησιμοποιήσουν";
    templateObj.συνεχίζει = "συνεχίζουν";
    templateObj.του_τους_ΜΙ = "τους";
    templateObj.αυτόν_ΜΙ = "αυτούς";
    templateObj.αποδώσει = "αποδώσουν";
    templateObj.ασκήσει = "ασκήσουν";
    templateObj.παρέλαβε = "παρέλαβαν";
    templateObj.προορίζει = "προορίζουν";
    templateObj.υποχρεούται = "υποχρεούνται";
    templateObj.εκκενώσει = "εκκενώσουν";
    templateObj.παραδώσει = "παραδώσουν";
    templateObj.κάνει = "κάνουν";
    templateObj.ευθύνεται = "ευθύνονται";
    templateObj.οφείλει_ΜΙΣ = "οφείλουν";
    templateObj.ενημερώνει_ΜΙΣ = "ενημερώνουν";
    templateObj.λάβει_ΜΙΣ = "λάβουν";
    templateObj.Ο_μισθωτής = "Οι μισθωτές";
    templateObj.του_μισθωτή = "των μισθωτών";
    templateObj.τον_μισθωτή = "τους μισθωτές";
    templateObj.στον_μισθωτή = "στους μισθωτές";
    templateObj.ο_ίδιος = "οι ίδιοι";
    templateObj.του_τους_ΜΙΣ = "τους";
    templateObj.αυτόν_ΜΙΣ = "αυτούς";
    templateObj.συμμορφώνεται = "συμμορφώνονται";
    templateObj.αναλαμβάνει = "αναλαμβάνουν";
    templateObj.προβεί = "προβούν";
    templateObj.λαβει = "λάβουν";
  }
  return templateObj;
};

export function transformAiMisthosiData(res) {
  const ekmisthotes = res.εκμισθωτές.map((ekmisthotes) => {
    const obj = {
      onoma_ek: ekmisthotes.όνομα_εκμισθωτή,
      eponymo_ek: ekmisthotes.επώνυμο_εκμισθωτή,
      patronymo_ek: ekmisthotes.όνομα_πατέρα__εκμισθωτή_στην_ονομαστική_κλίση,
      AFM_ek: ekmisthotes.ΑΦΜ_εκμισθωτή,
      katoikia_ek: ekmisthotes.εκμισθωτής_κάτοικος,
      fylo_ek: checkIfFemale(ekmisthotes.όνομα_εκμισθωτή.trim())
        ? "Γυναίκα"
        : "Άνδρας",
    };
    return obj;
  });

  const misthotes = res.μισθωτές.map((misthotes) => {
    const obj = {
      onoma_mi: misthotes.όνομα_μισθωτή,
      eponymo_mi: misthotes.επώνυμο_μισθωτή,
      patronymo_mi: misthotes.όνομα_πατέρα_μισθωτή_στην_ονομαστική_κλίση,
      AFM_mi: misthotes.ΑΦΜ_μισθωτή,
      katoikia_mi: misthotes.μισθωτής_κάτοικος,
      fylo_mi: checkIfFemale(misthotes.όνομα_μισθωτή.trim())
        ? "Γυναίκα"
        : "Άνδρας",
    };
    return obj;
  });

  function transformMisthosi(misthosi) {
    const obj = {
      misthoma: misthosi.μίσθωμα,
      diarkeia: misthosi.διάρκεια_σε_έτη,
      enarxi: reformatDate(misthosi.έναρξη),
      lixi: addYearsToDate(
        reformatDate(misthosi.έναρξη),
        parseInt(misthosi.διάρκεια_σε_έτη)
      ),
      perigrafi: capitalizeFirstLetter(misthosi.περιγραφή_ακινήτου_μισθίου),
      imnia_ypografis: reformatDate(misthosi.ημνία_υπογραφής),
      topos_symvasis: misthosi.τόπος_υπογραφής,
      anaprosarmogi: misthosi.αναπροσαρμογή,
      timarithmiki: misthosi.τιμαριθμική,
      stathero_epitokio: misthosi.σταθερό_επιτόκιο,
      eggyisi: misthosi.εγγύηση,
    };
    return obj;
  }
  const obj = {
    ekmisthotes,
    misthotes,
    misthosi: transformMisthosi(res.μίσθωση),
  };

  return obj;
}
