import MultiEauctionCard from "./MultiPoinikoCard";
import { Link } from "react-router-dom";

const MultiSingleEauction = ({ eauctions }) => {
  console.log(eauctions);
  // const afms = eauctions.map(r => r.afm)

  // const uniqueAfms = [...new Set(afms)]
  return (
    <article>
      {eauctions?.length && (
        <>
          {eauctions.map((singleEauction, i) => {
            const {
              id,
              afm,
              katastasi,
              afm_epispevdon,
              afm_ofeileti,
              eidos,
              epispevdon,
              imerominia_anartisis,
              imerominia_diexagogis,
              imerominia_dimosiefsis,
              onoma_ofeileti,
              timi_1is,
              xaraktiristika,
              link_id,
            } = singleEauction;
            return (
              <MultiEauctionCard
                key={link_id}
                singleEauction={{
                  afm,
                  katastasi,
                  afm_epispevdon,
                  afm_ofeileti,
                  eidos,
                  epispevdon,
                  imerominia_anartisis,
                  imerominia_diexagogis,
                  imerominia_dimosiefsis,
                  onoma_ofeileti,
                  timi_1is,
                  xaraktiristika,
                }}
              />
            );
          })}
        </>
      )}
      <Link className="piso_button" to="/eauction">
        <span>⬅Πίσω</span>
      </Link>
    </article>
  );
};

export default MultiSingleEauction;
