import UpdaceCard from "./UpdateCard";
import { useState } from 'react'
import Pagination from "../Util/Pagination";
import './updates.css';
const MultiEtairies = ({ updates }) => {
  const [page, setPage] = useState(0);
  const updatesMulti = [...updates]
  function dividePages(arr) {
    const res = []
    for (let index = 0; index < arr.length; index++) {
      const el = []
      if (index % 5 === 0) {
        res.push(el)
      }

      let i = Math.floor(index / 5)
      res[i].push(arr[index])
    }
    return res

  }



  const handlePage = async (e) => {
    if (e.target.getAttribute("data-key") === 'next') {
      setPage(page + 1)
    }
    if (e.target.getAttribute("data-key") === 'end') {
      setPage(dividePages(updatesMulti).length - 1)
    }
    if (e.target.getAttribute("data-key") === 'previous') {
      setPage(page - 1)
    }
    if (e.target.getAttribute("data-key") === 'start') {
      setPage(0)
    }



  }



  return (

    <section className="multi-updates" >


      {
        updates?.length
          ? (
            <>
              <article className="case_card pagin"><h3>Τελευταίες Ενημερώσεις</h3>
                <Pagination page={page} length={dividePages(updatesMulti).length} handlePage={handlePage} /></article>

              {
                dividePages(updatesMulti)[page].map((singleUpdate, i) => {
                  const { id, gak, year, court, diadikasia, antikeimeno, eak, arithmos_pinakiou, arithmos_apofasis, apotelesma, note, imerominia_katathesis, check_appeal, appeal, created_at, last_checked, updated_parts, diataktiko, dikasimos, arkat, imnia_eisagwgis, apotelesmate } = singleUpdate
                  return (<UpdaceCard key={`${page}${i}`} singleUpdate={{ id, gak, year, court, diadikasia, antikeimeno, eak, arithmos_pinakiou, arithmos_apofasis, apotelesma, note, imerominia_katathesis, check_appeal, appeal, created_at, last_checked, updated_parts, diataktiko, dikasimos, arkat, imnia_eisagwgis, apotelesmate }} />)
                })
              }

              <article className="case_card pagin">
                <Pagination page={page} length={dividePages(updatesMulti).length} handlePage={handlePage} /></article>
            </>
          ) : <article className="case_card pagin"><h3>Τελευταίες Ενημερώσεις</h3><span>Δεν υπάρχουν ενημερώσεις προς το παρόν</span></article>
      }
    </section >
  );
};

export default MultiEtairies;