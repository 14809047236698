import {
  ChevronDoubleRightIcon,
  ExclamationIcon,
  MoonIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";
import {
  Grid,
  Card,
  Flex,
  Text,
  Bold,
  Icon,
  ProgressBar,
  Title,
  Callout,
  CategoryBar,
  List,
  ListItem,
} from "@tremor/react";
import AstikaDikastiria from "./Parts/AstikaDikastiria";
import { useState, useEffect } from "react";
import AdjDikastiria from "./Parts/AdjDikastiria";
import EaucStats from "./Parts/EaucStats";
import UserStats from "./Parts/UserStats";

const Stats = ({ stats }) => {
  const [initStats, setInitStats] = useState(stats);
  useEffect(() => {
    setInitStats(stats);
  }, [stats]);

  return (
    <Grid numItemsSm={2} numItemsLg={3} className="gap-4 ">
      <AstikaDikastiria data={initStats} />
      <AdjDikastiria data={initStats} />
      <EaucStats data={initStats} />
      <UserStats data={initStats} />
    </Grid>
  );
};

export default Stats;
