import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "axios";

import { useState, useCallback, useEffect } from "react";
import QRCode from "react-qr-code";
// import ProthCard from './ProthCard';

import { useNavigate, useLocation, useParams } from "react-router-dom";
import { DateTime, Settings } from "luxon";

import "./Solon/solonCard.css";
import { dikastiriaLower } from "../Util/makeDikastiriaLowerCase";
import { prothesmiesNeasTaktikis, prothesmiesMikrodiaforon } from "prothesmies";
import { reverseDate } from "../../utils/reverseDate";
// import ExtraInfo from '../Visual/ExtraInfo';
import Container from "../Visual/Container";
import { getTopiki } from "../../utils/getTopiki";
import StoixeiaDikasimou from "./Solon/StoixeiaDikasimou";
import Lawyers from "./Solon/Lawyers";
import Applicants from "./Solon/Applicants";
import Defendants from "./Solon/Defendants";
import Appeals from "./Solon/Appeals";
import Diakinisi from "./Solon/Diakinisi";
Settings.defaultLocale = "el";

const details = {
  epidosi: "Επίδοση",
  paremvasi: "Παρέμβαση, προσεπίκληση, ανακοίνωση ή ανταγωγή",
  paremvasiProsek: "Παρέμβαση του προσεπικαλούμενου",
  protaseis: "Κατάθεση προτάσεων",
  prosthiki: "Κατάθεση προσθήκης",
  opsigeneis: "Οψιγενείς ή παραχρήμα αποδεικνυόμενοι ισχυρισμοί",
  opsigeneisAntikrousi: "Αντίκρουση σε οψιγενείς",
  proskomidi:
    "Προσκομιδή των αποδεικτικών από τους διαδίκους και η υποβολή του έγγραφου υπομνήματος του εναγομένου",
  proskomidiParemv:
    "Προσκομιδή των αποδεικτικών και η υποβολή του έγγραφου υπομνήματος της παρέμβασης ή της ανταγωγής",
  prosthikiParemv: "Κατάθεση προσθήκης επί της παρέμβασης ή της ανταγωγής",
};
const pedia = {
  apotelesma: "Αποτέλεσμα",
  arithmos_apofasis: "Αρ. Απόφασης",
  antikeimeno: "Αντικείμενο",
  arithmos_pinakiou: "Πινάκιο",
  eidos: "Είδος",
  diadikasia: "Διαδικασία",
};
function returnProthArray(proth) {
  const keys = Object.keys(proth);
  const arr = [];
  keys.forEach((r) => {
    const obj = { prothesmia: proth[r], onoma: details[r] };
    arr.push(obj);
  });
  return arr;
}

Date.createFromMysql = function (mysql_string) {
  var t,
    result = null;

  if (typeof mysql_string === "string") {
    t = mysql_string.split(/[- :]/);

    //when t[3], t[4] and t[5] are missing they defaults to zero
    result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
  }

  return result;
};
function formatDate(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({
    minutes: Math.abs(new Date().getTimezoneOffset()),
  });
  return `${formatedDate.toLocaleString(
    DateTime.DATE_FULL
  )}, ${formatedDate.toLocaleString({
    hour: "2-digit",
    minute: "2-digit",
    hourCycle: "h23",
  })}`;
}
function formatDateWithWeekDay(date) {
  const newDate = new Date(Date.createFromMysql(date));

  const formatedDate = DateTime.fromISO(newDate.toISOString()).plus({
    minutes: Math.abs(new Date().getTimezoneOffset()),
  });
  return `${formatedDate.toFormat("EEEE")}, ${formatedDate.toLocaleString(
    DateTime.DATE_FULL
  )}`;
}

const VSolonCard = ({
  singleCase,
  singleUpdates,
  prothesmiesSingle,
  handleDeleteCase,
}) => {
  const [note, setNote] = useState(singleCase?.note);
  const [showProth, setShowProth] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editing, setEditing] = useState(false);
  let params = useParams();

  const [userInput, setUserInput] = useState({});

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  const href = `https://mysolon.gr${location.pathname}/qr`;

  const [checkAppeal, setCheckAppeal] = useState(
    singleCase.check_appeal === 1 ? true : false
  );
  const handleInputChange = (event) => {
    let obj = { ...userInput };
    const key = event.currentTarget.name;
    console.log(event.currentTarget.value);
    obj[key] = event.currentTarget.value;
    setUserInput(obj);
  };
  // const [state, updateState] = useState();

  const afmParam = params?.afm;

  // const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getEauction = async () => {
      try {
        const response = await axiosPrivate.get(
          `/userinput/solon/${singleCase.id}`,
          {
            signal: controller.signal,
          }
        );
        isMounted &&
          response.data[0]?.data &&
          setUserInput(JSON.parse(response.data[0]?.data));
        // isMounted && setCasesFirst(response.data);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getEauction();
    // const interval = setInterval(() => {
    //   getCases()
    // }, 10000)
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getEauction = async () => {
      try {
        const response = await axiosPrivate.post(
          `/userinput/solon/${singleCase.id}`,
          JSON.stringify({ data: userInput }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        // isMounted && setUserInput(response.data);
        console.log(response.data);
        // isMounted && setCasesFirst(response.data);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };
    Object.keys(userInput).length !== 0 && getEauction();

    // console.log(userInput);
    // const interval = setInterval(() => {
    //   getCases()
    // }, 10000)
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInput]);

  useEffect(() => {
    setNote(singleCase.note);
  }, [singleCase]);

  const handleClicks = async (e) => {
    if (e.target.parentNode.getAttribute("data-icon") === "pen-to-square") {
      setShowEdit(!showEdit);
    }
    if (e.target.parentNode.getAttribute("data-icon") === "circle-check") {
      setShowEdit(!showEdit);
      try {
        const id = e.target.parentNode.getAttribute("data-key");
        await axiosPrivate.post("/cases/note", JSON.stringify({ id, note }), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
      } catch (err) {
        console.error(err);
      }
    }
    if (
      e.currentTarget?.firstChild?.getAttribute("data-icon") === "trash-can"
    ) {
      try {
        const id = e.currentTarget.getAttribute("data-key");
        await axiosPrivate.patch("/cases", JSON.stringify({ key: id }), {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        handleDeleteCase(id);
      } catch (err) {
        console.error(err);
      }
    }
    // if (e.target?.parentNode?.getAttribute('data-icon') === null) {
    //   handleOnClick()
    // }
  };

  const handleAppeal = async (e) => {
    setCheckAppeal(!checkAppeal);

    try {
      const id = e.target.getAttribute("data-key");
      await axiosPrivate.post(
        "/appeals",
        JSON.stringify({ id, check: !checkAppeal }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
    } catch (err) {
      console.error(err);
    }
  };
  const handleProth = async (e) => {
    setShowProth(!showProth);
  };
  const handleOnClick = useCallback(() => {
    navigate(`/cases/${singleCase.id}`, { replace: false });
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [navigate, singleCase]);
  const res = /\d{2}\/\d{2}\/\d{4}/.exec(singleCase?.arithmos_pinakiou);
  let imerominia = res ? res[0].replaceAll("/", ".") : undefined;
  let dikasimos = imerominia
    ? reverseDate(imerominia.replaceAll(".", "-"))
    : undefined;
  let prothesmies = [];
  if (
    (singleCase?.diadikasia?.includes("ΝΕΑ") ||
      singleCase?.antikeimeno?.includes("4335")) &&
    singleCase.eak.split("-")[1]
  ) {
    let imer = singleCase.eak.split("-")[1].replaceAll("/", "-").split("-");
    let imerominia = imer[2] + "-" + imer[1] + "-" + imer[0];
    prothesmies = returnProthArray(
      prothesmiesNeasTaktikis(imerominia, {
        dikasimos,
        topiki: getTopiki(singleCase.court),
        exoterikou: userInput.exoterikou === "Ναι",
        dimosio: userInput.dimosio === "Ναι",
        klisi: userInput.klisi === "Ναι",
      })
    ).filter((r) => typeof r.prothesmia === "string");
  }
  if (
    (singleCase?.antikeimeno?.includes("ΜΙΚΡΟΔΙΑΦΟΡ") ||
      singleCase?.diadikasia?.includes("ΜΙΚΡΟΔΙΑΦΟΡ")) &&
    singleCase.eak.split("-")[1] &&
    (singleCase.eak.includes("/2022") ||
      singleCase.eak.includes("/2023") ||
      singleCase.eak.includes("/2024"))
  ) {
    let imer = singleCase.eak.split("-")[1].replaceAll("/", "-").split("-");
    let imerominia = imer[2] + "-" + imer[1] + "-" + imer[0];
    prothesmies = returnProthArray(
      prothesmiesMikrodiaforon(imerominia, {
        topiki: getTopiki(singleCase.court),
        exoterikou: userInput.exoterikou === "Ναι",
        dimosio: userInput.dimosio === "Ναι",
      })
    ).filter((r) => typeof r.prothesmia === "string");
  }
  const diakinisi =
    singleCase?.extra_info?.diakinisi &&
    JSON.parse(singleCase.extra_info.diakinisi).length > 0 &&
    JSON.parse(singleCase.extra_info.diakinisi)[0].imnia_apostolis !== ""
      ? JSON.parse(singleCase.extra_info.diakinisi)
      : undefined;
  const appeals =
    singleCase?.extra_info?.appeals &&
    JSON.parse(singleCase.extra_info.appeals).length > 0 &&
    JSON.parse(singleCase.extra_info.appeals)[0].etos_endikou !== ""
      ? JSON.parse(singleCase.extra_info.appeals)
      : undefined;
  const dimosiefseis =
    singleCase?.extra_info?.dimosiefseis &&
    JSON.parse(singleCase.extra_info.dimosiefseis).length > 0 &&
    JSON.parse(singleCase.extra_info.dimosiefseis)[0].imnia_dimosiefsis !== ""
      ? JSON.parse(singleCase.extra_info.dimosiefseis)
      : undefined;

  const stoixeia_dikasimou = singleCase?.extra_info?.stoixeia_dikasimou
    ? JSON.parse(singleCase.extra_info.stoixeia_dikasimou)
    : undefined;
  const lawyers = singleCase?.extra_info?.lawyers
    ? JSON.parse(singleCase.extra_info.lawyers)
    : undefined;
  const applicants = singleCase?.extra_info?.applicants
    ? JSON.parse(singleCase.extra_info.applicants)
    : undefined;
  const defendants = singleCase?.extra_info?.defendants
    ? JSON.parse(singleCase.extra_info.defendants)
    : undefined;

  return (
    <section className="kartela-solon">
      <section className="stoixeia-dikasimos-prothesmies">
        <article className="stoixeia-ypothesis">
          <p>Καρτέλα Υπόθεσης</p>

          {singleCase?.note?.trim() ? (
            <h1>{singleCase?.note}</h1>
          ) : (
            <h1>
              ΓΑΚ {singleCase.gak}/
              {singleCase?.eak
                ? singleCase.eak.split("-")[1].replaceAll("/", ".")
                : singleCase.year}{" "}
              {dikastiriaLower(singleCase?.court)}
            </h1>
          )}
          {singleCase?.note?.trim() && (
            <h2>
              ΓΑΚ {singleCase.gak}/
              {singleCase?.eak
                ? singleCase.eak.split("-")[1].replaceAll("/", ".")
                : singleCase.year}{" "}
              {dikastiriaLower(singleCase?.court)}
            </h2>
          )}
          <h3>Είδος</h3>
          {singleCase?.eidos?.trim() ? (
            <p className="stoixeia-text">{singleCase?.eidos}</p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
          <h3>Πινάκιο</h3>
          {singleCase?.arithmos_pinakiou?.trim() ? (
            <p className="stoixeia-text">{singleCase?.arithmos_pinakiou}</p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
          <h3>Διαδικασία</h3>
          {singleCase?.diadikasia?.trim() ? (
            <p className="stoixeia-text">{singleCase?.diadikasia}</p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
          <h3>Αντικείμενο</h3>
          {singleCase?.antikeimeno?.trim() ? (
            <p className="stoixeia-text">{singleCase?.antikeimeno}</p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
          <h3>Αποτέλεσμα</h3>
          {singleCase?.apotelesma?.trim() ? (
            <p className="stoixeia-text">{singleCase?.apotelesma}</p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
          <h3>Αριθμός Απόφασης</h3>
          {singleCase?.arithmos_apofasis?.trim() ? (
            <p className="stoixeia-text">{singleCase?.arithmos_apofasis}</p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
          {dimosiefseis && (
            <>
              <h3>Τύπος Διατακτικού:</h3>
              <p className="stoixeia-text">
                {dimosiefseis[0].typos_diataktikou}
              </p>
              <h3>Ημ/νία Δημοσίευσης:</h3>
              <p className="stoixeia-text">
                {dimosiefseis[0].imnia_dimosiefsis}
              </p>
              <h3>Δια ταύτα:</h3>
              {dimosiefseis[0].dia_tafta.trim() ? (
                <p className="stoixeia-text">{dimosiefseis[0].dia_tafta}</p>
              ) : (
                <p className="stoixeia-text empty">---</p>
              )}
            </>
          )}
          <h3>Δημιουργήθηκε στις:</h3>
          {singleCase?.created_at?.trim() ? (
            <p className="stoixeia-text">
              {formatDate(singleCase?.created_at)}
            </p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
          <h3>Τελευταίος έλεγχος για ενημερώσεις στις:</h3>
          {singleCase?.last_checked?.trim() ? (
            <p className="stoixeia-text">
              {formatDate(singleCase?.last_checked)}
            </p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
          {diakinisi && <h3>Διακίνηση Υπόθεσης:</h3>}
          {diakinisi && (
            <p className="stoixeia-text">
              Τμήμα: {diakinisi.at(-1).tmima_pros} - Ημ/νία:{" "}
              {diakinisi.at(-1).imnia_apostolis}
            </p>
          )}
          {appeals && <h3>Ένδικα Μέσα:</h3>}
          {appeals && (
            <p className="stoixeia-text">
              Είδος: {appeals.at(-1).eidos} - ΓΑΚ: {appeals.at(-1).gak_endikou}{" "}
              - Έτος: {appeals.at(-1).etos_endikou}
            </p>
          )}
          <h3>Τελευταίος έλεγχος Διακίνησης και Ενδίκων Μέσων στις:</h3>
          {singleCase?.extra_info?.last_checked?.trim() ? (
            <p className="stoixeia-text">
              {formatDate(singleCase?.extra_info?.last_checked)}
            </p>
          ) : (
            <p className="stoixeia-text empty">---</p>
          )}
        </article>
        <section className="dikasimos-prothesmies">
          <article className="dikasimos">
            <h2>Δικάσιμος</h2>
            {imerominia?.trim() ? (
              <p className="stoixeia-text">{imerominia}</p>
            ) : (
              <p className="stoixeia-text empty">---</p>
            )}
          </article>
          <article className="prothesmies-kartela">
            <h2>Προθεσμίες</h2>
            {prothesmies.length ? (
              prothesmies.map(
                (r) =>
                  r.prothesmia &&
                  r.onoma && (
                    <div key={r.onoma}>
                      <p className="prothesmies-text">{r.onoma}</p>
                      <p className="prothesmies-date">
                        {reverseDate(r.prothesmia).replaceAll("-", ".")}
                      </p>{" "}
                    </div>
                  )
              )
            ) : (
              <p className="prothesmies-text">---</p>
            )}

            {/* {imerominia.trim() ? <p className='stoixeia-text'>{imerominia}</p> : <p className='stoixeia-text empty'>---</p>} */}
          </article>
          <div
            style={{
              height: "auto",
              margin: "0 auto",
              maxWidth: 64,
              width: "100%",
            }}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={href}
              viewBox={`0 0 256 256`}
            />
          </div>
        </section>
      </section>
      <section className="solon-info">
        {lawyers && <Lawyers data={lawyers} />}
        {applicants && <Applicants data={applicants} />}
        {defendants && <Defendants data={defendants} />}
        {stoixeia_dikasimou && <StoixeiaDikasimou data={stoixeia_dikasimou} />}
        {appeals && <Appeals data={appeals} />}
        {diakinisi && <Diakinisi data={diakinisi} />}

        <Container handleChange={handleInputChange} userInput={userInput} />
      </section>
      <section className="solon-enimerwseis">
        <article className="enimerwseis">
          <h2 className="enimerwseis-title">Ενημερώσεις</h2>
          {singleUpdates.length ? (
            <ol>
              {singleUpdates.map(
                (r) =>
                  r.updated_parts !== " " && (
                    <li key={r.id} className="enimerwseis-list-item">
                      <span className="enimerwseis-date">
                        {formatDateWithWeekDay(r.created_at)}
                      </span>
                      <p className="enimerwseis-allages-title">
                        Αλλαγές στα πεδία:
                      </p>
                      {r.updated_parts.split(",").map(
                        (k) =>
                          r[k]?.trim() !== "" && (
                            <p
                              className="enimerwseis-pedia-enimerwsi"
                              key={r[k]}
                            >
                              <span className="enimerwseis-pedia">
                                {pedia[k]}:{" "}
                              </span>
                              <span className="enimerwseis-enimerwsi">
                                {r[k]}
                              </span>
                            </p>
                          )
                      )}
                    </li>
                  )
              )}
            </ol>
          ) : (
            <p>Καμμία ενημέρωση προς το παρόν</p>
          )}
        </article>
      </section>
    </section>
  );
};

export default VSolonCard;
