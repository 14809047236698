import { removeDiacriticsAndCapitalize } from "../../utils/checkMisthosi";

export const glosses = [
  "Μανδαρινικά",
  "Ισπανικά",
  "Αγγλικά",
  "Χίντι",
  "Αραβικά",
  "Πορτογαλικά",
  "Βεγγαλικά (Μπάνγκλα)",
  "Ρωσικά",
  "Ιαπωνικά",
  "Παντζαμπικά",
  "Γερμανικά",
  "Ιαβανεζικά",
  "Γου (Σαγκαϊνεζικά)",
  "Μαλαϊκά",
  "Τελούγκου",
  "Βιετναμικά",
  "Κορεατικά",
  "Γαλλικά",
  "Μαράτι",
  "Ταμίλ",
  "Ούρντου",
  "Τουρκικά",
  "Ιταλικά",
  "Γιούε (συμπεριλαμβανομένων των Καντονέζικων)",
  "Ταϊλανδικά",
  "Γκουτζαρατικά",
  "Τζιν",
  "Νότια Μιν (συμπεριλαμβανομένων των Χόκκιεν και Τεοτσέου)",
  "Περσικά",
  "Πολωνικά",
  "Παστού",
  "Κανάντα",
  "Σιάνγκ (Χουνανέζικα)",
  "Μαλαγιαλάμ",
  "Σουνδανεζικά",
  "Χάουσα",
  "Όντια (Ορίγια)",
  "Βιρμανικά",
  "Χάκκα",
  "Ουκρανικά",
  "Μποτζπουρί",
  "Ταγκαλόγκ (Φιλιππινέζικα)",
  "Γιορούμπα",
  "Μαϊθίλι",
  "Ουζμπεκικά",
  "Σίντι",
  "Αμχαρικά",
  "Φούλα",
  "Ρουμανικά",
  "Ορόμο",
  "Ίγκμπο",
  "Αζερικά",
  "Αουάντι",
  "Κινεζικά Γκαν",
  "Σεμπουάνο (Βισαγιαϊκά)",
  "Ολλανδικά",
  "Κουρδικά",
  "Σερβικά",
  "Κροατικά",
  "Μαλαγασικά",
  "Σαραΐκι",
  "Νεπαλικά",
  "Σινχαλεζικά",
  "Τσιταγκονικά",
  "Ζουάνγκ",
  "Χμερ",
  "Τουρκμενικά",
  "Ασσαμικά",
  "Μαδουρεζικά",
  "Σομαλικά",
  "Μαρβάρι",
  "Μαγκάχι",
  "Χαργιάνβι",
  "Ουγγρικά",
  "Τσατισγκάρι",
  "Ελληνικά",
  "Τσέουα",
  "Ντεκάν",
  "Ακάν",
  "Καζακικά",
  "Βόρεια Μιν",
  "Σιλχέτι",
  "Ζουλού",
  "Τσεχικά",
  "Κινιαρουάντα",
  "Ντουντάρι",
  "Αϊτιανά Κρεολικά",
  "Ανατολικά Μιν",
  "Ιλοκάνο",
  "Κέτσουα",
  "Κιρούντι",
  "Σουηδικά",
  "Χμονγκ",
  "Σόνα",
  "Ουιγουρικά",
  "Χιλιγκαϊνόν/Ιλόνγκο (Βισαγιαϊκά)",
  "Μόσσι",
  "Ξόσα",
  "Λευκορωσικά",
  "Μπαλότσι",
  "Κονκάνι",
  "Φινλανδικά",
  "Νορβηγικά",
  "Ουαλικά",
  "Βουλγαρικά",
  "Αλβανικά",
  "Σλοβακικά",
  "Γεωργιανά",
  "Αρμενικά",
  "Φλαμανδικά",
  "Ισλανδικά",
  "Εσθονικά",
  "Εβραϊκά",
  "Λιθουανικά",
  "Μαλτέζικα",
  "Ιρλανδικά",
  "Σλοβενικά",
  "Λετονικά",
  "Δανικά",
]
  .map((dim) => removeDiacriticsAndCapitalize(dim))
  .sort();
