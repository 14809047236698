import {
  Card,
  Metric,
  Title,
  List,
  ListItem,
  Text,
  Subtitle,
} from "@tremor/react";

const MyPortal = () => {
  return (
    <Card maxWidth="max-w-2xl">
      <Metric>MyPortal 3</Metric>
      <Title>Links για την εφαρμογή</Title>

      <List>
        <ListItem>
          <Text>Windows</Text>
          <Text>
            <a
              href="https://mysolon-apps.s3.nl-ams.scw.cloud/myportal/MyPortal3-3.0.3-setup.exe"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Windows',
                // })
              }}
            >
              MyPortal3-3.0.3-setup.exe
            </a>
          </Text>
        </ListItem>
        {/* <ListItem>
          <Text>Windows Portable (δεν χρειάζεται εγκατάσταση)</Text>
          <Text>
            <a
              href="https://mysolon.gr/dist/My-Portal-0.1.1-portable.exe"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Windows_Portable',
                // })
              }}
            >
              My-Portal-0.1.1-portable.exe
            </a>
          </Text>
        </ListItem> */}
        <ListItem>
          <Text>Mac</Text>
          <Text>
            <a
              href="https://mysolon-apps.s3.nl-ams.scw.cloud/myportal/MyPortal3-3.0.3.dmg"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Mac_Intel',
                // })
              }}
            >
              MyPortal3-3.0.3.dmg
            </a>
          </Text>
        </ListItem>
        {/* <ListItem><Text>Mac (Apple Silicon)</Text><Text><a href="https://mysolon.gr/dist/my-portal-0.1.0-Apple-Silicon.dmg" onClick={() => {
          window.swetrix.track({
            ev: 'Mac_Apple_Silicon',
          })
        }}>my-portal-0.1.0-Apple-Silicon.dmg</a></Text></ListItem> */}
        {/* <ListItem>
          <Text>Linux (Deb)</Text>
          <Text>
            <a
              href="https://ktima-img.s3.nl-ams.scw.cloud/myportal2_0.0.7_amd64.deb"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Linux_Deb'
                // })
              }}
            >
              myportal2_0.0.7_amd64.deb
            </a>
          </Text>
        </ListItem> */}
        {/* <ListItem>
          <Text>Linux (Snap)</Text>
          <Text>
            <a
              href="https://drive.google.com/file/d/1QAh5pMuIS2wND3A-KjmDNlaE32NS2pXD/view?usp=share_link"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Linux_Snap'
                // })
              }}
            >
              my-portal_0.1.0_amd64.snap
            </a>
          </Text>
        </ListItem> */}
        {/* <ListItem>
          <Text>Linux (Appimage)</Text>
          <Text>
            <a
              href="https://drive.google.com/file/d/1Wp4s4ini410i-qveCV7Vd_QxlMtIDDuU/view?usp=share_link"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Linux_Appimage'
                // })
              }}
            >
              my-portal-0.1.0.AppImage
            </a>
          </Text>
        </ListItem> */}
      </List>
      <Subtitle>
        Αλλαγές στην έκδοση{" "}
        <a
          href="https://mysolon.userjot.com/updates/p/myportal-303"
          target="_blank"
          rel="noopener noreferrer"
        >
          3.0.3
        </a>
      </Subtitle>
    </Card>
  );
};

export default MyPortal;
