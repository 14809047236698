import React from "react";

const DiatagiRow = ({ handleDiatagiInputChange, diatagi }) => {
  const { edra = "", edra_topos = "" } = diatagi;
  return (
    <div className="all">
      <div className="vertical-line-focus"></div>
      <div className="form-section-row">
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="edra">
            Υλική Αρμοδιότητα
          </label>
          <select
            className="form-section-input"
            id="edra"
            value={diatagi.edra}
            name="edra"
            onChange={handleDiatagiInputChange}
          >
            <option value="Ειρηνοδικείο">Ειρηνοδικείο</option>
            <option value="Πρωτοδικείο">Πρωτοδικείο</option>
          </select>
        </div>
        <div className="form-section-single-input">
          <label className="form-section-label" htmlFor="edra_topos">
            Τοπική Αρμοδιότητα
          </label>
          <input
            className="form-section-input"
            type="text"
            id="edra_topos"
            placeholder="πχ. Αθηνών"
            name="edra_topos"
            value={diatagi.edra_topos}
            onChange={handleDiatagiInputChange}
          />
        </div>
      </div>
    </div>
  );
};

export default DiatagiRow;
