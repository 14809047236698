import React from 'react'

const EirPatras = ({ singleCase, handleKeyPress, handleClicks, showEdit, note, setNote }) => {
  const fixEidos = { asfalistika: 'Ασφαλιστικά', eidiki: 'Ειδική', ekousia: 'Εκουσία (Ν.4055/12)', mikr: 'Μικροδιαφορές', taktiki: 'Τακτική', yper: 'Υπερχρεωμένα (Ν.3869/10)', ptox: 'Πτωχεύσεις (Ν.4738/20)' }
  const data = singleCase?.data && JSON.parse(singleCase?.data)
  return (

    <>
      {(singleCase?.data && data.arapofasis) &&
        <>
          <h4><em>Αρ. Κατάθεσης: </em>{singleCase?.search_value}</h4 >
          <h4><em>Διαδικασία: </em>{fixEidos[singleCase?.eidos]}</h4 >
          <h4><em>Αρ.Απόφασης: </em>{data.arapofasis}</h4 >
        </>}



      {(singleCase?.data && !data.ardimosiefsis) &&

        <>
          <h4><em>Αρ. Κατάθεσης: </em>{data.arkat}</h4 ><h4>Δεν έχει εκδοθεί απόφαση έως τώρα</h4 >
        </>}
    </>





  )
}

export default EirPatras