import React, { useEffect, useState } from "react";
import { Card, Button } from "@tremor/react";
import { Show } from "../Social/Choose/Show";

const ProfileData = ({ initUserData, setEditProfile, sameUuid, fields }) => {
  const [userData, setUserData] = useState(initUserData);
  console.log("fields", fields);

  useEffect(() => {
    setUserData(initUserData);
  }, [initUserData]);

  console.log("userData", userData);

  return (
    <Card>
      <div className="profile-data">
        <div className="profile-data-item">
          <span className="profile-data-item-label">Όνομα</span>
          <span className="profile-data-item-value">
            {userData.first_name ? userData.first_name : "-"}
          </span>
        </div>
        <div className="profile-data-item">
          <span className="profile-data-item-label">Επώνυμο</span>
          <span className="profile-data-item-value">
            {userData.last_name ? userData.last_name : "-"}
          </span>
        </div>
        <div className="profile-data-item">
          <span className="profile-data-item-label">Σύλλογος</span>
          <span className="profile-data-item-value">
            {userData.syllogos ? userData.syllogos : "-"}
          </span>
        </div>
        <div className="profile-data-item">
          <span className="profile-data-item-label">Αρ. Μητρώου</span>
          <span className="profile-data-item-value">
            {userData.am ? userData.am : "-"}
          </span>
        </div>
        <div className="profile-data-item">
          <span className="profile-data-item-label">Έδρα</span>
          <span className="profile-data-item-value">
            {userData.edra ? userData.edra : "-"}
          </span>
        </div>
        {userData.askoumenos === "1" && (
          <div className="profile-data-item">
            <span className="profile-data-item-label">Ασκούμενος</span>
            <span className="profile-data-item-value">Ναι</span>
          </div>
        )}
        {/* <div className="profile-data-item">
          <span className="profile-data-item-label">Σύνδεσμος στο Μητρώο</span>
          <span className="profile-data-item-value">
            {userData.syllogos_link ? (
              <a href={userData.syllogos_link} target="_blank" rel="noreferrer">
                {decodeURI(userData.syllogos_link)}
              </a>
            ) : (
              "-"
            )}
          </span>
        </div> */}
      </div>
      <section className="profile-drastiriopoiisi mt-6">
        <h2>
          <strong>
            Θέλω να λαμβάνω υπενθύμιση με email, 7 ημέρες πριν την δικάσιμο,
            προθεσμία κτλ.
          </strong>

          {userData.notify && (
            <div className="profile-data-item">
              <span className="profile-data-item-value">
                {userData.notify === "1" ? "Ναι" : "Όχι"}
              </span>
            </div>
          )}
        </h2>
      </section>
      {sameUuid && (
        <button className="button-profile" onClick={setEditProfile}>
          Επεξεργασία
        </button>
      )}
      <section className="profile-drastiriopoiisi mt-6">
        <h2>
          <strong>Περιοχές Δραστηριοποίησης</strong>
        </h2>
      </section>
      <div className="profile-form-inputs mt-3">
        {fields.eirin.length > 0 && (
          <Show fields={fields.eirin} desc={"Ειρηνοδικεία"} />
        )}
        {fields.protod.length > 0 && (
          <Show fields={fields.protod} desc={"Πρωτοδικεία"} />
        )}
        {fields.efeteia.length > 0 && (
          <Show fields={fields.efeteia} desc={"Εφετεία"} />
        )}
        {fields.dioik.length > 0 && (
          <Show fields={fields.dioik} desc={"Διοικ.Πρωτοδικεία"} />
        )}
        {fields.dioikEfeteia.length > 0 && (
          <Show fields={fields.dioikEfeteia} desc={"Διοικ.Εφετεία"} />
        )}
        {fields.ypoth.length > 0 && (
          <Show fields={fields.ypoth} desc={"Υποθηκοφυλακεία"} />
        )}
        {fields.ktimat.length > 0 && (
          <Show fields={fields.ktimat} desc={"Κτημ.Γραφεία"} />
        )}
        {fields.dimoi.length > 0 && (
          <Show fields={fields.dimoi} desc={"Δήμοι/Ληξιαρχεία"} />
        )}
        {fields.DOY.length > 0 && <Show fields={fields.DOY} desc={"ΔΟΥ"} />}
        {fields.metaf.length > 0 && (
          <Show fields={fields.metaf} desc={"Μεταφράσεις"} />
        )}
      </div>
      {sameUuid && (
        <button className="button-profile" onClick={setEditProfile}>
          Επεξεργασία
        </button>
      )}
    </Card>
  );
};

export default ProfileData;
