export const eirinodikiaIndex = [
  {
    client: "ΑΓΙΑΣ",
    server: "Λάρισας",
  },
  {
    client: "ΑΙΓΙΝΑΣ",
    server: "Αιγίνης",
  },
  {
    client: "ΑΛΕΞΑΝΔΡΕΙΑΣ ΗΜΑΘΙΑΣ",
    server: "Αλεξάνδρειας",
  },
  {
    client: "Ανδρίτσαινας",
    server: "Ανδρίτσαινης",
  },
  {
    client: "Άνω Καλεντίνης",
    server: "Καλεντίνης",
  },
  {
    client: "Αρναίας Χαλκιδικής",
    server: "Αρναίας",
  },
];
export function isValidDateFormat(dateString) {
  // Define the regular expression to match YYYY/MM/DD
  const regex = /^\d{4}\/\d{2}\/\d{2}$/;

  // Test the dateString against the regular expression
  return regex.test(dateString);
}
export function transformDateFormat(dateString) {
  // Split the original date string by '/'
  const parts = dateString.split("/");

  // Check if the date string is in the correct format
  if (
    parts.length !== 3 ||
    parts[0].length !== 4 ||
    parts[1].length !== 2 ||
    parts[2].length !== 2
  ) {
    return "Invalid date format";
  }

  // Reorder the date parts and join them with '.'
  const transformed = `${parts[2]}.${parts[1]}.${parts[0]}`;

  return transformed;
}
