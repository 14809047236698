import ReactModal from "react-modal";
import HeaderAvatar from "../Visual/HeaderAvatar";
import { Link } from "react-router-dom";

export const LikesModal = ({ isOpen, onRequestClose, likes }) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={{
      content: {
        height: "80vh",
        margin: "0 auto",
        width: "fit-content",
        maxWidth: "85vw",
        minWidth: "50vw",
        zIndex: "1000",
      },
      overlay: {
        zIndex: "999",
      },
    }}
  >
    <div className="likes-modal-header">
      <ul className="likes-modal">
        {likes.map((user) => (
          <Link to={`/profile/${user.uuid}`}>
            <li
              key={user.id}
              className="post-header-poster post-header-details"
            >
              <HeaderAvatar
                userAvatar={user.avatar_uuid}
                avatarClass={"smd"}
                uuid={user.uuid}
                avatarUploaded={false}
                badge={user.badge === 1}
                rank={user?.rank}
              />
              {user.email}
            </li>
          </Link>
        ))}
      </ul>
    </div>
    <div className="likes-modal-button-section">
      <button
        onClick={onRequestClose}
        className="addcase_modal check_now post-footer-delete"
      >
        Κλείσιμο
      </button>
    </div>
  </ReactModal>
);
