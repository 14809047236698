export function getCurrentDateTime() {
  const now = new Date();

  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
export function getCurrentUTCDateTime() {
  const date = new Date();

  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2); // add leading zero if necessary
  const day = ("0" + date.getUTCDate()).slice(-2); // add leading zero if necessary

  const hours = ("0" + date.getUTCHours()).slice(-2); // add leading zero if necessary
  const minutes = ("0" + date.getUTCMinutes()).slice(-2); // add leading zero if necessary
  const seconds = ("0" + date.getUTCSeconds()).slice(-2); // add leading zero if necessary

  return (
    year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
  );
}
