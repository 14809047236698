import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "../api/axios";

const Confirmation = () => {
  const [confirmed, setConfirmed] = React.useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  // get uid search param from url and store it in a variable
  const uid = urlParams.get("uid");
  useEffect(() => {
    console.log(uid);
    // send uid to backend using axios
    axios.get(`/confirmation/${uid}`).then((res) => {
      console.log(res.data);
      // set confirmed to true if email was sent
      if (res.data.message === "Email confirmed") setConfirmed(true);
    });
  }, [uid]);

  return (
    <div>
      {/* If confirmed is true, show a message */}
      {confirmed && (
        <div>
          <p>Επιβεβαιώθηκε το email σας</p>
          <p>Μπορείτε να συνδεθείτε</p>
          <Link to="/login">Σύνδεση</Link>
        </div>
      )}
    </div>
  );
};

export default Confirmation;
