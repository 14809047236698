import { Icon, Flex, Title, Bold } from "@tremor/react";
import { CheckCircleIcon } from "@heroicons/react/solid";

import EauctionCard from "./PoinikoCard";
import { getPreviousDay } from "../../utils/checkMisthosi";

const MultiPoiniko = ({ eauctions, prothesmies, handleDeleteEauction }) => {
  // const afms = eauctions.map(r => r.afm)
  // const uniqueAfms = [...new Set(afms)]
  // console.log(uniqueAfms);
  console.log("eauctions", eauctions);

  return (
    <article>
      {eauctions?.length ? (
        <>
          {/* <Flex>
            <Flex justifyContent="center" className="-space-x-2 -mr-2">
              <Icon
                icon={CheckCircleIcon}
                color="emerald"
                tooltip="Operational"
              />

              <Title className="pl-2">
                Έγινε έλεγχος πλειστηριασμών μέχρι:{" "}
                <Bold>{getPreviousDay()}</Bold>
                {"  "}
              </Title>
            </Flex>
          </Flex> */}
          {eauctions
            .sort((a, b) =>
              a.created_at < b.created_at
                ? 1
                : b.created_at < a.created_at
                ? -1
                : 0
            )
            .map((eauction, i) => {
              return (
                <EauctionCard
                  key={`eauction-${i}`}
                  singleEauction={{
                    i,
                    id: eauction.id,
                    note: eauction.note,
                    abm: eauction.abm,
                    eisag: eauction.eisag,
                    aa: eauction.poinika[0]?.aa,
                    court: eauction.poinika[0]?.court,
                    ora: eauction.poinika[0]?.ora,
                    created_at: eauction.created_at,
                    date: eauction.poinika[0]?.date,
                    eidos: eauction.poinika[0]?.eidos,
                    gad: eauction.poinika[0]?.gad,
                    pinakio: eauction.poinika[0]?.pinakio,
                    room: eauction.poinika[0]?.room,
                    tropos: eauction.poinika[0]?.tropos,
                  }}
                  handleDeleteEauction={handleDeleteEauction}
                />
              );
            })}
        </>
      ) : (
        <p>Δεν έχετε καταχωρήσει ΑΒΜ προς έλεγχο</p>
      )}
    </article>
  );
};
export default MultiPoiniko;
