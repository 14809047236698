import {
  removeDiacriticsAndCapitalize,
  removeDuplicates,
} from "../../utils/checkMisthosi";

export const ktimat = removeDuplicates([
  "ΒΕΡΟΙΑΣ",
  "ΚΑΡΔΙΤΣΑΣ",
  "ΠΟΛΥΓΥΡΟΥ",
  "ΔΥΤΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ",
  "ΠΡΕΒΕΖΑΣ",
  "ΛΕΥΚΑΔΑΣ",
  "ΠΟΛΥΓΥΡΟΥ",
  "ΚΑΛΑΜΑΡΙΑΣ",
  "ΠΕΡΙΣΤΕΡΙΟΥ",
  "ΕΟΡΔΑΙΑΣ",
  "ΚΟΡΙΝΘΟΥ",
  "ΝΑΥΠΛΙΟΥ",
  "ΧΙΟΥ",
  "ΑΝΑΤ. ΜΑΚΕΔΟΝΙΑΣ & ΘΡΑΚΗΣ",
  "ΠΕΙΡΑΙΩΣ & ΝΗΣΩΝ",
  "ΚΟΡΙΝΘΟΥ",
  "ΠΑΤΜΟΥ",
  "ΧΑΛΑΝΔΡΙΟΥ",
  "ΠΕΛΟΠΟΝΝΗΣΟΥ",
  "ΣΤΕΡΕΑΣ ΕΛΛΑΔΑΣ",
  "ΚΥΚΛΑΔΩΝ",
  "ΒΕΡΟΙΑΣ",
  "ΣΠΑΡΤΗΣ",
  "ΕΔΕΣΣΑΣ",
  "ΝΕΑΣ ΙΩΝΙΑΣ",
  "ΣΕΡΡΩΝ",
  "ΔΥΤΙΚΗΣ ΕΛΛΑΔΑΣ",
  "ΑΙΓΙΑΛΕΙΑΣ",
  "ΑΙΓΙΟΥ",
  "ΒΟΡΕΙΟΥ ΑΙΓΑΙΟΥ",
  "ΕΔΕΣΣΑΣ",
  "ΗΛΙΟΥΠΟΛΗΣ",
  "ΚΑΛΛΙΘΕΑΣ",
  "ΚΑΛΑΜΑΤΑΣ",
  "ΠΥΡΓΟΥ",
  "ΠΕΡΙΣΤΕΡΙΟΥ",
  "ΤΡΙΚΑΛΩΝ",
  "ΑΜΦΙΣΣΑΣ",
  "ΠΡΕΒΕΖΑΣ",
  "ΛΕΙΒΑΔΙΑΣ",
  "ΓΡΕΒΕΝΩΝ",
  "ΑΡΤΑΣ",
  "ΚΥΠΑΡΙΣΣΙΑΣ",
  "ΧΑΛΑΝΔΡΙΟΥ",
  "ΚΑΛΑΜΑΡΙΑΣ",
  "ΕΔΕΣΣΑΣ",
  "ΠΕΛΟΠΟΝΝΗΣΟΥ",
  "ΑΤΤΙΚΗΣ",
  "ΓΙΑΝΝΙΤΣΩΝ",
  "ΚΑΡΠΕΝΗΣΙΟΥ",
  "ΚΟΡΙΝΘΟΥ",
  "ΠΥΡΓΟΥ",
  "ΑΜΑΛΙΑΔΑΣ",
  "ΜΕΣΟΛΟΓΓΙΟΥ",
  "ΚΟΡΙΝΘΟΥ",
  "ΧΑΝΙΩΝ",
  "ΤΥΡΝΑΒΟΥ",
  "ΜΑΡΑΘΩΝΑ",
  "ΗΓΟΥΜΕΝΙΤΣΑΣ",
  "ΘΗΡΑΣ",
  "ΜΗΛΟΥ",
  "ΚΥΠΑΡΙΣΣΙΑΣ",
  "ΗΛΙΟΥΠΟΛΗΣ",
  "ΣΤΕΡΕΑΣ ΕΛΛΑΔΑΣ",
  "ΛΗΜΝΟΥ",
  "ΑΓΙΟΥ ΝΙΚΟΛΑΟΥ",
  "ΠΕΙΡΑΙΩΣ & ΝΗΣΩΝ",
  "ΣΠΑΡΤΗΣ",
  "ΖΑΚΥΝΘΟΥ",
  "ΒΕΡΟΙΑΣ",
  "ΤΗΝΟΥ",
  "ΠΥΡΓΟΥ",
  "ΗΠΕΙΡΟΥ",
  "ΚΑΛΥΜΝΟΥ",
  "ΧΑΛΚΙΔΑΣ",
  "ΛΕΡΟΥ",
  "ΣΑΜΟΥ",
  "ΔΥΤΙΚΗΣ ΕΛΛΑΔΑΣ",
  "ΜΕΣΟΛΟΓΓΙΟΥ",
  "ΑΙΓΙΑΛΕΙΑΣ",
  "ΑΙΓΙΟΥ",
  "ΚΕΝΤΡΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ",
  "ΚΑΛΑΜΑΤΑΣ",
  "ΣΠΑΡΤΗΣ",
  "ΚΟΡΙΝΘΟΥ",
  "ΣΕΡΡΩΝ",
  "ΛΕΥΚΑΔΑΣ",
  "ΜΕΣΟΛΟΓΓΙΟΥ",
  "ΠΕΛΟΠΟΝΝΗΣΟΥ",
  "ΑΙΓΙΑΛΕΙΑΣ",
  "ΑΙΓΙΟΥ",
  "ΤΡΙΚΑΛΩΝ",
  "ΣΕΡΡΏΝ",
  "ΗΠΕΙΡΟΥ",
  "ΣΕΡΡΩΝ",
  "ΑΜΑΛΙΑΔΑΣ",
  "ΣΑΜΟΥ",
  "ΚΑΛΑΜΑΤΑΣ",
  "ΒΟΡΕΙΟΥ ΑΙΓΑΙΟΥ",
  "ΘΕΣΣΑΛΟΝΙΚΗΣ",
  "ΑΘΗΝΩΝ",
  "ΠΟΛΥΓΥΡΟΥ",
  "ΤΡΙΚΑΛΩΝ",
  "ΣΕΡΡΩΝ",
  "ΧΑΝΙΩΝ",
  "ΚΑΡΔΙΤΣΑΣ",
  "ΗΠΕΙΡΟΥ",
  "ΚΥΠΑΡΡΙΣΙΑΣ",
  "ΣΠΑΡΤΗΣ",
  "ΧΑΛΚΙΔΑΣ",
  "ΔΥΤΙΚΗΣ ΜΑΚΕΔΟΝΙΑΣ",
  "ΡΕΘΥΜΝΟΥ",
  "ΧΙΟΥ",
  "ΠΑΛΑΙΟΥ ΦΑΛΗΡΟΥ",
  "ΘΕΣΣΑΛΙΑΣ",
  "ΚΗΦΙΣΙΑΣ",
])
  .map((dim) => removeDiacriticsAndCapitalize(dim))
  .sort();
