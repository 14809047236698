import { Icon, Flex, Title, Bold } from "@tremor/react";
import { CheckCircleIcon } from "@heroicons/react/solid";

import EauctionCard from "./EauctionCard";
import { getPreviousDay } from "../../utils/checkMisthosi";

const MultiEauction = ({ eauctions, prothesmies, handleDeleteEauction }) => {
  // const afms = eauctions.map(r => r.afm)
  // const uniqueAfms = [...new Set(afms)]
  // console.log(uniqueAfms);

  return (
    <article>
      {eauctions?.length ? (
        <>
          <Flex>
            <Flex justifyContent="center" className="-space-x-2 -mr-2">
              <Icon
                icon={CheckCircleIcon}
                color="emerald"
                tooltip="Operational"
              />

              <Title className="pl-2">
                Έγινε έλεγχος πλειστηριασμών μέχρι:{" "}
                <Bold>{getPreviousDay()}</Bold>
                {"  "}
              </Title>
            </Flex>
          </Flex>
          {eauctions
            .sort((a, b) =>
              a.created_at < b.created_at
                ? 1
                : b.created_at < a.created_at
                ? -1
                : 0
            )
            .map((eauction, i) => {
              const {
                id,
                link,
                unique_id,
                katastasi,
                imerominia_diexagogis,
                imerominia_anartisis,
                eidos,
                topos,
                timi,
                updated,
                last_checked,
                created_at,
                note,
                auction_dates,
              } = eauction;
              return (
                <EauctionCard
                  key={`eauction-${i}`}
                  singleEauction={{
                    i,
                    id,
                    link,
                    unique_id,
                    katastasi,
                    imerominia_diexagogis,
                    imerominia_anartisis,
                    eidos,
                    topos,
                    timi,
                    updated,
                    last_checked,
                    created_at,
                    note,
                    auction_dates,
                  }}
                  handleDeleteEauction={handleDeleteEauction}
                />
              );
            })}
        </>
      ) : (
        <p>Δεν έχετε καταχωρήσει ΑΦΜ προς έλεγχο</p>
      )}
    </article>
  );
};
export default MultiEauction;
