import {
  Card,
  Metric,
  Title,
  List,
  ListItem,
  Text,
  Subtitle,
} from "@tremor/react";

const MyKtima = () => {
  return (
    <Card maxWidth="max-w-2xl">
      <Metric>MyKtima</Metric>
      <Title>Links για την εφαρμογή</Title>

      <List>
        <ListItem>
          <Text>Windows</Text>
          <Text>
            <a
              href="https://ktima-img.s3.nl-ams.scw.cloud/myktima-0.0.4-setup.exe"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Windows',
                // })
              }}
            >
              myktima-0.0.4-setup.exe
            </a>
          </Text>
        </ListItem>
        {/* <ListItem>
          <Text>Windows Portable (δεν χρειάζεται εγκατάσταση)</Text>
          <Text>
            <a
              href="https://mysolon.gr/dist/My-Portal-0.1.1-portable.exe"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Windows_Portable',
                // })
              }}
            >
              My-Portal-0.1.1-portable.exe
            </a>
          </Text>
        </ListItem> */}
        <ListItem>
          <Text>Mac</Text>
          <Text>
            <a
              href="https://ktima-img.s3.nl-ams.scw.cloud/myktima-0.0.2.dmg"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Mac_Intel',
                // })
              }}
            >
              myktima-0.0.2.dmg
            </a>
          </Text>
        </ListItem>
        {/* <ListItem><Text>Mac (Apple Silicon)</Text><Text><a href="https://mysolon.gr/dist/my-portal-0.1.0-Apple-Silicon.dmg" onClick={() => {
          window.swetrix.track({
            ev: 'Mac_Apple_Silicon',
          })
        }}>my-portal-0.1.0-Apple-Silicon.dmg</a></Text></ListItem> */}
        <ListItem>
          <Text>Linux (Deb)</Text>
          <Text>
            <a
              href="https://ktima-img.s3.nl-ams.scw.cloud/myktima_0.0.4_amd64.deb"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Linux_Deb'
                // })
              }}
            >
              myktima_0.0.4_amd64.deb
            </a>
          </Text>
        </ListItem>
        {/* <ListItem>
          <Text>Linux (Snap)</Text>
          <Text>
            <a
              href="https://drive.google.com/file/d/1QAh5pMuIS2wND3A-KjmDNlaE32NS2pXD/view?usp=share_link"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Linux_Snap'
                // })
              }}
            >
              my-portal_0.1.0_amd64.snap
            </a>
          </Text>
        </ListItem> */}
        {/* <ListItem>
          <Text>Linux (Appimage)</Text>
          <Text>
            <a
              href="https://drive.google.com/file/d/1Wp4s4ini410i-qveCV7Vd_QxlMtIDDuU/view?usp=share_link"
              onClick={() => {
                // window.swetrix.track({
                //   ev: 'Linux_Appimage'
                // })
              }}
            >
              my-portal-0.1.0.AppImage
            </a>
          </Text>
        </ListItem> */}
      </List>
      <Subtitle>v0.0.4 - 30.01.2024</Subtitle>
      <Subtitle>- Διορθώθηκαν κάποια μικρά bugs</Subtitle>
      <Subtitle>v0.0.3 - 03.01.2024</Subtitle>
      <Subtitle>
        - Διορθώθηκε bug το οποίο δεν επέτρεπε την σύνδεση στο Portal Olomeleia
      </Subtitle>

      <Subtitle>v0.0.2 - 29.12.2023</Subtitle>
      <Subtitle>
        - Μόνο ένα instance της εφαργομής μπορεί να τρέχει ταυτόχρονα
      </Subtitle>
      <Subtitle>
        - Προστέθηκε η δυνατότητα λήψης του αποσπάσματος χωρικής βάσης
      </Subtitle>
      <Subtitle>
        - Προστέθηκε η προβολή του ΚΑΕΚ στον χάρτη του Κτηματολογίου
      </Subtitle>
    </Card>
  );
};

export default MyKtima;
