import React from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'

function EtairiesHeader() {
  let params = useParams();

  const location = useLocation()
  console.log(location);
  console.log(params)
  return (
    <div><div className='prothesmies-buttons'>
      {/* <Link className={location.pathname.includes('gemi') ? 'etairia-button active' : 'etairia-button'} to={`/etairies/${singleEtairia?.afm}/gemi`}>ΓΕΜΗ</Link> */}
      <NavLink className='prothesmies-button' to={`/etairies/${params.caseId}/gemi`}>ΓΕΜΗ</NavLink>
      <NavLink className='prothesmies-button' to={`/etairies/${params.caseId}/diavgeia`}>Διαύγεια</NavLink>
      <NavLink className='prothesmies-button' to={`/etairies/${params.caseId}/mitrwo`}>Πτωχεύσεις</NavLink>
      <NavLink className='prothesmies-button' to={`/etairies/${params.caseId}/eauction`}>Eauction</NavLink>

    </div></div>
  )
}

export default EtairiesHeader