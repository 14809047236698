import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { usePageVisibility } from "react-page-visibility";
import { CashIcon } from "@heroicons/react/solid";
import { createAvatar } from "@dicebear/core";
import { miniavs } from "@dicebear/collection";

// import { useLocation } from 'react-router-dom'
import { useContext, useEffect, useState, useRef, useMemo } from "react";
import AuthContext from "../context/AuthProvider";
import { useNavigate, useLocation, Link } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import axios from "axios";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useInterval } from "../utils/useInterval";
import FlipNumbers from "react-flip-numbers";
import Notifications from "./Notif/Notifications";
import HeaderAvatar from "./Visual/HeaderAvatar";

function removeNumberInParentheses(string) {
  const regex = /\(\d+\)/g;
  return string.replace(regex, "");
}

const Header = () => {
  const {
    auth,
    credits,
    setCredits,
    checking,
    setChecking,
    avatarUploaded,
    notifLength,
    setNotifLength,
  } = useContext(AuthContext);
  const isVisible = usePageVisibility();
  const [sticky, setSticky] = useState("");

  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [showNotif, setShowNotif] = useState(false);
  const [fetchingUpdates, setFetchingUpdates] = useState(false);

  const [updates, setUpdates] = useState([]);
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState();
  const refreshCont = useRef(0);

  const closeNotif = () => {
    setShowNotif(false);
  };

  // listen to pageshow event

  useEffect(() => {
    // console.log('useEffect', notifLength)
    if (navigator.setAppBadge) {
      navigator.setAppBadge(notifLength);
    }
  }, [notifLength]);

  useEffect(() => {
    window.addEventListener("touchstart", pullStart);
    window.addEventListener("touchmove", pull);
    window.addEventListener("touchend", endPull);
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });
  const initLoading = () => {
    refreshCont.current.classList.add("pull-loading");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };
  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
  };
  const pull = (e) => {
    /**
     * get the current user touch event data
     */
    const touch = e.targetTouches[0];
    /**
     * get the touch position on the screen's Y axis
     */
    const { screenY } = touch;
    /**
     * The length of the pull
     *
     * if the start touch position is lesser than the current touch position, calculate the difference, which gives the `pullLength`
     *
     * This tells us how much the user has pulled
     */
    let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
    setPullChange(pullLength);
    console.log({ screenY, startPoint, pullLength, pullChange });
  };
  const endPull = (e) => {
    setStartPoint(0);
    setPullChange(0);
    if (pullChange > 220) initLoading();
  };

  useEffect(() => {
    const hY = document.getElementById("user-header").offsetTop;
    localStorage.setItem("headerY", hY);
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);
  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */

    const stickyClass =
      window.scrollY >= localStorage.getItem("headerY") ? "is-sticky" : "";
    setSticky(stickyClass);
  };
  const classes = `user-header ${sticky}`;
  const user_classes = `user ${sticky}`;

  const getSeen = async () => {
    try {
      const response = await axiosPrivate.get("/updates/latest/seen");
      setNotifLength(0);
    } catch (err) {
      console.error(err);
    }
  };

  const handleShowNotif = async (e) => {
    setShowNotif(!showNotif);
    let isMounted = true;
    const controller = new AbortController();
    if (showNotif && notifLength !== 0) {
      try {
        const response = await axiosPrivate.get("/updates/latest", {
          signal: controller.signal,
        });
        isMounted && setUpdates(response.data.updates);
        setCredits(response?.data?.credits ?? 0);
        setChecking(response.data.checking);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
      isMounted = false;
      controller.abort();
    }
  };
  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    localStorage.clear();
    await logout();
    navigate("/");
  };
  const avatar = useMemo(() => {
    return createAvatar(miniavs, {
      size: 16,
      seed: auth?.uuid,
      backgroundType: ["gradientLinear", "solid"],
      backgroundColor: ["b6e3f4", "c0aede", "d1d4f9"],
      // ... other options
    }).toDataUriSync();
  }, [auth?.uuid]);
  useEffect(() => {
    if (location.pathname === "/" && auth?.user) {
      document.title = "MySolon.gr";
    }
    if (location.pathname === "/astika" && auth?.user) {
      document.title = "Αστικά | MySolon.gr";
    }
    if (location.pathname === "/out" && auth?.user) {
      document.title = "Αστικά(Εκτός Σόλων) | MySolon.gr";
    }
    if (location.pathname === "/adjustice" && auth?.user) {
      document.title = "Διοικητικά | MySolon.gr";
    }

    if (location.pathname === "/imerologio" && auth?.user) {
      document.title = "Ημερολόγιο | MySolon.gr";
    }
    if (location.pathname === "/eauction" && auth?.user) {
      document.title = "Eauction | MySolon.gr";
    }
    if (location.pathname === "/poinika" && auth?.user) {
      document.title = "Πινάκια Ποινικών | MySolon.gr";
    }
    if (location.pathname === "/etairies" && auth?.user) {
      document.title = "Εταιρίες | MySolon.gr";
    }
    if (location.pathname === "/updates" && auth?.user) {
      document.title = "Επισκόπηση | MySolon.gr";
    }
    if (location.pathname === "/calendar" && auth?.user) {
      document.title = "Ημερολόγιο | MySolon.gr";
    }
    if (location.pathname === "/prothesmies/neataktiki" && !auth?.user) {
      document.title = "Υπολογισμός Προθεσμιών Νέας Τακτικής | MySolon.gr";
    }
    if (location.pathname === "/prothesmies/mikrodiafores" && !auth?.user) {
      document.title = "Υπολογισμός Προθεσμιών Μικροδιαφορών | MySolon.gr";
    }
    if (location.pathname === "/prothesmies/diatpliromis" && !auth?.user) {
      document.title = "Υπολογισμός Προθεσμιών Διαταγών Πληρωμής | MySolon.gr";
    }
    if (location.pathname === "/prothesmies/apopoiisis" && !auth?.user) {
      document.title =
        "Υπολογισμός Προθεσμιών Αποποίησης Κληρονομίας | MySolon.gr";
    }
    if (notifLength !== 0 && document.title.indexOf(")") === -1) {
      document.title = `(${notifLength}) ${document.title}`;
    }
    if (notifLength === 0 && document.title.indexOf(")") !== -1) {
      // Remove () from title
      document.title = removeNumberInParentheses(document.title);
    }
  }, [location, updates, notifLength, showNotif, auth?.user]);
  const askForNotificationPermissionAndSubscribe = async () => {
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      console.log("Permission not granted for Notification");
      return;
    }

    const registration = await navigator.serviceWorker.register(
      "/service-worker.js",
      {
        scope: "./",
      }
    );

    // Waiting until the service worker is active.
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;

    // Subscribing the user (generating a subscription object)
    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BMWj-ENsRfCbM1m5vEc101nZbLXItODeLv1H-1q1KdyND6tNlouQcBpmh9sreMVLzmI9iXb5hOnXs_BLGSdUh84",
    });

    // Sending the subscription object to the backend
    await axiosPrivate.post("/subscription", JSON.stringify({ subscription }), {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    });

    // console.log('Subscribed successfully.');
  };

  useEffect(() => {
    if (auth?.user) {
      const askForNotificationPermissionAndSubscribe = async () => {
        const permission = await Notification.requestPermission();

        if (permission !== "granted") {
          console.log("Permission not granted for Notification");
          return;
        }

        const registration = await navigator.serviceWorker.register(
          "/service-worker.js",
          {
            scope: "./",
          }
        );

        // Waiting until the service worker is active.
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;

        // Subscribing the user (generating a subscription object)
        const subscription =
          await serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey:
              "BMWj-ENsRfCbM1m5vEc101nZbLXItODeLv1H-1q1KdyND6tNlouQcBpmh9sreMVLzmI9iXb5hOnXs_BLGSdUh84",
          });

        // Sending the subscription object to the backend
        await axiosPrivate.post(
          "/subscription",
          JSON.stringify({ subscription }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        // console.log('Subscribed successfully.');
      };

      askForNotificationPermissionAndSubscribe();
    }
  }, [auth]);

  useEffect(() => {
    if (auth?.user) {
      let isMounted = true;
      const controller = new AbortController();

      const getNotif = async () => {
        try {
          const response = await axiosPrivate.get("/updates/latest", {
            signal: controller.signal,
          });
          isMounted && setUpdates(response.data.updates);
          setCredits(response.data.credits);
          setChecking(response.data.checking);

          isMounted &&
            localStorage.setItem(
              "notif",
              response.data.updates.filter((r) => r.seen !== 1).length
            );
          setNotifLength(
            response.data.updates.filter((r) => r.seen !== 1).length
          );
        } catch (err) {
          console.error(err);
          if (!axios.isCancel(err)) {
            navigate("/login", { state: { from: location }, replace: true });
          }
        }
      };
      getNotif();

      return () => {
        isMounted = false;
        controller.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  function getNotif() {
    let isMounted = true;
    const controller = new AbortController();

    const getNotif = async () => {
      try {
        const response = await axiosPrivate.get("/updates/latest", {
          signal: controller.signal,
        });
        isMounted && setUpdates(response.data.updates);
        setCredits(response.data.credits);
        setChecking(response.data.checking);

        isMounted &&
          localStorage.setItem(
            "notif",
            response.data.updates.filter((r) => r.seen !== 1).length
          );
        setNotifLength(
          response.data.updates.filter((r) => r.seen !== 1).length
        );
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };
    getNotif();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }

  // Listen to pageshow event and get notif
  useEffect(() => {
    if (auth?.user) {
      window.addEventListener("focus", getNotif);
    }

    return () => {
      window.removeEventListener("focus", getNotif);
    };
  }, [auth?.user]);

  useInterval(
    async () => {
      let isMounted = true;
      const controller = new AbortController();

      const getNotif = async () => {
        try {
          setFetchingUpdates(true);

          const response = await axiosPrivate.get("/updates/latest", {
            signal: controller.signal,
          });
          isMounted && setUpdates(response.data.updates);
          setCredits(response.data.credits);
          setChecking(response.data.checking);
          isMounted &&
            localStorage.setItem(
              "notif",
              response.data.updates.filter((r) => r.seen !== 1).length
            );
          setNotifLength(
            response.data.updates.filter((r) => r.seen !== 1).length
          );
          setFetchingUpdates(false);

          // isMounted && setCasesFirst(response.data);
        } catch (err) {
          console.error(err);
          if (!axios.isCancel(err)) {
            // do something
            navigate("/login", { state: { from: location }, replace: true });
          }
        }
      };
      if (auth?.user) {
        if (!fetchingUpdates) {
          getNotif();
        }
      }

      return () => {
        isMounted = false;
        controller.abort();
      };
    },
    isVisible ? 8000 : 120000
  );
  const bell = useRef(null);
  // const location = useLocation()
  // const paths = ['/register', '/login', '/linkpage']
  return (
    <div ref={refreshCont}>
      <div
        className="refresh-container w-fit -mt-10 m-auto"
        style={{ marginTop: pullChange / 3.118 || "" }}
      >
        <div className="refresh-icon p-2 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6  bg-white   rounded-full"
            style={{ transform: `rotate(${pullChange}deg)` }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </div>
      </div>

      <header>
        <div className="head">
          <Link to="/">
            <p className="head-title">
              <span>MySolon</span>
            </p>
          </Link>
          <p className="head-title"></p>

          {auth?.user && (
            <div className="head-title" onClick={getSeen}>
              <FontAwesomeIcon
                ref={bell}
                title="Ενημερώσεις"
                onClick={handleShowNotif}
                icon={faBell}
              />
              <span className={notifLength === 0 ? "invisible" : "notif-count"}>
                {notifLength > 9 ? "+" : notifLength}
              </span>
              <div
                className={
                  showNotif
                    ? "notifications visible"
                    : "notifications invisible"
                }
              >
                <Notifications
                  updates={updates.filter(
                    (r) =>
                      r.court !== undefined ||
                      r.like_id !== undefined ||
                      r.comment_id !== undefined ||
                      r.periohi !== undefined ||
                      r.afm_eauction_id !== undefined ||
                      r.appeal_update !== undefined
                  )}
                  notifLength={notifLength}
                  handleShowNotif={handleShowNotif}
                  closeNotif={closeNotif}
                  bell={bell}
                />
              </div>
            </div>
          )}
        </div>

        <div className="head-navigation">
          {auth?.user && (
            <Link
              className={
                location.pathname === "/"
                  ? "button1-active big-screen"
                  : "button1 big-screen"
              }
              to="/"
            >
              Αρχική
            </Link>
          )}
          {auth?.user && (
            <Link
              className={
                location.pathname === "/astika"
                  ? " button1-active big-screen"
                  : "button1 big-screen"
              }
              to="/astika"
            >
              Αστικά
            </Link>
          )}
          {auth?.user && (
            <Link
              className={
                location.pathname === "/out"
                  ? " button1-active big-screen"
                  : "button1 big-screen"
              }
              to="/out"
            >
              Αστικά(Εκτός Σόλων)
            </Link>
          )}
          {auth?.user && (
            <Link
              className={
                location.pathname === "/adjustice"
                  ? "button1-active big-screen"
                  : "button1 big-screen"
              }
              to="/adjustice"
            >
              Διοικητικά
            </Link>
          )}

          {auth?.user && (
            <Link
              className={
                location.pathname === "/eauction"
                  ? "button1-active big-screen"
                  : "button1 big-screen"
              }
              to="/eauction"
            >
              Eauction
            </Link>
          )}

          {/* {auth?.user && <Link className={location.pathname === '/imerologio' ? ' button1-active big-screen' : 'button1 big-screen'} to="/imerologio">Ημερολόγιο</Link>} */}
          {auth?.user && (
            <Link
              className={
                location.pathname === "/calendar"
                  ? "button1-active big-screen"
                  : "button1 big-screen"
              }
              to="/calendar"
            >
              Ημερολόγιο
            </Link>
          )}

          {auth?.user && (
            <Link
              className={
                location.pathname === "/poinika"
                  ? "button1-active big-screen"
                  : "button1 big-screen"
              }
              to="/poinika"
            >
              Πιν.Ποινικών
            </Link>
          )}

          {/* {auth?.user && <Link className={location.pathname === '/imerologio' ? ' button1-active' : 'button1'} to="/imerologio">Ενημερώσεις</Link>} */}

          {/* {auth?.user && (location.pathname !== '/' ? <Link className="button1 big-screen" to="/">Υποθέσεις</Link> : <Link className="button1 big-screen" to="/imerologio">Ημερολόγιο</Link>)} */}
          {auth?.user && (
            <a onClick={signOut} href="/" className="button1">
              Αποσύνδεση
            </a>
          )}

          {/* <a
          className="links" href='*'
        >Οδηγίες</a
        >
        <a
          className="links" href='*'

        > Στατιστικά</a
        > */}
          {/* {auth?.user && <a onClick={signOut} href="/" class="button1">Απόσυνδεση</a>} */}
        </div>
        {/* <div className="flexGrow">

      </div> */}
      </header>

      <Link to="/">
        <img className="dot" src="/images/logo.jpeg" alt="logo" />
      </Link>

      <div className={classes} id="user-header">
        {auth?.user && (
          <section className={user_classes}>
            <Link to={`/profile/${auth?.uuid}`} className="header-user-line">
              <HeaderAvatar
                uuid={auth?.uuid}
                avatarUploaded={avatarUploaded}
                avatar={avatar}
                userAvatar={auth?.avatar}
                avatarClass={"sm"}
              />
              {auth?.user}
            </Link>
            <CashIcon height={"1rem"} />{" "}
            <FlipNumbers
              height={12}
              width={9}
              color="#469a7f"
              background="#f1f1f1"
              play
              perspective={100}
              numberStyle={{
                textIndent: "0px",
              }}
              numbers={credits >= 0 ? String(credits) : String(0)}
            />
          </section>
        )}
      </div>
      {auth?.user && (
        <div className="extra-menu">
          <Link
            className={location.pathname === "/" ? "button1-active" : "button1"}
            to="/"
          >
            Αρχική
          </Link>
          <Link
            className={
              location.pathname === "/astika" ? " button1-active" : "button1"
            }
            to="/astika"
          >
            Αστικά
          </Link>
          <Link
            className={
              location.pathname === "/adjustice" ? "button1-active" : "button1"
            }
            to="/adjustice"
          >
            Διοικητικά
          </Link>

          <Link
            className={
              location.pathname === "/eauction" ? "button1-active" : "button1"
            }
            to="/eauction"
          >
            Eauction
          </Link>
        </div>
      )}

      {auth?.user && (
        <div className="extra-menu">
          {/* <Link className={location.pathname === '/imerologio' ? ' button1-active' : 'button1'} to="/imerologio">Ημερολόγιο</Link> */}
          <Link
            className={
              location.pathname === "/out" ? " button1-active" : "button1"
            }
            to="/out"
          >
            Αστικά (Εκτός Σόλων)
          </Link>
          <Link
            className={
              location.pathname === "/calendar" ? "button1-active" : "button1"
            }
            to="/calendar"
          >
            Ημερολόγιο
          </Link>

          <Link
            className={
              location.pathname === "/poinika" ? "button1-active" : "button1"
            }
            to="/poinika"
          >
            Πιν.Ποινικών
          </Link>

          {/* <Link className={location.pathname === '/etairies' ? 'button1-active' : 'button1'} to="/etairies">Εταιρίες</Link>
      <Link className={location.pathname === '/' ? ' button1-active' : 'button1'} to="/">Υποθέσεις</Link>
      <Link className={location.pathname === '/imerologio' ? ' button1-active' : 'button1'} to="/imerologio">Ημερολόγιο</Link> */}
        </div>
      )}
    </div>
  );
};

export default Header;
