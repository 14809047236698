export const syllogoi = [
  "Αγρινίου",
  "Αθηνών",
  "Αιγίου",
  "Αλεξανδρουπόλεως",
  "Αμαλιάδας",
  "Άμφισσας",
  "Άρτας",
  "Βέροιας",
  "Βόλου",
  "Γιαννιτσών",
  "Γρεβενών",
  "Γυθείου",
  "Δράμας",
  "Εδέσσης",
  "Ευρυτανίας",
  "Ζάκυνθος",
  "Ηλείας",
  "Ηρακλείου",
  "Θεσπρωτίας",
  "Θεσσαλονίκης",
  "Θηβών",
  "Ιωαννίνων",
  "Καβάλας",
  "Καλαβρύτων",
  "Καλαμάτας",
  "Καρδίτσας",
  "Καστοριάς",
  "Κατερίνης",
  "Κέρκυρας",
  "Κεφαλληνίας",
  "Κιλκίς",
  "Κοζάνης",
  "Κορίνθου",
  "Κυπαρισσίας",
  "Κω",
  "Λαμίας",
  "Λαρίσης",
  "Λασιθίου",
  "Λευκάδας",
  "Λιβαδειάς",
  "Μεσολογγίου",
  "Μυτιλήνης",
  "Νάξου",
  "Ναυπλίου",
  "Ξάνθης",
  "Ορεστιάδας",
  "Πατρών",
  "Πειραιώς",
  "Πρεβέζης",
  "Ρεθύμνης",
  "Ροδόπης",
  "Ρόδου",
  "Σάμου",
  "Σερρών",
  "Σπάρτης",
  "Σύρου",
  "Τρικάλων",
  "Τριπόλεως",
  "Φλωρίνης",
  "Χαλκίδας",
  "Χαλκιδικής",
  "Χανίων",
  "Χίου",
]