import { useState, useEffect } from "react";
import MultiUpdates from './MultiUpdates';
import MultiDikasimoi from './MultiDikasimoi';
import DashboardTop from './DashboardTop';
import DashboardJTop from './DashboardJTop';


import { useInterval } from "../../utils/useInterval";

import axios from 'axios'
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";



const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [cases, setCases] = useState([]);
  const [jCases, setJCases] = useState([]);
  const [dikasimoi, setDikasimoi] = useState([]);


  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  // const [state, updateState] = useState();
  const [search, setSearch] = useState();

  // const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getJCases = async () => {
      try {
        const response = await axiosPrivate.get('/adjustice', {
          signal: controller.signal
        });
        isMounted && setJCases(response.data);

      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate('/login', { state: { from: location }, replace: true });

        }
      }
    }

    getJCases();

    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const cases = JSON.parse(localStorage.getItem('cases'));
    if (cases) {
      setCases(cases);
    }
    let isMounted = true;
    const controller = new AbortController();

    const getUpdates = async () => {
      try {
        const response = await axiosPrivate.get("/updates", {
          signal: controller.signal,
        });
        isMounted && setUpdates(response.data);
        // isMounted && setCasesFirst(response.data);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getUpdates();
    // const interval = setInterval(() => {
    //   getCases()
    // }, 10000)
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(updates);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getDikasimoi = async () => {
      try {
        const response = await axiosPrivate.get("/updates/dikasimoi", {
          signal: controller.signal,
        });
        isMounted && setDikasimoi(response.data);
        // isMounted && setCasesFirst(response.data);
      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate("/login", { state: { from: location }, replace: true });
        }
      }
    };

    getDikasimoi();
    // const interval = setInterval(() => {
    //   getCases()
    // }, 10000)
    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);










  return (

    <section className="dashboard" >
      <section className="dashboard-top">
        <DashboardTop cases={cases} />
        <DashboardJTop cases={jCases} />

      </section>
      <section className="dashboard-middle">

        <MultiUpdates updates={updates} />

        <MultiDikasimoi dikasimoi={dikasimoi} /></section>


    </section >

  )
}

export default Updates