import React from "react";
import {
  Bold,
  Callout,
  CategoryBar,
  Card,
  Flex,
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Title,
  BarList,
  Button,
  Grid,
} from "@tremor/react";
import { Link } from "react-router-dom";
import { truncateCourt } from "../../../utils/truncateCourt";
const DikasimosListItem = ({ singleCase }) => {
  return (
    <ListItem>
      <div>
        <Link to={`/cases/${singleCase.id}`}>
          {" "}
          <Text>
            <Bold>
              {singleCase.gak}/{singleCase.year}
            </Bold>
          </Text>
          <Text truncate={true}>{truncateCourt(singleCase.court)}</Text>
          {singleCase.note && <Text truncate={true}>{singleCase.note}</Text>}
        </Link>
      </div>
      <div>
        <Text textAlignment="text-right">
          <Bold>{singleCase.date}</Bold>
        </Text>
      </div>
    </ListItem>
  );
};

export default DikasimosListItem;
