import {
  Bold,
  Callout,
  CategoryBar,
  Card,
  Flex,
  Icon,
  List,
  ListItem,
  Metric,
  ProgressBar,
  Text,
  Title,
  BarList,
  Button,
  Grid,
} from "@tremor/react";

import { useLocation } from "react-router-dom";
import {
  extractLastDate,
  formatDate,
  isDateAfterToday,
} from "../../../utils/extractLastDateForDikasimos";
import { truncateCourt } from "../../../utils/truncateCourt";

import DashboardListItem from "./DikasimosListItem";

export default function Example({ cases }) {
  const casesWithDikasimo = cases
    .filter((item) => item?.arithmos_pinakiou?.includes("/"))
    .map((item) => {
      const date = extractLastDate(item?.arithmos_pinakiou);
      return { ...item, date };
    })
    .filter((item) => isDateAfterToday(item.date))
    .sort((a, b) => {
      const dateA = formatDate(a.date);
      const dateB = formatDate(b.date);
      return dateA - dateB;
    });

  return (
    <>
      {casesWithDikasimo.length > 0 && (
        <Card>
          <Text>
            <Bold>Μελλοντικές Δικάσιμοι - {casesWithDikasimo.length}</Bold>
          </Text>
          <List className="mt-4">
            {casesWithDikasimo.map((singleCase, i) => (
              <DashboardListItem
                key={`${i}-dikasimos`}
                singleCase={singleCase}
              />
            ))}
          </List>
        </Card>
      )}
    </>
  );
}
