import React from "react";
import { efeteia as efeteiaUnfilt } from "../Efeteia";
import { XCircleIcon } from "@heroicons/react/solid";
import {
  MultiSelect,
  MultiSelectItem,
  Icon,
  List,
  ListItem,
} from "@tremor/react";
import { removeDiacriticsAndCapitalize } from "../../../utils/checkMisthosi";

const ChooseEfeteia = ({ efeteiaSelected, setEfeteiaSelected }) => {
  const efeteia = efeteiaUnfilt
    .map((dim) => removeDiacriticsAndCapitalize(dim))
    .sort();
  const handleDeleteListItem = (e) => {
    const value =
      e.target.parentNode.parentNode.parentNode.firstChild.textContent;
    const newEfeteiaSelected = efeteiaSelected.filter((dim) => dim !== value);
    setEfeteiaSelected(newEfeteiaSelected);
  };

  return (
    <div className="form-group">
      <label htmlFor="efeteia">Εφετεία:</label>
      <MultiSelect
        placeholder="Επιλέξτε Εφετείο"
        onValueChange={setEfeteiaSelected}
        value={efeteiaSelected}
      >
        {efeteia.map((dim, i) => {
          return (
            <MultiSelectItem key={`${i}-${dim}`} value={dim}>
              {dim}
            </MultiSelectItem>
          );
        })}
      </MultiSelect>
      <List>
        {efeteiaSelected?.map((dim, i) => {
          return (
            <ListItem className="dimoi-list" key={`${i}-${dim}`}>
              <span>{dim}</span>
              <span>
                <Icon
                  onClick={handleDeleteListItem}
                  className={"cursor-pointer"}
                  icon={XCircleIcon}
                  size={"sm"}
                  color="red"
                  tooltip="Διαγραφή"
                ></Icon>
              </span>
              <ListItem />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default ChooseEfeteia;
