import { useNavigate } from "react-router-dom"

const Unauthorized = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <section className="container">

      <h1>Δεν έχει ολοκληρωθεί η διαδικασία αποδοχής σας.</h1>
      <br />
      <p>Θα λάβετε ειδοποίηση σύντομα στο email σας που θα σας ενημερώνει για την ολοκλήρωση της εγγραφής σας!</p>
    </section>
  )
}

export default Unauthorized