export function getCurrentDateTimeString() {
  const date = new Date();

  // Use the Date object's methods to get the year, month, day, hours, minutes, and seconds
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // The getUTCMonth() method returns a 0-based value, so we need to add 1 to getUTC the correct month number
  const day = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Pad the values with leading zeroes if they are less than 10, so they always have two digits
  const paddedMonth = month < 10 ? `0${month}` : month;
  const paddedDay = day < 10 ? `0${day}` : day;
  const paddedHours = hours < 10 ? `0${hours}` : hours;
  const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  // Return the date and time as a string in the desired format
  return `${year}-${paddedMonth}-${paddedDay} ${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}
