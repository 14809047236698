import { useState, useEffect } from "react";
import Cases from './ExsCases';
import AddCase from './AddExsCase';
import AddCaseModal from './AddExsCaseModal';
import { useInterval } from "../../utils/useInterval";

import axios from 'axios'
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getAdjCourt } from "../../utils/getAdjCourts";

function fixCourt(court) {
  if (court === 'protpatras') {
    return 'Πρωτ. Πάτρας'
  }
  if (court === 'eirpatras') {
    return 'Ειρ. Πάτρας'
  }
  if (court === 'protkos') {
    return 'Πρωτ. Κω'
  }
  if (court === 'eirnikaias') {
    return 'Ειρ. Νικαίας'
  }
  if (court === 'protlarisas') {
    return 'Πρωτ. Λάρισας'
  } else {
    return court
  }
}
const Exsolon = () => {
  const [cases, setCases] = useState([]);
  const [casesFirst, setCasesFirst] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useState();
  const [newAdded, setNewAdded] = useState(false);

  const [notif, setNotif] = useState(true)


  useInterval(async () => {
    setNotif(JSON.parse(localStorage.getItem("notif") === '0' ? false : true))
  }, 2000)

  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();

    const getCases = async () => {
      try {
        const response = await axiosPrivate.get('/exscases', {
          signal: controller.signal
        });
        isMounted && setCases(response.data);
        setCasesFirst(response.data);
        isMounted && setNotif(false)
        isMounted && localStorage.setItem("notif", '0');


      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate('/login', { state: { from: location }, replace: true });

        }
      }
    }

    notif && getCases();
    newAdded && setTimeout(function () {
      getCases()
    }, 2000);
    setNewAdded(false)


    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notif, newAdded])
  useEffect(() => {

    let isMounted = true;
    const controller = new AbortController();

    const getCases = async () => {
      try {
        const response = await axiosPrivate.get('/exscases', {
          signal: controller.signal
        });
        isMounted && setCases(response.data);
        setCasesFirst(response.data);



      } catch (err) {
        console.error(err);
        if (!axios.isCancel(err)) {
          // do something
          navigate('/login', { state: { from: location }, replace: true });

        }
      }
    }

    setTimeout(function () {
      getCases()
      console.log('hi');
    }, 2000);


    return () => {
      isMounted = false;
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAdded])

  useEffect(() => {
    if (search) {
      setCases(casesFirst.filter(r => fixCourt(r.court).toUpperCase().includes(search.toUpperCase()) || r.note.toUpperCase().includes(search.toUpperCase())))
    } else { setCases(casesFirst) }

  }, [search])

  casesFirst.forEach(r => {
    r?.data && console.log(JSON.parse(r?.data));
  })


  console.log(cases);

  const [showModal, setShowModal] = useState(true)
  const handleClickAddCaseModal = () => { setShowModal(!showModal) }
  const handleAddCase = (singleCase) => {
    setCases([singleCase, ...cases,])
    setNewAdded(true)
  }
  const handleDeleteCase = (singleCase) => {
    setCases(cases.filter(e => e.id.toString() !== singleCase))
  }

  // useInterval(async () => {
  //   let isMounted = true;
  //   const controller = new AbortController();

  //   const getCases = async () => {
  //     try {
  //       const response = await axiosPrivate.get('/adjustice', {
  //         signal: controller.signal
  //       });
  //       isMounted && setCases(response.data);

  //     } catch (err) {
  //       console.error(err);
  //       if (!axios.isCancel(err)) {
  //         // do something
  //         navigate('/login', { state: { from: location }, replace: true });

  //       }
  //     }
  //   }

  //   getCases();

  //   return () => {
  //     isMounted = false;
  //     controller.abort();
  //   }
  // }, 30000)




  return (

    <section>
      <Link to={`/`} className='addcase_modal analytika' >Πίσω στα δικαστήρια του solon.gov.gr</Link>

      <section className="container_search" >
        {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}

        {/* <div className="close_addcase"><p onClick={() => handleClickAddCaseModal()}>❌</p></div> */}
        {/* <span></span> */}
        {/* <h1>Sign In</h1> */}
        <form className="form_search" >
          {/* <label htmlFor="username">Email:</label> */}
          <div className="input-container ic1">
            <input
              className='input'
              placeholder=' '
              type="text"
              id="Αναζήτηση"
              autoComplete="on"
              onChange={(e) => setSearch(e.target.value)}
              value={search || ''}
              required
            />
            <div className="cut"></div>
            <label htmlFor="Αναζήτηση" className="placeholder">🔎 Αναζήτηση (πχ. Νικαίας)</label></div>

          {/* <label htmlFor="password">Password:</label> */}



        </form>

      </section>

      {showModal ? <AddCaseModal handleClickAddCaseModal={handleClickAddCaseModal} /> : <AddCase handleClickAddCaseModal={handleClickAddCaseModal} cases={cases} handleAddCase={handleAddCase} />}
      <Cases cases={cases} handleDeleteCase={handleDeleteCase} />


    </section>
  )
}

export default Exsolon