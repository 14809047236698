import { useState, useEffect, useCallback } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import VOutSolonCard from "../Cards/VOutSolonCard";
import ScrollToTop from "../ScrollToTop";

const OutViewCase = () => {
  const [singleCase, setSingleCase] = useState();
  let params = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const [singleUpdates, setSingleUpdates] = useState();

  const [state, updateState] = useState();
  const [loading, setLoading] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  useEffect(() => {
    let title = "";
    if (singleCase?.note !== undefined && singleCase?.note?.trim() !== "") {
      title = title + singleCase.note + " | ";
    }
    title =
      title +
      singleCase?.dikastirio +
      " " +
      singleCase?.topos +
      " | MySolon.gr";

    if (!singleCase) {
      title = "Δεν βρέθηκε η υπόθεση | MySolon.gr";
    }
    document.title = title;
  }, [location, singleCase]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getCase = async () => {
      try {
        setLoading(true);
        const response = await axiosPrivate.get(`/outsolon/${params.caseId}`, {
          signal: controller.signal,
        });

        isMounted && setSingleCase(response.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        navigate("/login", { state: { from: location }, replace: true });
      }
    };

    getCase();

    return () => {
      isMounted = false;
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  async function saveCase() {
    try {
      const response = await axiosPrivate.post(
        `/outsolon/${singleCase.id}`,
        JSON.stringify(singleCase)
      );
    } catch (err) {
      console.error(err);
    }
  }

  if (
    singleCase && // 👈 null and undefined check
    Object.keys(singleCase).length !== 0 &&
    Object.getPrototypeOf(singleCase) === Object.prototype
  ) {
  }
  function modifySingleCase({ name, value }) {
    if (name !== "note" && name !== "diadikasia") {
      const userInput = { ...singleCase.userInput, [name]: value };
      setSingleCase((prev) => ({ ...prev, userInput }));
    }
    if (name === "antikeimeno") {
      const extra_info = JSON.stringify({
        ...JSON.parse(singleCase.extra_info),
        [name]: value,
      });
      setSingleCase((prev) => ({ ...prev, extra_info }));
    }
    if (name == "note" || name == "diadikasia" || name == "katathesi") {
      setSingleCase((prev) => ({ ...prev, [name]: value }));
    } else {
      const extra_info = JSON.stringify({
        ...JSON.parse(singleCase.extra_info),
        [name]: value,
      });
      setSingleCase((prev) => ({ ...prev, extra_info }));
    }
  }

  return loading ? (
    <h2>Ανακτώνται τα δεδομένα... Παρακαλώ περιμένετε...</h2>
  ) : singleCase ? (
    <div>
      <ScrollToTop />
      <VOutSolonCard
        singleCase={singleCase}
        singleUpdates={singleUpdates}
        modifySingleCase={modifySingleCase}
        saveCase={saveCase}
      />
    </div>
  ) : (
    <h2>Δεν βρέθηκε η υπόθεση.</h2>
  );
};

export default OutViewCase;
