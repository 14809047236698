import React from 'react'

const AddCaseEirNikaias = ({ eidos, arkat, handleChangeDiad, setArkat, year, setYear }) => {

  return (
    <>
      <div className="input-container ic2">
        <select value={eidos} onChange={handleChangeDiad} className='input'>
          <option key={'epanaprosd'} value={'epanaprosd'}>Επαναπροσδιορισμοί</option>
          <option key={'dimosiefseis'} value={'dimosiefseis'}>Αγωγές</option>
          <option key={'diatages'} value={'diatages'}>Διαταγές</option>

        </select>
        <div className="cut"></div>
        <label htmlFor="eidos" className="placeholder">Διαδικασία</label>
      </div>
      {eidos === 'epanaprosd' && <div className="input-container ic2">
        <input
          className='input'
          placeholder=' '
          type="text"
          id="arkat"
          autoComplete="on"
          onChange={(e) => setArkat(e.target.value)}
          value={arkat}
          required
        />
        <div className="cut"></div>
        <label htmlFor="arkat" className="placeholder">Κωδικός Πλατφόρμας</label></div>}
      {['dimosiefseis', 'diatages'].includes(eidos) && <><div className="input-container ic2">
        <input
          className='input'
          placeholder=' '
          type="text"
          id="arkat"
          autoComplete="on"
          onChange={(e) => setArkat(e.target.value)}
          value={arkat}
          required
        />
        <div className="cut"></div>
        <label htmlFor="arkat" className="placeholder">Αρ.Κατάθεσης</label></div>
        <div className="input-container ic2">
          <input
            className='input'
            placeholder=' '
            type="number"
            id="year"
            autoComplete="on"
            onChange={(e) => setYear(e.target.value)}
            value={year}
            required
          />
          <div className="cut"></div>
          <label htmlFor="arkat" className="placeholder">Έτος</label></div></>}
    </>
  )

}

export default AddCaseEirNikaias